import { styled, typography } from '@pulse-web-ui/theme';

export const Label = styled.div``;

export const BaseValue = styled.span`
  ${typography.regular16}
  line-height: 24px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: inline;
  margin-right: 10px;
`;

export const Value = styled.span<{ isPromo?: boolean }>`
  ${typography.medium32}
  color: ${({ theme, isPromo }) =>
    isPromo ? theme.colors.text.interactive : theme.colors.text.primary};
  text-decoration: initial;
  display: inline;
`;

export const ValueDesc = styled.div`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.primary};
`;
