import { AuthEndpoints, SubmitEndpoints } from '@src/constants/request-data';

export const checkIfShouldSendPlatformType = (url: string) => {
  const endpointsWithPlatformType: Record<string, string> = {
    ...AuthEndpoints,
    ...SubmitEndpoints,
  };
  let isMatch = false;

  for (const key in endpointsWithPlatformType) {
    if (endpointsWithPlatformType[key] === url) {
      isMatch = true;
      break;
    }
  }

  return isMatch;
};
