import { formatInTimeZone, toDate, utcToZonedTime } from 'date-fns-tz';

import { getValidTimeZone } from './get-valid-timezone';

// TODO: Нужен ресерч на предмет объединения createDateWithTimezone, convertDateByTimeZone, convertDateFormatInTimeZone
export const convertDateByTimeZone = (date: Date | null): Date | null => {
  if (date instanceof Date) {
    return utcToZonedTime(date, getValidTimeZone(date));
  }

  return date;
};

// TODO: Нужен ресерч на предмет объединения createDateWithTimezone, convertDateByTimeZone, convertDateFormatInTimeZone
export const convertDateFormatInTimeZone = (date: Date): Date => {
  const formattedDate = formatInTimeZone(
    date,
    getValidTimeZone(date),
    'yyyy-MM-dd HH:mm:ss zzz'
  );

  return toDate(formattedDate);
};
