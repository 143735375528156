import i18n from '@src/i18n/config';
import { SelectedDuration } from '@src/types';

const periodCardDescription = {
  P1M: {
    description: i18n.t('COMMON:description.flexibleOption'),
    details: i18n.t('COMMON:description.flexibleOptionDetails'),
  },
  P3M: {
    description: i18n.t('COMMON:description.middleOption'),
    details: i18n.t('COMMON:description.middleOptionDetails'),
  },
  P1Y: {
    description: i18n.t('COMMON:description.profitableOption'),
    details: i18n.t('COMMON:description.profitableOptionDetails'),
  },
};

export const getPaymentPeriodCardDescription = (duration: SelectedDuration) => {
  return periodCardDescription[duration];
};
