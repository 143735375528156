import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FeatureFlags } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';
import { getDaysDuration } from '@src/utils';

interface Props {
  featureFlag: FeatureFlags;
  minDate: Date;
  maxDate: Date;
}

export const PaymentPeriodHint = ({ featureFlag, minDate, maxDate }: Props) => {
  const { t } = useTranslation();
  const {
    res: [isFeatureSubscriptionType],
    isFeatureFlagsLoading,
  } = useFeatureFlags([featureFlag]);

  if (isFeatureFlagsLoading) {
    return null;
  }

  return (
    <>
      {isFeatureSubscriptionType && (
        <Trans
          values={{
            daysCount: getDaysDuration(minDate, maxDate),
          }}
        >
          {t('COMMON:hints.chooseAnyDateFromTheNextDays')}
        </Trans>
      )}
    </>
  );
};
