import { media, styled, typography } from '@pulse-web-ui/theme';

export const StyledContainer = styled.div`
  background: ${({ theme }) => theme?.colors?.surface?.primary};
`;

export const StyledLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.medium16}
  line-height: 24px;
  padding-bottom: 16px;

  ${media.desktop} {
    ${typography.medium18}
  }
`;

export const StyledBox = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  min-height: 24px;
`;

export const StyledPrice = styled.div`
  margin-left: auto;
  text-align: right;
  min-width: 100px;
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.regular12}
  line-height: 18px;
  ${media.desktop} {
    ${typography.regular14}
    line-height: 20px;
  }
`;

export const StyledBaseBox = styled.div`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }

  ${media.desktop} {
    padding-bottom: 24px;
  }
`;

export const StyledOption = styled.div`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors?.neutral['10']};
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme?.colors?.neutral['0']};
  }
`;

export const StyledCheckIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTooltip = styled.div`
  margin-left: 6px;

  & > div > span {
    display: flex;
    align-items: center;
  }

  ${media.desktop} {
    margin-left: 8px;
  }
`;

export const StyledOptionLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.medium14}
  line-height: 20px;
  background: ${({ theme }) => theme?.colors?.surface?.primary};
  padding-bottom: 8px;

  ${media.desktop} {
    ${typography.medium16}
    line-height: 24px;
  }
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.primary};
  ${typography.regular12}
  line-height: 18px;
  padding: 0 4px;

  ${media.desktop} {
    ${typography.regular14}
    line-height: 20px;
  }
`;
