/* eslint-disable indent */
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { componentsConfigs } from '@src/components-configs';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product } from '@src/constants';
import { FIRST_STEP_FOR_SETTINGS } from '@src/constants/preset';
import { FormIFLGetPricesFull } from '@src/constants/request-data';
import { StepWizardForm } from '@src/features';
import { useCloneStore, useComponentsConfig } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import {
  IFLFlatActionTypes,
  IFLFlatStoreState,
  IFLHouseActionTypes,
  IFLHouseStoreState,
  PetsActionTypes,
  PetsStoreState,
  Store,
} from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { ComponentsConfigType } from '@src/types';

export const PresetSetting: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateWizard: { currentStep, lastStep },
      stateFormPets: { sublimits, presetData },
      stateFormIFLHouse: {
        presetData: iflHousePresetData,
        insuranceSubproducts: iflHouseInsuranceSubproducts,
      },
      stateFormIFLFlat: {
        presetData: iflFlatPresetData,
        insuranceSubproducts: iflFlatInsuranceSubproducts,
      },
    },
    dispatch,
  } = useContext(Store);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // TODO: выпилить чтение значения из локалстора, переписать переход в функцию только после установки значения в стор
  const selectedProduct = localStorage.getItem('selectedProduct') as Product;
  const clonedStore = useCloneStore(selectedProduct);
  const { components, analyticEvent, handleAnalytic } = useComponentsConfig(
    selectedProduct,
    ComponentsConfigType.SETTINGS
  );
  const maxStep = components?.length || 0;
  const step =
    componentsConfigs?.[selectedProduct]?.[ComponentsConfigType.MAIN]
      ?.components?.length;

  const goBack = () => {
    if (currentStep === FIRST_STEP_FOR_SETTINGS) {
      switch (selectedProduct) {
        case Product.PETS:
          dispatch({
            type: PetsActionTypes.UpdateState,
            payload: clonedStore as PetsStoreState,
          });
          break;
        case Product.HOUSE:
          dispatch({
            type: IFLHouseActionTypes.UpdateState,
            payload: clonedStore as IFLHouseStoreState,
          });
          break;
        case Product.APARTMENT:
          dispatch({
            type: IFLFlatActionTypes.UpdateState,
            payload: clonedStore as IFLFlatStoreState,
          });
          break;
      }

      if (analyticEvent) {
        sendAnalyticEvent(analyticEvent);
      }
    }

    if (step) {
      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: step,
      });
    }

    queryClient.resetQueries(FormIFLGetPricesFull);

    navigate(-1);
  };

  const handleNextStep = async () => {
    if (currentStep === maxStep && analyticEvent) {
      sendAnalyticEvent(analyticEvent);
    }
    switch (selectedProduct) {
      case Product.PETS:
        dispatch({
          type: PetsActionTypes.SetPresetData,
          payload: {
            ...presetData,
            risks:
              sublimits?.risks.map(({ code, defaultInsuranceSum }) => ({
                code,
                defaultInsuranceSum,
              })) || [],
          },
        });
        break;
      case Product.HOUSE:
        dispatch({
          type: IFLHouseActionTypes.SetPresetData,
          payload: {
            ...iflHousePresetData,
            buildingMaterial: iflHousePresetData?.buildingMaterial || '',
            risks: iflHousePresetData?.risks || [],
            subObjects:
              iflHouseInsuranceSubproducts?.subObjects?.map(
                ({ code, defaultInsuranceSum }) => ({
                  code,
                  defaultInsuranceSum,
                })
              ) || [],
          },
        });
        break;
      case Product.APARTMENT:
        dispatch({
          type: IFLFlatActionTypes.SetPresetData,
          payload: {
            ...iflFlatPresetData,
            risks: iflFlatPresetData?.risks || [],
            subObjects:
              iflFlatInsuranceSubproducts?.subObjects?.map(
                ({ code, defaultInsuranceSum }) => ({
                  code,
                  defaultInsuranceSum,
                })
              ) || [],
          },
        });
        break;
    }

    if (step) {
      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: step,
      });
    }

    navigate(-1);
  };

  useEffect(() => {
    if (handleAnalytic instanceof Function) {
      handleAnalytic(currentStep);
    }
  }, [currentStep]);

  const onNextStep = currentStep === maxStep ? handleNextStep : undefined;
  const nextButtonlabel =
    currentStep === maxStep ? t('COMMON:success.apply') : '';
  const prevButtonLabel =
    currentStep === FIRST_STEP_FOR_SETTINGS
      ? t('COMMON:buttons.backToPayment')
      : '';

  return (
    <BaseLayout>
      <StepWizardForm
        maxStep={maxStep}
        goBack={goBack}
        nextButtonLabel={nextButtonlabel}
        prevButtonLabel={prevButtonLabel}
        onNextStep={onNextStep}
        isSettingPage
      >
        {components?.map((Component, index) => {
          if (currentStep === index + 1) {
            return (
              <React.Fragment key={index}>
                <Component />
              </React.Fragment>
            );
          }
          return null;
        })}
      </StepWizardForm>
    </BaseLayout>
  );
};
