/* eslint-disable indent */
import { StoreActions } from '@src/store';

import { PetsActionTypes, PetsStoreState } from './index';

export type PetsStoreReducer = (
  state: PetsStoreState,
  action: StoreActions
) => PetsStoreState;

export const formPetsStoreReducer: PetsStoreReducer = (state, action) => {
  switch (action?.type) {
    case PetsActionTypes.SetSelectedIProduct:
      return {
        ...state,
        selectedIProduct: action.payload,
      };
    case PetsActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };
    case PetsActionTypes.SetRisksPage:
      return {
        ...state,
        risksPage: action.payload,
      };
    case PetsActionTypes.SetGetPrices:
      return {
        ...state,
        getPrices: action.payload,
      };
    case PetsActionTypes.SetGetPricesFull:
      return {
        ...state,
        getPricesFull: action.payload,
      };
    case PetsActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case PetsActionTypes.SetSelectedDate:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case PetsActionTypes.SetInsuranceSum:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case PetsActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    case PetsActionTypes.SetPromoCodeApplyed:
      return {
        ...state,
        promoCodeApplyed: action.payload,
      };
    case PetsActionTypes.SetPets:
      return {
        ...state,
        pets: action.payload,
      };
    case PetsActionTypes.SetSelectedPetData:
      return {
        ...state,
        selectedPetData: action.payload,
      };
    case PetsActionTypes.SetSublimits:
      return {
        ...state,
        sublimits: action.payload,
      };
    case PetsActionTypes.SetCurrentSubLimits:
      return {
        ...state,
        currentSubLimits: action.payload,
      };
    case PetsActionTypes.SetSelectedPetKindName:
      return {
        ...state,
        selectedPetKindName: action.payload,
      };
    case PetsActionTypes.SetCurrentSum:
      return {
        ...state,
        currentSum: action.payload,
      };
    case PetsActionTypes.SetCurrentSumMinLimit:
      return {
        ...state,
        currentSumMinLimit: action.payload,
      };
    case PetsActionTypes.SetCurrentSumMaxLimit:
      return {
        ...state,
        currentSumMaxLimit: action.payload,
      };
    case PetsActionTypes.SetCurrentSumErr:
      return {
        ...state,
        currentSumErr: action.payload,
      };
    case PetsActionTypes.SetSelectedId:
      return {
        ...state,
        selectedId: action.payload,
      };
    case PetsActionTypes.SetPresetData:
      return {
        ...state,
        presetData: action.payload,
      };
    case PetsActionTypes.UpdateState:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
