/* eslint-disable indent */
import { addYears, subDays } from 'date-fns';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FRACTION_TWO, UnicodeEnum } from '@src/constants';
import { MitePolicyChoose } from '@src/constants/mite-choose';
import { Store } from '@src/store';
import { OrderItemValueType, RiskElementsType, RiskType } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  numFormat,
  numWord,
} from '@src/utils';
import { fullNewOrderDate } from '@src/utils/full-new-order-date';

import {
  OrderItemList,
  OrderItemSubValue,
  OrderItemValue,
  OrderItemWrapper,
  SubText,
  SubTextLighter,
} from '../../order/components/components.styles';

const newDateWithoutYear = (date: Date) =>
  date.toLocaleDateString('ru-RU', {
    month: 'long',
    day: 'numeric',
  });

const getStartDate = (
  selectedStartDate: Date,
  selectedEndDate: Date
): string => {
  if (selectedStartDate.getFullYear() !== selectedEndDate.getFullYear()) {
    return fullNewOrderDate(selectedStartDate).replace('●', '');
  }

  return newDateWithoutYear(selectedStartDate);
};

export const useMiteOrderDataFormatting = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateMite: {
        choosedPolicy,
        insuranceProductPolicy,
        insurancePrice,
        promoCode,
        numberInsuredPersons,
        insuredPersons,
        startDate,
      },
      stateAuth: { displayPhoneNumber },
      stateUser: { promoCodeFailedMessage },
    },
  } = useContext(Store);
  const program = insuranceProductPolicy?.antimitePrograms?.find(
    (program) => program.insuranceProgram === choosedPolicy
  );

  const label = `${currencyRuLocaleWithoutFraction(
    Number(insurancePrice?.premiumAndDeltaPromo || program?.basePrice),
    FRACTION_TWO
  )} ${
    insurancePrice?.premiumAndDeltaPromo
      ? t('COMMON:labels.priceInFirstYear')
      : t('COMMON:labels.pricePerYear')
  }`;

  let subLabel = '';

  if (insurancePrice?.isSuccessfulPromo && program?.basePrice) {
    subLabel = `${currencyRuLocaleWithoutFraction(
      Number(program.basePrice),
      FRACTION_TWO
    )}${UnicodeEnum.NBSP}₽ ${t('COMMON:hints.fromSecondYear')}`;
  }

  const riskElements = program?.risks.reduce(
    (arr: Array<RiskElementsType>, risk: RiskType) => {
      if (risk?.riskElements) {
        return [...arr, ...risk.riskElements];
      }
      return arr;
    },
    []
  );

  const countRisks = riskElements?.length || 0;

  const orderInsureComponent = (
    <OrderItemWrapper>
      {insuredPersons.map(({ firstName, lastName, middleName, birthDate }) => (
        <div key={`${firstName}${lastName}${middleName}${birthDate}`}>
          <OrderItemValue>
            {lastName} {firstName} {middleName}
          </OrderItemValue>
          {birthDate && (
            <OrderItemSubValue>
              {new Date(birthDate).toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
            </OrderItemSubValue>
          )}
        </div>
      ))}
    </OrderItemWrapper>
  );

  const risks = riskElements?.map(({ name }) => (
    <OrderItemList key={name}>{name}</OrderItemList>
  ));

  const validity = (
    <>
      {getStartDate(startDate, addYears(startDate, 1))} -{' '}
      {fullNewOrderDate(subDays(addYears(startDate, 1), 1)).replace('●', '•')}{' '}
      <SubText>{t('MITE_FORM:oneYear')}</SubText>
    </>
  );

  const coverAmount = program?.risks?.map(({ limit, description }, index) => (
    <div key={`${limit}${description}`}>
      <OrderItemList marginBottom={4} marginTop={index > 0 ? 8 : 0}>
        {t('COMMON:price.rubles', { price: limit && numFormat(limit) })}
      </OrderItemList>
      {choosedPolicy !== MitePolicyChoose.PERSON && (
        <SubTextLighter>{description}</SubTextLighter>
      )}
    </div>
  ));

  const checkForFailedPromoCode = () => {
    if (promoCodeFailedMessage) {
      return t('COMMON:errors.promoCodeNotApplied');
    }
    return insurancePrice?.isSuccessfulPromo === undefined ||
      insurancePrice?.isSuccessfulPromo === null
      ? ''
      : insurancePrice?.isSuccessfulPromo === false && !promoCode
      ? ''
      : t('COMMON:errors.promoCodeNotApplied');
  };

  const orderArray = [
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.periodOfPayment'),
      value: label,
      secondsValue: subLabel,
      successValue: insurancePrice?.isSuccessfulPromo
        ? t('COMMON:success.promoCodeApplied')
        : '',
      failedValue: checkForFailedPromoCode(),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.validity'),
      value: validity,
    },
    {
      type:
        countRisks > 1
          ? OrderItemValueType.ACCORDION
          : OrderItemValueType.SIMPLE,
      title: t('COMMON:headers.insuranceRisks'),
      value: countRisks > 1 ? risks : program?.risks[0].name,
      accordionTitle: `${countRisks} ${numWord(countRisks, [
        t('COMMON:declensions.risk'),
        t('COMMON:declensions.risk-2'),
        t('COMMON:declensions.risk-3'),
      ])}`,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.coverAmount'),
      value: coverAmount,
    },
    {
      type:
        numberInsuredPersons > 1
          ? OrderItemValueType.ACCORDION
          : OrderItemValueType.SIMPLE,
      title:
        numberInsuredPersons > 1
          ? t('COMMON:labels.insuredPersons')
          : t('COMMON:labels.insuredPerson'),
      value:
        numberInsuredPersons > 1
          ? orderInsureComponent
          : `${insuredPersons[0].lastName} ${insuredPersons[0].firstName} ${
              insuredPersons[0]?.middleName ?? ''
            }`,
      accordionTitle:
        numberInsuredPersons > 1 &&
        `${numberInsuredPersons} ${numWord(numberInsuredPersons, [
          t('COMMON:declensions.human'),
          t('COMMON:declensions.human-2'),
          t('COMMON:declensions.human-3'),
        ])}`,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.phone'),
      value: displayPhoneNumber,
    },
  ];

  return {
    orderArray,
  };
};
