import { styled, typography } from '@pulse-web-ui/theme';

export const IconColumnTitle = styled.div`
  ${typography.medium18}
  letter-spacing: 0.18px;
`;

export const StyledColumnText = styled.div`
  ${typography.regular16}
  line-height: 24px;
`;
