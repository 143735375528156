/* eslint-disable indent */
import { StoreActions } from '@src/store';

import { SportNSActionTypes } from './index';
import type { SportNSStoreState } from './index';

export const formSportNSStoreReducer = (
  state: SportNSStoreState,
  action: StoreActions
): SportNSStoreState => {
  switch (action?.type) {
    case SportNSActionTypes.SetInsuranceProduct:
      return {
        ...state,
        insuranceProduct: action.payload,
      };
    case SportNSActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    case SportNSActionTypes.SetPromoCodeApplyed:
      return {
        ...state,
        promoCodeApplyed: action.payload,
      };
    case SportNSActionTypes.SetSportKinds:
      return {
        ...state,
        sportKinds: action.payload,
      };
    case SportNSActionTypes.SetChoosedSportKinds:
      return {
        ...state,
        choosedSportKinds: action.payload,
      };
    case SportNSActionTypes.SetNumberInsurePersons:
      return {
        ...state,
        numberInsurePersons: action.payload,
      };
    case SportNSActionTypes.SetInsuranceProductDate:
      return {
        ...state,
        insuranceProductDate: action.payload,
      };
    case SportNSActionTypes.SetInsuranceProductStartDate:
      return {
        ...state,
        selectedStartDate: action.payload,
      };
    case SportNSActionTypes.SetInsuranceProductEndDate:
      return {
        ...state,
        selectedEndDate: action.payload,
      };
    case SportNSActionTypes.SetPolicyOnYear:
      return {
        ...state,
        policyOnYear: action.payload,
      };
    case SportNSActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };
    case SportNSActionTypes.SetSublimit:
      return {
        ...state,
        sublimit: action.payload,
      };
    case SportNSActionTypes.SetInsuranceSum:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case SportNSActionTypes.SetInsurancePrice:
      return {
        ...state,
        insurancePrice: action.payload,
      };
    case SportNSActionTypes.SetInsurePersons:
      return {
        ...state,
        insurePersons: action.payload,
      };
    case SportNSActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case SportNSActionTypes.UpdateState:
      return {
        ...state,
        ...action.payload,
      };
    case SportNSActionTypes.SetPresetData:
      return {
        ...state,
        presetData: action.payload,
      };
    case SportNSActionTypes.SetIsScrollToErrorElement:
      return {
        ...state,
        isScrollToErrorElement: action.payload,
      };
    default:
      return state;
  }
};
