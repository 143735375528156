export const numBetween = (
  currentVal: number,
  a: number,
  b: number,
  inclusive: boolean
) => {
  const min = Math.min.apply(Math, [a, b]);
  const max = Math.max.apply(Math, [a, b]);
  return inclusive
    ? currentVal >= min && currentVal <= max
    : currentVal > min && currentVal < max;
};
