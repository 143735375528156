import { useEffect } from 'react';

export const useHandleGoBack = (result: 'success' | 'error') => {
  useEffect(() => {
    const popStateHandler = () => {
      localStorage.setItem('paymentResult', result);
    };

    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', popStateHandler);

    return () => window.removeEventListener('popstate', popStateHandler);
  }, []);
};
