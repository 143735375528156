import React from 'react';

export const PetsIcon = () => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.3987 8.64196C31.7548 8.90991 32.2452 8.90991 32.6013 8.64196C35.5295 6.43847 39.3492 4.86311 44.0871 4.86311C48.3472 4.86311 53.3397 6.69483 57.0537 10.7139C61.0432 15.0312 63.0403 21.3015 61.8663 28.8345C61.2629 32.7072 59.8202 37.8393 55.7166 43.5248C51.7993 48.9521 45.6448 54.575 36.2423 60.1011C33.6061 61.6505 30.3939 61.6505 27.7576 60.1011C18.3552 54.575 12.2007 48.9521 8.28335 43.5248C4.17975 37.8393 2.73712 32.7072 2.13363 28.8345C0.959709 21.3015 2.95681 15.0311 6.94627 10.7139C10.6602 6.69484 15.6527 4.86311 19.9129 4.86311C24.6508 4.86311 28.4705 6.43847 31.3987 8.64196Z"
        fill="#E4E7EE"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_1804_177)">
        <path
          d="M23.4683 35.2003C22.4175 36.4933 21.8461 38.0915 21.8461 39.7375C21.8461 41.3835 22.4175 42.9817 23.4683 44.2747C24.1667 45.1643 25.1669 45.7862 26.2923 46.0307C27.4178 46.2751 28.5961 46.1263 29.6193 45.6105C30.3523 45.2327 31.1698 45.0351 31.9999 45.0351C32.8301 45.0351 33.6476 45.2327 34.3805 45.6105C35.4038 46.1263 36.5821 46.2751 37.7075 46.0307C38.8329 45.7862 39.8331 45.1643 40.5315 44.2747C41.5823 42.9817 42.1538 41.3835 42.1538 39.7375C42.1538 38.0915 41.5823 36.4933 40.5315 35.2003L36.4835 31.0404C35.9197 30.4282 35.2287 29.9384 34.456 29.6031C33.6833 29.2678 32.8463 29.0944 31.9997 29.0944C31.153 29.0944 30.316 29.2678 29.5433 29.6031C28.7706 29.9384 28.0796 30.4282 27.5158 31.0404L23.4683 35.2003Z"
          fill="#FFC000"
          stroke="#1F232B"
          strokeWidth="1.73684"
          strokeLinejoin="round"
        />
        <path
          d="M29.9692 21.2586C29.9692 28.2071 23.2 28.2071 23.2 21.2586C23.2 14.4394 29.9692 13.9838 29.9692 21.2586Z"
          fill="#FFC000"
          stroke="#1F232B"
          strokeWidth="1.73684"
          strokeLinejoin="round"
        />
        <path
          d="M40.8 21.2586C40.8 28.2071 34.0307 28.2071 34.0307 21.2586C34.0307 14.4394 40.8 13.9838 40.8 21.2586Z"
          fill="#FFC000"
          stroke="#1F232B"
          strokeWidth="1.73684"
          strokeLinejoin="round"
        />
        <path
          d="M48.9231 29.7884C48.9231 36.737 42.1539 36.737 42.1539 29.7884C42.1539 22.9692 48.9231 22.5136 48.9231 29.7884Z"
          fill="#FFC000"
          stroke="#1F232B"
          strokeWidth="1.73684"
          strokeLinejoin="round"
        />
        <path
          d="M21.8462 29.7884C21.8462 36.737 15.0769 36.737 15.0769 29.7884C15.0769 22.9692 21.8462 22.5136 21.8462 29.7884Z"
          fill="#FFC000"
          stroke="#1F232B"
          strokeWidth="1.73684"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1804_177">
          <rect
            width="35.2"
            height="31.4947"
            fill="white"
            transform="translate(14.4 15.3158)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
