import { UnicodeEnum } from '@src/constants';

export const fullNewOrderDate = (date: Date) => {
  const formatDate = date.toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return formatDate.replace('г.', UnicodeEnum.DOT);
};
