import i18n from '@src/i18n/config';

import CatOff from '../icons/cat-off.png';
import CatOn from '../icons/cat-on.png';
import DogOff from '../icons/dog-off.png';
import DogOn from '../icons/dog-on.png';

export enum TelevetPlusPetType {
  DOG = 'DOG',
  CAT = 'CAT',
}

export const petGender = {
  male: i18n.t('PETS_TELEVET_PLUS:labels.male'),
  female: i18n.t('PETS_TELEVET_PLUS:labels.female'),
};

export const petTypeIconPath = {
  [TelevetPlusPetType.DOG]: {
    iconPath: DogOff,
    selectedIconPath: DogOn,
  },
  [TelevetPlusPetType.CAT]: {
    iconPath: CatOff,
    selectedIconPath: CatOn,
  },
};

export const petGenderList = [
  {
    id: petGender.male,
    label: petGender.male,
    value: petGender.male,
  },
  {
    id: petGender.female,
    label: petGender.female,
    value: petGender.female,
  },
];
export const TELEVET_PROGRAM_KEY = 'TELEVET';
export const TELEVET_KIND = 'TELEVETPLUS';

export const TELEVET_RISK_CODE = 'Pet_5';
