import type { FC } from 'react';
import { useContext } from 'react';

import { IFLSumDescriptions } from '@src/features/ifl-sum-descriptions';
import { Store } from '@src/store';

export const IFLFlatSumDescriptions: FC = () => {
  const {
    state: {
      stateFormIFLFlat: { insuranceSubproducts },
    },
  } = useContext(Store);

  return <IFLSumDescriptions data={insuranceSubproducts?.subObjects} />;
};
