import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import { ClientCategory, Product } from '@src/constants';
import { UserRegistrationDataTypes } from '@src/types';

import {
  PersonalInfoDataProps,
  RegUtm,
  RegistrationFormProps,
  UserProfileDataTypes,
} from './user-store.types';

export interface UserStoreState {
  isPhoneFromQuery?: boolean; // Наличия телефона из query params
  isPrivateOffice?: boolean; // доступ личного кабинета клиента к анкетам
  profile: UserProfileDataTypes | undefined;
  regUtm?: RegUtm | undefined;
  cid?: string | null;
  userRegistrationData?: UserRegistrationDataTypes | undefined; // входные данные для эндпоинта user/registration
  cachedPersonalInfoData?: PersonalInfoDataProps; // закэшированные данные из полей и чекбоксов на странице с ФИО
  cachedPhoneNumber?: string | undefined; // закэшированный телефон из поля на форме ввода телефона
  isPureSubscription: boolean; // если true - партнер, типа ПИК, начинать с экрана с ФИО
  cachedRegistrationFormData?: RegistrationFormProps; // закэшированные данные из полей и чекбоксов на СТАРОЙ странице регистрации. !!! Когда переведем все продукты на новое флоу нужно удалить
  isSubscriptionExists?: boolean; // выбранное пользователем значение есть подписка или нет
  selectedProduct?: Product; // выбранная подписка
  cachedProduct?: Product; // закэшированная подписка на форме выбора подписок
  cachedDadaValue?: DaDataAddressSuggestion; // закэшированные данные из daData по выбранному адресу
  authenticationRefetchUid?: string; // создаем каждый раз новый UID при возвращении на страницу номера телефона. Так как если номер не менялся, то после запроса v1/user/authentication новый confirmationId не запишется в хранилище
  isScrinning?: boolean; // true прокидывается для ScoreErrorPage со страницы OrderDetail
  preset?: string; // наименование пресета
  promoCodeFailedMessage?: string;
  clientCategoryFromUrl?: ClientCategory; // категория клиента, которую могут передавать в queryParams в пресетах
  regionFiasIdFromUrl?: string; // fias_id региона, который могут передавать в queryParams в пресетах для ИФЛ
  isScoringSuccess: boolean;
  agentLogin?: string; // логин агента если подписка оформляется через ЛК цифрового агента
}

export const initUserStoreState: UserStoreState = {
  profile: undefined,
  regUtm: undefined,
  cid: null,
  isPureSubscription: false,
  isScrinning: false,
  promoCodeFailedMessage: undefined,
  isSubscriptionExists: true,
  isScoringSuccess: false,
};
