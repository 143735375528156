import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FRACTION_TWO } from '@src/constants';
import { OrderActionTypes, Store } from '@src/store';
import { GetPrices, OrderItemType, OrderItemValueType } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  getAdditionalRisksToString,
  getFormPeriodLabels,
  getMainRisksToString,
  numFormat,
} from '@src/utils';

export const useHouseOrderArray = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLHouse: {
        promoCode,
        risks,
        insuranceSum,
        selectedDuration,
        selectedDate,
        getPricesFull,
        dadaValue,
      },
      stateAuth: { displayPhoneNumber },
      stateUser: { profile, promoCodeFailedMessage },
    },
    dispatch,
  } = useContext(Store);
  const formPeriodLabels = getFormPeriodLabels();

  return useCallback(
    (pricesResponse?: GetPrices) => {
      let selectedPrice = getPricesFull?.prices.filter(
        (item) => item.duration === selectedDuration
      )[0]?.premiumAndDelta;

      let selectedPromoPrice = getPricesFull?.prices.filter(
        (item) => item.duration === selectedDuration
      )[0]?.premiumAndDeltaPromo;

      if (!selectedPrice) {
        const defaultSelectedDuration = pricesResponse
          ? pricesResponse.defaultContractDuration
            ? pricesResponse.defaultContractDuration
            : pricesResponse?.prices[0]?.duration
          : getPricesFull?.prices[0]?.duration || null;

        selectedPrice = getPricesFull?.prices.filter(
          (item) => item.duration === defaultSelectedDuration
        )[0]?.premiumAndDelta;

        selectedPromoPrice = getPricesFull?.prices.filter(
          (item) => item.duration === defaultSelectedDuration
        )[0]?.premiumAndDeltaPromo;
      }

      let label = `${
        selectedPrice
          ? currencyRuLocaleWithoutFraction(Number(selectedPrice), FRACTION_TWO)
          : ''
      } ${formPeriodLabels.get(selectedDuration!)?.label}`;
      let subLabel = '';
      const isSuccessfulPromo = getPricesFull?.isSuccessfulPromo;

      if (!!selectedPromoPrice) {
        label = `${currencyRuLocaleWithoutFraction(
          Number(selectedPromoPrice),
          FRACTION_TWO
        )} ${formPeriodLabels.get(selectedDuration!)?.promoLabel}`;
        subLabel =
          `${
            formPeriodLabels.get(selectedDuration!)?.promoSubLabel
          } ${currencyRuLocaleWithoutFraction(
            Number(selectedPrice),
            FRACTION_TWO
          )} ₽` || '';
      }

      const name = [
        profile?.profile?.lastName,
        profile?.profile?.firstName,
        profile?.profile?.middleName !== ''
          ? profile?.profile?.middleName
          : null,
      ].join(' ');

      const checkForFailedPromoCode = () => {
        if (promoCodeFailedMessage) {
          return t('COMMON:errors.promoCodeNotApplied');
        }
        return isSuccessfulPromo === undefined ||
          isSuccessfulPromo === null ||
          promoCode === '' ||
          (isSuccessfulPromo && !selectedPromoPrice)
          ? ''
          : t('COMMON:errors.promoCodeNotApplied') || '';
      };

      const orderArray: OrderItemType[] = [
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.periodOfPayment'),
          value: label,
          secondsValue: subLabel,
          successValue:
            isSuccessfulPromo && selectedPromoPrice
              ? t('COMMON:success.promoCodeApplied') || ''
              : '',
          failedValue: checkForFailedPromoCode(),
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.addressOfObject'),
          value: String(dadaValue?.value),
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.mainRisks'),
          value: getMainRisksToString(risks),
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.additionalRisks'),
          value:
            getAdditionalRisksToString(risks) ||
            t('COMMON:placeholders.notSelected'),
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.coverAmount'),
          value: `${numFormat(insuranceSum)} ₽`,
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.subscriptionWillStart'),
          value: `с ${selectedDate?.toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`,
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.insurant'),
          value: name,
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('COMMON:labels.phoneOfNumber'),
          value: displayPhoneNumber,
        },
      ];

      dispatch({
        type: OrderActionTypes.SetOrder,
        payload: orderArray,
      });
    },
    [
      getPricesFull?.prices,
      selectedDuration,
      dadaValue,
      risks,
      insuranceSum,
      selectedDate,
      displayPhoneNumber,
      profile,
    ]
  );
};
