import { useContext, useEffect, useState } from 'react';

import { Product, UseQueryStatus } from '@src/constants';
import {
  AuthActionTypes,
  AuthFlowActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';

const getPath = (
  partnerAuthRegType: string,
  stepAuth: number | undefined,
  selectedProduct: string | undefined,
  hasProfile: boolean,
  preset: string | undefined
): string => {
  if (partnerAuthRegType !== 'base') {
    return '/personal-info';
  }

  if (!stepAuth && !hasProfile) {
    return '/login';
  }

  if (preset && !selectedProduct) {
    return '/login';
  }

  if (selectedProduct) {
    return `/${selectedProduct}`;
  }

  return '';
};

export const useStartPath = (): {
  isLoading: boolean;
  hasError: boolean;
  path: string;
} => {
  const {
    state: {
      stateAuthFlow: { authFlowParams, status },
      stateUser: { selectedProduct, profile, preset },
      stateAuthFlow: { initAuthState },
    },
    dispatch,
  } = useContext(Store);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [path, setPath] = useState('');

  useEffect(() => {
    if (status === UseQueryStatus.IDLE && !initAuthState) {
      setIsLoading(false);
    }

    if (status === UseQueryStatus.ERROR && !initAuthState) {
      setHasError(true);
      setIsLoading(false);
    }

    if (status === UseQueryStatus.SUCCESS && !initAuthState) {
      setIsLoading(false);
    }
  }, [status, initAuthState]);

  useEffect(() => {
    if (authFlowParams && !initAuthState) {
      const { partnerAuthRegType, sepScrAuth, stepAuth, product } =
        authFlowParams;

      // Clear profile for 'PIK' partner
      if (partnerAuthRegType !== 'base') {
        dispatch({
          type: UserActionTypes.SetProfile,
          payload: undefined,
        });
        dispatch({
          type: UserActionTypes.SetCachedPhoneNumber,
          payload: undefined,
        });
        dispatch({
          type: AuthActionTypes.SetPhoneNumber,
          payload: '',
        });
      }

      setPath(
        getPath(
          partnerAuthRegType,
          stepAuth,
          selectedProduct || product,
          !!profile?.profile?.firstName,
          preset
        )
      );

      if (stepAuth > 0) {
        dispatch({
          type: AuthFlowActionTypes.SetAuthStep,
          payload: stepAuth,
        });
      }

      if (product) {
        dispatch({
          type: UserActionTypes.SetSelectedProduct,
          payload: product as Product,
        });
      }

      dispatch({
        type: UserActionTypes.SetIsPureSubscription,
        payload: partnerAuthRegType !== 'base',
      });

      dispatch({
        type: AuthFlowActionTypes.SetSepScrAuth,
        payload: sepScrAuth,
      });
    }
  }, [authFlowParams, selectedProduct, initAuthState, profile, preset]);

  return { isLoading, hasError, path };
};
