import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { SelectorCard } from '@pulse-web-ui/selector-card';

import {
  AdaptiveListWrapper,
  Container,
  FormLabel,
  FormSub,
  LinkContainer,
  RiskWrapper,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useNextStep, useRequest } from '@src/hooks';
import { IFLHouseActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode } from '@src/types';
import { addTestAttribute, evenRisk } from '@src/utils';

import { useIflHouseDraft } from './hooks';
import { createRisksFromPresetData } from './utils';

export const FormInsuranceRisks: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: {
      stateFormIFLHouse: {
        risks,
        selectedIProduct,
        selectedBuildingMaterialCode,
        presetData,
      },
      stateUser: { preset },
    },
    dispatch,
  } = useContext(Store);

  const [listBoxStatus, setListBoxStatus] = useState<'error' | undefined>(
    undefined
  );

  useEffect(() => {
    if (!preset) {
      sendAnalyticEvent(analyticEvents.iflStep2Risk);
      sendAnalyticEvent(analyticEvents.iflHouseStep2Risk, {
        b_type: selectedBuildingMaterialCode?.toLowerCase(),
      });
    }

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const handleKeyPressEnter = () => {
    validatePage();
    if (listBoxStatus !== 'error') {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const { isLoading, error, res, refetch } = useRequest(
    'formIFLGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: selectedIProduct?.code,
    },
    [],
    true
  );

  useEffect(() => {
    if (!risks) {
      refetch();
    }
  }, [risks]);

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !risks?.some(evenRisk) || false,
    });

    setListBoxStatus(!risks?.some(evenRisk) ? 'error' : undefined);

    return risks?.some(evenRisk) || false;
  }, [risks]);

  useNextStep(validatePage);
  useIflHouseDraft();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: listBoxStatus === 'error',
    });
  }, [listBoxStatus]);

  useEffect(() => {
    if (!risks && !isLoading && res && res.risks.length > 0) {
      const payload = !!presetData
        ? createRisksFromPresetData(presetData, res.risks)
        : res.risks;

      dispatch({
        type: IFLHouseActionTypes.SetRisks,
        payload,
      });
    }
  }, [risks, isLoading, res]);

  useEffect(() => {
    validatePage();
  }, [risks]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: isLoading,
    });
  }, [isLoading]);

  if (isLoading) return <Skeleton />;

  if (error) return <GlobalErrorInfo retryHandler={refetch} />;

  const onClick = (code: string) => {
    setStepUpdated();

    setListBoxStatus(undefined);

    dispatch({
      type: IFLHouseActionTypes.SetInsuranceSubproducts,
      payload: undefined,
    });

    const newRisks = risks?.map((item) => {
      if (item.code === code) {
        if (preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetChangeRisk);
        } else {
          sendAnalyticEvent(
            item.active
              ? analyticEvents.deselectOptionRealty
              : analyticEvents.selectOptionRealty,
            {
              option: item.name,
            }
          );
        }

        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });

    dispatch({
      type: IFLHouseActionTypes.SetRisks,
      payload: newRisks,
    });

    dispatch({
      type: IFLHouseActionTypes.SetInsuranceSubproducts,
      payload: undefined,
    });

    dispatch({
      type: IFLHouseActionTypes.SetGetSubobjectsRefetchUid,
      payload: uuidv4(),
    });

    dispatch({
      type: IFLHouseActionTypes.SetEmptyDependencies,
      payload: [],
    });

    dispatch({
      type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
      payload: undefined,
    });
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: IFLHouseActionTypes.SetRisksPage,
      payload: 'primary',
    });
    navigate('/house-risk-descriptions');
  };

  return (
    <Container>
      <FormLabel>{t('COMMON:headers.youChooseWePay')}</FormLabel>
      <FormSub isError={listBoxStatus === 'error'}>
        {t('COMMON:hints.risksToReceivePayment')}
      </FormSub>
      <AdaptiveListWrapper>
        {(!risks || risks?.length === 0) && t('COMMON:errors.noData')}
        {risks?.map((item) => {
          return item.binding === true ? (
            <RiskWrapper>
              <SelectorCard
                key={item.code}
                id={item.code}
                name={item.code}
                mobileFullWidth
                label={item.name}
                description={item.description}
                checked={item.active}
                readOnly={!item.switchability}
                onClick={() => onClick(item.code)}
                testId="house-form-risks"
              />
            </RiskWrapper>
          ) : (
            <></>
          );
        })}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutMainRisks') || ''}
          {...addTestAttribute('house-form-button-about-risks')}
        />
      </LinkContainer>
    </Container>
  );
};
