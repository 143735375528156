/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { IFLHouseStoreState } from './index';
import { IFLHouseActionTypes } from './index';

export type IFLHouseStoreReducer = (
  state: IFLHouseStoreState,
  action: StoreActions
) => IFLHouseStoreState;

export const formIFLHouseStoreReducer: IFLHouseStoreReducer = (
  state,
  action
) => {
  switch (action?.type) {
    case IFLHouseActionTypes.SetSelectedIProduct:
      // TODO: сохранить выбранный регион в черновике
      return {
        ...state,
        selectedIProduct: action.payload,
      };
    case IFLHouseActionTypes.SetSelectedRegion:
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case IFLHouseActionTypes.SetRegions:
      return {
        ...state,
        regions: action.payload,
      };
    case IFLHouseActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };
    case IFLHouseActionTypes.SetRisksPage:
      return {
        ...state,
        risksPage: action.payload,
      };
    case IFLHouseActionTypes.SetInsuranceSubproducts:
      return {
        ...state,
        insuranceSubproducts: action.payload,
      };
    case IFLHouseActionTypes.SetGetPrices:
      return {
        ...state,
        getPrices: action.payload,
      };
    case IFLHouseActionTypes.SetGetPricesFull:
      return {
        ...state,
        getPricesFull: action.payload,
      };
    case IFLHouseActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case IFLHouseActionTypes.SetSelectedDate:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case IFLHouseActionTypes.SetDadaValue:
      return {
        ...state,
        dadaValue: action.payload,
      };
    case IFLHouseActionTypes.SetInsuranceSum:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case IFLHouseActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    case IFLHouseActionTypes.SetPromoCodeApplyed:
      return {
        ...state,
        promoCodeApplyed: action.payload,
      };
    case IFLHouseActionTypes.SetMaterials:
      return {
        ...state,
        materials: action.payload,
      };
    case IFLHouseActionTypes.SetSelectedBuildingMaterialCode:
      return {
        ...state,
        selectedBuildingMaterialCode: action.payload,
      };
    case IFLHouseActionTypes.SetFieldWithEmptyDependencies:
      return {
        ...state,
        fieldWithEmptyDependencies: action.payload,
      };
    case IFLHouseActionTypes.SetEmptyDependencies:
      return {
        ...state,
        emptyDependencies: action.payload,
      };
    case IFLHouseActionTypes.SetIsObjectDataError:
      return {
        ...state,
        isObjectDataError: action.payload,
      };
    case IFLHouseActionTypes.SetGetSubobjectsRefetchUid:
      return {
        ...state,
        getSubobjectsRefetchUid: action.payload,
      };
    case IFLHouseActionTypes.SetCurrentSum:
      return {
        ...state,
        currentSum: action.payload,
      };
    case IFLHouseActionTypes.SetCurrentSumMinLimit:
      return {
        ...state,
        currentSumMinLimit: action.payload,
      };
    case IFLHouseActionTypes.SetCurrentSumMaxLimit:
      return {
        ...state,
        currentSumMaxLimit: action.payload,
      };
    case IFLHouseActionTypes.SetCurrentSumErr:
      return {
        ...state,
        currentSumErr: action.payload,
      };
    case IFLHouseActionTypes.SetSelectedId:
      return {
        ...state,
        selectedId: action.payload,
      };
    case IFLHouseActionTypes.SetIsSelectedCustomStreet:
      return {
        ...state,
        isSelectedCustomStreet: action.payload,
      };
    case IFLHouseActionTypes.SetIsSelectedCustomHouse:
      return {
        ...state,
        isSelectedCustomHouse: action.payload,
      };
    case IFLHouseActionTypes.SetIsEmptyStreetSuggestions:
      return {
        ...state,
        isEmptyStreetSuggestions: action.payload,
      };
    case IFLHouseActionTypes.SetIsEmptyHouseSuggestions:
      return {
        ...state,
        isEmptyHouseSuggestions: action.payload,
      };
    case IFLHouseActionTypes.SetPresetData:
      return {
        ...state,
        presetData: action.payload,
      };
    case IFLHouseActionTypes.UpdateState:
      return {
        ...state,
        ...action.payload,
      };
    case IFLHouseActionTypes.SetIsSpecialTermsInsurance:
      return {
        ...state,
        isSpecialTermsInsurance: action.payload,
      };
    case IFLHouseActionTypes.SetIsHouseNotFound:
      return {
        ...state,
        isHouseNotFound: action.payload,
      };
    default:
      return state;
  }
};
