/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { NSStoreState } from './index';
import { NSActionTypes } from './index';

export const formNSStoreReducer = (
  state: NSStoreState,
  action: StoreActions
): NSStoreState => {
  switch (action?.type) {
    case NSActionTypes.SetInsuranceProduct:
      return {
        ...state,
        insuranceProduct: action.payload,
      };
    case NSActionTypes.SetAgeRatios:
      return {
        ...state,
        ageRatios: action.payload,
      };
    case NSActionTypes.SetNumberInsurePersons:
      return {
        ...state,
        numberInsurePersons: action.payload,
      };
    case NSActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };
    case NSActionTypes.SetSelectedMainRisks:
      return {
        ...state,
        selectedMainRisks: action.payload,
      };
    case NSActionTypes.SetSelectedAdditionalRisks:
      return {
        ...state,
        selectedAdditionalRisks: action.payload,
      };
    case NSActionTypes.SetInsuranceSum:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case NSActionTypes.SetSelectedDate:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case NSActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case NSActionTypes.SetGetPrices:
      return {
        ...state,
        getPrices: action.payload,
      };
    case NSActionTypes.SetSubscriptionDateLimit:
      return {
        ...state,
        subscriptionDateLimit: action.payload,
      };
    case NSActionTypes.SetInsurePersons:
      return {
        ...state,
        insurePersons: action.payload,
      };
    case NSActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    case NSActionTypes.SetPromoCodeApplyed:
      return {
        ...state,
        promoCodeApplyed: action.payload,
      };
    case NSActionTypes.UpdateState:
      return {
        ...state,
        ...action.payload,
      };
    case NSActionTypes.SetRisksPage:
      return {
        ...state,
        risksPage: action.payload,
      };
    default:
      return state;
  }
};
