import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { axiosInstance, refreshToken } from '@src/utils';

import { useNavigateToPersonalInfo } from './use-navigate-to-personal-info';
import { useSendCrashAnalytics } from './use-send-crash-analytics';

export const useAxiosInterceptors = () => {
  const {
    state: {
      stateAuth: { authTokens },
      stateAuthFlow: { sepScrAuth, authFlowParams },
      stateUser: { profile },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToPersonalInfo = useNavigateToPersonalInfo();

  const handleResponse = useCallback(
    (res: AxiosResponse) => {
      const csrfToken = res?.data?.csrfToken;

      if (csrfToken && csrfToken !== localStorage.getItem('csrfToken')) {
        localStorage.setItem('csrfToken', csrfToken);
      }

      navigateToPersonalInfo({
        status: res?.status,
        profile: res?.data,
        responseURL: res?.request?.responseURL,
      });

      return res;
    },
    [navigateToPersonalInfo]
  );

  const sendCrashAnalytics = useSendCrashAnalytics();

  const handleError = useCallback(
    async (err: AxiosError<{ code: string }>) => {
      sendCrashAnalytics(err);
      const status = err?.response?.status;
      const data = err?.response?.data as { code: string };
      const isSepScrLogin =
        !authFlowParams ||
        authFlowParams?.partnerAuthRegType !== 'base' ||
        sepScrAuth;

      if (
        status == 401 &&
        err?.response?.request.responseURL.includes('refresh-token')
      ) {
        if (isSepScrLogin) {
          navigate('/login');
        } else {
          navigate(pathname);
        }
        localStorage.removeItem('csrfToken');

        throw Error('REFRESH TOKEN UNAUTHORIZED');
      }

      if (status === 401) {
        if (authTokens) {
          try {
            const result = await refreshToken();

            if (result?.data) {
              const {
                authorization: {
                  accessToken,
                  csrfToken,
                  refreshToken,
                  isVip,
                  userId,
                  name,
                },
              } = result.data;

              if (csrfToken) {
                localStorage.setItem('csrfToken', csrfToken);
              }

              dispatch({
                type: AuthActionTypes.SetAuthTokens,
                payload: {
                  authorization: {
                    accessToken,
                    refreshToken,
                    csrfToken,
                  },
                  isVip,
                },
              });

              dispatch({
                type: UserActionTypes.SetProfile,
                payload: {
                  ...profile,
                  profile: {
                    ...profile?.profile,
                    firstName: name,
                    userId,
                  },
                },
              });

              if (err.config.headers) {
                err.config.headers[
                  'Authorization'
                ] = `Bearer ${result.data.authorization.accessToken}`;
              }
              return axiosInstance.request(err.config);
            }
          } catch (error) {
            localStorage.removeItem('csrfToken');

            dispatch({
              type: AuthActionTypes.SetAuthTokens,
              payload: undefined,
            });
            navigate('/login', {
              state: { nextRoute: pathname, isUnauthorized: true },
            });

            throw error;
          }
        } else {
          navigate('/login', {
            state: { nextRoute: pathname, isUnauthorized: true },
          });
        }
      }

      if (status === 403 && data?.code === 'INVALID_ROLE') {
        dispatch({
          type: UserActionTypes.SetUserRegistrationData,
          payload: undefined,
        });
        navigate('/personal-info', { state: { nextRoute: pathname } });
      }

      return Promise.reject(err);
    },
    [
      authFlowParams,
      sendCrashAnalytics,
      sepScrAuth,
      navigate,
      pathname,
      dispatch,
      authTokens,
      profile,
      profile?.profile,
    ]
  );

  const interceptor = useMemo(() => {
    return axiosInstance.interceptors.response.use(handleResponse, handleError);
  }, [handleResponse, handleError]);

  useEffect(() => {
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [interceptor]);
};
