import { useContext } from 'react';

import {
  IFLFlatActionTypes,
  IFLHouseActionTypes,
  MiteActionTypes,
  NSActionTypes,
  PetsActionTypes,
  SportNSActionTypes,
  Store,
} from '@src/store';
import { initIFLFlatStoreState } from '@src/store/ifl-flat';
import { initIFLHouseStoreState } from '@src/store/ifl-house';
import { initMiteStoreState } from '@src/store/mite';
import { initNSStoreState } from '@src/store/ns';
import { initPetsStoreState } from '@src/store/pets';
import { initSportNSStoreState } from '@src/store/sport';

export const useClearStoreProducts = () => {
  const { dispatch } = useContext(Store);

  return () => {
    dispatch({
      type: PetsActionTypes.UpdateState,
      payload: initPetsStoreState,
    });
    dispatch({
      type: MiteActionTypes.UpdateState,
      payload: initMiteStoreState,
    });
    dispatch({
      type: IFLHouseActionTypes.UpdateState,
      payload: initIFLHouseStoreState,
    });
    dispatch({
      type: IFLFlatActionTypes.UpdateState,
      payload: initIFLFlatStoreState,
    });
    dispatch({
      type: NSActionTypes.UpdateState,
      payload: initNSStoreState,
    });

    dispatch({
      type: SportNSActionTypes.UpdateState,
      payload: initSportNSStoreState,
    });
  };
};
