import { handleDeclinePaymentAnalyticsEvents } from './handle-decline-payment-analytics-events';
import { handleNavigateToPaymentAnalyticsEvents } from './handle-navigate-to-payment-analytics-events';
import { handleOrderAnalyticsEvents } from './handle-order-analytics-event';
import { handleRegistrationComplete } from './handle-registration-complete';
import { handleRegistrationStart } from './handle-registration-start';
import { handleRetryPaymentAnalyticsEvents } from './handle-retry-payment-analytics-events';
import { handleSignupComplete } from './handle-signup-complete';

export const handleAnalyticsEvents = {
  handleOrderAnalyticsEvents,
  handleRetryPaymentAnalyticsEvents,
  handleDeclinePaymentAnalyticsEvents,
  handleNavigateToPaymentAnalyticsEvents,
  handleRegistrationStart,
  handleRegistrationComplete,
  handleSignupComplete,
};
