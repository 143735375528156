import { useContext, useEffect, useState } from 'react';

import { sendAnalyticEvent } from '@src/components/web-analytic';
import { FeatureFlags, analyticEvents } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';
import { Store } from '@src/store';
import { handleAnalyticsEvents } from '@src/utils';

export const usePersonalDataConsentVersion = (isUpdateProfile?: boolean) => {
  const {
    state: {
      stateUser: { preset, selectedProduct },
    },
  } = useContext(Store);

  const {
    res: [PDConsentAlternativeVersion, PDConsentSplitMainAlternativeVersion],
    isFeatureFlagsLoading,
  } = useFeatureFlags([
    FeatureFlags.PDConsentAlternativeVersion,
    FeatureFlags.PDConsentSplitMainAlternativeVersion,
  ]);

  const [isPDConsentAlternativeVersion, setIsPDConsentAlternativeVersion] =
    useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!isFeatureFlagsLoading) {
      if (PDConsentAlternativeVersion) {
        localStorage.setItem('isPDConsentAlternativeVersion', '1');
      }

      const ints = new Uint32Array(1);
      window.crypto.getRandomValues(ints);
      const rand: number = ints[0] / 0xffffffff;

      const isAlternativeVersion =
        PDConsentAlternativeVersion ||
        (PDConsentSplitMainAlternativeVersion && rand < 0.5);
      const isPDConsentVersion = localStorage.getItem(
        'isPDConsentAlternativeVersion'
      );

      !!selectedProduct &&
        !isUpdateProfile &&
        handleAnalyticsEvents.handleRegistrationStart(
          selectedProduct,
          !!preset
        );

      if (isPDConsentVersion === null) {
        if (isAlternativeVersion) {
          localStorage.setItem('isPDConsentAlternativeVersion', '1');
        } else {
          localStorage.setItem('isPDConsentAlternativeVersion', '0');
        }
        setIsPDConsentAlternativeVersion(isAlternativeVersion);
        if (!isUpdateProfile) {
          if (preset) {
            sendAnalyticEvent(analyticEvents.signupPresetStart);
          } else {
            timeout = setTimeout(() => {
              sendAnalyticEvent(analyticEvents.signupStart, {
                basic_screen: !isAlternativeVersion,
              });
            }, 1000);
          }
        }
      } else {
        setIsPDConsentAlternativeVersion(isPDConsentVersion === '1');
        if (!isUpdateProfile) {
          if (preset) {
            sendAnalyticEvent(analyticEvents.signupPresetStart);
          } else {
            timeout = setTimeout(() => {
              sendAnalyticEvent(analyticEvents.signupStart, {
                basic_screen: isPDConsentVersion !== '1',
              });
            }, 1000);
          }
        }
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    isFeatureFlagsLoading,
    PDConsentAlternativeVersion,
    PDConsentSplitMainAlternativeVersion,
  ]);

  return {
    isPDConsentAlternativeVersion,
    isFeatureFlagsLoading,
  };
};
