import {
  SubscriptionCard,
  SubscriptionCardGroup,
} from '@pulse-web-ui/subscription-card';
import { media, styled } from '@pulse-web-ui/theme';

export const StyledSubscriptionCard = styled(SubscriptionCard)`
  ${media.desktop} {
    width: calc(100% / 3 - 16px / 3);
    height: 152px;
  }
`;

export const StyledSubscriptionCardGroup = styled(SubscriptionCardGroup)`
  margin-bottom: 32px;
  gap: 8px;
`;
