import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';
import { Telegram2, Vk2 } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import type { BaseThemeType } from '@pulse-web-ui/theme';

import {
  SupportBlockContent,
  SupportBlockTitle,
  SupportButtonsWrapper,
} from './rgs-support.styles';

export const RGSSupportBlock = () => {
  const { t } = useTranslation();
  const theme = useTheme() as BaseThemeType;

  return (
    <SupportBlockContent>
      <SupportBlockTitle>
        {t('AUTH:headers.contactSupportSecond')}
      </SupportBlockTitle>
      <SupportButtonsWrapper>
        <Button
          variant="primary"
          icon={<Telegram2 width={24} color={theme.colors.icon.primary} />}
          onClick={() => window.open('https://t.me/Pulse_insure_bot', '_blank')}
        >
          {t('COMMON:labels.telegram')}
        </Button>
        <Button
          variant="primary"
          icon={<Vk2 width={24} color={theme.colors.icon.primary} />}
          onClick={() => window.open('https://vk.com/pulse.insure', '_blank')}
        >
          {t('COMMON:labels.vk')}
        </Button>
      </SupportButtonsWrapper>
    </SupportBlockContent>
  );
};
