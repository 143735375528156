import { useContext, useEffect } from 'react';

import { Store, WizardActionTypes } from '@src/store';

export const useNextStep = (pageState: () => boolean | Promise<boolean>) => {
  const {
    state: { stateWizard },
    dispatch,
  } = useContext(Store);

  const setNextStep = () => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: stateWizard.currentStep + 1,
    });
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: false,
    });
  };

  useEffect(() => {
    if (stateWizard.wantNextStep) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: false,
      });

      const isValid = pageState();

      if (typeof isValid === 'boolean') {
        if (isValid) {
          setNextStep();
        }
      } else {
        isValid.then((isValidResponse) => {
          if (isValidResponse) {
            setNextStep();
          }
        });
      }
    }
  }, [stateWizard.wantNextStep]);
};
