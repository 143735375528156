import React, { VFC, memo } from 'react';

import { NewRadio, NewRadioGroup } from '@pulse-web-ui/new-radio-group';

import { PaymentPeriodsListWrapper, Skeleton } from '@src/components';
import { FeatureFlags } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';
import { Price, SelectedDuration } from '@src/types';

import {
  StyledSubscriptionCard,
  StyledSubscriptionCardGroup,
} from './payment-period-list.styles';

interface Props {
  onChange: (value: SelectedDuration) => void;
  selectedDuration: SelectedDuration | null;
  prices: Price[];
  getLabel: (
    price: Price,
    isFeatureSubscriptionType: boolean
  ) => JSX.Element | string | undefined;
  getDescription: (
    price: Price,
    isFeatureSubscriptionType: boolean
  ) => JSX.Element | string | undefined;
  getSubtitle: (price: Price) => JSX.Element | string | undefined;
  featureFlag: FeatureFlags;
  isOnlyOnePeriodAllowed?: boolean;
}

export const PaymentPeriodList: VFC<Props> = memo(
  ({
    onChange,
    selectedDuration,
    prices,
    getLabel,
    getDescription,
    getSubtitle,
    featureFlag,
    isOnlyOnePeriodAllowed,
  }) => {
    const {
      res: [isFeatureSubscriptionType],
      isFeatureFlagsLoading,
    } = useFeatureFlags([featureFlag]);

    if (isFeatureFlagsLoading) {
      return <Skeleton />;
    }

    return isFeatureSubscriptionType ? (
      <StyledSubscriptionCardGroup
        name="duration"
        onChange={onChange}
        selectedValue={selectedDuration}
      >
        {prices.map((item) => (
          <StyledSubscriptionCard
            key={`${item.duration}-key`}
            value={item.duration}
            title={getLabel(item, isFeatureSubscriptionType)}
            subTitle={getSubtitle(item)}
            description={getDescription(item, isFeatureSubscriptionType)}
            discount={
              !isOnlyOnePeriodAllowed &&
              !!Number(item.discount) &&
              `-${item.discount}%`
            }
          />
        ))}
      </StyledSubscriptionCardGroup>
    ) : (
      <PaymentPeriodsListWrapper>
        <NewRadioGroup
          name="duration"
          onChange={onChange}
          selectedValue={selectedDuration}
        >
          {prices.map((item) => (
            <NewRadio
              key={`${item.duration}-key`}
              value={item.duration}
              label={getLabel(item, isFeatureSubscriptionType)}
              description={getDescription(item, isFeatureSubscriptionType)}
            />
          ))}
        </NewRadioGroup>
      </PaymentPeriodsListWrapper>
    );
  }
);
