import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const useNsDraft = () => {
  const {
    state: {
      stateFormNS: {
        insuranceProduct,
        ageRatios,
        numberInsurePersons,
        risks,
        selectedMainRisks,
        selectedAdditionalRisks,
        insuranceSum,
        selectedDate,
        selectedDuration,
        getPrices,
        subscriptionDateLimit,
        promoCode,
        promoCodeApplyed,
      },
      stateFormNS,
      stateUser: { profile, selectedProduct, agentLogin },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    if (!agentLogin) {
      const data = {
        dataObject: {
          ...stateFormNS,
          promoCode: promoCodeApplyed && promoCode,
        },
        profile,
        selectedProduct,
        currentStep: currentStep || 0,
      };

      setDraftLS(data);
    }
  }, [
    insuranceProduct,
    ageRatios,
    numberInsurePersons,
    risks,
    selectedMainRisks,
    selectedAdditionalRisks,
    insuranceSum,
    selectedDate,
    selectedDuration,
    getPrices,
    subscriptionDateLimit,
    promoCode,
    agentLogin,
  ]);

  return;
};
