/* eslint-disable indent */
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { analyticEvents } from '@src/constants';
import {
  PetsAdditionalRisks,
  PetsData,
  PetsInsuranceSum,
  PetsMainRisks,
  PetsPaymentPeriod,
} from '@src/pages/pets-form';
import {
  ComponentsConfig,
  ComponentsConfigType,
  HandleAnalytic,
} from '@src/types';

const handleAnalytics: HandleAnalytic = (currentStep: number) => {
  switch (currentStep) {
    case 1:
      sendAnalyticEvent(analyticEvents.petToStepRiskPreset);
      break;
    case 2:
      sendAnalyticEvent(analyticEvents.petToStepAddRiskPreset);
      break;
    case 3:
      sendAnalyticEvent(analyticEvents.petToStepCoveragekPreset);
      break;
    default:
      break;
  }
};

export const petsComponentsConfig: ComponentsConfig = {
  [ComponentsConfigType.MAIN]: {
    components: [PetsData, PetsPaymentPeriod],
  },
  [ComponentsConfigType.SETTINGS]: {
    components: [PetsMainRisks, PetsAdditionalRisks, PetsInsuranceSum],
  },
  [ComponentsConfigType.DEFAULT]: {
    components: [
      PetsData,
      PetsMainRisks,
      PetsAdditionalRisks,
      PetsInsuranceSum,
      PetsPaymentPeriod,
    ],
  },
  analyticEvent: analyticEvents.petToConfigExitPreset,
  handleAnalytic: handleAnalytics,
};
