import { useContext, useEffect } from 'react';

import { useThemeRequest } from '@src/hooks';
import { AuthFlowActionTypes, Store } from '@src/store';
import { AuthFlow, ThemeConfig } from '@src/types';

import { getThemeURL } from '../utils';

export const useThemeAuthFlowRequest = (
  resConfig: ThemeConfig | undefined,
  partnername: string
) => {
  const { dispatch } = useContext(Store);

  const {
    isLoading,
    res: resAuthFlow,
    status: statusAuthFLow,
    error,
    refetch,
  } = useThemeRequest<AuthFlow>(
    'auth-flow',
    getThemeURL(resConfig, partnername, 'auth-flow', 'auth-url'),
    [resConfig, partnername],
    !!getThemeURL(resConfig, partnername, 'auth-flow', 'auth-url')
  );

  useEffect(() => {
    if (resAuthFlow) {
      dispatch({
        type: AuthFlowActionTypes.SetAuthFLowParams,
        payload: resAuthFlow,
      });
    }
  }, [resAuthFlow]);

  useEffect(() => {
    dispatch({
      type: AuthFlowActionTypes.SetStatus,
      payload: statusAuthFLow,
    });
  }, [statusAuthFLow]);

  return { isLoading, error, refetch };
};
