import { PetsDataForm } from '../pets-televet-plus.types';
import { TelevetPlusPetType, petGender } from './pet-data';

export const petsDataFormDefaultValues: PetsDataForm = {
  kind: TelevetPlusPetType.DOG,
  gender: petGender.male,
  breed: '',
  age: '',
  name: '',
};
