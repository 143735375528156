import { Option } from '@pulse-web-ui/select';

import i18n from '@src/i18n/config';
import { PetData } from '@src/store/pets';
import { getMonthDeclensions, getYearDeclensions } from '@src/utils';

import {
  MAX_MONTH_AGE,
  getAgeMeasure,
  getNumberFromString,
  isMonth,
  plural,
} from './age-utils';

const createAgeOptions = (
  minAge: string,
  maxAge: string,
  options: Option[] = []
): Option[] => {
  const isSameMinAndMaxAgeMeasure =
    (isMonth(minAge) && isMonth(maxAge)) ||
    (!isMonth(minAge) && !isMonth(maxAge));
  const minAgeNumber = getNumberFromString(minAge);
  const maxAgeNumber = getNumberFromString(maxAge);
  const monthDeclensions = getMonthDeclensions();
  const yearDeclensions = getYearDeclensions();

  if (
    (isSameMinAndMaxAgeMeasure && minAgeNumber < maxAgeNumber) ||
    (!isSameMinAndMaxAgeMeasure &&
      isMonth(minAge) &&
      minAgeNumber < MAX_MONTH_AGE)
  ) {
    return createAgeOptions(
      `${minAgeNumber + 1}${getAgeMeasure(minAge)}`,
      maxAge,
      [
        ...options,
        {
          value: minAge,
          label: isMonth(minAge)
            ? `${minAgeNumber} ${plural(minAgeNumber, monthDeclensions)}`
            : `${minAgeNumber} ${plural(minAgeNumber, yearDeclensions)}`,
        },
      ]
    );
  } else if (
    !isSameMinAndMaxAgeMeasure &&
    isMonth(minAge) &&
    minAgeNumber === MAX_MONTH_AGE
  ) {
    return createAgeOptions('1Y', maxAge, [
      ...options,
      { value: minAge, label: `11 ${i18n.t('COMMON:declensions.month-3')}` },
    ]);
  } else {
    return [
      ...options,
      {
        value: maxAge,
        label: isMonth(maxAge)
          ? `${maxAgeNumber} ${plural(maxAgeNumber, monthDeclensions)}`
          : `${maxAgeNumber} ${plural(maxAgeNumber, yearDeclensions)}`,
      },
    ];
  }
};

export const getAgeOptionsByKind = (
  petsData: PetData[],
  kind: string
): Option[] => {
  const { minAge, maxAge } = petsData.find((pet) => pet.kind === kind) || {};

  return minAge && maxAge ? createAgeOptions(minAge, maxAge) : [];
};
