/* eslint-disable indent */
import amplitude from 'amplitude-js';
import ym from 'react-yandex-metrika';

import { AnalyticsType } from '../web-analytic.types';
import { adriverEvent } from './adriver-event';
import { sendCpaScaleoAnalytic } from './send-cpa-scaleo-analytic';
import { sendRoxotAnalytic } from './send-roxot-analytic';

const sendEventToAmplitude = (
  triggerEvent: string,
  params?: any
): (() => void) => {
  const timeoutId = setTimeout(() => {
    amplitude.getInstance().logEvent(triggerEvent, params);
  }, 250);

  return () => clearTimeout(timeoutId);
};

export const sendAnalyticEvent = (
  triggerEvent: string,
  params?: any,
  type?: AnalyticsType
): void => {
  switch (type) {
    case AnalyticsType.myTracker:
      const _tmr = window._tmr || (window._tmr = []);
      _tmr.push({
        type: 'reachGoal',
        id: Number(window.envKeys.MY_TRACKER_KEY),
        goal: triggerEvent,
      });
      break;
    case AnalyticsType.adriver:
      adriverEvent(
        {
          sid: `${Number(window.envKeys.ADRIVER_KEY)}`,
          bt: `${Number(window.envKeys.ADRIVER_KEY2)}`,
          sz: triggerEvent,
        },
        { id: '', gid1: '', yid1: '' }
      );
      break;
    case AnalyticsType.salesNinja:
      const isReadyNinja = window?.SalesNinja?.ready;
      const ninja = window?.ninja;

      if (isReadyNinja && typeof ninja === 'function') {
        ninja('reachGoal', triggerEvent, params);
      }
      break;
    case AnalyticsType.cpaScaleo:
      sendCpaScaleoAnalytic(triggerEvent, params);
      break;
    case AnalyticsType.roxot:
      sendRoxotAnalytic(triggerEvent);
      break;
    case AnalyticsType.ym:
      ym('reachGoal', triggerEvent, params);
      break;
    case AnalyticsType.amplitude:
      sendEventToAmplitude(triggerEvent, params);
      break;
    default:
      ym('reachGoal', triggerEvent, params);
      sendEventToAmplitude(triggerEvent, params);
  }
};
