/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import { MoneyRangeInput } from '@pulse-web-ui/range-input';

import {
  AdaptiveHeadersWrapper,
  AdaptivePerMonthFooter,
  AdaptiveSliderListWrapper,
  Container,
  HeaderAdaptive1WithSubTitle,
  HeaderAdaptive5,
  IflSumPerMonthContainer,
  Skeleton,
  SumWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { FeatureFlags, analyticEvents } from '@src/constants';
import { GlobalErrorInfo, IflSumPerMonth, TotalSum } from '@src/features';
import { IflSumPerMonthError } from '@src/features/ifl-sum-per-month';
import {
  useFeatureFlags,
  useHandlePressKey,
  useMergeSublimits,
  useNextStep,
  useRequest,
} from '@src/hooks';
import { PetRisk, PetsActionTypes, Store } from '@src/store';
import { Sublimits } from '@src/store/pets/pets-store.types';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  numBetween,
  numFormat,
} from '@src/utils';

import { usePetsDraft } from './hooks';
import { createSublimitsFromPresetData, sublimitRisksConverter } from './utils';

let schemaObj: Record<string, any> = {};
let defaults: Record<string, number> = {};
const LIABILITY_2 = 'Liability_2';

export const PetsInsuranceSum: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasChange, setHasChange] = useState<boolean>(false);

  const {
    state: {
      stateFormPets: {
        selectedIProduct,
        risks,
        selectedPetData,
        currentSubLimits,
        sublimits,
        currentSum,
        currentSumMinLimit,
        currentSumMaxLimit,
        currentSumErr,
        presetData,
        promoCodeApplyed,
      },
      stateWizard: { currentStep },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const [schema, setSchema] = useState<any>();
  const {
    res: [isFeaturePriceMonth],
  } = useFeatureFlags([FeatureFlags.PetsPriceMonth]);

  useEffect(() => {
    // TODO: добавить аналитику по питомцам
    // sendAnalyticEvent(analyticEvents.);

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const isError = (code: string) =>
    code === LIABILITY_2 &&
    sublimits?.risks?.length === 1 &&
    sublimits?.risks[0].code === LIABILITY_2
      ? true
      : false;

  const getErrorMessage = (code: string) =>
    code === LIABILITY_2
      ? t('COMMON:errors.civilLiabilityCannotBeInsuredWithoutCovering')
      : undefined;

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const { isLoading, error, res, refetch } = useRequest<Sublimits>(
    'petsFormGetSublimits',
    'post',
    '/v2/references/get-sublimits',
    {
      productCode: selectedIProduct?.code,
      kind: selectedPetData?.kind,
      risks: risksArray,
    },
    [risks, selectedPetData?.kind, selectedIProduct?.code]
  );

  const {
    isFetching: isFetchingSumPerQuarter,
    error: errorSumPerQuarter,
    res: resSumPerQuarter,
    refetch: refetchSumPerQuarter,
  } = useRequest(
    'petsFormGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      insuranceSum: Number(currentSum),
      productCode: selectedIProduct?.code,
      returnMinDuration: true,
      risks: risksArray,
      pets: {
        kind: selectedPetData?.kind,
        age: selectedPetData?.age,
        coverages: sublimitRisksConverter(sublimits?.risks ?? []),
      },
      promoCode: promoCodeApplyed ? promoCodeApplyed : undefined,
    },
    [
      currentSum,
      selectedIProduct?.code,
      risks,
      selectedPetData?.kind,
      selectedPetData?.age,
      sublimits,
      promoCodeApplyed,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: isLoading,
    });
  }, [isLoading]);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: (errors as any).inner?.reduce(
              (allErrors: any, currentError: ValidationError) => ({
                ...allErrors,
                [currentError.path as string]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const resolver = useYupValidationResolver(schema);

  const {
    control,
    formState: { errors, isValid },
    reset,
    trigger,
  } = useForm<Record<string, number>>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...defaults,
    },
  });

  const handleKeyPressEnter = () => {
    if (currentSumErr || !isValid) {
      return;
    }

    if (!!presetData) {
      const lastStepForMain = 2;

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: lastStepForMain,
      });
      navigate(-1);
    } else {
      dispatch({ type: WizardActionTypes.UpdateWantNextStep, payload: true });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, currentSum, isValid);

  const updateLimits = async (code: string, value: number) => {
    setStepUpdated();
    const valid = await trigger(code);

    if (valid) {
      const updatedSublimits: Sublimits = {
        risks:
          sublimits?.risks.map((item) => {
            if (item.code === code) {
              return {
                ...item,
                defaultInsuranceSum: String(value),
              };
            }

            return item;
          }) || [],
        totalMaxLimit: currentSumMaxLimit,
        totalMinLimit: currentSumMinLimit,
      };

      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: updatedSublimits,
      });

      let totalSum = 0;

      updatedSublimits.risks.map(
        (risk) => (totalSum += Number(risk.defaultInsuranceSum))
      );

      dispatch({
        type: PetsActionTypes.SetCurrentSum,
        payload: totalSum,
      });
    }
  };

  const validatePage = useCallback(() => !currentSumErr, [currentSumErr]);

  const handleRefetch = () => {
    if (error) refetch();
    if (errorSumPerQuarter) refetchSumPerQuarter();
  };

  useNextStep(validatePage);
  usePetsDraft();

  useEffect(() => {
    if (sublimits) {
      let totalSum = 0;

      defaults = {};
      schemaObj = {};

      sublimits.risks.forEach((risk: PetRisk) => {
        schemaObj[risk.code] = yup
          .number()
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          )
          .typeError(t('COMMON:errors.mustEnterNumber') || '')
          .min(
            Number(risk.minLimit),
            `${t(
              'COMMON:errors.amountNotLess'
            )} ${currencyRuLocaleWithoutFraction(Number(risk.minLimit))} ₽`
          )
          .max(
            Number(risk.maxLimit),
            `${t(
              'COMMON:errors.amountNoMore'
            )} ${currencyRuLocaleWithoutFraction(Number(risk.maxLimit))} ₽`
          )
          .integer(t('COMMON:errors.sumMustBeIntegerValue') || '')
          .required();

        totalSum += Number(risk.defaultInsuranceSum);
        defaults[risk.code] = Number(risk.defaultInsuranceSum);
      });

      const newSchema = yup.object(schemaObj);
      setSchema(newSchema);

      dispatch({
        type: PetsActionTypes.SetCurrentSum,
        payload: totalSum,
      });
      reset(defaults);
    }
  }, [sublimits?.risks]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (sublimits?.risks) {
        refetchSumPerQuarter();
        if (!!presetData && hasChange) {
          sendAnalyticEvent(analyticEvents.petChangeCoveragePreset);
          setHasChange(false);
        }
      }
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [sublimits?.risks]);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: !!presetData
          ? createSublimitsFromPresetData(presetData, res, sublimits)
          : !!sublimits
          ? sublimits
          : res,
      });

      dispatch({
        type: PetsActionTypes.SetCurrentSumMinLimit,
        payload: res.totalMinLimit ?? selectedIProduct?.minProductLimit ?? '0',
      });
      dispatch({
        type: PetsActionTypes.SetCurrentSumMaxLimit,
        payload: res.totalMaxLimit ?? selectedIProduct?.maxProductLimit ?? '0',
      });
    }
  }, [isLoading, res, presetData]);

  const handleMergedSublimits = useCallback(
    (value: PetRisk[]) => {
      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: {
          ...sublimits,
          risks: value,
        },
      });
    },
    [dispatch, sublimits]
  );

  useMergeSublimits<PetRisk>({
    firstSublimits: res?.risks,
    secondSublimits: sublimits?.risks,
    handleMergedSublimits,
  });

  useEffect(() => {
    if (currentSubLimits) {
      dispatch({
        type: PetsActionTypes.SetCurrentSumMinLimit,
        payload:
          currentSubLimits.totalMinLimit ??
          selectedIProduct?.minProductLimit ??
          '0',
      });
      dispatch({
        type: PetsActionTypes.SetCurrentSumMaxLimit,
        payload:
          currentSubLimits.totalMaxLimit ??
          selectedIProduct?.maxProductLimit ??
          '0',
      });
    }
  }, []);

  useEffect(() => {
    if (currentSum > 0) {
      dispatch({
        type: PetsActionTypes.SetInsuranceSum,
        payload: Number(currentSum),
      });
    }
  }, [currentSum]);

  useEffect(() => {
    dispatch({
      type: PetsActionTypes.SetCurrentSumErr,
      payload: !numBetween(
        currentSum,
        Number(currentSumMinLimit),
        Number(currentSumMaxLimit),
        true
      ),
    });
  }, [currentSum, currentSumMinLimit, currentSumMaxLimit]);

  useEffect(() => {
    const disableNav = currentSumErr || !isValid;

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: disableNav,
    });
  }, [currentSumErr, currentStep, isValid]);

  if (isLoading) return <Skeleton />;

  if (
    error ||
    (errorSumPerQuarter &&
      errorSumPerQuarter?.response?.data?.code !== 'VALIDATION_ERROR' &&
      errorSumPerQuarter?.response?.data?.code !== 'BUSINESS_ERROR' &&
      errorSumPerQuarter?.response?.data?.code !== 'TECHNICAL_ERROR')
  )
    return <GlobalErrorInfo retryHandler={handleRefetch} />;

  if (!isLoading && res?.risks?.length === 0)
    return (
      <div>
        <h3>{t('COMMON:labels.listEmpty')}</h3>
      </div>
    );

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <HeaderAdaptive1WithSubTitle>
          {t('PETS_FORM_DATA:headers.selectSumsInsured')}
        </HeaderAdaptive1WithSubTitle>
        <HeaderAdaptive5>
          {t('PETS_FORM_DATA:hints.maximumPayout')}
        </HeaderAdaptive5>
      </AdaptiveHeadersWrapper>
      <AdaptiveSliderListWrapper marginBottom={32}>
        {sublimits?.risks.map((item: PetRisk) => (
          <Controller
            key={`${item.code}-key`}
            name={item.code}
            control={control}
            render={({ field }) => (
              <MoneyRangeInput
                isPriceFormatted
                id={item.code}
                label={item.name}
                value={Number(item.defaultInsuranceSum) || 0}
                min={Number(item.minLimit) || 0}
                max={Number(item.maxLimit) || 0}
                step={Number(item.step) || 0}
                onChange={(value: number) => {
                  field.onChange(value);
                  updateLimits(item.code, value);
                  setHasChange(true);
                }}
                error={!!errors[item.code]?.message || isError(item.code)}
                helperErrorText={
                  errors[item.code]?.message ||
                  (isError(item.code) ? getErrorMessage(item.code) : undefined)
                }
                testId={`pet-form-${item.code}`}
              />
            )}
          />
        ))}
      </AdaptiveSliderListWrapper>
      {sublimits?.risks && (
        <AdaptivePerMonthFooter>
          <SumWrapper padding="0">
            <TotalSum
              title={t('PETS_FORM_DATA:hints.totalRefundAmount') || ''}
              totalSum={numFormat(currentSum)}
              isError={currentSumErr}
              subtitleText={
                `${t('COMMON:labels.from')} ${numFormat(
                  Number(currentSumMinLimit)
                )} ₽ ` +
                `${t('COMMON:labels.to')} ${numFormat(
                  Number(currentSumMaxLimit)
                )} ₽`
              }
            />
          </SumWrapper>
          <SumWrapper padding="0">
            <IflSumPerMonthContainer>
              {(currentSum || currentSum === 0) && sublimits.risks && (
                <IflSumPerMonth
                  isLoading={isFetchingSumPerQuarter || !!errorSumPerQuarter}
                  disabled={isLoading}
                  sumPerMonth={Math.ceil(
                    resSumPerQuarter?.prices[0].premiumAndDelta / 3
                  )}
                  sumPromoPerMonth={
                    resSumPerQuarter?.prices[0]?.premiumAndDeltaPromo &&
                    Math.ceil(
                      Number(resSumPerQuarter?.prices[0].premiumAndDeltaPromo) /
                        3
                    )
                  }
                  title={
                    (isFeaturePriceMonth
                      ? t('COMMON:labels.pricePerMonth')
                      : t('PETS_FORM_DATA:hints.pricePerMonth')) || ''
                  }
                  description={
                    isFeaturePriceMonth
                      ? t('PETS_FORM_DATA:labels.writeInThreeMonths', {
                          cost: `${currencyRuLocaleWithoutFraction(
                            Math.ceil(
                              resSumPerQuarter?.prices[0]
                                ?.premiumAndDeltaPromo ||
                                resSumPerQuarter?.prices[0].premiumAndDelta
                            )
                          )} ₽`,
                        })
                      : ''
                  }
                />
              )}
              {errorSumPerQuarter && (
                <IflSumPerMonthError
                  errorResponse={errorSumPerQuarter}
                  currentSumErr={
                    currentSumErr &&
                    (currentSum < Number(currentSumMinLimit) ? 'less' : 'gt')
                  }
                />
              )}
            </IflSumPerMonthContainer>
          </SumWrapper>
        </AdaptivePerMonthFooter>
      )}
    </Container>
  );
};
