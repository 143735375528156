import React from 'react';

export const HouseIcon = () => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.8002 23.4478V59.0652H7.2002V23.4478L12.9484 19.4652V9.86523L20.0002 5.86523V14.088L32.0002 6.26523L56.8002 23.4478Z"
        fill="#E4E7EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0001 26.2641C34.2093 26.2641 36.0001 24.4732 36.0001 22.2641C36.0001 20.0549 34.2093 18.2641 32.0001 18.2641C29.791 18.2641 28.0001 20.0549 28.0001 22.2641C28.0001 24.4732 29.791 26.2641 32.0001 26.2641ZM32.0001 27.8641C35.0929 27.8641 37.6001 25.3569 37.6001 22.2641C37.6001 19.1713 35.0929 16.6641 32.0001 16.6641C28.9074 16.6641 26.4001 19.1713 26.4001 22.2641C26.4001 25.3569 28.9074 27.8641 32.0001 27.8641Z"
        fill="#21145A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8 5.3664C20.7999 5.07561 20.6422 4.80773 20.3878 4.66673C20.1335 4.52574 19.8227 4.53385 19.5761 4.68791L12.3761 9.18565C12.1421 9.33183 11.9999 9.58826 12 9.86415L12 18.9781L6.73043 22.7991C6.5229 22.9496 6.40005 23.1904 6.40005 23.4468V59.0641H1.60005C1.15822 59.0641 0.800049 59.4223 0.800049 59.8641C0.800049 60.306 1.15822 60.6641 1.60005 60.6641L62.4 60.6642C62.8419 60.6642 63.2 60.306 63.2 59.8642C63.2 59.4223 62.8419 59.0642 62.4 59.0642L28.8 59.0641V42.2641C28.8 38.7295 25.9347 35.8641 22.4 35.8641C18.8654 35.8641 16 38.7295 16 42.2641V59.0641H8.00005V23.8549L13.5037 19.8642H13.6L13.6 19.7943L20.675 14.6642L20.8 14.6642L20.8 14.5735L32.0077 6.44676L56 23.2779V29.267C56 29.7088 56.3582 30.067 56.8 30.067C57.2419 30.067 57.6 29.7088 57.6 29.267V22.8619C57.6 22.6012 57.473 22.3568 57.2595 22.207L32.4595 4.80923C32.18 4.61315 31.8068 4.61607 31.5304 4.81649L20.8 12.5972L20.8 5.3664ZM19.2 13.7573L19.2 6.80941L13.6 10.3077L13.6 17.8179L19.2 13.7573ZM27.2 42.2641V59.0641H17.6V42.2641C17.6 39.6132 19.7491 37.4641 22.4 37.4641C25.051 37.4641 27.2 39.6132 27.2 42.2641Z"
        fill="#21145A"
      />
      <path
        d="M63.2001 44.6652C63.2001 51.7345 57.4693 57.4652 50.4001 57.4652C43.3309 57.4652 37.6001 51.7345 37.6001 44.6652C37.6001 37.596 43.3309 31.8652 50.4001 31.8652C57.4693 31.8652 63.2001 37.596 63.2001 44.6652Z"
        fill="#1AA800"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4001 55.8652C56.5857 55.8652 61.6001 50.8508 61.6001 44.6652C61.6001 38.4796 56.5857 33.4652 50.4001 33.4652C44.2145 33.4652 39.2001 38.4796 39.2001 44.6652C39.2001 50.8508 44.2145 55.8652 50.4001 55.8652ZM50.4001 57.4652C57.4693 57.4652 63.2001 51.7345 63.2001 44.6652C63.2001 37.596 57.4693 31.8652 50.4001 31.8652C43.3309 31.8652 37.6001 37.596 37.6001 44.6652C37.6001 51.7345 43.3309 57.4652 50.4001 57.4652Z"
        fill="#21145A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4625 40.0125C56.823 40.2679 56.9083 40.7672 56.6529 41.1277L49.8529 50.7277C49.7107 50.9285 49.484 51.0527 49.2382 51.0644C48.9925 51.0761 48.755 50.9741 48.5943 50.7878L44.9943 46.6139C44.7057 46.2793 44.743 45.7742 45.0776 45.4856C45.4121 45.197 45.9173 45.2343 46.2059 45.5689L49.1382 48.9687L55.3472 40.2029C55.6026 39.8423 56.1019 39.7571 56.4625 40.0125Z"
        fill="white"
      />
    </svg>
  );
};
