import React from 'react';

import { CheckSmall } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';
import {
  Tooltip,
  TooltipBgColor,
  TriggerDirection,
} from '@pulse-web-ui/tooltip';

import {
  StyledBaseBox,
  StyledBox,
  StyledCheckIcon,
  StyledContainer,
  StyledLabel,
  StyledOption,
  StyledOptionLabel,
  StyledPrice,
  StyledText,
  StyledTooltip,
} from './risk-list.styles';

export enum RiskChildType {
  RISK,
  OPTION,
}

export interface RiskChild {
  type: RiskChildType;
  title?: string;
  coverage?: string;
  description?: string;
  children?: RiskChild[];
}

interface Props {
  risks: RiskChild[];
}

export const RiskList = ({ risks }: Props) => {
  const theme: any = useTheme();

  const renderElement = (
    { type, title, coverage, description, children }: RiskChild,
    isFirstLevel: boolean
  ) => {
    if (type === RiskChildType.RISK) {
      return (
        <StyledBaseBox key={title}>
          {isFirstLevel ? (
            <StyledLabel>{title}</StyledLabel>
          ) : (
            <StyledOptionLabel>{title}</StyledOptionLabel>
          )}
          {children?.map((risk) => renderElement(risk, false))}
        </StyledBaseBox>
      );
    }

    return (
      <StyledOption key={title}>
        <StyledBox>
          <StyledCheckIcon>
            <CheckSmall width={24} color={theme.colors.accent.success.normal} />
          </StyledCheckIcon>
          <StyledText>{title}</StyledText>
          <StyledPrice>{coverage}</StyledPrice>
          <StyledTooltip>
            <Tooltip
              tooltipText={description}
              tooltipBgColor={TooltipBgColor.GRAY}
              trigger={TriggerDirection.HOVER}
            />
          </StyledTooltip>
        </StyledBox>
      </StyledOption>
    );
  };

  return (
    <StyledContainer>
      {risks.map((risk) => renderElement(risk, true))}
    </StyledContainer>
  );
};
