import type { ReactNode } from 'react';

import { FormSub, FormSubTitle } from '../../form-elements';

interface Props {
  title: string;
  subTitle?: ReactNode | string;
}

export const FormSubTitleWithSubText = ({ title, subTitle }: Props) => (
  <>
    <FormSubTitle style={{ marginBottom: !!subTitle ? 8 : 16 }}>
      {title}
    </FormSubTitle>
    {subTitle && <FormSub margin={'0 0 16px'}>{subTitle}</FormSub>}
  </>
);
