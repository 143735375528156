import { Product } from '@src/constants';
import { IFLFlatActionTypes, IFLHouseActionTypes } from '@src/store';

import { SmartAddressProps } from '../smart-address.types';

export const cityDefaultValue = {
  city: '',
  city_area: '',
  city_district: '',
  city_district_fias_id: '',
  city_district_kladr_id: '',
  city_district_type: '',
  city_district_type_full: '',
  city_district_with_type: '',
  city_fias_id: '',
  city_kladr_id: '',
  city_type: '',
  city_type_full: '',
  city_with_type: '',
};

export const settlementDefaultValue = {
  settlement: '',
  settlement_fias_id: '',
  settlement_kladr_id: '',
  settlement_type: '',
  settlement_type_full: '',
};

export const streetDefaultValue = {
  street: '',
  street_fias_id: '',
  street_kladr_id: '',
  street_type: '',
  street_type_full: '',
  street_with_type: '',
};

export const houseDefaultValue = {
  house: '',
  house_fias_id: '',
  house_kladr_id: '',
  house_type_full: '',
  house_type: '',
  block_type: '',
  block: '',
  block_type_full: '',
};

export const flatDefaultValue = {
  flat: '',
  flat_area: '',
  flat_price: null,
  flat_type: '',
  flat_type_full: '',
  flat_fias_id: '',
  flat_cadnum: null,
};

export const formDefaultValue = { city: '', street: '', house: '', flat: '' };

export const setDadaValueActions = {
  [Product.APARTMENT]: IFLFlatActionTypes.SetDadaValue,
  [Product.HOUSE]: IFLHouseActionTypes.SetDadaValue,
};

export const setCustomStreetActions: Record<
  SmartAddressProps['type'],
  | IFLFlatActionTypes.SetIsSelectedCustomStreet
  | IFLHouseActionTypes.SetIsSelectedCustomStreet
> = {
  [Product.APARTMENT]: IFLFlatActionTypes.SetIsSelectedCustomStreet,
  [Product.HOUSE]: IFLHouseActionTypes.SetIsSelectedCustomStreet,
};

export const setCustomHouseActions: Record<
  SmartAddressProps['type'],
  | IFLFlatActionTypes.SetIsSelectedCustomHouse
  | IFLHouseActionTypes.SetIsSelectedCustomHouse
> = {
  [Product.APARTMENT]: IFLFlatActionTypes.SetIsSelectedCustomHouse,
  [Product.HOUSE]: IFLHouseActionTypes.SetIsSelectedCustomHouse,
};

export const emptyStreetSuggestionsActions: Record<
  SmartAddressProps['type'],
  | IFLFlatActionTypes.SetIsEmptyStreetSuggestions
  | IFLHouseActionTypes.SetIsEmptyStreetSuggestions
> = {
  [Product.APARTMENT]: IFLFlatActionTypes.SetIsEmptyStreetSuggestions,
  [Product.HOUSE]: IFLHouseActionTypes.SetIsEmptyStreetSuggestions,
};

export const emptyHouseSuggestionsActions: Record<
  SmartAddressProps['type'],
  | IFLFlatActionTypes.SetIsEmptyHouseSuggestions
  | IFLHouseActionTypes.SetIsEmptyHouseSuggestions
> = {
  [Product.APARTMENT]: IFLFlatActionTypes.SetIsEmptyHouseSuggestions,
  [Product.HOUSE]: IFLHouseActionTypes.SetIsEmptyHouseSuggestions,
};
