/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@pulse-web-ui/alert';
import { Button } from '@pulse-web-ui/button';
import { Coins, Info, InsurancePet } from '@pulse-web-ui/icons';
import { SelectorCard } from '@pulse-web-ui/selector-card';
import { useTheme } from '@pulse-web-ui/theme';

import {
  AdaptiveListWrapper,
  Container,
  ControllerContainer,
  FormLabel,
  FormSub,
  IflSumPerMonthContainer,
  LinkContainer,
  RiskWrapper,
  Skeleton,
  SumWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { FeatureFlags, analyticEvents } from '@src/constants';
import {
  GlobalErrorInfo,
  IflSumPerMonth,
  SumPerMonthErrorMessage,
} from '@src/features';
import {
  useFeatureFlags,
  useHandlePressKey,
  useNextStep,
  useRequest,
} from '@src/hooks';
import {
  createSublimitsFromPresetData,
  getTemporaryFranchise,
  sublimitRisksConverter,
} from '@src/pages/pets-form/utils';
import { Coverage, PetsActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode, Risk } from '@src/types';
import {
  addTestAttribute,
  currencyRuLocaleWithoutFraction,
  evenRisk,
} from '@src/utils';

import { CostOnRiskTitle } from './components/cost-on-risk-title';
import { usePetsDraft } from './hooks';
import { StyledStrongMessage } from './pets-risks.styles';

export const PetsMainRisks: FC = () => {
  const theme: any = useTheme();
  const {
    state: {
      stateFormPets: {
        selectedIProduct,
        risks,
        selectedPetData,
        sublimits,
        currentSubLimits,
        presetData,
        promoCodeApplyed,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formattedSubLimits, setFormattedSubLimits] = useState<
    Coverage[] | undefined
  >();
  const [listBoxStatus, setListBoxStatus] = useState<'error' | undefined>(
    undefined
  );
  const {
    res: [isShowCostOnRiskScreens, isTeleveterinaryInRisksList],
    isFeatureFlagsLoading,
  } = useFeatureFlags([
    FeatureFlags.ShowCostOnRiskScreens,
    FeatureFlags.TeleveterinaryInRisksList,
  ]);

  const activeRisks = useMemo(
    () =>
      risks
        ?.filter((riskItem) => {
          if (riskItem.active && riskItem.binding) {
            return riskItem;
          }
        })
        .map((filteredRiskItem) => filteredRiskItem.code),
    [risks]
  );

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const handleKeyPressEnter = () => {
    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: true,
    });
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const { isLoading, error, res, refetch, isRefetching } = useRequest<{
    risks: Risk[];
  }>(
    'petsFormGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: selectedIProduct?.code,
      kind: selectedPetData?.kind,
    },
    [selectedIProduct?.code, selectedPetData?.kind],
    true
  );

  const {
    isLoading: isLoadingGetSubLimits,
    error: errorGetSubLimits,
    res: resGetSubLimits,
    refetch: refetchGetSubLimits,
  } = useRequest(
    'risksSubLimits',
    'post',
    '/v2/references/get-sublimits',
    {
      productCode: selectedIProduct?.code,
      kind: selectedPetData?.kind,
      risks: risks?.map((risk) => risk.code),
    },
    [risks, selectedPetData?.kind, selectedIProduct?.code],
    true
  );

  const {
    isLoading: isLoadingGetPrices,
    error: errorGetPrices,
    res: resGetPrices,
    refetch: refetchGetPrices,
    isRefetching: isRefetchingGetPrices,
  } = useRequest(
    'risksGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      insuranceSum: 0,
      productCode: selectedIProduct?.code,
      returnMinDuration: true,
      risks: activeRisks,
      pets: {
        kind: selectedPetData?.kind,
        age: selectedPetData?.age,
        coverages: formattedSubLimits,
      },
      promoCode: promoCodeApplyed ? promoCodeApplyed : undefined,
    },
    [
      selectedIProduct?.code,
      risks,
      selectedPetData?.kind,
      selectedPetData?.age,
      formattedSubLimits,
      promoCodeApplyed,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload:
        isLoading ||
        isRefetching ||
        isLoadingGetSubLimits ||
        isLoadingGetPrices ||
        isFeatureFlagsLoading,
    });
  }, [
    isLoading,
    isRefetching,
    isLoadingGetSubLimits,
    isLoadingGetPrices,
    isFeatureFlagsLoading,
  ]);

  useEffect(() => {
    if (!risks) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (isShowCostOnRiskScreens && formattedSubLimits) {
      refetchGetPrices();
    }
  }, [formattedSubLimits, isShowCostOnRiskScreens]);

  useEffect(() => {
    if (isShowCostOnRiskScreens && risks && !currentSubLimits) {
      refetchGetSubLimits();
    }
  }, [risks, currentSubLimits, isShowCostOnRiskScreens]);

  useEffect(() => {
    if (isShowCostOnRiskScreens && !isLoadingGetSubLimits && resGetSubLimits) {
      dispatch({
        type: PetsActionTypes.SetCurrentSubLimits,
        payload: resGetSubLimits,
      });
    }
  }, [isLoadingGetSubLimits, resGetSubLimits, isShowCostOnRiskScreens]);

  useEffect(() => {
    if (isShowCostOnRiskScreens && currentSubLimits?.risks) {
      const changedLimits =
        sublimits?.risks.filter((risk) => activeRisks?.includes(risk.code)) ||
        [];

      const currentSelectedRisks =
        currentSubLimits.risks.filter(
          (risk) =>
            activeRisks?.includes(risk.code) &&
            !changedLimits.find((item) => item.code === risk.code)
        ) || [];

      setFormattedSubLimits(
        sublimitRisksConverter([...currentSelectedRisks, ...changedLimits])
      );
    }
  }, [currentSubLimits, sublimits, isShowCostOnRiskScreens, activeRisks]);

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !risks?.some(evenRisk) || false,
    });

    setListBoxStatus(!risks?.some(evenRisk) ? 'error' : undefined);

    return risks?.some(evenRisk) || false;
  }, [risks]);

  useNextStep(validatePage);
  usePetsDraft();

  useEffect(() => {
    validatePage();
  }, [risks]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: listBoxStatus === 'error',
    });
  }, [listBoxStatus]);

  useEffect(() => {
    if (
      !presetData &&
      !isLoading &&
      !isRefetching &&
      res &&
      res.risks?.length > 0
    ) {
      dispatch({
        type: PetsActionTypes.SetRisks,
        payload: !!risks?.length
          ? res.risks.map((risk) => {
              const currentRisk = risks?.find(({ code }) => risk.code === code);

              if (!!currentRisk) {
                return { ...currentRisk };
              }

              return { ...risk };
            })
          : res.risks,
      });
    }
  }, [presetData, isLoading, isRefetching, res]);

  const onClick = (code: string) => {
    if (!!presetData) {
      sendAnalyticEvent(analyticEvents.petChangeRiskPreset);
    }
    setStepUpdated();

    dispatch({
      type: PetsActionTypes.SetSublimits,
      payload: undefined,
    });

    setListBoxStatus(undefined);

    const newRisks = risks?.map((item) => {
      if (item.code === code) {
        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });

    dispatch({
      type: PetsActionTypes.SetRisks,
      payload: newRisks,
    });

    if (isShowCostOnRiskScreens) {
      const currentSelectedRisks =
        currentSubLimits?.risks.filter((risk) =>
          newRisks?.find((item) => item.active && item.code === risk.code)
        ) || [];

      const payload = {
        ...currentSubLimits,
        risks: currentSelectedRisks,
      };

      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: !!presetData
          ? createSublimitsFromPresetData(presetData, payload)
          : payload,
      });

      setFormattedSubLimits(sublimitRisksConverter(currentSelectedRisks));
    }
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: PetsActionTypes.SetRisksPage,
      payload: 'primary',
    });
    navigate('/pets-risk-descriptions');
  };

  const handleRefetch = () => {
    if (error) refetch();
    if (errorGetSubLimits) refetchGetSubLimits();
    if (errorGetPrices) refetchGetPrices();
  };

  if (
    error ||
    errorGetSubLimits ||
    (errorGetPrices &&
      errorGetPrices?.response?.status !== 401 &&
      errorGetPrices?.response?.data?.code !== 'VALIDATION_ERROR' &&
      errorGetPrices?.response?.data?.code !== 'BUSINESS_ERROR' &&
      errorGetPrices?.response?.data?.code !== 'TECHNICAL_ERROR')
  )
    return <GlobalErrorInfo retryHandler={handleRefetch} />;

  if (
    isLoading ||
    isRefetching ||
    isFeatureFlagsLoading ||
    isLoadingGetSubLimits ||
    (!currentSubLimits && isShowCostOnRiskScreens)
  )
    return <Skeleton />;

  return (
    <Container>
      <FormLabel>{t('COMMON:headers.selectMainRisks')}</FormLabel>
      <FormSub margin="8px 0 24px">
        {t('COMMON:hints.selectOneRiskToContinue')}
      </FormSub>
      <ControllerContainer margin="0 0 32px 0">
        {isTeleveterinaryInRisksList ? (
          <Alert
            type="warning"
            description={
              <Trans
                components={{
                  span: <StyledStrongMessage />,
                }}
              >
                {t('PETS_FORM_DATA:hints.weCompensateExpenses')}
              </Trans>
            }
            icon={
              <Coins
                width={24}
                color={
                  theme.colors.icon?.quaternary || theme.colors.text.tertiary
                }
              />
            }
          />
        ) : (
          <Alert
            type="benefit"
            title={
              t('PETS_FORM_DATA:labels.televeterinaryAlreadyIncluded') || ''
            }
            description={
              t('PETS_FORM_DATA:hints.contactVeterinarianByPhone') || ''
            }
            icon={<InsurancePet width={24} color={theme.colors.icon.primary} />}
          />
        )}
      </ControllerContainer>
      <AdaptiveListWrapper>
        {(!risks || risks?.length === 0) && t('COMMON:errors.noData')}
        {risks?.map((item) => {
          return item.binding ? (
            <RiskWrapper key={item.code}>
              <SelectorCard
                key={`${item.code}_${item.name}`}
                id={item.code}
                mobileFullWidth
                name={item.code}
                label={item.name}
                description={item.description}
                checked={item.active}
                readOnly={!item.switchability}
                onClick={() => onClick(item.code)}
                caption={
                  item?.temporaryFranchise
                    ? getTemporaryFranchise(item.temporaryFranchise)
                    : null
                }
                testId="pet-form-risks"
              />
            </RiskWrapper>
          ) : null;
        })}
        {isTeleveterinaryInRisksList && (
          <RiskWrapper>
            <SelectorCard
              mobileFullWidth
              readOnly
              id="televeterinary"
              name="televeterinary"
              label={t('PETS_FORM_DATA:labels.televeterinary')}
              description={
                t('PETS_FORM_DATA:hints.contactVeterinarianPhoneAndVideo') || ''
              }
              testId="pet-form-risks"
            />
          </RiskWrapper>
        )}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutMainRisks') || ''}
          {...addTestAttribute('pet-form-button-about-risks')}
        />
      </LinkContainer>
      {isShowCostOnRiskScreens && (
        <SumWrapper>
          <IflSumPerMonthContainer>
            {formattedSubLimits && (
              <IflSumPerMonth
                isError={!activeRisks?.length}
                isLoading={
                  isLoadingGetPrices ||
                  isRefetchingGetPrices ||
                  !!errorGetPrices
                }
                disabled={isLoadingGetSubLimits}
                sumPerMonth={Math.ceil(
                  Number(resGetPrices?.prices[0].premiumAndDelta) / 3
                )}
                sumPromoPerMonth={
                  resGetPrices?.prices[0]?.premiumAndDeltaPromo &&
                  Math.ceil(
                    Number(resGetPrices?.prices[0].premiumAndDeltaPromo) / 3
                  )
                }
                sumPerMonthDesc={t('COMMON:labels.perMonth')}
                title={
                  <CostOnRiskTitle activeRiskCount={activeRisks?.length || 0} />
                }
                description={t('PETS_FORM_DATA:labels.writeInThreeMonths', {
                  cost: `${currencyRuLocaleWithoutFraction(
                    Math.ceil(
                      resGetPrices?.prices[0]?.premiumAndDeltaPromo ||
                        resGetPrices?.prices[0].premiumAndDelta
                    )
                  )} ₽`,
                })}
              />
            )}
            {!activeRisks?.length && (
              <SumPerMonthErrorMessage>
                {t('PETS_FORM_DATA:hints.chooseOneRisk')}
              </SumPerMonthErrorMessage>
            )}
          </IflSumPerMonthContainer>
        </SumWrapper>
      )}
    </Container>
  );
};
