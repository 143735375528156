/* eslint-disable indent */
import { lazy } from 'react';

import { sendAnalyticEvent } from '@src/components/web-analytic';
import { analyticEvents } from '@src/constants';
import {
  ComponentsConfig,
  ComponentsConfigType,
  HandleAnalytic,
} from '@src/types';

export const PetsTelevetPlusProtection = lazy(
  () => import('@src/pages/pets-televet-plus/pets-televet-plus-protection')
);

export const PetsTelevetPlusData = lazy(
  () => import('@src/pages/pets-televet-plus/pets-televet-plus-data')
);

const handleAnalytics: HandleAnalytic = (currentStep: number) => {
  switch (currentStep) {
    case 1:
      sendAnalyticEvent(analyticEvents.petToStepRiskPreset);
      break;
    case 2:
      sendAnalyticEvent(analyticEvents.petToStepAddRiskPreset);
      break;
    case 3:
      sendAnalyticEvent(analyticEvents.petToStepCoveragekPreset);
      break;
    default:
      break;
  }
};

export const petsTelevetPlusComponentsConfig: ComponentsConfig = {
  [ComponentsConfigType.MAIN]: {
    components: [],
  },
  [ComponentsConfigType.SETTINGS]: {
    components: [],
  },
  [ComponentsConfigType.DEFAULT]: {
    components: [PetsTelevetPlusData, PetsTelevetPlusProtection],
  },
  analyticEvent: analyticEvents.petToConfigExitPreset,
  handleAnalytic: handleAnalytics,
};
