import { Risk } from '@src/types';

export const getMainRisksToString = (risks: Risk[] | undefined): string => {
  if (!!risks?.length) {
    const mainRisks = risks
      ?.filter((item) => item.binding && item.active)
      .map((item) => item.name.toLowerCase())
      .join(', ');

    return mainRisks.charAt(0).toUpperCase() + mainRisks.slice(1);
  }

  return '';
};
