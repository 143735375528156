/* eslint-disable indent */
import { mixins, styled } from '@pulse-web-ui/theme';

import type { ContentProps, ContentWrapperProps } from './base-layout.types';

export const ContentWrapper = styled.div<ContentWrapperProps>`
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : 'auto')};
  padding: 0;
  ${mixins.flex({ horizontal: false })}
`;
export const Content = styled.div<ContentProps>`
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  ${mixins.flex({ horizontal: false })}
  ${({ verticalAlignment }) =>
    verticalAlignment
      ? mixins.flex({
          horizontal: false,
          flexGrow: 1,
          justifyContent: 'center',
        })
      : null}
`;

export const ContentFooter = styled.div`
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  ${mixins.flex({
    horizontal: true,
    justifyContent: 'space-between',
    wrap: false,
  })}
`;
