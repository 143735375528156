import { useContext, useMemo } from 'react';

import { Store } from '@src/store';

export const useMaterials = () => {
  const {
    state: {
      stateFormIFLHouse: { materials },
    },
  } = useContext(Store);

  const preparedMaterials = useMemo(
    () =>
      materials?.map((material) => ({
        id: material.nameForUser || '',
        label: material.nameForUser || '',
        value: material.code || '',
        description: material.shortDescription || '',
        disabled: !material.activeOption,
        message: material.waitingMessage,
      })),
    [materials]
  );

  return { preparedMaterials };
};
