import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

export interface DeclinePaymentAnalyticsEventsProps {
  selectedProduct: string | null;
  preset: string | null;
}

export const handleDeclinePaymentAnalyticsEvents = ({
  selectedProduct,
  preset,
}: DeclinePaymentAnalyticsEventsProps): void => {
  {
    const productsEvents: Record<string, () => void> = {
      apartment() {
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflFlatPresetPaymentDecline);
        } else {
          sendAnalyticEvent(analyticEvents.iflPaymentDecline);
          sendAnalyticEvent(analyticEvents.iflFlatPaymentDecline);
        }
      },
      house() {
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetPaymentDecline);
        } else {
          sendAnalyticEvent(analyticEvents.iflPaymentDecline);
          sendAnalyticEvent(analyticEvents.iflHousePaymentDecline);
        }
      },
      pets() {
        const event = !!preset
          ? analyticEvents.petPaymentDeclinePreset
          : analyticEvents.petPaymentDecline;
        sendAnalyticEvent(event);
      },
      ['sport-ns']() {
        sendAnalyticEvent(analyticEvents.sportPaymentDecline);
      },
      mite() {
        sendAnalyticEvent(analyticEvents.mitePaymentDecline);
      },
      ns() {
        sendAnalyticEvent(analyticEvents.nsPaymentDecline);
      },
      [Product.PETS_TELEVET_PLUS]() {
        sendAnalyticEvent(analyticEvents.petTelPayDecline);
      },
    };

    if (selectedProduct) {
      productsEvents[selectedProduct]?.();
    }
  }
};
