import { media, styled, typography } from '@pulse-web-ui/theme';

export const StyledCostTitle = styled.span`
  color: ${({ theme }) => theme.colors.text.interactive};
`;

export const StyledStrongMessage = styled.span`
  ${typography.medium16}

  ${media.desktop} {
    ${typography.medium18}
  }
`;
