/* eslint-disable indent */
import { useContext, useEffect, useMemo } from 'react';

import { OrderActionTypes, Store } from '@src/store';
import { OrderRequestData } from '@src/types';
import { prepareDate } from '@src/utils';

import { sublimitRisksConverter } from '../utils';

export const usePetsOrderData = () => {
  const {
    state: {
      stateFormPets: {
        selectedIProduct,
        selectedDuration,
        risks,
        selectedDate,
        insuranceSum,
        promoCode,
        selectedPetData,
        sublimits,
        getPrices,
      },
      stateUser: { agentLogin },
    },
    dispatch,
  } = useContext(Store);

  const orderData: OrderRequestData = useMemo(
    () => ({
      productCode: selectedIProduct?.code,
      ...(agentLogin && { agentLogin }),
      insuranceSum: String(insuranceSum),
      contractDuration: selectedDuration,
      risks: risks
        ?.filter((item) => item.active === true)
        .map((item) => item.code),
      effectiveSince: prepareDate(selectedDate),
      ...(promoCode &&
        getPrices?.isSuccessfulPromo !== false && { promoCode: promoCode }),
      pets: selectedPetData
        ? {
            ...selectedPetData,
            coverages: sublimits?.risks
              ? sublimitRisksConverter(sublimits?.risks)
              : [],
          }
        : undefined,
    }),
    [
      selectedIProduct,
      insuranceSum,
      selectedDuration,
      risks,
      selectedDate,
      promoCode,
      getPrices?.isSuccessfulPromo,
      selectedPetData,
      sublimits?.risks,
    ]
  );

  useEffect(
    () => () => {
      dispatch({
        type: OrderActionTypes.SetOrderRequestData,
        payload: orderData,
      });
    },
    [orderData]
  );
};
