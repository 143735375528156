import {
  FormInsuranceSportNSChoose,
  FormInsuranceSportNSCoverage,
  FormInsuranceSportNSData,
  FormSportNSQuantity,
} from '@src/pages/sport-form';
import type { ComponentsConfig } from '@src/types';
import { ComponentsConfigType } from '@src/types';

export const sportNSComponentsConfig: ComponentsConfig = {
  [ComponentsConfigType.MAIN]: {
    components: [
      FormSportNSQuantity,
      FormInsuranceSportNSCoverage,
      FormInsuranceSportNSData,
    ],
  },
  [ComponentsConfigType.SETTINGS]: {
    components: [],
  },
  [ComponentsConfigType.DEFAULT]: {
    components: [
      FormSportNSQuantity,
      FormInsuranceSportNSChoose,
      FormInsuranceSportNSCoverage,
      FormInsuranceSportNSData,
    ],
  },
  analyticEvent: '',
};
