import { useCallback, useContext } from 'react';

import { MiteActionTypes, SportNSActionTypes, Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { FormInsuranceProfile, InsurePerson } from '@src/types';

const insurePersonFormatting = (insurePerson: InsurePerson) => ({
  ...insurePerson,
  firstName: insurePerson.firstName?.trim(),
  lastName: insurePerson.lastName?.trim(),
  middleName: insurePerson.middleName?.trim(),
});

export const useNsDetailsInsured = () => {
  const {
    state: {
      stateWizard: { currentStep },
      stateUser: { profile: stateProfile },
    },
    dispatch,
  } = useContext(Store);

  const profileDataForForm: FormInsuranceProfile = {
    birthDate: stateProfile?.profile?.birthDate || '',
    firstName: stateProfile?.profile?.firstName || '',
    lastName: stateProfile?.profile?.lastName || '',
    middleName: stateProfile?.profile?.middleName || '',
    primaryRecordId: stateProfile?.profile?.primaryRecordId || undefined,
  };

  const storeFwNavDisabled = useCallback((data: boolean) => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: data,
    });
  }, []);

  const storeCurrentStep = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: currentStep + 1,
    });
  }, []);

  const storeWantNextStep = useCallback((data: boolean) => {
    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: data,
    });
  }, []);

  const storeInsurePersons = useCallback((data: InsurePerson[]) => {
    dispatch({
      type: NSActionTypes.SetInsurePersons,
      payload: data.map(insurePersonFormatting),
    });
  }, []);

  const storeSportNSInsurePersons = useCallback((data: InsurePerson[]) => {
    dispatch({
      type: SportNSActionTypes.SetInsurePersons,
      payload: data.map(insurePersonFormatting),
    });
  }, []);

  const storeMiteInsurePersons = useCallback((data: InsurePerson[]) => {
    dispatch({
      type: MiteActionTypes.SetInsuredPersons,
      payload: data.map(insurePersonFormatting),
    });
  }, []);

  return {
    storeFwNavDisabled,
    storeCurrentStep,
    storeWantNextStep,
    storeInsurePersons,
    profileDataForForm,
    storeSportNSInsurePersons,
    storeMiteInsurePersons,
  };
};
