import type { AuthTokens, AuthorizePageState } from './authorize-store.types';

export interface AuthStoreState {
  phoneNumber: string;
  displayPhoneNumber: string;
  authenticationToken?: string;
  confirmationId?: string;
  authorizeFailState?: AuthorizePageState | undefined;
  authorizeRefRoute?: string;
  authTokens?: AuthTokens | undefined;
  authorizeConfirmState?: AuthorizePageState | undefined;
  isRefreshTokenLoading: boolean;
}

export const initAuthStoreState: AuthStoreState = {
  phoneNumber: '',
  displayPhoneNumber: '',
  authenticationToken: undefined,
  confirmationId: undefined,
  authorizeFailState: undefined,
  authorizeRefRoute: undefined,
  authTokens: undefined,
  isRefreshTokenLoading: true,
};
