import { useContext, useEffect } from 'react';

import { Store, UserActionTypes } from '@src/store';

export const resetPromo = (
  promoCodeValue?: string,
  successfulPromo?: boolean | string,
  callback?: (() => void | null) | undefined | null,
  ...deps: Array<string | undefined>
): void => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    if (promoCodeValue?.length === 0 && !successfulPromo) {
      dispatch({
        type: UserActionTypes.SetPromoCodeFailedMessage,
        payload: undefined,
      });
      callback?.();
    }
  }, [...deps]);
};
