import React, { VFC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledCostTitle } from '@src/pages/pets-form/pets-risks.styles';
import { getRiskDeclensions, numWord } from '@src/utils';

import { CostOnRiskTitleProps } from './cost-on-risk-title.types';

export const CostOnRiskTitle: VFC<CostOnRiskTitleProps> = memo(
  ({ activeRiskCount }) => {
    const { t } = useTranslation();
    const riskDeclensions = getRiskDeclensions();

    return (
      <div>
        {activeRiskCount === 1
          ? t('COMMON:declensions.selected')
          : t('COMMON:declensions.selected-2')}
        <StyledCostTitle>{` ${activeRiskCount} ${numWord(
          activeRiskCount,
          riskDeclensions
        )}`}</StyledCostTitle>
      </div>
    );
  }
);
