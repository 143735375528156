import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { RadioCardGroup } from '@pulse-web-ui/radio-card-group';

import {
  AdaptiveListWrapper,
  Container,
  FormLabel,
  FormSub,
  LinkContainer,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useNextStep, useRequest } from '@src/hooks';
import { IFLHouseActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode } from '@src/types';
import { addTestAttribute } from '@src/utils';

import { useIflHouseDraft } from '../hooks';
import { useMaterials } from './use-materials';

export const FormInsuranceMaterials: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLHouse: {
        materials,
        selectedIProduct,
        selectedRegion,
        selectedBuildingMaterialCode,
      },
    },
    dispatch,
  } = useContext(Store);

  const [isRadioCardGroupError, setIsRadioCardGroupError] =
    useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflHouseMaterials);

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const handleKeyPressEnter = () => {
    validatePage();
    if (!isRadioCardGroupError) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const { isLoading, res, error, refetch } = useRequest(
    'formIFLHouseGetMaterials',
    'post',
    '/v1/references/get-materials',
    {
      productCode: selectedIProduct?.code,
      region: selectedRegion?.region,
    },
    [selectedIProduct?.code, selectedRegion?.region],
    true
  );

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !selectedBuildingMaterialCode || false,
    });

    setIsRadioCardGroupError(!selectedBuildingMaterialCode);

    return !!selectedBuildingMaterialCode || false;
  }, [selectedBuildingMaterialCode]);
  const { preparedMaterials } = useMaterials();

  useNextStep(validatePage);
  useIflHouseDraft();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: isRadioCardGroupError,
    });
  }, [isRadioCardGroupError]);

  useEffect(() => {
    if (!materials) {
      refetch();
    }
  }, [materials, selectedRegion]);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: IFLHouseActionTypes.SetMaterials,
        payload: res.materials,
      });
    }
  }, [isLoading, res]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: isLoading,
    });
  }, [isLoading]);

  if (isLoading) return <Skeleton />;

  if (error) return <GlobalErrorInfo retryHandler={refetch} />;

  const handleMaterialObjectClick = (event: ChangeEvent<HTMLInputElement>) => {
    setStepUpdated();
    setIsRadioCardGroupError(false);

    dispatch({
      type: IFLHouseActionTypes.SetInsuranceSubproducts,
      payload: undefined,
    });

    dispatch({
      type: IFLHouseActionTypes.SetSelectedBuildingMaterialCode,
      payload: event.target.value,
    });
  };

  const descriptionNavHandler = () => {
    navigate('/house-materials-descriptions');
  };

  return (
    <Container>
      <FormLabel marginBottom={32}>
        {t('IFL_HOUSE_FORM:headers.selectTypeOfHouse')}
      </FormLabel>
      {isRadioCardGroupError && (
        <FormSub
          isError={isRadioCardGroupError}
          {...addTestAttribute('house-form-materials-error')}
        >
          {t('IFL_HOUSE_FORM:hints.selectOneTypeOfHouse')}
        </FormSub>
      )}
      <AdaptiveListWrapper>
        {!isLoading &&
          (!materials || materials?.length === 0) &&
          t('COMMON:errors.noData')}
        {preparedMaterials && preparedMaterials.length > 0 ? (
          <RadioCardGroup
            name="radioCardGroup"
            value={selectedBuildingMaterialCode}
            onChange={handleMaterialObjectClick}
            items={preparedMaterials}
            error={isRadioCardGroupError}
            testId="house-form-materials"
          />
        ) : null}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={
            t('IFL_HOUSE_FORM:labels.whichHousesConsideredStoneOrWooden') || ''
          }
          {...addTestAttribute('house-form-materials-info-button')}
        />
      </LinkContainer>
    </Container>
  );
};
