import Axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';

import type { Method, Url } from '@src/types';

export const useB2PRequest = <T>(
  key: string,
  method: Method,
  url: Url,
  request?: T & AxiosRequestConfig<T>,
  deps?: any[],
  fetchManual?: boolean,
  authToken?: string
) => {
  const headers = {
    'Content-type': 'application/x-www-form-urlencoded',
  };

  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  if (authToken) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  const { isLoading, error, data, refetch } = useQuery(
    [key, deps],
    () =>
      Axios[method](`${window.envUrls.BEST2PAY_API_URL}${url}`, request, {
        headers: authToken ? authHeaders : headers,
      }),
    {
      enabled: fetchManual ? false : true,
      staleTime: 100000,
    }
  );

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return { isLoading, error, res, refetch };
};
