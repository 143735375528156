import { AxiosRequestConfig } from 'axios';
import type { FC } from 'react';
import { ReactElement } from 'react';

import {
  ClientCategory,
  ClientDocumentType,
  ObjectType,
  ProductKind,
} from '@src/constants';
import { SelectedPetData } from '@src/store/pets';

export type Risk = {
  active: boolean;
  binding: boolean;
  code: string;
  description: string;
  extendedDescription?: string;
  imageUrl?: string;
  insuranceType?: string;
  name: string;
  switchability?: boolean;
  temporaryFranchise?: number;
};

export type RiskPage = 'primary' | 'secondary';

export type Region = {
  region: string;
  regionName: string;
  regionTariff: null | undefined;
  version: string;
};

export type InsuranceProduct = {
  code: string;
  name: string;
  description?: string;
  minLimit: number;
  maxLimit: number;
  regions: Region[];
  step: number;
  effectivePeriod: number;
  defaultValue: null;
  maxObjectsNumber: number;
};

export type SelectedDuration = 'P1M' | 'P3M' | 'P1Y';

export type SelectedDate = Date;

export type Price = {
  discount: string | number;
  duration: SelectedDuration;
  premiumAndDelta: string;
  premiumAndDeltaPromo: null | string;
};

export type GetPrices =
  | {
      defaultContractDuration?: SelectedDuration;
      isSuccessfulPromo: boolean;
      prices: Price[];
    }
  | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type Action<T extends { [index: string]: any }> =
  ActionMap<T>[keyof ActionMap<T>];
export type Status = {
  status: 'error' | 'default';
  message?: string;
};

export type Method = 'post' | 'get' | 'patch' | 'put';
export type Url = string;

export enum OrderItemValueType {
  SIMPLE = 'simple',
  BLOCK = 'block',
  ACCORDION = 'accordion',
}
export type OrderItemType = {
  type: OrderItemValueType;
  title: string;
  value: string | undefined | null | ReactElement | ReactElement[];
  secondsValue?: string;
  isColsIdentical?: boolean;
  successValue?: string;
  failedValue?: string;
  testId?: string;
  showSeparator?: boolean;
};
export type OrderItemsBlockType = {
  type: OrderItemValueType;
  title: string;
  value: Array<OrderItemType> | undefined;
  onClick?: () => void;
  testId?: string;
  showSeparator?: boolean;
};
export type OrderItemAccordionType = {
  type: OrderItemValueType;
  title: string;
  value: JSX.Element | string;
  accordionTitle: string;
  testId?: string;
};

export type DocumentType = {
  clientDocument: string;
  clientDocumentType: ClientDocumentType;
  isNew?: null;
};

export type Person = {
  age: number | undefined;
  ageMin: number | undefined;
  birthDate: Date;
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  primaryRecordId?: number | undefined;
};

export type InsurePerson = {
  ageMin: number;
  birthDate: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  isMe: boolean;
  isDisabledForm: boolean;
  primaryRecordId?: number | undefined;
};

export type InsurancePersonsForm = {
  persons: ({ id?: string } & InsurePerson)[];
};

export type NumberInsurePersons = {
  numberChildren: number;
  numberAdults: number;
  numberElderly: number;
};

export type Profile = {
  birthDate: string;
  email: string;
  firstName: string;
  intermediary: {
    campaign: string;
    mediaSource: string;
  };
  lastName: string;
  middleName: string;
  phone: string;
  primaryRecordId: number;
};

export type UserProfile = {
  hasSubscriptions: boolean;
  profile: Profile;
};

export type FormInsuranceProfile = {
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  birthDate: string | undefined;
  primaryRecordId?: number | undefined;
};

export type FormInsuranceContactsProps = {
  firstName: string;
  lastName: string;
  middleName?: string;
  useMiddleName: boolean;
  email: string;
  birthDate: string;
};

export type FormPromoProps = {
  promoCode?: string;
};

export type ResponseError = { code: string };

export type InsuranceSubProduct = {
  code: string;
  name: string;
  description: string;
  imageUrl: string;
  defaultInsuranceSum: string;
  maxLimit: number;
  minLimit: number;
  step: number;
  tooltip?: string;
};

export type InsuranceScheme = Record<string, string[]>;

export type InsuranceSubproducts = {
  subObjects: InsuranceSubProduct[];
  subObjectsDependencyScheme?: InsuranceScheme;
  totalMinLimit?: string;
  totalMaxLimit?: string;
  applicationStatus?: string;
};

export type AddressDataType = {
  address?: string;
  addressCode?: string;
  regionCode?: string;
  streetCode?: string;
  houseCode?: string;
  flatCode?: string;
  placeCode?: string;
  cityCode?: string;
};

export type InsuranceConvertedSubProduct = {
  code: string;
  insuranceSum: string;
};

export type OrderRequestDataPerson = Omit<Person, 'birthDate'> & {
  birthDate: string | undefined;
};

export interface InsuranceObject {
  objectType: ObjectType;
  birthDate?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  primaryRecordId?: number;
  isInsurer: boolean;
}

export type OrderRequestData = {
  productCode: string | undefined;
  contractDuration: SelectedDuration | null | string;
  risks?: string[];
  effectiveSince?: string;
  insuranceSum?: string | undefined;
  accidents?: {
    persons: OrderRequestDataPerson[];
  };
  personProperties?: {
    subObjects: InsuranceConvertedSubProduct[] | undefined;
    address: AddressDataType;
  };
  pets?: SelectedPetData;
  promoCode?: string;
  buildingMaterial?: string;
  kind?: ProductKind;
  sports?: string[];
  insuranceObjects?: InsuranceObject[];
};

interface BaseSubmitDataParameters {
  effectiveSince: string;
  productCode: string;
  contractDuration: string;
  promoCode?: string;
  risks: string[];
}

interface SportNsSubmitDataProperty {
  insuranceSum: number;
  kind: ProductKind;
  sports: string[];
  insuranceObjects: InsuranceObject[];
}
export interface SubmitData {
  baseParameters: BaseSubmitDataParameters;
  productProperty: SportNsSubmitDataProperty;
}

interface MiteSubmitDataProperty {
  kind: ProductKind;
  program: string;
  insuranceObjects: InsuranceObject[];
}

export interface SumbitDataMite {
  baseParameters: BaseSubmitDataParameters;
  productProperty: MiteSubmitDataProperty;
}

interface SubmitBaseParametersTelevetPlus {
  effectiveSince: string;
  productCode: string;
  contractDuration: string;
  risks: string[];
  promoCode?: string;
  agentLogin?: string;
}

interface SubmitInsuranceObjectsTelevetPlus {
  objectType: string;
  name: string;
  breed: string;
  gender: string;
  age: string;
}

interface SubmitProductPropertyTelevetPlus {
  program: string;
  kind: string;
  insuranceObjects: SubmitInsuranceObjectsTelevetPlus[];
}

export interface SubmitDataTelevetPlus {
  baseParameters: SubmitBaseParametersTelevetPlus;
  productProperty: SubmitProductPropertyTelevetPlus;
}

export type UserLockedFieldsTypes = {
  birthDate: boolean;
  email: boolean;
  firstName: boolean;
  lastName: boolean;
  middleName: boolean;
  phone: boolean;
};

export type UserProfileTypes = {
  birthDate?: null | string;
  clientCategory?: ClientCategory | null;
  email?: null | string;
  firstName?: null | string;
  intermediary?: {
    campaign: 'SMS';
    mediaSource: '0000';
  };
  lastName?: null | string;
  middleName?: null | string;
  phone?: null | string;
  primaryRecordId?: number;
  utmCampaign?: null | string;
  utmContent?: null | string;
  utmMedium?: null | string;
  utmSource?: null | string;
  wmId?: null | string;
  userId?: string;
};

export type UserPolice = {
  approvedAt: string;
  code: string;
};
export interface UserRegistrationDataTypes
  extends Omit<
    UserProfileTypes,
    'birthDate' | 'email' | 'intermediary' | 'phone' | 'primaryRecordId'
  > {
  birthDay?: null | string;
  extendedIntermediaryChannelCode?: string;
  userPolicies?: UserPolice[];
  userPoliciesCode003?: boolean;
  userPoliciesCode002?: boolean;
  useMiddleName?: boolean;
}

export type InsuranceProductType = {
  code: string;
  defaultInsuranceSum: null;
  maxObjectsNumber: null;
  maxProductLimit: string;
  minObjectLimit: string;
  minProductLimit: string;
  name: string;
  regions: Region[];
  step: null | string | number;
};

export type RiskElementsType = {
  code: string;
  name: string;
  description?: string;
  imageUrl?: string;
};

export type RiskType = {
  code: string;
  name?: string;
  description?: string;
  imageUrl?: string;
  limit?: number;
  riskElements?: Array<RiskElementsType>;
};

export type AntimiteProgramType = {
  insuranceProgram: string;
  name: string;
  basePrice: string;
  showByDefault: boolean;
  minNumObjects: number;
  maxNumObjects: number;
  insurancePeriod: string;
  endDate: string;
  startDate: string;
  risks: Array<RiskType>;
};

export type InsuranceProductPolicyType = {
  productCode: string;
  productName: string;
  antimitePrograms?: Array<AntimiteProgramType>;
};

export type InsuranceSubProductType = {
  code: string;
  defaultInsuranceSum: null | string;
  description: string;
  imageUrl: string;
  maxLimit: string;
  minLimit: string;
  name: string;
  step: null | string | number;
};

export type FullDescriptionObject = {
  title: string;
  points?: Array<string>;
};

export type MaterialObject = {
  code: string;
  nameForUser: string;
  shortDescription: string;
  linkToFullDescription: string;
  fullDescriptionTitle: string;
  fullDescription: Array<FullDescriptionObject>;
  activeOption: boolean;
  imageUrl?: string;
  waitingMessage?: string;
};

export type RequestParams<T> = {
  key: string;
  method: Method;
  url: Url;
  request?: T & AxiosRequestConfig<T>;
  deps?: any[];
  accessToken?: string;
  fetchManual?: boolean;
};

export type Materials = Array<MaterialObject>;
export interface InsurancePrice {
  isSuccessfulPromo: boolean;
  premiumAndDelta: string;
  premiumAndDeltaPromo?: string;
}

export enum KeyCode {
  ENTER = 13,
}

export type DraftType<T> = {
  common: {
    userId: string;
    step: string;
    productType: string;
  };
  order: T;
};

export enum ComponentsConfigType {
  MAIN = 'main',
  SETTINGS = 'settings',
  DEFAULT = 'default',
}

export interface HandleAnalytic {
  (currentStep: number): void;
}

export type ComponentsConfig = {
  [key in ComponentsConfigType]: {
    components:
      | (() => JSX.Element)[]
      | FC<Record<string, number> | { key: number }>[];
  };
} & {
  analyticEvent: string;
  handleAnalytic?: HandleAnalytic;
};

export interface ThemeConfig {
  'theme-url'?: string;
  'texts-url'?: string;
  'auth-url'?: string;
  'icons-url'?: string;
}

export type AuthFlow = {
  partnerAuthRegType: string;
  sepScrAuth: boolean;
  stepAuth: number;
  product: string;
  allowedPeriod?: SelectedDuration;
  defaultContractDuration?: SelectedDuration;
};

export type QueryStatus = 'idle' | 'loading' | 'error' | 'success';

export type SuggestionType = {
  value?: string;
  country?: string;
  region?: string;
  region_fias_id?: string;
  city?: string;
  city_fias_id?: string;
  settlement?: string;
  settlement_fias_id?: string;
  street?: string;
  street_with_type?: string;
  street_fias_id?: string;
  house?: string;
  house_fias_id?: string;
  geo_lat?: string;
  geo_lon?: string;
};

export type RegistrationResponse = {
  authorization: {
    accessToken: string;
    refreshToken?: string;
    csrfToken?: string;
  };
  isVip: boolean;
};

export type RefreshTokenResponse = {
  authorization: {
    accessToken: string;
    csrfToken?: string;
    refreshToken?: string;
    isVip: boolean;
    userId: string;
    name: string;
    phone: string;
  };
};

export type ConfirmAuthentificationResponse = {
  accessToken: string;
  isVip: boolean;
  userId: string;
  name: string;
  csrfToken?: string;
  refreshToken?: string;
};
