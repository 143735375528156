import { css, mixins, styled, typography } from '@pulse-web-ui/theme';

import { OrderSubTitle } from '@src/components';

export const ClientOrderPageLayout = styled.div`
  ${mixins.flex({ horizontal: false })};
  min-height: 100vh;
`;

export const ClientOrderPageWrapper = styled.div<{ error: boolean }>`
  ${mixins.flex({ justifyContent: 'center' })};
  width: 100%;
  box-sizing: border-box;
  padding: ${({ error }) => (error ? '16px 0 0 0' : '16px 16px 24px')};
  background: ${({ theme, error }) =>
    error ? theme.colors.surface.background : theme.colors.surface.primary};

  ${({ error }) =>
    error &&
    css`
      flex: auto;
    `}
`;

export const StyledOrderSubTitle = styled(OrderSubTitle)`
  color: ${({ theme }) => theme.colors.text.error};
`;

export const AccordionSubLabel = styled.div`
  ${typography.medium20}
  color: ${({ theme }) => theme?.colors?.text?.primary};
  margin-top: 4px;
`;

export const AccordionLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.text?.secondary};
  ${typography.regular14}
  line-height: 20px;
`;
