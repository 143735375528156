import { Product } from '@src/constants';
import type { ComponentsConfig } from '@src/types';

import { flatComponentsConfig } from './flat-components-config';
import { houseComponentsConfig } from './house-components-config';
import { petsComponentsConfig } from './pets-components-config';
import { petsTelevetPlusComponentsConfig } from './pets-televet-plus-components-config';
import { sportNSComponentsConfig } from './sport-ns-components-config';

export const componentsConfigs: { [key in Product]?: ComponentsConfig } = {
  [Product.PETS]: petsComponentsConfig,
  [Product.HOUSE]: houseComponentsConfig,
  [Product.SPORT]: sportNSComponentsConfig,
  [Product.APARTMENT]: flatComponentsConfig,
  [Product.PETS_TELEVET_PLUS]: petsTelevetPlusComponentsConfig,
};
