/* eslint-disable indent */
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { PageBackButton, StyledButton } from '@src/common-components/button';
import { ArrowLeftIcon2 } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  IFrameMessage,
  POST_MESSAGE_TYPE,
  Product,
  analyticEvents,
  iflRoute,
  nsRoute,
} from '@src/constants';
import { useIsDesktop } from '@src/hooks';
import { Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { addTestAttribute, scrollToIframe } from '@src/utils';

import { PhoneVerification } from '../phone-verification';
import { WizardProps } from './index';
import { ContentArea, NavFooter } from './step-wizard-form.styles';
import { NavHeader } from './step-wizard-header';

export const StepWizardForm: FC<WizardProps> = ({
  maxStep,
  useResetSteps,
  children,
  nextButtonLabel,
  prevButtonLabel,
  goBack,
  onNextStep,
  isSettingPage,
  scrollToIframeOnChange,
  isAgreementWithButton,
}) => {
  const {
    state: {
      stateWizard: { currentStep, fwNavDisabled, isPageLoading, globalError },
      stateAuth: { authorizeRefRoute },
      stateAuthFlow: { authStep, sepScrAuth },
      stateUser: { isPureSubscription, selectedProduct, agentLogin },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  if (useResetSteps) {
    useResetSteps();
  }

  const setStep = (step: number) => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: step,
    });
  };

  const setNextStep = useCallback(() => {
    if (onNextStep instanceof Function) {
      onNextStep();
      if (scrollToIframeOnChange) scrollToIframe();
      return;
    }

    if (sepScrAuth && currentStep === authStep) {
      const step = currentStep === maxStep ? currentStep : currentStep + 1;
      const nextRoute = currentStep === maxStep ? '/order-detail' : pathname;

      navigate('/login', { state: { currentStep: step, nextRoute } });
      if (scrollToIframeOnChange) scrollToIframe();
      return;
    }

    if (!!agentLogin?.length) {
      window.top?.postMessage(
        JSON.stringify({
          message: IFrameMessage.SCROLL_TO_TOP,
          type: POST_MESSAGE_TYPE,
          id: uuidv4(),
        }),
        window.envUrls.AGENT_URL
      );
    }

    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: true,
    });
    if (scrollToIframeOnChange) scrollToIframe();
  }, [
    scrollToIframeOnChange,
    onNextStep,
    sepScrAuth,
    currentStep,
    authStep,
    maxStep,
    pathname,
    agentLogin,
  ]);

  const onClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep,
        });
        break;
      // TODO: добавить аналитику для питомцев
    }

    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: false,
    });

    if (currentStep === 1 && goBack) {
      goBack();
    } else {
      setStep(currentStep - 1);
    }

    if (scrollToIframeOnChange) scrollToIframe();
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetLastStep,
      payload: maxStep,
    });
  }, []);

  const showButton =
    isPureSubscription ||
    currentStep > 1 ||
    selectedProduct === Product.MITE ||
    isSettingPage;

  return (
    <>
      {showButton && (
        <PageBackButton
          variant="text"
          icon={<ArrowLeftIcon2 />}
          onClick={onClickBack}
          {...addTestAttribute('page.backButton')}
        >
          {prevButtonLabel || t('COMMON:buttons.back')}
        </PageBackButton>
      )}
      <NavHeader currentStep={currentStep} maxStep={maxStep} />
      <ContentArea>{children}</ContentArea>
      {!isPageLoading && !globalError && (
        <NavFooter>
          {currentStep === authStep && !sepScrAuth && !isSettingPage ? (
            <PhoneVerification
              onCodeSuccess={setNextStep}
              isSubmitButtonDisabled={fwNavDisabled}
              isAgreementWithButton={isAgreementWithButton}
            />
          ) : (
            <StyledButton
              variant="primary"
              onClick={setNextStep}
              disabled={fwNavDisabled}
              adaptiveWidth={!isDesktop}
              {...addTestAttribute('button.submit')}
            >
              {nextButtonLabel || t('COMMON:buttons.continue')}
            </StyledButton>
          )}
        </NavFooter>
      )}
    </>
  );
};
