/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { AvailableSubscriptionStoreState } from './available-subscription-store.state';
import { AvailableSubscriptionActionTypes } from './available-subscription-store.types';

export type AvailableSubscriptionStoreReducer = (
  state: AvailableSubscriptionStoreState,
  action: StoreActions
) => AvailableSubscriptionStoreState;

export const availableSubscriptionStoreReducer: AvailableSubscriptionStoreReducer =
  (state, action) => {
    switch (action?.type) {
      case AvailableSubscriptionActionTypes.SetRisks:
        return {
          ...state,
          risks: action.payload,
        };

      case AvailableSubscriptionActionTypes.SetSubobjects:
        return {
          ...state,
          subobjects: action.payload,
        };

      case AvailableSubscriptionActionTypes.SetInsuranceSum:
        return {
          ...state,
          insuranceSum: action.payload,
        };

      case AvailableSubscriptionActionTypes.SetInsuranceSumPerMonth:
        return {
          ...state,
          insuranceSumPerMonth: action.payload,
        };

      case AvailableSubscriptionActionTypes.SetInsurancePromoSumPerMonth:
        return {
          ...state,
          insurancePromoSumPerMonth: action.payload,
        };

      case AvailableSubscriptionActionTypes.SetNextRoute:
        return {
          ...state,
          nextRoute: action.payload,
        };

      case AvailableSubscriptionActionTypes.SetPrevRoute:
        return {
          ...state,
          prevRoute: action.payload,
        };

      case AvailableSubscriptionActionTypes.SetOrderData:
        return {
          ...state,
          orderData: action.payload,
        };

      case AvailableSubscriptionActionTypes.ResetState:
        return {
          ...action.payload,
        };

      default:
        return state;
    }
  };
