import React, { useContext, useEffect } from 'react';
import type { VFC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OrderActionTypes, Store, UserActionTypes } from '@src/store';

export const AgentController: VFC = () => {
  const { dispatch } = useContext(Store);
  const [searchParams] = useSearchParams();
  const paymentSubscriptionId = searchParams.get('paymentSubscriptionId');
  const agentLogin = searchParams.get('agent_login');

  useEffect(() => {
    if (agentLogin) {
      dispatch({
        type: UserActionTypes.SetAgentLogin,
        payload: agentLogin as string,
      });
    }
  }, [agentLogin]);

  useEffect(() => {
    if (paymentSubscriptionId) {
      dispatch({
        type: OrderActionTypes.SetSubscriptionId,
        payload: paymentSubscriptionId,
      });

      localStorage.setItem('paymentSubscriptionId', paymentSubscriptionId);
    }
  }, [paymentSubscriptionId]);

  return null;
};
