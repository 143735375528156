export enum CheckUserDataActionTypes {
  SetRisks = 'CHECK_USER_DATA/SET_RISKS',
  SetSubobjects = 'CHECK_USER_DATA/SET_SUBOBJECTS',
  SetProductCode = 'CHECK_USER_DATA/SET_PRODUCT_CODE',
  SetPromoCode = 'CHECK_USER_DATA/SET_PROMO_CODE',
  SetAddress = 'CHECK_USER_DATA/SET_ADDRESS',
  SetRegionCode = 'CHECK_USER_DATA/SET_REGION_CODE',
  SetAddressCode = 'CHECK_USER_DATA/SET_ADDRESS_CODE',
  SetBuildingMaterial = 'CHECK_USER_DATA/SET_BUILDING_MATERIAL',
  SetPrices = 'CHECK_USER_DATA/SET_PRICES',
  SetSelectedDuration = 'CHECK_USER_DATA/SET_SELECTED_DURATION',
  ResetState = 'CHECK_USER_DATA/RESET_STATE',
}
