import i18n from '@src/i18n/config';
import { getPetsSubscriptionTypeLabels } from '@src/pages/pets-form/utils';
import type { Price } from '@src/types';
import { currencyRuLocaleWithoutFraction } from '@src/utils';

export const PetsRadioDescRender = ({
  premiumAndDelta,
  premiumAndDeltaPromo,
  duration,
}: Price) => {
  const price = !!premiumAndDeltaPromo ? premiumAndDeltaPromo : premiumAndDelta;
  const petsSubscriptionTypeLabels = getPetsSubscriptionTypeLabels();

  return (
    <>{`${i18n.t(
      'PETS_FORM_DATA:labels.whenPaying'
    )} ${currencyRuLocaleWithoutFraction(Math.ceil(Number(price)))} ₽ ${
      petsSubscriptionTypeLabels.get(duration)?.description
    }`}</>
  );
};
