import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const SubscriptionDataWrapper = styled.div`
  box-sizing: border-box;
  padding: 24px;
  border-radius: ${({ theme }) => theme.common.radius};
  border: ${({ theme }) => `2px solid ${theme.colors.neutral[30]}`};
  margin-bottom: 32px;
  width: 100%;
`;

export const SubscriptionDataContent = styled.div`
  ${mixins.flex({})}
  width: 100%;
  row-gap: 16px;
  column-gap: 48px;
`;

export const SubscriptionDataContentItem = styled.div`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;

  ${media.desktop} {
    width: calc(50% - 24px);
  }
`;

export const SubscriptionDataRisk = styled.div`
  ${mixins.flex({})}
  width: 100%;
`;

export const SubscriptionDataRiskText = styled.div`
  width: calc(100% - 35px);
`;

export const CheckIconWrapper = styled.div`
  ${mixins.flex({ alignItems: 'center', justifyContent: 'center' })}
  width: 22px;
  height: 22px;
  background: ${({ theme }) => theme.colors.icon.success};
  border-radius: 50%;
  margin-right: 13px;
`;

export const SumWrapper = styled.div`
  width: 100%;

  ${media.desktop} {
    width: calc(50% - 4px);
  }
`;

export const SubobjectPrice = styled.div`
  ${typography.medium18}
`;

export const SubobjectName = styled.div`
  margin-bottom: 4px;
`;

export const ButtonsWrapper = styled.div`
  ${mixins.flex({ gap: 16 })}
  width: 100%;
  margin-top: 32px;
`;
