import { useContext, useEffect, useMemo } from 'react';

import { useRequest } from '@src/hooks';
import { PetsActionTypes, Store } from '@src/store';
import { GetPrices } from '@src/types';

import {
  createRisksFromPresetData,
  createSublimitsFromPresetData,
  sublimitRisksConverter,
} from '../utils';

export const useRequestData = (
  autoInitPromo: boolean,
  localPromo: string | undefined
) => {
  const {
    state: {
      stateFormPets: {
        risks,
        insuranceSum,
        selectedIProduct,
        selectedPetData,
        selectedPetData: { age: petAge = '', kind: petKind = '' } = {},
        presetData,
        sublimits,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const { res: risksRes } = useRequest(
    'petsFormGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: selectedIProduct?.code,
      kind: selectedPetData?.kind,
    },
    [selectedIProduct?.code, selectedPetData?.kind]
  );

  useEffect(() => {
    if (!risks && !!presetData && !!risksRes) {
      dispatch({
        type: PetsActionTypes.SetRisks,
        payload: createRisksFromPresetData(presetData, risksRes.risks),
      });
    }
  }, [risks, presetData, risksRes]);

  const activeCodeRisks = useMemo(
    () =>
      risks
        ?.filter((riskItem) => {
          if (riskItem.active) {
            return riskItem;
          }
        })
        .map((filteredRiskItem) => filteredRiskItem.code) || [],
    [risks]
  );

  const { res: sublimitsRes } = useRequest(
    'petsFormGetSublimits',
    'post',
    '/v2/references/get-sublimits',
    {
      productCode: selectedIProduct?.code,
      kind: selectedPetData?.kind,
      risks: activeCodeRisks,
    },
    [activeCodeRisks, selectedPetData?.kind, selectedIProduct?.code],
    !activeCodeRisks.length
  );

  useEffect(() => {
    if (!!presetData && !!sublimitsRes) {
      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: createSublimitsFromPresetData(presetData, sublimitsRes),
      });
      dispatch({
        type: PetsActionTypes.SetInsuranceSum,
        payload: presetData.risks.reduce(
          (acc, { defaultInsuranceSum }) =>
            (acc += Number(defaultInsuranceSum)),
          0
        ),
      });
    }
  }, [presetData, sublimitsRes]);

  const {
    isLoading: isPricesLoading,
    error: pricesError,
    res: pricesRes,
  } = useRequest<GetPrices>(
    'petsFormGetPricesFull',
    'post',
    '/v1/subscription/get-prices',
    {
      productCode: selectedIProduct?.code,
      risks: activeCodeRisks,
      pets: {
        kind: petKind,
        age: petAge,
        coverages: sublimits?.risks
          ? sublimitRisksConverter(sublimits.risks)
          : [],
      },
      returnMinDuration: false,
      promoCode: localPromo,
      insuranceSum: Number(insuranceSum),
    },
    [
      activeCodeRisks,
      sublimits?.risks,
      petKind,
      petAge,
      localPromo,
      autoInitPromo,
      insuranceSum,
      selectedIProduct?.code,
    ],
    !(activeCodeRisks && sublimits?.risks.length),
    authTokens?.authorization?.accessToken
  );

  return {
    isPricesLoading,
    pricesError,
    pricesRes,
  };
};
