import { Spinner } from '@pulse-web-ui/spinner';
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

import type { SumPerMonthWrapperProps } from './sum-per-month.types';

export const SumPerMonthWrapper = styled.div<SumPerMonthWrapperProps>`
  background: ${({ theme }) => theme.colors.surface.accent};
  color: ${({ theme }) => theme.colors.neutral[0]};
  padding: 16px;
  border-radius: ${({ theme }) => theme.common.radius};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  min-height: 81px;
  box-sizing: border-box;

  ${mixins.flex({ horizontal: false, justifyContent: 'space-between' })}
  ${media.desktop} {
    align-items: center;
    padding: 9px 34px;
    min-height: 68px;
  }
`;

export const SumPerMonthValue = styled.div`
  ${typography.bold22}
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const SumPerMonthSubtitle = styled.div`
  ${typography.regular16}
  white-space: nowrap;
`;

export const StyledSpinner = styled(Spinner)``;

export const SumPerMonthErrorMessage = styled.div`
  ${typography.regular14}
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.text.error};
  padding-top: 16px;

  ${media.desktop} {
    padding-top: 13px;
  }
`;
