import { Risk } from '@src/types';

export const getAdditionalRisksToString = (
  risks: Risk[] | undefined
): string => {
  if (!!risks?.length) {
    const additionalRisks = risks
      ?.filter((item) => item.active && !item.binding)
      .map((item) => item.name.toLowerCase())
      .join(', ');

    return additionalRisks.charAt(0).toUpperCase() + additionalRisks.slice(1);
  }

  return '';
};
