import React, { FC, Suspense, lazy, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { PageBackButton } from '@src/common-components/button';
import { Skeleton } from '@src/components';
import {
  IFrameMessage,
  POST_MESSAGE_TYPE,
  Product,
  RoutePath,
} from '@src/constants';
import { StepWizardForm } from '@src/features';
import { useComponentsConfig } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { ComponentsConfigType } from '@src/types';

const ArrowLeftIcon2 = lazy(
  () => import('@src/components/icons/lazy/arrow-left-icon-2')
);

export const PetsTelevetPlusForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { components } = useComponentsConfig(
    Product.PETS_TELEVET_PLUS,
    ComponentsConfigType.DEFAULT
  );
  const maxStep = components?.length || 0;

  const {
    state: {
      stateWizard: { currentStep },
      stateUser: { agentLogin },
    },
    dispatch,
  } = useContext(Store);

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      navigate(RoutePath.LOGIN);
    } else {
      navigate(-1);
    }
  };

  const postGoBackMessageToDigitalAgent = useCallback(() => {
    window.top?.postMessage(
      JSON.stringify({
        message: IFrameMessage.TRIGGER_GO_BACK,
        type: POST_MESSAGE_TYPE,
      }),
      window.envUrls.AGENT_URL
    );
  }, []);

  return (
    <>
      {!!agentLogin?.length && currentStep === 1 && (
        <PageBackButton
          variant="text"
          icon={
            <Suspense fallback="">
              <ArrowLeftIcon2 />
            </Suspense>
          }
          onClick={postGoBackMessageToDigitalAgent}
        >
          {t('COMMON:buttons.back')}
        </PageBackButton>
      )}
      <StepWizardForm maxStep={maxStep} goBack={goBack} isAgreementWithButton>
        <Suspense fallback={<Skeleton />}>
          {components?.map(
            (Component, index) =>
              currentStep === index + 1 && <Component key={index} />
          )}
        </Suspense>
      </StepWizardForm>
    </>
  );
};
