/* eslint-disable indent */
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { analyticEvents } from '@src/constants';
import { FormInsuranceAdditionalRisks } from '@src/pages/ifl-house-form/form-insurance-additional-risks';
import { FormInsuranceAddress } from '@src/pages/ifl-house-form/form-insurance-address';
import { FormInsuranceMaterials } from '@src/pages/ifl-house-form/form-insurance-materials';
import { FormInsurancePeriod } from '@src/pages/ifl-house-form/form-insurance-period';
import { FormInsuranceRisks } from '@src/pages/ifl-house-form/form-insurance-risks';
import { FormInsuranceSum } from '@src/pages/ifl-house-form/form-insurance-sum';
import {
  ComponentsConfig,
  ComponentsConfigType,
  HandleAnalytic,
} from '@src/types';

const handleAnalytics: HandleAnalytic = (currentStep: number) => {
  switch (currentStep) {
    case 1:
      sendAnalyticEvent(analyticEvents.iflHousePresetRisk);
      break;
    case 2:
      sendAnalyticEvent(analyticEvents.iflHousePresetAddRisk);
      break;
    case 3:
      sendAnalyticEvent(analyticEvents.iflHousePresetToCoverage);
      break;
    default:
      break;
  }
};

export const houseComponentsConfig: ComponentsConfig = {
  [ComponentsConfigType.MAIN]: {
    components: [FormInsuranceAddress, FormInsurancePeriod],
  },
  [ComponentsConfigType.SETTINGS]: {
    components: [
      FormInsuranceRisks,
      FormInsuranceAdditionalRisks,
      FormInsuranceSum,
    ],
  },
  [ComponentsConfigType.DEFAULT]: {
    components: [
      FormInsuranceAddress,
      FormInsuranceMaterials,
      FormInsuranceRisks,
      FormInsuranceAdditionalRisks,
      FormInsuranceSum,
      FormInsurancePeriod,
    ],
  },
  analyticEvent: analyticEvents.iflHousePresetConfigExit,
  handleAnalytic: handleAnalytics,
};
