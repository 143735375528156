import { css, media, styled, typography } from '@pulse-web-ui/theme';

export const OtpContainer = styled.div`
  margin-bottom: 56px;
  ${media.desktop} {
    margin-bottom: 8px;
  }

  .otp-input {
    input {
      transition: border-color 0.2s ease-in-out;
    }
  }
`;

interface InputProps {
  error?: boolean;
}

export const StyledOtpInput = styled.input<InputProps>`
  ${typography.regular18}
  width: 48px !important;
  height: 56px;
  margin-right: 8px;
  box-sizing: border-box;
  border-radius: calc(${({ theme }) => theme.common.radius} * 3);
  background-color: ${({ theme }) => theme.colors.neutral[10]};
  color: ${({ theme }) => theme.colors.text.primary};
  outline: none;

  ${({ theme, error }) =>
    !!error
      ? css`
          border: 1px solid ${theme.colors?.accent.error.normal};
          background-color: ${theme.colors?.accent.error.faded};
          color: ${theme.colors?.accent.error.normal};

          &:hover {
            border: 1px solid ${theme.colors?.accent.error.normal};
          }
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.neutral[10]};

          &:hover {
            border: 1px solid ${({ theme }) => theme.colors.neutral[30]};
          }
        `};
`;
