import { FullDescriptionObject } from '@src/types';

import {
  MaterialFullDescriptionWrapper,
  SubTitle,
} from './material-full-description.styles';

export const MaterialFullDescription = ({
  title,
  points,
}: FullDescriptionObject) => (
  <MaterialFullDescriptionWrapper>
    <SubTitle>{title}</SubTitle>
    {points && points.length > 0 ? (
      <ul>
        {points.map((point) => (
          <li>{point}</li>
        ))}
      </ul>
    ) : null}
  </MaterialFullDescriptionWrapper>
);
