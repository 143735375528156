/* eslint-disable indent */
import { Product } from '@src/constants';
import i18n from '@src/i18n/config';

export const getAuthSubtitle = (product?: Product) => {
  switch (product) {
    case Product.MITE:
      return i18n.t('AUTH:labels.enterYourDetails');
    case Product.PETS_TELEVET_PLUS:
      return i18n.t('AUTH:labels.dataToSubscribe');
    default:
      return i18n.t('AUTH:labels.withoutThisData');
  }
};
