/* eslint-disable indent */
import { useTranslation } from 'react-i18next';

import { SumPerPeriodErrorMessage } from './ifl-sum-per-period.styles';
import { SumPerPeriodErrorProps } from './ifl-sum-per-period.types';

export const SumPerPeriodError = ({
  errorResponse,
  currentSumErr,
}: SumPerPeriodErrorProps) => {
  const { t } = useTranslation();
  const errorCode = errorResponse?.response?.data?.code;

  if (
    errorCode === 'VALIDATION_ERROR' ||
    errorCode === 'TECHNICAL_ERROR' ||
    errorCode === 'BUSINESS_ERROR'
  ) {
    let msg =
      errorCode === 'BUSINESS_ERROR'
        ? t('COMMON:errors.costMoreReduceYourCoverage')
        : t('COMMON:errors.impossibleCalculate');

    switch (currentSumErr) {
      case 'less':
        msg = t('COMMON:errors.coverageLessMin');
        break;
      case 'gt':
        msg = t('COMMON:errors.coverageAboveLimit');
        break;
    }
    return <SumPerPeriodErrorMessage>{msg}</SumPerPeriodErrorMessage>;
  }

  return null;
};
