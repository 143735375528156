import { FlatPresetData } from '@src/store/ifl-flat';
import { Risk } from '@src/types';

export const createRisksFromPresetData = (
  presetData: FlatPresetData,
  risks: Risk[]
): Risk[] => {
  const codes = presetData.risks;

  return risks.map((risk: Risk) => ({
    ...risk,
    active: codes.includes(risk.code),
  }));
};
