/* eslint-disable indent */
import i18n from '@src/i18n/config';

export const getInsurancePersonSubTitle = (ageMin: number) => {
  const ageCategorySubTitle = {
    children: `1 – 17 ${i18n.t('COMMON:hints.years')}`,
    adults: `18 – 69 ${i18n.t('COMMON:hints.years')}`,
    elderly: `70 ${i18n.t('COMMON:hints.yearsAndOlder')}`,
  };

  switch (ageMin) {
    case 1:
      return ageCategorySubTitle.children;
    case 18:
      return ageCategorySubTitle.adults;
    case 70:
      return ageCategorySubTitle.elderly;
    default:
      break;
  }
};
