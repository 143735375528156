import { MOSCOW_TIME_ZONE_OFFSET, TIME_ZONE_MOSCOW } from '@src/constants';

const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getValidTimeZone = (date: Date) =>
  date.getTimezoneOffset() < MOSCOW_TIME_ZONE_OFFSET
    ? getUserTimezone()
    : TIME_ZONE_MOSCOW;
