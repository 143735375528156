import i18n from '@src/i18n/config';

export const getPetsPaymentPeriodLabels = () =>
  new Map([
    [
      'P3M',
      {
        label: i18n.t('PETS_FORM_DATA:labels.perQuarter'),
        promoLabel: i18n.t('PETS_FORM_DATA:labels.inFirstQuarter'),
        promoSubLabel: i18n.t('COMMON:hints.fromSecondQuarter'),
      },
    ],
    [
      'P1Y',
      {
        label: i18n.t('PETS_FORM_DATA:labels.inYear'),
        promoLabel: i18n.t('PETS_FORM_DATA:labels.inFirstYear'),
        promoSubLabel: i18n.t('COMMON:hints.fromSecondYear'),
      },
    ],
  ]);
