/* eslint-disable indent */
import i18n from '@src/i18n/config';

export const getRiskDeclensions = () => [
  i18n.t('COMMON:declensions.risk'),
  i18n.t('COMMON:declensions.risk-2'),
  i18n.t('COMMON:declensions.risk-3'),
];

export const getYearDeclensions = () => [
  i18n.t('COMMON:declensions.year'),
  i18n.t('COMMON:declensions.year-2'),
  i18n.t('COMMON:declensions.year-3'),
];

export const getMonthDeclensions = () => [
  i18n.t('COMMON:declensions.month'),
  i18n.t('COMMON:declensions.month-2'),
  i18n.t('COMMON:declensions.month-3'),
];

export const getDayDeclensions = () => [
  i18n.t('COMMON:declensions.day'),
  i18n.t('COMMON:declensions.day-2'),
  i18n.t('COMMON:declensions.day-3'),
];

export const getPersonDeclensions = () => [
  i18n.t('COMMON:declensions.human'),
  i18n.t('COMMON:declensions.human-2'),
  i18n.t('COMMON:declensions.human-3'),
];

export const getPluralMonthDeclensions = () => ({
  one: i18n.t('COMMON:declensions.month'),
  few: i18n.t('COMMON:declensions.month-2'),
  many: i18n.t('COMMON:declensions.month-3'),
});

export const getPluralYearDeclensions = () => ({
  one: i18n.t('COMMON:declensions.year'),
  few: i18n.t('COMMON:declensions.year-2'),
  many: i18n.t('COMMON:declensions.year-3'),
});
