import { useEffect } from 'react';

import i18n from '@src/i18n/config';
import { ThemeConfig } from '@src/types';

export const useDictionary = (
  resTexts: ThemeConfig | undefined,
  isLoadingTexts: boolean
) => {
  useEffect(() => {
    if (resTexts && (resTexts as any)?.ru && !isLoadingTexts) {
      Object.keys((resTexts as any).ru)?.forEach((item) => {
        if ((resTexts as any).ru[item]) {
          i18n.addResourceBundle(
            'ru',
            item,
            (resTexts as any).ru[item],
            true,
            true
          );
        }
      });
    }
  }, [resTexts, isLoadingTexts]);
};
