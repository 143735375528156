import { memo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Header3 } from '@src/components';

import {
  HeaderProgressEmptyItem,
  HeaderProgressItem,
  HeaderProgressWrapper,
} from './step-wizard-form.styles';
import type { WizardHeaderTypes } from './step-wizard-form.types';

export const NavHeader: FC<WizardHeaderTypes> = memo(
  ({ currentStep, maxStep }) => {
    const { t } = useTranslation();
    const countArray = [];
    const currentCount = currentStep || 1;
    const maxCount = maxStep || 5;
    let i = 0;

    do {
      i += 1;
      countArray.push(i);
    } while (i < maxCount);

    return (
      <>
        <Header3>
          <span>{`${t('COMMON:headers.step')} ${currentCount}`}</span>
          &nbsp;/&nbsp;
          <span>{maxCount}</span>
        </Header3>
        <HeaderProgressWrapper>
          {countArray.map((index) =>
            index <= currentCount ? (
              <HeaderProgressItem key={index} />
            ) : (
              <HeaderProgressEmptyItem key={index} />
            )
          )}
        </HeaderProgressWrapper>
      </>
    );
  }
);
