/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { PresetSettingStoreState } from './preset-setting-store.state';
import { PresetSettingActionTypes } from './preset-setting-store.types';

export type PresetSettingStoreReducer = (
  state: PresetSettingStoreState,
  action: StoreActions
) => PresetSettingStoreState;

export const presetSettingStoreReducer: PresetSettingStoreReducer = (
  state,
  action
) => {
  switch (action?.type) {
    case PresetSettingActionTypes.SetIsPresetSettingDisplayed:
      return {
        ...state,
        isPresetSettingDisplayed: action.payload,
      };

    default:
      return state;
  }
};
