import { insurancePersonDefaultData } from '@src/constants/index';

import { SportNSStoreState } from './index';

export const initSportNSStoreState: SportNSStoreState = {
  numberInsurePersons: 1,
  selectedStartDate: null,
  selectedEndDate: null,
  policyOnYear: false,
  insurePersons: [insurancePersonDefaultData],
  selectedDuration: null,
  insuranceSum: undefined,
  isScrollToErrorElement: false,
};
