import i18n from '@src/i18n/config';
import type { AgeRatio } from '@src/store/ns';

type AgeRatiosFormatted = {
  [key: string]: AgeRatio;
};

type AgeRatiosLabels = {
  children: string;
  adults: string;
  elderly: string;
};

export const getAgeCategory = () => ({
  children: i18n.t('COMMON:labels.children'),
  adults: i18n.t('COMMON:labels.adults'),
  elderly: i18n.t('COMMON:labels.elderly'),
});

export const ageRatiosFormat = (
  ageRatios: AgeRatio[]
): AgeRatiosLabels | undefined => {
  const ageCategory = {
    children: 'Ребенок',
    adults: 'Взрослый',
    elderly: 'Пожилой',
  };

  if (!ageRatios.length) return;

  const ageRatiosFormatted: AgeRatiosFormatted = {};

  ageRatios.forEach((ageRatio: AgeRatio) => {
    ageRatiosFormatted[ageRatio.ageCategory] = ageRatio;
  });

  return {
    children: `${ageRatiosFormatted[ageCategory.children].ageMin} – ${
      ageRatiosFormatted[ageCategory.children].ageMax
    } ${i18n.t('COMMON:hints.years')}`,
    adults: `${ageRatiosFormatted[ageCategory.adults].ageMin} – ${
      ageRatiosFormatted[ageCategory.adults].ageMax
    } ${i18n.t('COMMON:hints.years')}`,
    elderly: `${ageRatiosFormatted[ageCategory.elderly].ageMin} ${i18n.t(
      'COMMON:hints.yearsAndOlder'
    )}`,
  };
};
