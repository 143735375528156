import axios from 'axios';

const CPA_SCALEO_TOKEN = window.envKeys.CPA_SCALEO_TOKEN;
const BASE_URL = 'https://epromo.scaletrk.com/track/conv-addchange';

export const sendCpaScaleoAnalytic = (
  event: string,
  params: { cid: string; adv_order_id: string }
) => {
  const { cid, adv_order_id } = params;

  const url = `${BASE_URL}?click_id=${cid}&token=${CPA_SCALEO_TOKEN}&adv_order_id=${adv_order_id}&conv_status=${event}`;

  axios.get(url);
};
