/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { Store } from '@src/store';

export const useResetNsSteps = () => {
  const {
    state: { stateWizard },
  } = useContext(Store);

  useEffect(() => {
    if (stateWizard.updateFormState) {
      switch (stateWizard.currentStep) {
        default:
          break;
      }
    }
  }, [stateWizard.currentStep, stateWizard.updateFormState]);
};
