import {
  GetPrices,
  InsuranceProductType,
  InsuranceSubProduct,
  Risk,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

export enum PetsTelevetPlusActionTypes {
  SET_SELECTED_PRODUCT_DATA = 'PETS_TELEVET_PLUS/SET_SELECTED_PRODUCT_DATA',
  SET_RISKS = 'PETS_TELEVET_PLUS/SET_RISKS',
  SET_INSURANCE_SUM = 'PETS_TELEVET_PLUS/SET_INSURANCE_SUM',
  SET_GET_PRICES = 'PETS_TELEVET_PLUS/SET_GET_PRICES',
  SET_GET_PRICES_FULL = 'PETS_TELEVET_PLUS/SET_GET_PRICES_FULL',
  SET_SELECTED_DURATION = 'PETS_TELEVET_PLUS/SET_SELECTED_DURATION',
  SET_SELECTED_DATE = 'PETS_TELEVET_PLUS/SET_SELECTED_DATE',
  SET_PROMO_CODE = 'PETS_TELEVET_PLUS/SET_PROMO_CODE',
  SET_PROMO_CODE_APPLYED = 'PETS_TELEVET_PLUS/SET_PROMO_CODE_APPLYED',
  SET_RISKS_PAGE = 'PETS_TELEVET_PLUS/SET_RISKS_PAGE',
  SET_PETS = 'PETS_TELEVET_PLUS/SET_PETS',
  SET_SELECTED_PET_DATA = 'PETS_TELEVET_PLUS/SET_SELECTED_PET_DATA',
  SET_SELECTED_PET_KIND_NAME = 'PETS_TELEVET_PLUS/SET_SELECTED_PET_KIND_NAME',
  SET_SUBLIMITS = 'PETS_TELEVET_PLUS/SET_SUBLIMITS',
  SET_CURRENT_SUM = 'PETS_TELEVET_PLUS/SET_CURRENT_SUM',
  SET_CURRENT_SUM_MIN_LIMIT = 'PETS_TELEVET_PLUS/SET_CURRENT_SUM_MIN_LIMIT',
  SET_CURRENT_SUM_MAX_LIMIT = 'PETS_TELEVET_PLUS/SET_CURRENT_SUM_MAX_LIMIT',
  SET_CURRENT_SUM_ERR = 'PETS_TELEVET_PLUS/SET_CURRENT_SUM_ERR',
  SET_SELECTED_ID = 'PETS_TELEVET_PLUS/SET_SELECTED_ID',
  SET_CURRENT_SUB_LIMITS = 'PETS_TELEVET_PLUS/SET_CURRENT_SUB_LIMITS',
  UPDATE_STATE = 'PETS_TELEVET_PLUS/UPDATE_STATE',
  SET_INSURANCE_PROGRAMS = 'PETS_TELEVET_PLUS/SET_INSURANCE_PROGRAMS',
  SET_INSURANCE_PRICE = 'PETS_TELEVET_PLUS/SET_INSURANCE_PRICE',
  RESET_STATE = 'PETS_TELEVET_PLUS/RESET_STATE',
}
export enum RiskPageVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
interface Breed {
  breedCategory: string;
  breedNames: string[];
}
export interface PetData {
  kind: string;
  kindName: string;
  minAge: string;
  maxAge: string;
  breeds?: Breed[];
}
export interface Coverage {
  code: string;
  insuranceSum: string;
}
export interface SelectedPetData {
  kind: string;
  breed?: string;
  age: string;
  gender: string;
  name: string;
  coverages?: Coverage[];
}
export interface PetRisk {
  code: string;
  name: string;
  description: string;
  minLimit: string;
  maxLimit: string;
  defaultInsuranceSum: string;
  step: string;
  imageUrl: string;
}
export interface Sublimits {
  risks: PetRisk[];
  totalMinLimit?: string;
  totalMaxLimit?: string;
}
export type InsuranceSum = number;
export type InsuranceScheme = Record<string, string[]>;
export type SelectedIProduct = undefined | InsuranceProductType;
export interface InsuranceSubproducts {
  subObjects: InsuranceSubProduct[];
  subObjectsDependencyScheme?: InsuranceScheme;
  totalMinLimit?: string;
  totalMaxLimit?: string;
}
export interface RiskElements {
  code: string;
  name: string;
  description?: string;
  imageUrl?: string;
}
export interface Risks {
  code: string;
  name?: string;
  description?: string;
  imageUrl?: string;
  limit?: number;
  riskElements?: Array<RiskElements>;
}
export interface AntimitePrograms {
  insuranceProgram: string;
  name: string;
  basePrice: string;
  showByDefault: boolean;
  minNumObjects: number;
  maxNumObjects: number;
  insurancePeriod: string;
  startDate: string;
  endDate: string;
  risks: Array<Risks>;
}
export interface InsurancePrograms {
  productCode: string;
  productName: string;
  antimitePrograms?: Array<AntimitePrograms>;
}
export interface InsurancePrice {
  program: string;
  premiumAndDelta: string;
  premiumAndDeltaPromo?: string;
  isSuccessfulPromo?: boolean;
}
export interface PetsTelevetPlusStoreState {
  selectedIProduct?: InsuranceProductType;
  risks?: Risk[];
  insuranceSum: InsuranceSum;
  getPrices: GetPrices;
  getPricesFull: GetPrices;
  selectedDuration: null | SelectedDuration;
  selectedDate: null | SelectedDate;
  promoCode?: string;
  promoCodeApplyed?: string;
  risksPage: RiskPageVariant;
  pets: PetData[];
  selectedPetData?: SelectedPetData;
  sublimits?: Sublimits;
  currentSubLimits?: Sublimits;
  selectedPetKindName?: string;
  currentSum: number;
  currentSumMinLimit: string;
  currentSumMaxLimit: string;
  currentSumErr: boolean;
  selectedId?: string;
  insurancePrograms?: InsurancePrograms;
  insurancePrice?: InsurancePrice;
}
