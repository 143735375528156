import { media, mixins, styled } from '@pulse-web-ui/theme';

export const StyledAddressWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 8, wrap: true })}
  margin-bottom: 16px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;

export const StyledAddressBox = styled.div`
  ${mixins.flex({ wrap: true, gap: 8 })}

  & > div {
    width: 100%;
  }

  ${media.desktop} {
    ${mixins.flex({ gap: 16, wrap: false })}
  }
`;
