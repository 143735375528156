import type { FC } from 'react';

import { BaseLayout } from '../../layouts/base-layout';
import { MiteForm } from './mite-form';

export const MitePage: FC = () => (
  <BaseLayout>
    <MiteForm />
  </BaseLayout>
);
