import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, miteRoute } from '@src/constants';
import { MitePolicyCards } from '@src/features/mite-policy/mite-policy';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

export const MitePolicyChoice: FC = () => {
  const { dispatch } = useContext(Store);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('successText');
    localStorage.removeItem('startDate');

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: miteRoute,
    });

    sendAnalyticEvent(analyticEvents.miteToStepPolicy);
  }, []);

  const goBack = () => {
    dispatch({
      type: AuthActionTypes.SetConfirmationId,
      payload: undefined,
    });
    dispatch({
      type: AuthActionTypes.SetAuthenticationToken,
      payload: undefined,
    });
    dispatch({
      type: UserActionTypes.SetCachedPhoneNumber,
      payload: undefined,
    });
    dispatch({
      type: UserActionTypes.SetAuthenticationRefetchUid,
      payload: uuidv4(),
    });

    navigate('/');
  };

  return <MitePolicyCards />;
};
