import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FRACTION_TWO } from '@src/constants';
import { OrderActionTypes, Store } from '@src/store';
import { OrderItemValueType, OrderItemsBlockType } from '@src/types';
import {
  convertDateByTimeZone,
  currencyRuLocaleWithoutFraction,
  getAdditionalRisksToString,
  getMainRisksToString,
  numFormat,
} from '@src/utils';

import { getFormattedAge, getPetsPaymentPeriodLabels } from '../utils';

export const usePetsOrderArray = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { profile, promoCodeFailedMessage },
      stateFormPets: {
        insuranceSum,
        promoCode,
        risks,
        selectedPetData: {
          name: petName = '',
          age: petAge = '',
          gender: petGender = '',
          breed: petBreed = '',
        } = {},
        selectedPetKindName,
        selectedDuration,
        selectedDate,
        getPricesFull,
      },
      stateAuth: { displayPhoneNumber },
    },
    dispatch,
  } = useContext(Store);
  const petsPaymentPeriodLabels = getPetsPaymentPeriodLabels();

  return useCallback(() => {
    const selectedPrice = getPricesFull?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0]?.premiumAndDelta;

    const selectedPromoPrice = getPricesFull?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0]?.premiumAndDeltaPromo;

    let label = `${
      selectedPrice
        ? currencyRuLocaleWithoutFraction(Number(selectedPrice), FRACTION_TWO)
        : ''
    } ₽ ${petsPaymentPeriodLabels.get(selectedDuration!)?.label}`;

    let subLabel = '';
    const isSuccessfulPromo = getPricesFull?.isSuccessfulPromo;

    if (!!selectedPromoPrice) {
      label = `${currencyRuLocaleWithoutFraction(
        Number(selectedPromoPrice),
        FRACTION_TWO
      )} ₽ ${petsPaymentPeriodLabels.get(selectedDuration!)?.promoLabel}`;
      subLabel =
        `${currencyRuLocaleWithoutFraction(
          Number(selectedPrice),
          FRACTION_TWO
        )} ₽ ${
          petsPaymentPeriodLabels.get(selectedDuration!)?.promoSubLabel
        }` || '';
    }

    const name = [
      profile?.profile?.lastName,
      profile?.profile?.firstName,
      profile?.profile?.middleName !== '' ? profile?.profile?.middleName : null,
    ].join(' ');

    const checkForFailedPromoCode = () => {
      if (promoCodeFailedMessage) {
        return t('COMMON:errors.promoCodeNotApplied');
      }

      return isSuccessfulPromo === undefined ||
        isSuccessfulPromo === null ||
        promoCode === '' ||
        (isSuccessfulPromo && !selectedPromoPrice)
        ? ''
        : t('COMMON:errors.promoCodeNotApplied') || '';
    };

    const orderArray: OrderItemsBlockType[] = [
      {
        type: OrderItemValueType.BLOCK,
        title: t('PETS_FORM_DATA:labels.personalData'),
        value: [
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.insurant'),
            value: name,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.phoneOfNumber'),
            value: displayPhoneNumber,
          },
        ],
      },
      {
        type: OrderItemValueType.BLOCK,
        title: t('PETS_FORM_DATA:labels.petData'),
        value: [
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_FORM_DATA:labels.pet'),
            value: `${selectedPetKindName} • ${petGender} • ${getFormattedAge(
              petAge
            )}`,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_FORM_DATA:labels.breed'),
            value: petBreed,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_FORM_DATA:labels.whatNameOfPet'),
            value: petName,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.mainRisks'),
            value: getMainRisksToString(risks),
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.additionalRisks'),
            value:
              getAdditionalRisksToString(risks) ||
              t('COMMON:placeholders.notSelected') ||
              '',
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_FORM_DATA:hints.maximumRefundAmount'),
            value: `${numFormat(insuranceSum)} ₽`,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('ORDER:labels.priceOfPayment'),
            value: label,
            secondsValue: subLabel,
            successValue:
              isSuccessfulPromo && selectedPromoPrice
                ? t('COMMON:success.promoCodeApplied') || ''
                : '',
            failedValue: checkForFailedPromoCode(),
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.subscriptionWillStart') || '',
            value: `${convertDateByTimeZone(selectedDate)?.toLocaleDateString(
              'ru-RU',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }
            )}`,
          },
        ],
      },
    ];

    dispatch({
      type: OrderActionTypes.SetOrder,
      payload: orderArray,
    });
  }, [
    getPricesFull?.prices,
    selectedDuration,
    risks,
    insuranceSum,
    selectedDate,
    displayPhoneNumber,
    profile,
    selectedPetKindName,
    petGender,
    petAge,
    petBreed,
    petName,
  ]);
};
