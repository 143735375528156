import type { ImageSelectorProps } from '@pulse-web-ui/image-selector';

import type { PetData } from '@src/store/pets-televet-plus';

import { TelevetPlusPetType, petTypeIconPath } from '../constants';

export const preparePetTypeSelectorData = (pets: PetData[]) => {
  const petTypeValues = Object.values(TelevetPlusPetType);
  const petSelectorData: ImageSelectorProps[] = [];

  petTypeValues.forEach((kind) => {
    const pet = pets.find((pet) => pet.kind === kind);
    if (pet) {
      petSelectorData.push({
        value: kind,
        label: pet.kindName,
        ...petTypeIconPath[kind],
      });
    }
  });

  return petSelectorData;
};
