import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormLabel } from '@src/components';
import { FeatureFlags } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';

interface Props {
  featureFlag: FeatureFlags;
  marginBottom?: number;
  isOnlyOnePeriodAllowed?: boolean;
}

export const PaymentPeriodsTitle = ({
  featureFlag,
  marginBottom,
  isOnlyOnePeriodAllowed,
}: Props) => {
  const { t } = useTranslation();
  const {
    res: [isFeatureSubscriptionType],
    isFeatureFlagsLoading,
  } = useFeatureFlags([featureFlag]);

  if (isFeatureFlagsLoading) {
    return null;
  }

  return (
    <FormLabel marginBottom={marginBottom}>
      {isFeatureSubscriptionType && !isOnlyOnePeriodAllowed
        ? t('COMMON:headers.chooseSubscriptionType')
        : t('COMMON:headers.paymentOfPeriod')}
    </FormLabel>
  );
};
