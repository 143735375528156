import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const CheckUserDataWrapper = styled.div`
  ${mixins.flex({ alignItems: 'center' })}
  flex-direction: column;
`;

export const SpinnerWrapper = styled.div`
  margin-bottom: 34px;
`;
