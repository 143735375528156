/* eslint-disable indent */
import { useCallback, useContext } from 'react';

import { Product, insurancePersonDefaultData } from '@src/constants';
import { insurePersonsFormatter } from '@src/pages/ns-form/utils';
import {
  MiteActionTypes,
  NSActionTypes,
  SportNSActionTypes,
  Store,
} from '@src/store';

export const useClearInsuredPersons = () => {
  const {
    state: {
      stateFormNSSport: { numberInsurePersons: sportInsuredPersonsNumber },
      stateFormNS: { numberInsurePersons: nsInsuredPersonsNumber },
      stateMite: { numberInsuredPersons: miteInsuredPersonsNumber },
      stateUser: { selectedProduct },
    },
    dispatch,
  } = useContext(Store);

  return useCallback(() => {
    switch (selectedProduct) {
      case Product.SPORT:
        dispatch({
          type: SportNSActionTypes.SetInsurePersons,
          payload: new Array(sportInsuredPersonsNumber).fill(
            insurancePersonDefaultData
          ),
        });
        break;
      case Product.MITE:
        dispatch({
          type: MiteActionTypes.SetInsuredPersons,
          payload: new Array(miteInsuredPersonsNumber).fill(
            insurancePersonDefaultData
          ),
        });
        break;
      case Product.NS:
        dispatch({
          type: NSActionTypes.SetInsurePersons,
          payload: insurePersonsFormatter(nsInsuredPersonsNumber),
        });
        break;
    }
  }, [
    selectedProduct,
    sportInsuredPersonsNumber,
    nsInsuredPersonsNumber,
    miteInsuredPersonsNumber,
  ]);
};
