import { RiskPageVariant } from './pets-televet-plus-store.types';
import type { PetsTelevetPlusStoreState } from './pets-televet-plus-store.types';

export const initPetsTelevetPlusStoreState: PetsTelevetPlusStoreState = {
  selectedIProduct: undefined,
  risks: undefined,
  insuranceSum: 200000,
  getPrices: undefined,
  getPricesFull: undefined,
  selectedDuration: null,
  selectedDate: null,
  promoCode: undefined,
  promoCodeApplyed: undefined,
  risksPage: RiskPageVariant.PRIMARY,
  pets: [],
  selectedPetData: undefined,
  sublimits: undefined,
  currentSubLimits: undefined,
  selectedPetKindName: undefined,
  currentSum: 250_000,
  currentSumMinLimit: '0',
  currentSumMaxLimit: '0',
  currentSumErr: false,
  selectedId: undefined,
  insurancePrograms: undefined,
  insurancePrice: undefined,
};
