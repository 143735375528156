import type { FC } from 'react';
import { useContext } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';
import {
  DatePickerIcon,
  HorizontalRegulatorIcon,
  RangeLimitsIcon,
} from '@pulse-web-ui/colored-icons';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';
import { useTheme } from '@pulse-web-ui/theme';

import {
  FormSub,
  FormSubLabel,
  GridColumnContainer,
  HeaderAdaptive1,
  UniversalColumn,
  UniversalColumnsWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, iflHouseRoute } from '@src/constants';
import { Store } from '@src/store';
import { getDeeplink } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import { IconColumnTitle, StyledColumnText } from './vip-page.styles';

export const VipPage: FC = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { profile },
      stateAuth: { authorizeRefRoute },
    },
  } = useContext(Store);

  return (
    <BaseLayout verticalAlignment={false}>
      <GridColumnContainer gridStep={3}>
        <UniversalColumn align="left" gap={0}>
          <HeaderAdaptive1 isVip>
            {t('VIP:headers.congratulations.title', {
              name: profile?.profile.firstName,
            })}
          </HeaderAdaptive1>
          <FormSubLabel isVip>
            {t('VIP:hints.specialConditionsAvailable')}
          </FormSubLabel>
        </UniversalColumn>
        <FormSub margin="0 0 40px ">
          {isBrowser ? (
            <DisplayQRButton />
          ) : (
            <Button
              variant="primary"
              label={t('COMMON:buttons.downloadApp') || ''}
              onClick={() => {
                sendAnalyticEvent(analyticEvents.authVIPapp);
                getDeeplink();
              }}
            />
          )}
        </FormSub>
      </GridColumnContainer>
      <UniversalColumnsWrapper>
        <UniversalColumn
          align="left"
          bgColor={theme.colors?.surface?.background}
          gap={16}
        >
          <RangeLimitsIcon />
          <IconColumnTitle>
            {t('VIP:hints.coverageAmount')} <br />
            {t('VIP:hints.upTo60MlnRuble')}
          </IconColumnTitle>
          <StyledColumnText>
            {t('VIP:hints.extendedCoverageLimit')}
          </StyledColumnText>
        </UniversalColumn>
        {authorizeRefRoute === iflHouseRoute && (
          <UniversalColumn
            align="left"
            bgColor={theme.colors?.surface?.background}
            gap={16}
          >
            <HorizontalRegulatorIcon />
            <IconColumnTitle>
              {t('VIP:hints.individualCoverage')}
            </IconColumnTitle>
            <StyledColumnText>
              {t('VIP:hints.coatingOfStructuralElementsOfHouse')}
            </StyledColumnText>
          </UniversalColumn>
        )}
        <UniversalColumn
          align="left"
          bgColor={theme.colors?.surface?.background}
          gap={16}
        >
          <DatePickerIcon />
          <IconColumnTitle>
            {t('VIP:hints.choosingPolicyStartDate')}
          </IconColumnTitle>
          <StyledColumnText>
            {t('VIP:hints.avoidOverlappingInsurancePeriods')}
          </StyledColumnText>
        </UniversalColumn>
      </UniversalColumnsWrapper>
    </BaseLayout>
  );
};
