import { HelperText } from '@pulse-web-ui/helper-text';
import { media, mixins, styled } from '@pulse-web-ui/theme';

import { AdaptiveContainerInverse } from '@src/components';

export const StyledHelperText = styled(HelperText)`
  margin-bottom: 0px;
`;

export const StyledAdaptiveContainerInverse = styled(AdaptiveContainerInverse)`
  ${mixins.flex({
    horizontal: false,
    gap: 0,
  })};

  ${media.desktop} {
    ${mixins.flex({ horizontal: false, reverse: true, gap: 0 })}

    & > div {
      margin-bottom: 0px;
    }

    & > :first-child {
      margin-bottom: 0px;
    }
  }
`;
