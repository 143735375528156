import i18n from '@src/i18n/config';
import type { Price } from '@src/types';
import { getFormPeriodLabels } from '@src/utils';

export const RadioDescriptionRender = ({ duration, discount }: Price) => {
  const formPeriodLabels = getFormPeriodLabels();

  if (duration === 'P3M') {
    return i18n.t('COMMON:description.radioP3MText', {
      discount,
    });
  }

  if (duration === 'P1Y') {
    return i18n.t('COMMON:description.radioP1YText', {
      discount,
    });
  }

  return formPeriodLabels.get(duration)?.description;
};
