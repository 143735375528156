import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Product } from '@src/constants';
import { Store } from '@src/store';

export const useNavigateToVip = () => {
  const {
    state: {
      stateUser: { selectedProduct },
      stateFormIFLHouse: { presetData: housePresetData },
      stateAuth: { authTokens },
    },
  } = useContext(Store);
  const navigate = useNavigate();

  const shouldNavigateToVip = useMemo(
    () =>
      !(selectedProduct === Product.HOUSE && housePresetData) &&
      (authTokens?.authorization?.isVip || authTokens?.isVip),
    [selectedProduct, housePresetData, authTokens]
  );

  useEffect(() => {
    if (shouldNavigateToVip) {
      navigate('/vip');
    }
  }, [shouldNavigateToVip]);

  return { shouldNavigateToVip };
};
