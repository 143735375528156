import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import type { SuggestionType } from '@src/types';

type InitDataType = {
  suggestions: SuggestionType[];
};
type ResultType = {
  suggestions: DaDataAddressSuggestion[];
};

export const suggestionsResConverter = (initData: InitDataType) => {
  const resultData: DaDataAddressSuggestion[] = [];
  initData.suggestions.forEach((element) => {
    const {
      value,
      country,
      region,
      region_fias_id,
      city,
      city_fias_id,
      settlement,
      settlement_fias_id,
      street,
      street_with_type,
      street_fias_id,
      house,
      house_fias_id,
      geo_lat,
      geo_lon,
    } = element;
    const newSuggestionItem: DaDataAddressSuggestion = {
      data: {
        area: '',
        area_fias_id: '',
        area_kladr_id: '',
        area_type: '',
        area_type_full: '',
        area_with_type: '',
        beltway_distance: '',
        beltway_hit: null,
        block: '',
        block_type: '',
        block_type_full: '',
        federal_district: '',
        capital_marker: '0',
        city: city || '',
        city_area: '',
        city_district: '',
        city_district_fias_id: '',
        city_district_kladr_id: '',
        city_district_type: '',
        city_district_type_full: '',
        city_district_with_type: '',
        city_fias_id: city_fias_id || '',
        city_kladr_id: '',
        city_type: '',
        city_type_full: '',
        city_with_type: city || '',
        country: country || '',
        country_iso_code: '',
        fias_id:
          house_fias_id ||
          street_fias_id ||
          settlement_fias_id ||
          city_fias_id ||
          '',
        fias_level: '',
        flat: '',
        flat_area: '',
        flat_price: null,
        flat_type: '',
        flat_type_full: '',
        flat_fias_id: '',
        flat_cadnum: null,
        geo_lat: geo_lat || '',
        geo_lon: geo_lon || '',
        geoname_id: '',
        history_values: null,
        house: house || '',
        house_fias_id: house_fias_id || '',
        house_kladr_id: '',
        house_type: '',
        house_type_full: '',
        house_cadnum: null,
        entrance: null,
        floor: null,
        kladr_id: '',
        okato: '',
        oktmo: '',
        postal_box: '',
        postal_code: '',
        qc: null,
        qc_complete: null,
        qc_geo: null,
        qc_house: null,
        region: region || '',
        region_fias_id: region_fias_id || '',
        region_kladr_id: '',
        region_type: '',
        region_type_full: '',
        region_with_type: region || '',
        settlement: settlement || '',
        settlement_fias_id: settlement_fias_id || '',
        settlement_kladr_id: '',
        settlement_type: '',
        settlement_type_full: '',
        settlement_with_type: settlement || '',
        source: '',
        square_meter_price: '',
        street: street || street_with_type || '',
        street_fias_id: street_fias_id || '',
        street_kladr_id: '',
        street_type: '',
        street_type_full: '',
        street_with_type: street_with_type || street || '',
        stead: '',
        stead_fias_id: '',
        stead_kladr_id: '',
        stead_type: '',
        stead_type_full: '',
        stead_cadnum: null,
        tax_office: '',
        tax_office_legal: '',
        timezone: '',
        unparsed_parts: null,
        fias_code: '',
        region_iso_code: '',
        fias_actuality_state: '',
        metro: null,
        divisions: '',
      },
      unrestricted_value: '',
      value: value || '',
    };

    resultData.push(newSuggestionItem);
  });

  const result: ResultType = {
    suggestions: resultData,
  };

  return result;
};
