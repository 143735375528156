import { Option } from '@pulse-web-ui/autocomplete-select';
import { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import { Product } from '@src/constants';
import { Region } from '@src/types';

export type SmartAddressProps = {
  type: Product.APARTMENT | Product.HOUSE;
  handleRegionChange: (val?: string) => void;
  setHouseSelectedStatus: (data: boolean) => void;
  isSelectedCustomStreet: boolean;
  isSelectedCustomHouse: boolean;
  isEmptyStreetSuggestions: boolean;
  isEmptyHouseSuggestions: boolean;
  selectedRegion?: Region;
  regions?: Option[];
  dadaValue?: DaDataAddressSuggestion;
  setIsCheckHouseStatus: (val: boolean) => void;
  isCheckHouseStatus: boolean;
  testId?: string;
};

export type SmartAddressValues = {
  region?: string;
  city?: string;
  street?: string;
  house?: string;
  flat?: string;
};

export enum SmartAddressBounds {
  COUNTRY = 'country',
  REGION = 'region',
  AREA = 'area',
  CITY = 'city',
  SETTLEMENT = 'settlement',
  STREET = 'street',
  HOUSE = 'house',
  FLAT = 'flat',
}
