/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { ArrowRight } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { useTheme } from '@pulse-web-ui/theme';

import { ControllerContainer } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { USER_AGREEMENTS_URL, analyticEvents } from '@src/constants';
import { useClearInsuredPersons, useHandlePressKey } from '@src/hooks';
import { StyledFooter } from '@src/pages/authorization/authorization-pages.styles';
import {
  AuthActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { KeyCode } from '@src/types';
import {
  addTestAttribute,
  phoneDisplayValueCasting,
  phoneValueCasting,
} from '@src/utils';
import { allowOnlyNumbers } from '@src/utils/allow-only-numbers';

import { Resend } from '../otp/components';
import { useAuthentication, useConfirmAuthentication } from './hooks';
import { phoneVerificationSchema } from './phone-verification-schema';
import {
  PhoneVerificationFormValues,
  PhoneVerificationProps,
} from './phone-verification-types';
import {
  CodeContainer,
  CodeInputWrapper,
  PhoneVerificationContainer,
  ReturnToPhoneChangeButton,
  StyledButton,
  StyledOTPInput,
  StyledPolicyLink,
  StyledSiteAgreements,
} from './phone-verification.styles';

const EXPIRE_PERIOD = 30;
const OTP_MAX_LENGTH = 5;

export const PhoneVerification = ({
  onCodeSuccess,
  onPhoneSuccess,
  onReturnToChangePhone,
  isSubmitButtonDisabled,
  isAgreementWithButton,
}: PhoneVerificationProps) => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { cachedPhoneNumber, preset, agentLogin, isPhoneFromQuery },
      stateAuth: { phoneNumber, authTokens },
    },
    dispatch,
  } = useContext(Store);
  const {
    control,
    getValues,
    formState: { dirtyFields, errors },
    setError,
    setValue,
    watch,
    clearErrors,
    trigger,
    reset,
  } = useForm<PhoneVerificationFormValues>({
    resolver: yupResolver(phoneVerificationSchema),
    shouldFocusError: true,
    mode: 'all',
  });

  const [isCodeDisplayed, setIsCodeDisplayed] = useState(false);
  const [phone, setPhone] = useState<string>();
  const [expiredTime, setExpiredTime] = useState<number>();
  const [codePlaceholder, setCodePlaceholder] = useState<string>();
  const [isPhoneErrorDisplayed, setIsPhoneErrorDisplayed] = useState(false);
  const [isAuthenticationSucceed, setIsAuthenticationSucceed] = useState(false);
  const [isMWError, setIsMWError] = useState(false);
  const otpCodeRef = useRef<HTMLInputElement | null>(null);
  const [isValidationError, setIsValidationError] = useState(false);
  const [blockedNumber, setBlockedNumber] = useState<string>();
  const authorizationLimitExceededMessage = t(
    'AUTH:errors.authorizationLimitExceeded'
  );
  const [isAuthenticationStart, setIsAuthenticationStart] = useState(false);
  const [isConfirmAuthenticationStart, setIsConfirmAuthenticationStart] =
    useState(false);
  const clearInsuredPersons = useClearInsuredPersons();

  const authenticationHandler = useCallback(() => {
    if (isAuthenticationStart) {
      if (!!phoneNumber?.length && phone !== phoneNumber) {
        clearInsuredPersons();

        dispatch({
          type: UserActionTypes.SetUserRegistrationData,
          payload: undefined,
        });
        dispatch({
          type: UserActionTypes.SetCachedPersonalInfoData,
          payload: undefined,
        });
        dispatch({
          type: UserActionTypes.SetProfile,
          payload: undefined,
        });
        dispatch({
          type: AuthActionTypes.SetAuthTokens,
          payload: undefined,
        });
        dispatch({
          type: OrderActionTypes.SetOrderRequestData,
          payload: undefined,
        });
        dispatch({
          type: OrderActionTypes.SetCachedOrderRequestData,
          payload: undefined,
        });
        dispatch({
          type: UserActionTypes.SetAuthenticationRefetchUid,
          payload: uuidv4(),
        });
      }

      setExpiredTime(Date.now() / 1000 + EXPIRE_PERIOD);
      setIsMWError(false);
      setIsCodeDisplayed(true);
      setIsAuthenticationStart(false);
      onPhoneSuccess && onPhoneSuccess();
    }
  }, [
    phone,
    phoneNumber,
    clearInsuredPersons,
    onPhoneSuccess,
    isAuthenticationStart,
  ]);

  const authenticationErrorHandler = useCallback(
    (message) => {
      if (
        message === authorizationLimitExceededMessage &&
        blockedNumber !== phone
      ) {
        setBlockedNumber(phone);
      }

      if (blockedNumber !== phoneValueCasting(getValues('phone') || '')) {
        return;
      }

      setError('phone', { type: 'string', message });
      setIsMWError(true);
    },
    [phone, blockedNumber, getValues('phone')]
  );

  const { refetch: refetchAuthentication, isLoading: isAuthenticationLoading } =
    useAuthentication({
      phone,
      authenticationHandler,
      authenticationErrorHandler,
    });

  const confirmAuthenticationHandler = useCallback(() => {
    if (isConfirmAuthenticationStart) {
      setExpiredTime(undefined);
      sendAnalyticEvent(analyticEvents.enterOtpSuccess);
      setIsAuthenticationSucceed(true);
      setIsMWError(false);
      setIsConfirmAuthenticationStart(false);
      onCodeSuccess && onCodeSuccess();
    }
  }, [onCodeSuccess, isConfirmAuthenticationStart]);

  const confirmAuthenticationErrorHandler = useCallback(
    (message) => {
      sendAnalyticEvent(analyticEvents.enterOtpFail);

      if (message === authorizationLimitExceededMessage) {
        setError('phone', { type: 'string', message });
        setValue('code', undefined);
        setIsMWError(true);
        setBlockedNumber(phone);
      } else {
        setError('code', {
          type: 'string',
          message,
        });
      }
      setIsConfirmAuthenticationStart(false);
    },
    [phone]
  );

  const refetchConfirmAuthentication = useConfirmAuthentication({
    code: getValues('code'),
    confirmAuthenticationHandler,
    confirmAuthenticationErrorHandler,
  });

  const handleEnteringPhone = () => {
    if (!!errors.phone || !!checkIfAgentLoginAndPhoneEquals()) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
      const formPhone = getValues('phone');

      if (authTokens && phoneValueCasting(formPhone!) === phoneNumber) {
        onCodeSuccess && onCodeSuccess();
      } else {
        setIsAuthenticationStart(true);
        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });
        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        setExpiredTime(Date.now() / 1000 + EXPIRE_PERIOD);
        setPhone(phoneValueCasting(formPhone || ''));

        if (preset) {
          sendAnalyticEvent(analyticEvents.requestPresetNewOtp);
        } else {
          sendAnalyticEvent(analyticEvents.requestNewOtp);
        }
      }
    }
  };

  useEffect(() => {
    const value = phoneDisplayValueCasting(
      cachedPhoneNumber || phoneNumber
    )?.replace('+7', '');

    if (!!value) {
      reset({ phone: value }, { keepDefaultValues: true });
    }
  }, [reset]);

  useEffect(() => {
    otpCodeRef.current?.focus();
  }, [isCodeDisplayed]);

  const handleEnteringCode = () => {
    if (!dirtyFields.code || !!errors.code) return;
    if (isAuthenticationSucceed) {
      onCodeSuccess && onCodeSuccess();
    } else {
      setIsConfirmAuthenticationStart(true);
      refetchConfirmAuthentication();
    }
  };

  const handleReturnToChangePhone = () => {
    setIsCodeDisplayed(false);
    setExpiredTime(undefined);
    setValue('code', undefined);
    clearErrors('code');
    setIsAuthenticationSucceed(false);
    setValue('phone', undefined);
    onReturnToChangePhone && onReturnToChangePhone();
  };

  const checkIfAgentLoginAndPhoneEquals = useCallback(() => {
    const phoneVal = `+7${allowOnlyNumbers(getValues('phone') || '')}`;

    if (!!agentLogin && phoneVal === agentLogin) {
      setError('phone', {
        type: 'string',
        message: t('COMMON:errors.phoneEqualsAgentPhone') || '',
      });

      return true;
    }

    return false;
  }, [getValues('phone'), agentLogin]);

  const handleResend = () => {
    setValue('code', undefined);
    clearErrors('code');
    setExpiredTime(Date.now() / 1000 + EXPIRE_PERIOD);
    if (!checkIfAgentLoginAndPhoneEquals()) {
      refetchAuthentication();
    }
  };

  useEffect(() => {
    const onlyNumValue = allowOnlyNumbers(otpCodeRef.current?.value || '');
    setValue('code', onlyNumValue);
  }, [watch('code')]);

  useEffect(() => {
    checkIfAgentLoginAndPhoneEquals();
  }, [checkIfAgentLoginAndPhoneEquals]);

  useEffect(() => {
    if (
      !!blockedNumber &&
      blockedNumber === phoneValueCasting(getValues('phone') || '')
    ) {
      setError('phone', {
        type: 'string',
        message: authorizationLimitExceededMessage,
      });
      setValue('code', undefined);
      setIsMWError(true);
    } else if (
      !!errors.phone &&
      blockedNumber !== phoneValueCasting(getValues('phone') || '')
    ) {
      clearErrors('phone');
      setIsMWError(false);
    }
  }, [blockedNumber, getValues('phone')]);

  useEffect(() => {
    if (!!errors.phone && (isMWError || isValidationError)) {
      setIsCodeDisplayed(false);
      setIsPhoneErrorDisplayed(true);
    }
  }, [errors.phone, isMWError, isValidationError]);

  useEffect(() => {
    if (!errors.phone) {
      setIsPhoneErrorDisplayed(false);
    }
  }, [errors.phone]);

  useEffect(() => {
    if (!getValues('phone')) {
      if (preset) {
        sendAnalyticEvent(analyticEvents.signupPresetPhone);
      } else {
        sendAnalyticEvent(analyticEvents.signupPhone);
      }
    }
  }, []);

  useEffect(() => {
    const formattedPhoneValue = phoneValueCasting(getValues('phone') || '');

    if (
      !!phoneNumber &&
      formattedPhoneValue !== phoneNumber &&
      formattedPhoneValue.split(/\d/).length - 1 === 11
    ) {
      if (preset) {
        sendAnalyticEvent(analyticEvents.signupPresetPhone);
      } else {
        sendAnalyticEvent(analyticEvents.signupPhone);
      }
    }
  }, [getValues('phone'), phoneNumber]);

  useEffect(() => {
    const code = getValues('code');
    if (code?.length === OTP_MAX_LENGTH) handleEnteringCode();
  }, [watch('code')]);

  const isPhoneButtonDisabled = useMemo(() => {
    if (isPhoneErrorDisplayed) {
      return true;
    }

    return (
      !getValues('phone') ||
      !dirtyFields.phone ||
      (isPhoneErrorDisplayed && !!errors.phone)
    );
  }, [
    isPhoneErrorDisplayed,
    phoneNumber,
    dirtyFields.phone,
    errors.phone,
    getValues('phone'),
  ]);

  useEffect(() => {
    if (
      isAuthenticationStart &&
      !!phone &&
      !checkIfAgentLoginAndPhoneEquals() &&
      !isPhoneButtonDisabled
    ) {
      refetchAuthentication();
    }
  }, [
    phone,
    isAuthenticationStart,
    isPhoneButtonDisabled,
    checkIfAgentLoginAndPhoneEquals,
  ]);

  const handleKeyPressEnter = () => {
    trigger('phone').then((value) => {
      setIsValidationError(!value);
    });

    !isCodeDisplayed
      ? !isPhoneButtonDisabled && handleEnteringPhone()
      : handleEnteringCode();
  };

  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [
    isPhoneButtonDisabled,
  ]);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: phoneValueCasting(value.phone!),
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <PhoneVerificationContainer>
      <ControllerContainer
        margin={
          isPhoneErrorDisplayed
            ? '0 0 45px 0'
            : isCodeDisplayed || isAgreementWithButton
            ? '0 0 32px 0'
            : '0 0 65px 0'
        }
      >
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, onChange }, fieldState }) => (
            <Input
              type="tel"
              label={t('COMMON:labels.phone') || ''}
              value={value || ''}
              onChange={onChange}
              disabled={isPhoneFromQuery || isCodeDisplayed}
              error={isPhoneErrorDisplayed && !!fieldState.error}
              {...addTestAttribute('phoneVerification.phone.input')}
              {...(isPhoneErrorDisplayed && fieldState.error?.message
                ? {
                    hintObject: {
                      message: fieldState.error?.message,
                    },
                  }
                : isCodeDisplayed || isAgreementWithButton
                ? {}
                : {
                    hintObject: {
                      message:
                        t('COMMON:hints.phoneNumberToFindOutIfRegistered') ||
                        '',
                    },
                  })}
            />
          )}
        />
      </ControllerContainer>
      {!isCodeDisplayed && (
        <StyledFooter width={720}>
          <Button
            variant="primary"
            label={t('COMMON:buttons.continue') || ''}
            onClick={handleEnteringPhone}
            disabled={isPhoneButtonDisabled}
            isLoading={isAuthenticationLoading}
            {...addTestAttribute('phoneVerification.button.continue')}
          />
          {isAgreementWithButton && (
            <Trans
              components={{
                SiteAgreements: <StyledSiteAgreements />,
                StyledPolicyLink: <StyledPolicyLink />,
              }}
              values={{ href: USER_AGREEMENTS_URL }}
            >
              {t('AUTH:hints.siteAgreements')}
            </Trans>
          )}
        </StyledFooter>
      )}
      {isCodeDisplayed && !isAuthenticationLoading && (
        <ControllerContainer margin="0 0 32px 0">
          <CodeContainer>
            <CodeInputWrapper>
              <Controller
                control={control}
                name="code"
                render={({ field: { value, onChange }, fieldState }) => (
                  <StyledOTPInput
                    label={t('COMMON:labels.smsCode') || ''}
                    value={value || ''}
                    ref={(e) => (otpCodeRef.current = e)}
                    onChange={onChange}
                    maxLength={OTP_MAX_LENGTH}
                    error={!!fieldState.error?.message}
                    placeholder={codePlaceholder}
                    onFocus={() => setCodePlaceholder('00000')}
                    onBlur={() => setCodePlaceholder(undefined)}
                    disabled={isAuthenticationSucceed}
                    inputMode="numeric"
                    {...addTestAttribute('phoneVerification.code.input')}
                    {...(fieldState.error?.message
                      ? {
                          hintObject: {
                            message: fieldState.error?.message,
                          },
                        }
                      : {})}
                  />
                )}
              />
              {expiredTime && (
                <Resend
                  isExpiryTimestampText
                  expiryTimestamp={expiredTime}
                  onResend={handleResend}
                />
              )}
              {!isPhoneFromQuery && (
                <ReturnToPhoneChangeButton
                  onClick={handleReturnToChangePhone}
                  {...addTestAttribute('phoneVerification.button.changePhone')}
                >
                  {t('COMMON:buttons.changePhone')}
                </ReturnToPhoneChangeButton>
              )}
            </CodeInputWrapper>
            <StyledButton
              variant="square"
              onClick={handleEnteringCode}
              disabled={isSubmitButtonDisabled}
              error={errors.code?.message}
              {...addTestAttribute('phoneVerification.button.sendCode')}
            >
              <ArrowRight
                width={30}
                color={
                  !!errors.code?.message
                    ? theme.colors.icon.inverse
                    : theme.colors.icon.primary
                }
              />
            </StyledButton>
          </CodeContainer>
        </ControllerContainer>
      )}
    </PhoneVerificationContainer>
  );
};
