import { createRef, forwardRef, memo, useEffect, useRef } from 'react';
import OtpInput from 'react-otp-input';

import { HelperText } from '@pulse-web-ui/helper-text';

import { CenteredWrapper, GridColumnContainer } from '@src/components';

import { Resend } from './components';
import { OtpContainer, StyledOtpInput } from './otp.styles';
import { OtpProps } from './otp.types';

export const Otp = memo(
  forwardRef<HTMLDivElement, OtpProps>(
    (
      {
        expiryTimestamp,
        onResend,
        status,
        onAbleResendOtp,
        limit = 6,
        onChange,
        extraMessage,
        disabled,
        alignment,
        isExpiryTimestampText,
        gridStep,
        style,
        code,
      },
      ref
    ) => {
      const inputRefs = useRef<HTMLInputElement[]>([]);
      inputRefs.current = new Array(limit).map(
        (_, i) => inputRefs.current[i] ?? createRef()
      );
      const hasError = status?.status === 'error';
      const isAfterErrorState = code?.length === limit && hasError;

      useEffect(() => {
        if (hasError && !!inputRefs.current?.length) {
          inputRefs.current.forEach((input, index) => {
            if (index === 0) {
              input.focus();
            } else {
              input.blur();
            }
          });
        }
      }, [hasError]);

      const otpChangeHandler = (val: string) => {
        if (!isAfterErrorState) {
          onChange(val);
        }
      };

      /* eslint-disable indent */
      const handleKeyDown =
        (onKeyDown: React.KeyboardEventHandler) =>
        (e: React.KeyboardEvent<HTMLInputElement>) => {
          const inputValue = (e.target as HTMLInputElement).value;
          const isNumberKeyPressed = !!Number(e.key) || e.key === '0';
          const sameValue = inputValue === e.key;

          if (isAfterErrorState && isNumberKeyPressed) {
            if (sameValue) {
              onChange(e.key);
            } else {
              onChange('');
            }
          }
          onKeyDown(e);
        };
      /* eslint-enable */

      return (
        <OtpContainer style={style} data-component="otp" ref={ref}>
          <GridColumnContainer gridStep={gridStep}>
            <CenteredWrapper alignment={alignment}>
              <div style={{ textAlign: 'start' }}>
                <HelperText message={status?.message} status={status?.status}>
                  <OtpInput
                    shouldAutoFocus
                    containerStyle="otp-input"
                    inputType="number"
                    value={code}
                    onChange={otpChangeHandler}
                    numInputs={limit}
                    renderSeparator={<span>&nbsp;</span>}
                    renderInput={({ ref, ...props }, index) => (
                      <StyledOtpInput
                        ref={(instance) => {
                          if (instance) {
                            inputRefs.current[index] = instance;
                          }
                          ref(instance);
                        }}
                        disabled={disabled}
                        error={hasError}
                        {...props}
                        onKeyDown={handleKeyDown(props.onKeyDown)}
                        type="text"
                      />
                    )}
                  />
                </HelperText>
              </div>
            </CenteredWrapper>

            {extraMessage && <CenteredWrapper>{extraMessage}</CenteredWrapper>}
            {expiryTimestamp && (
              <CenteredWrapper>
                <Resend
                  isExpiryTimestampText={isExpiryTimestampText}
                  expiryTimestamp={expiryTimestamp}
                  onResend={onResend}
                  onAbleResendOtp={onAbleResendOtp}
                />
              </CenteredWrapper>
            )}
          </GridColumnContainer>
        </OtpContainer>
      );
    }
  )
);
