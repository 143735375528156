/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { AuthFlowStoreState } from './auth-flow-store.state';
import { AuthFlowActionTypes } from './auth-flow-store.types';

export type AuthFlowStoreReducer = (
  state: AuthFlowStoreState,
  action: StoreActions
) => AuthFlowStoreState;

export const authFlowStoreReducer: AuthFlowStoreReducer = (state, action) => {
  switch (action?.type) {
    case AuthFlowActionTypes.SetAuthStep:
      return {
        ...state,
        authStep: action.payload,
      };

    case AuthFlowActionTypes.SetAuthFLowParams:
      return {
        ...state,
        authFlowParams: action.payload,
      };

    case AuthFlowActionTypes.SetStatus:
      return {
        ...state,
        status: action.payload,
      };

    case AuthFlowActionTypes.SetSepScrAuth:
      return {
        ...state,
        sepScrAuth: action.payload,
      };

    case AuthFlowActionTypes.SetInitAuthState:
      return {
        ...state,
        initAuthState: action.payload,
      };

    default:
      return state;
  }
};
