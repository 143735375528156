import { memo, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BaseLayout } from '@src/layouts';
import { Store } from '@src/store';

import { ScoreErrorContent } from './score-error-content';

export const ScoreErrorPage = memo(() => {
  const [searchParams] = useSearchParams();
  const {
    state: {
      stateFormIFLFlat: { isObjectDataError },
      stateFormIFLHouse: { isObjectDataError: isObjectHouseDataError },
      stateUser: { isScrinning, agentLogin: agentLoginFromStore },
    },
  } = useContext(Store);

  const agentLogin = searchParams.get('agent_login') || agentLoginFromStore;

  return (
    <BaseLayout>
      <ScoreErrorContent
        isObjectDataError={isObjectDataError || isObjectHouseDataError}
        isScrinning={isScrinning}
        isAgentFlow={!!agentLogin}
      />
    </BaseLayout>
  );
});
