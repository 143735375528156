import { FullDescriptionObject } from '@src/types';

import { MaterialFullDescription } from './material-full-description';
import { MatterialsGroups, Title } from './material.styles';
import { MaterialProps } from './material.types';

export const Material = ({
  fullDescriptionTitle,
  fullDescription,
}: MaterialProps) => (
  <MatterialsGroups>
    <Title>{fullDescriptionTitle}</Title>
    {fullDescription?.map((fullDescriptionObject: FullDescriptionObject) => (
      <MaterialFullDescription
        title={fullDescriptionObject.title}
        points={fullDescriptionObject.points}
      />
    ))}
  </MatterialsGroups>
);
