import { PetsPresetData } from '@src/store/pets';
import { Risk } from '@src/types';

export const createRisksFromPresetData = (
  presetData: PetsPresetData,
  risks: Risk[]
): Risk[] => {
  const codes = presetData.risks.map(({ code }) => code);

  return risks.map((risk: Risk) => ({
    ...risk,
    active: codes.includes(risk.code),
  }));
};
