import { SubmitData } from '@src/types';

export const changeMiteUserPrimaryRecordId = (
  orderRequestData: SubmitData,
  primaryRecordId: number
): SubmitData => ({
  ...orderRequestData,
  productProperty: {
    ...orderRequestData.productProperty,
    insuranceObjects: orderRequestData.productProperty.insuranceObjects.map(
      (insuranceObject) => {
        if (insuranceObject.isInsurer) {
          return { ...insuranceObject, primaryRecordId };
        }

        return insuranceObject;
      }
    ),
  },
});
