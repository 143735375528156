import { media, styled } from '@pulse-web-ui/theme';

export const StyledAppContent = styled.div<{ hasPadding: boolean }>`
  padding: ${({ hasPadding }) => (hasPadding ? '0 16px' : '0')};
  box-sizing: border-box;

  ${media.desktop} {
    padding: 0;
  }
`;
