/* eslint-disable indent */
import { useContext } from 'react';

import { ProductKind } from '@src/constants';
import { Store } from '@src/store';
import { Risk, SubmitData } from '@src/types';
import { prepareDate } from '@src/utils';

const getRisks = (risks: Array<Risk> | undefined) => {
  return risks?.map(({ code }) => code);
};

export const useSportNsOrderData = () => {
  const {
    state: {
      stateFormNSSport,
      stateUser: { agentLogin },
    },
  } = useContext(Store);

  const orderData: SubmitData = {
    baseParameters: {
      effectiveSince: prepareDate(stateFormNSSport.selectedStartDate),
      productCode: stateFormNSSport.insuranceProduct?.code || '',
      ...(agentLogin && { agentLogin }),
      contractDuration: stateFormNSSport.selectedDuration || '',
      risks: getRisks(stateFormNSSport.risks) || [],
      ...(stateFormNSSport.promoCode &&
        stateFormNSSport.insurancePrice?.isSuccessfulPromo !== false && {
          promoCode: stateFormNSSport.promoCode,
        }),
      ...(stateFormNSSport?.presetData?.legalCode && {
        legalCode: stateFormNSSport.presetData.legalCode,
      }),
    },
    productProperty: {
      insuranceSum: stateFormNSSport.insuranceSum || 0,
      kind: ProductKind.SPORT,
      sports:
        stateFormNSSport.choosedSportKinds?.map(({ value }) => value) || [],
      insuranceObjects: [],
    },
  };

  return {
    orderData,
  };
};
