/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { Store } from '@src/store';

const sendAnalytic = (wantNextStep: boolean, currentStep: number) => {
  if (wantNextStep) {
    switch (currentStep) {
      case 1:
        sendAnalyticEvent(analyticEvents.petToStepRisk);
        break;
      case 2:
        sendAnalyticEvent(analyticEvents.petToStepAddRisk);
        break;
      case 3:
        sendAnalyticEvent(analyticEvents.petToStepCoverage);
        break;
      case 4:
        sendAnalyticEvent(analyticEvents.petToStepPeriod);
        break;
      default:
        break;
    }
  }
};

export const useAnalyticsPets = () => {
  const {
    state: {
      stateWizard: { wantNextStep, currentStep },
      stateFormPets: { presetData },
    },
  } = useContext(Store);

  useEffect(() => {
    if (currentStep === 6) {
      sendAnalyticEvent(analyticEvents.petToStepYourData);
    }
  }, []);

  useEffect(() => {
    if (!presetData) {
      sendAnalytic(wantNextStep, currentStep);
    }
  }, [wantNextStep, currentStep, presetData]);
};
