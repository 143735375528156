import { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import { AddressDataType } from '@src/types';

export const getAddress = (
  dadaValue: DaDataAddressSuggestion | undefined
): AddressDataType => ({
  address: dadaValue?.value || '',
  addressCode: dadaValue?.data?.fias_id || undefined,
  regionCode: dadaValue?.data.region_fias_id,
  streetCode: dadaValue?.data.street_fias_id || undefined,
  houseCode: dadaValue?.data.house_fias_id || undefined,
  flatCode: dadaValue?.data.flat_fias_id || undefined,
  ...(dadaValue?.data.settlement_fias_id
    ? { placeCode: dadaValue?.data.settlement_fias_id }
    : {}),
  ...(dadaValue?.data.city_fias_id
    ? { cityCode: dadaValue?.data.city_fias_id }
    : {}),
});
