import { InsurePerson, OrderRequestDataPerson } from '@src/types';
import { calculateAge, getFormattedDate } from '@src/utils';

export const orderRequestPersonsData = (
  insurePersons: InsurePerson[]
): OrderRequestDataPerson[] => {
  return insurePersons.map((person) => {
    const {
      firstName,
      middleName,
      lastName,
      birthDate,
      ageMin,
      primaryRecordId,
    } = person;

    return {
      firstName: firstName?.trim(),
      middleName: middleName?.trim(),
      lastName: lastName?.trim(),
      ageMin,
      primaryRecordId,
      birthDate: birthDate ? getFormattedDate(new Date(birthDate)) : undefined,
      age: birthDate ? calculateAge(new Date(birthDate)) : undefined,
    };
  });
};
