import { media, mixins, styled } from '@pulse-web-ui/theme';

export const PageContent = styled.div`
  padding-top: 0;
`;

export const SectionContainer = styled.div<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '32px'};
`;

export const PromoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  width: 100%;

  ${media.desktop} {
    flex-wrap: nowrap;
  }
`;

export const PromoTextInputWrapper = styled.div`
  flex: auto;
`;
