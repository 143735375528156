import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { phoneDisplayValueCasting } from '@src/utils';

export const usePhoneFromQuery = () => {
  const [searchParams] = useSearchParams();

  const deviceType = searchParams.get('platform');
  const phone = searchParams.get('phone');

  const { dispatch } = useContext(Store);

  useEffect(() => {
    if (deviceType && phone) {
      dispatch({
        type: AuthActionTypes.SetPhoneNumber,
        payload: phoneDisplayValueCasting(phone).trimStart().slice(1),
      });
      dispatch({
        type: UserActionTypes.SetIsPhoneFromQuery,
        payload: true,
      });
    }
  }, [deviceType, phone]);
};
