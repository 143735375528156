import { FeatureFlags } from '@src/constants';

import { useStorageQuery } from './use-storage-query';

export const useFeatureFlags = (featureFlags: FeatureFlags[]) => {
  const queryResult = useStorageQuery<{ features: string[] }>(
    'featureFlags',
    '/themes/common-features.json',
    undefined,
    {
      cacheTime: Infinity,
    }
  );

  const {
    isLoading: isFeatureFlagsLoading,
    error: featureFlagsError,
    data,
    refetch: featureFlagsRefetch,
    isFetching: isFeatureFlagsFetching,
  } = queryResult;

  let res: boolean[] = Array(featureFlags.length).fill(false);

  if (!isFeatureFlagsLoading && data?.data?.features) {
    res = featureFlags.map((flag) => data.data.features.includes(flag));
  }

  return {
    res,
    isFeatureFlagsLoading,
    featureFlagsError,
    featureFlagsRefetch,
    isFeatureFlagsFetching,
  };
};
