import React, { ReactElement } from 'react';

export const SportIcon = (): ReactElement => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="45.5764"
        y="23.9636"
        width="33.2"
        height="6"
        transform="rotate(135 45.5764 23.9636)"
        fill="#FFC000"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="28.323"
        y="62.7131"
        width="35.6"
        height="6"
        rx="3"
        transform="rotate(-135 28.323 62.7131)"
        fill="#E4E7EE"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="32.8485"
        y="58.1876"
        width="35.6"
        height="6"
        rx="3"
        transform="rotate(-135 32.8485 58.1876)"
        fill="#E4E7EE"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="52.0818"
        y="38.9543"
        width="35.6"
        height="6"
        rx="3"
        transform="rotate(-135 52.0818 38.9543)"
        fill="#E4E7EE"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="35.6769"
        y="5.01316"
        width="35.6"
        height="6"
        rx="3"
        transform="rotate(45 35.6769 5.01316)"
        fill="#E4E7EE"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="17.0093"
        y="60.4504"
        width="16.4"
        height="6"
        rx="3"
        transform="rotate(-135 17.0093 60.4504)"
        fill="#E4E7EE"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="46.9906"
        y="7.27589"
        width="16.4"
        height="6"
        rx="3"
        transform="rotate(45 46.9906 7.27589)"
        fill="#E4E7EE"
        stroke="#1F232B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
