/* eslint-disable indent */
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FRACTION_TWO,
  Product,
  petsRoute,
  televetPlusRoute,
} from '@src/constants';
import { Store } from '@src/store';
import { OrderItemValueType } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  getFormPeriodLabels,
} from '@src/utils';

export const useOrderPrice = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLHouse: { selectedDuration: selectedDurationIflHouse },
      stateFormIFLFlat: { selectedDuration: selectedDurationIflFlat },
      stateFormNS: { selectedDuration: selectedDurationNS },
      stateFormPets: { selectedDuration: selectedDurationPets },
      statePetsTelevetPlus: { insurancePrice },
      stateUser: { selectedProduct },
      stateOrder: { orderSubmitData },
      stateAuth: { authorizeRefRoute },
    },
  } = useContext(Store);

  const formPeriodLabels = getFormPeriodLabels();
  const priceDescription = useMemo(() => {
    switch (authorizeRefRoute) {
      case petsRoute:
      case televetPlusRoute:
        return t('ORDER:labels.priceOfPayment');
      default:
        return t('ORDER:labels.costOfPayment');
    }
  }, [authorizeRefRoute]);

  const isShowPromoValue = selectedProduct === Product.PETS_TELEVET_PLUS;
  const pricePeriod = formPeriodLabels.get(
    selectedDurationIflFlat ||
      selectedDurationIflHouse ||
      selectedDurationNS ||
      selectedDurationPets ||
      ''
  )?.label;
  const isSuccessfulPromo = Boolean(
    orderSubmitData?.isSuccessfulPromo && insurancePrice?.premiumAndDelta
  );
  const inYearDescription =
    selectedProduct === Product.MITE ||
    selectedProduct === Product.PETS_TELEVET_PLUS
      ? isShowPromoValue && isSuccessfulPromo
        ? t('COMMON:labels.priceInFirstYear')
        : t('COMMON:labels.pricePerYear')
      : t('COMMON:labels.perMonth');

  const premiumValue = orderSubmitData?.premiumAndDelta
    ? currencyRuLocaleWithoutFraction(
        Number(orderSubmitData.premiumAndDelta),
        FRACTION_TWO
      )
    : '';

  let secondsValue = '';

  if (isSuccessfulPromo) {
    secondsValue =
      `${currencyRuLocaleWithoutFraction(
        Number(insurancePrice?.premiumAndDelta),
        FRACTION_TWO
      )} ₽ ${t('COMMON:hints.fromSecondYear')}` || '';
  }

  return {
    type: OrderItemValueType.SIMPLE,
    title: priceDescription,
    value: `${premiumValue} ${pricePeriod || inYearDescription}`,
    ...(isShowPromoValue
      ? {
          secondsValue,
          successValue: isSuccessfulPromo
            ? t('COMMON:success.promoCodeApplied') || ''
            : '',
        }
      : {}),
  };
};
