import { OrderRequestData } from '@src/types';

export const changeNsUserPrimaryRecordId = (
  orderRequestData: OrderRequestData,
  primaryRecordId: number
): OrderRequestData => ({
  ...orderRequestData,
  accidents: {
    ...orderRequestData.accidents,
    persons:
      orderRequestData.accidents?.persons.map((person) => {
        if (person.primaryRecordId) {
          return {
            ...person,
            primaryRecordId,
          };
        }
        return person;
      }) || [],
  },
});
