/* eslint-disable */
// @ts-nocheck
import { useContext, useMemo } from 'react';

import { Product } from '@src/constants';
import { Store } from '@src/store';

export const useLimits = () => {
  const {
    state: { stateFormIFLFlat, stateFormIFLHouse },
  } = useContext(Store);

  const product = localStorage.getItem('selectedProduct');

  const limits = useMemo(() => {
    let selectedState;

    switch (product) {
      case Product.APARTMENT:
        selectedState = stateFormIFLFlat;
        break;
      case Product.HOUSE:
        selectedState = stateFormIFLHouse;
        break;
      default:
        break;
    }

    if (!selectedState) return;

    return {
      min: Number(selectedState.currentSumMinLimit),
      max: Number(selectedState.currentSumMaxLimit),
    };
  }, [product, stateFormIFLFlat, stateFormIFLHouse]);

  return limits;
};
