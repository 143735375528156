/* eslint-disable indent */
type MinDate = Date | null;

export const getDatepickerPeriod = (
  effectiveSince: Date,
  ageMin?: number
): { minDate: MinDate; maxDate: Date } => {
  let minDate: MinDate = new Date(effectiveSince);
  const maxDate = new Date(effectiveSince);

  if (!ageMin) {
    return {
      minDate: null,
      maxDate: new Date(),
    };
  }
  // 1 день
  const dayMilliseconds = 24 * 60 * 60 * 1000;

  switch (ageMin) {
    case 1:
      minDate.setFullYear(minDate.getFullYear() - 18);
      maxDate.setFullYear(maxDate.getFullYear() - 1);
      maxDate.setDate(maxDate.getDate() - 1);
      maxDate.setTime(maxDate.getTime() - dayMilliseconds);
      break;
    case 18:
      minDate.setFullYear(minDate.getFullYear() - 70);
      maxDate.setFullYear(maxDate.getFullYear() - 18);
      maxDate.setTime(maxDate.getTime() - dayMilliseconds);
      break;
    case 70:
      minDate = null;
      maxDate.setFullYear(maxDate.getFullYear() - 70);
      maxDate.setTime(maxDate.getTime() - dayMilliseconds);
      break;
    default:
      break;
  }

  return {
    minDate,
    maxDate,
  };
};
