/* eslint-disable indent */
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FRACTION_TWO } from '@src/constants';
import {
  TELEVET_PROGRAM_KEY,
  TELEVET_RISK_CODE,
} from '@src/pages/pets-televet-plus/constants';
import { OrderActionTypes, Store } from '@src/store';
import { OrderItemValueType, OrderItemsBlockType } from '@src/types';
import {
  convertDateFormatInTimeZone,
  currencyRuLocaleWithoutFraction,
  numFormat,
} from '@src/utils';

import { getFormattedAge } from '../utils';

export const usePetsTelevetPlusOrderArray = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { profile, promoCodeFailedMessage },
      statePetsTelevetPlus: {
        insuranceSum,
        promoCode,
        risks,
        selectedPetData: {
          name: petName = '',
          age: petAge = '',
          gender: petGender = '',
          breed: petBreed = '',
        } = {},
        selectedPetKindName,
        selectedDuration,
        selectedDate,
        getPricesFull,
        insurancePrograms,
        insurancePrice,
      },
      stateAuth: { displayPhoneNumber },
    },
    dispatch,
  } = useContext(Store);
  const petsPaymentPeriodLabels = {
    label: t('PETS_TELEVET_PLUS:labels.inYear'),
    promoLabel: t('PETS_TELEVET_PLUS:labels.inFirstYear'),
    promoSubLabel: t('COMMON:hints.fromSecondYear'),
  };

  const televetProgram = useMemo(
    () =>
      insurancePrograms?.antimitePrograms?.find(
        (program) => program.insuranceProgram === TELEVET_PROGRAM_KEY
      ),
    [insurancePrograms?.antimitePrograms?.length]
  );

  const televetProgramRisk = useMemo(
    () =>
      televetProgram?.risks?.find((risk) => risk.code === TELEVET_RISK_CODE),
    [televetProgram]
  );

  const riskElements = useMemo(
    () => televetProgramRisk?.riskElements?.map((item) => item.name).join(', '),
    [televetProgramRisk]
  );

  return useCallback(() => {
    const selectedPrice = televetProgram?.basePrice;
    const selectedPromoPrice = insurancePrice?.premiumAndDeltaPromo;

    let label = `${
      selectedPrice
        ? currencyRuLocaleWithoutFraction(Number(selectedPrice), FRACTION_TWO)
        : ''
    } ₽ ${petsPaymentPeriodLabels.label}`;

    let subLabel = '';
    const isSuccessfulPromo = insurancePrice?.isSuccessfulPromo;

    if (isSuccessfulPromo) {
      label = `${currencyRuLocaleWithoutFraction(
        Number(selectedPromoPrice),
        FRACTION_TWO
      )} ₽ ${petsPaymentPeriodLabels.promoLabel}`;
      subLabel =
        `${currencyRuLocaleWithoutFraction(
          Number(insurancePrice?.premiumAndDelta),
          FRACTION_TWO
        )} ₽ ${petsPaymentPeriodLabels.promoSubLabel}` || '';
    }

    const name = [
      profile?.profile?.lastName,
      profile?.profile?.firstName,
      profile?.profile?.middleName !== '' ? profile?.profile?.middleName : null,
    ].join(' ');

    const checkForFailedPromoCode = () => {
      if (promoCodeFailedMessage) {
        return t('COMMON:errors.promoCodeNotApplied');
      }

      return isSuccessfulPromo === undefined ||
        isSuccessfulPromo === null ||
        promoCode === '' ||
        (isSuccessfulPromo && !selectedPromoPrice)
        ? ''
        : t('COMMON:errors.promoCodeNotApplied') || '';
    };

    const orderArray: OrderItemsBlockType[] = [
      {
        type: OrderItemValueType.BLOCK,
        title: t('PETS_TELEVET_PLUS:labels.personalData'),
        value: [
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.insurant'),
            value: name,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.phoneOfNumber'),
            value: displayPhoneNumber,
          },
        ],
      },
      {
        type: OrderItemValueType.BLOCK,
        title: t('PETS_TELEVET_PLUS:labels.petData'),
        value: [
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_TELEVET_PLUS:labels.whatNameOfPet'),
            value: petName,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_TELEVET_PLUS:labels.pet'),
            value: `${selectedPetKindName} • ${petGender} • ${getFormattedAge(
              petAge
            )}`,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_TELEVET_PLUS:labels.breed'),
            value: petBreed,
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: televetProgramRisk?.description || '',
            value: t('COMMON:price.toRubles', {
              price: numFormat(Number(televetProgramRisk?.limit)),
            }),
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('PETS_TELEVET_PLUS:labels.inSubscription'),
            value: riskElements || '',
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('ORDER:labels.priceOfPayment'),
            value: label,
            secondsValue: subLabel,
            successValue:
              isSuccessfulPromo && selectedPromoPrice
                ? t('COMMON:success.promoCodeApplied') || ''
                : '',
            failedValue: checkForFailedPromoCode(),
          },
          {
            type: OrderItemValueType.SIMPLE,
            title: t('COMMON:labels.subscriptionWillStart') || '',
            value: selectedDate
              ? `с ${convertDateFormatInTimeZone(selectedDate)
                  ?.toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                  .replace(' г.', '')}`
              : '',
          },
        ],
      },
    ];

    dispatch({
      type: OrderActionTypes.SetOrder,
      payload: orderArray,
    });
  }, [
    getPricesFull?.prices,
    selectedDuration,
    risks,
    insuranceSum,
    selectedDate,
    displayPhoneNumber,
    profile,
    selectedPetKindName,
    petGender,
    petAge,
    petBreed,
    petName,
    insurancePrograms,
    insurancePrice,
  ]);
};
