/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError } from 'axios';
import { useContext, useEffect } from 'react';

import { Store, WizardActionTypes } from '@src/store';

interface ErrorResponse {
  code: string;
}

export const useBlockNextStep = (
  isLoading: boolean,
  requestError: AxiosError<ErrorResponse, any> | null | boolean
) => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    if (!isLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: !!requestError,
      });
    }
  }, [isLoading, requestError]);
};
