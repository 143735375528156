import { useMemo } from 'react';

import { breakpoints } from '@pulse-web-ui/theme';

import useWindowSize from '@src/hooks/use-window-size';

export const useIsDesktop = (): boolean => {
  const [width = 360] = useWindowSize();

  return useMemo(() => width >= breakpoints.DESKTOP, [width]);
};
