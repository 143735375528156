/* eslint-disable quotes */
import { startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';

import { TIME_ZONE_MOSCOW } from '../constants';

export const prepareDate = (date: Date | null): string => {
  if (date instanceof Date) {
    const roundedDate = startOfDay(date);
    const formattedDate = format(roundedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
      timeZone: TIME_ZONE_MOSCOW,
    });

    return formattedDate;
  }

  return '';
};
