import { SubscriptionCardGroup } from '@pulse-web-ui/subscription-card';
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const PromoSubmitButton = styled.div`
  ${typography.bold18};
  width: 100%;
  ${media.desktop} {
    width: auto;
  }
  div div {
    transition: 300ms ease all;
  }
`;

export const StyledSubscriptionCardGroup = styled(SubscriptionCardGroup)`
  margin-bottom: 32px;
`;

export const SettingsWrap = styled.div`
  ${mixins.flex({ justifyContent: 'space-between', alignItems: 'center' })}
  row-gap: 12px;
  margin-bottom: 32px;

  h2 {
    margin-bottom: 0;
  }
`;
