import { useCallback, useMemo } from 'react';
import { useQueries } from 'react-query';

import { useQueryFunction } from '@src/hooks';
import type { RequestParams } from '@src/types';

export const useRequests = <T>(queriesProp: RequestParams<T>[]) => {
  const queryResults = useQueries(
    queriesProp.map((query) => {
      const queryFn = useQueryFunction(
        query.method,
        query.url,
        query?.request,
        query?.accessToken
      );

      return {
        queryKey: [query.key, query.deps],
        queryFn: queryFn,
      };
    })
  );

  const res = useMemo(
    () => queryResults.map((query) => ({ ...query, data: query?.data?.data })),
    [queryResults]
  );
  const isLoading = useMemo(
    () => queryResults.some((query) => query.isLoading),
    [queryResults]
  );
  const isFetching = useMemo(
    () => queryResults.some((query) => query.isFetching),
    [queryResults]
  );
  const isError = useMemo(
    () => queryResults.some((query) => query.isError),
    [queryResults]
  );
  const refetchAll = useCallback(() => {
    queryResults.forEach((query) => query.refetch());
  }, [queryResults]);

  return { res, isLoading, isFetching, isError, refetchAll };
};
