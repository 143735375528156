/* eslint-disable indent */
import { Product } from '@src/constants';

const getEventId = (product: Product | undefined): string | undefined => {
  switch (product) {
    case Product.APARTMENT:
      return '5f886a4e-900b-4ad1-ba83-a89102e5615c';

    case Product.HOUSE:
      return 'a95c71b5-9de4-412c-9df2-3add880291cf';

    case Product.MITE:
      return '04e3d8a7-6d2f-40e1-b0f8-d9d510ff0945';

    case Product.NS:
      return '9f32ad1a-d16a-4a37-8efa-5cbb5910c529';

    case Product.PETS:
      return '32ed5063-c111-43c5-9ee7-95b4ec62fff6';

    case Product.SPORT:
      return '14c359d3-fa7f-46a1-b98d-079e63d8adb2';

    default:
      return undefined;
  }
};

export const sendRoxotAnalytic = (product: string) => {
  const eventId = getEventId(product as Product);
  if (eventId) {
    fetch(`https://rap.skcrtxr.com/pub/pix/${eventId}`);
  }
};
