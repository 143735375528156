/* eslint-disable indent */
import { insurancePersonDefaultData } from '@src/constants';
import { InsurePerson, NumberInsurePersons } from '@src/types';

export const insurePersonsFormatter = (
  data: NumberInsurePersons
): InsurePerson[] => {
  const newInsurePersons: InsurePerson[] = [];

  Object.keys(data).forEach((key) => {
    if (data[key as keyof NumberInsurePersons]) {
      switch (key) {
        case 'numberChildren':
          for (let i = 0; i < data.numberChildren; i++) {
            newInsurePersons.push({ ...insurancePersonDefaultData, ageMin: 1 });
          }
          break;
        case 'numberAdults':
          for (let i = 0; i < data.numberAdults; i++) {
            newInsurePersons.push({
              ...insurancePersonDefaultData,
              ageMin: 18,
            });
          }
          break;
        case 'numberElderly':
          for (let i = 0; i < data.numberElderly; i++) {
            newInsurePersons.push({
              ...insurancePersonDefaultData,
              ageMin: 70,
            });
          }
          break;
      }
    }
  });

  return newInsurePersons;
};
