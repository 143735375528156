import React from 'react';

export const NsIcon = () => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="62.6052"
        y="48.6953"
        width="20.6429"
        height="65.6"
        rx="10.3215"
        transform="rotate(135 62.6052 48.6953)"
        fill="#E4E7EE"
      />
      <path
        d="M48.5235 34.1909L55.3387 41.0061C59.4002 45.0676 59.4002 51.6525 55.3387 55.7139V55.7139C51.2773 59.7754 44.6924 59.7754 40.6309 55.7139L36.3883 51.4713L33.8157 48.8987M30.4216 16.089L27.903 13.5704L23.6604 9.32773C19.5989 5.26628 13.014 5.26628 8.95254 9.32773V9.32773C4.89109 13.3892 4.89109 19.9741 8.95254 24.0356L13.1952 28.2782L15.7138 30.7968"
        stroke="#21145A"
        strokeWidth="2"
      />
      <rect
        x="47.876"
        y="2.02734"
        width="20.8"
        height="65.6"
        rx="10.4"
        transform="rotate(45 47.876 2.02734)"
        fill="#E4E7EE"
        stroke="#21145A"
        strokeWidth="2"
      />
      <ellipse
        cx="22.7476"
        cy="32.814"
        rx="2.4"
        ry="2.4"
        transform="rotate(45 22.7476 32.814)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <ellipse
        cx="27.3357"
        cy="37.4019"
        rx="2.4"
        ry="2.4"
        transform="rotate(45 27.3357 37.4019)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <ellipse
        cx="31.853"
        cy="41.8101"
        rx="2.4"
        ry="2.4"
        transform="rotate(45 31.853 41.8101)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <circle
        cx="27.3275"
        cy="28.2344"
        r="1.65"
        transform="rotate(-45 27.3275 28.2344)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <ellipse
        cx="31.9158"
        cy="32.8218"
        rx="2.4"
        ry="2.4"
        transform="rotate(45 31.9158 32.8218)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <ellipse
        cx="36.3157"
        cy="37.2222"
        rx="2.4"
        ry="2.4"
        transform="rotate(45 36.3157 37.2222)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <ellipse
        cx="31.7156"
        cy="23.8453"
        rx="2.4"
        ry="2.4"
        transform="rotate(45 31.7156 23.8453)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <ellipse
        cx="36.2412"
        cy="28.3707"
        rx="2.4"
        ry="2.4"
        transform="rotate(45 36.2412 28.3707)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
      <circle
        cx="40.7666"
        cy="32.8961"
        r="1.65"
        transform="rotate(45 40.7666 32.8961)"
        fill="#1698D9"
        stroke="#21145A"
        strokeWidth="1.5"
      />
    </svg>
  );
};
