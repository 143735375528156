import {
  DraftType,
  OrderRequestData,
  SubmitData,
  SumbitDataMite,
} from '@src/types';

import type { IFLFlatStoreState } from '../ifl-flat';

export interface DraftStoreState {
  draft?: DraftType<OrderRequestData | SubmitData | SumbitDataMite>;
  formDraft?: IFLFlatStoreState;
  checkDraft: boolean;
}

export const initDraftStoreState: DraftStoreState = {
  draft: undefined,
  checkDraft: true,
  formDraft: undefined,
};
