import type { OrderItemType } from '@src/types';

import {
  OrderCheckoutItemSubValue,
  OrderCheckoutItemTitle,
  OrderCheckoutItemValue,
  OrderCheckoutItemWrapper,
  OrderItemStatusValue,
  OrderItemValueWrapper,
} from './components.styles';

export const OrderCheckoutItem = ({
  isColsIdentical,
  title,
  value,
  secondsValue,
  successValue,
}: OrderItemType) => (
  <OrderCheckoutItemWrapper>
    <OrderCheckoutItemTitle isColsIdentical={isColsIdentical}>
      {title}
    </OrderCheckoutItemTitle>
    <OrderItemValueWrapper>
      {value && <OrderCheckoutItemValue>{value}</OrderCheckoutItemValue>}
      {secondsValue && (
        <OrderCheckoutItemSubValue>{secondsValue}</OrderCheckoutItemSubValue>
      )}
      {successValue && (
        <OrderItemStatusValue error={false}>
          {successValue}
        </OrderItemStatusValue>
      )}
    </OrderItemValueWrapper>
  </OrderCheckoutItemWrapper>
);
