/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { Store } from '@src/store';

export const useAnalyticsMite = () => {
  const {
    state: {
      stateWizard: { wantNextStep, currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    if (wantNextStep) {
      switch (currentStep) {
        case 1:
          sendAnalyticEvent(analyticEvents.miteToStepData);
          break;
        default:
          break;
      }
    }
  }, [wantNextStep]);
};
