import i18n from '@src/i18n/config';

export const getFormPeriodLabels = () =>
  new Map([
    [
      'P1M',
      {
        label: i18n.t('COMMON:labels.monthlyPrice'),
        description: i18n.t('COMMON:hints.changeConditionsInMonth'),
        promoLabel: i18n.t('COMMON:labels.priceInFirstMonth'),
        promoSubLabel: i18n.t('COMMON:hints.fromSecondMonth'),
      },
    ],
    [
      'P3M',
      {
        label: i18n.t('COMMON:labels.pricePerQuarter'),
        description: i18n.t('COMMON:hints.changeConditionsThreeMonths'),
        promoLabel: i18n.t('COMMON:labels.priceInFirstQuarter'),
        promoSubLabel: i18n.t('COMMON:hints.fromSecondQuarter'),
      },
    ],
    [
      'P1Y',
      {
        label: i18n.t('COMMON:labels.pricePerYear'),
        description: i18n.t('COMMON:hints.changeConditionsOnlyYear'),
        promoLabel: i18n.t('COMMON:labels.priceInFirstYear'),
        promoSubLabel: i18n.t('COMMON:hints.fromSecondYear'),
      },
    ],
  ]);
