import React, { useState } from 'react';

import { ArrowDown, ArrowUp } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import { OrderItemAccordionType } from '@src/types';
import { addTestAttribute } from '@src/utils';

import {
  AccordionArrowIcon,
  AccordionToggle,
  OrderItemTitle,
  OrderItemValue,
  OrderItemWrapper,
} from './components.styles';

export const OrderAccordionItem = ({
  title,
  value,
  accordionTitle,
  testId = 'orderAccordionItem',
}: OrderItemAccordionType) => {
  const theme: any = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OrderItemWrapper>
      <AccordionToggle
        onClick={() => setIsOpen(!isOpen)}
        {...addTestAttribute(`${testId}.toggle`)}
      >
        <OrderItemWrapper>
          <OrderItemTitle {...addTestAttribute(`${testId}.title`)}>
            {title}
          </OrderItemTitle>
          <OrderItemValue>{accordionTitle}</OrderItemValue>
          <AccordionArrowIcon>
            {isOpen ? (
              // TODO: RGS_ONLINE_THEME. Проверить цвет иконки для партнеров
              <ArrowUp color={theme.colors?.icon?.primary} width={20} />
            ) : (
              // TODO: RGS_ONLINE_THEME. Проверить цвет иконки для партнеров
              <ArrowDown color={theme.colors?.icon?.primary} width={20} />
            )}
          </AccordionArrowIcon>
        </OrderItemWrapper>
      </AccordionToggle>
      {isOpen && <div {...addTestAttribute(`${testId}.value`)}>{value}</div>}
    </OrderItemWrapper>
  );
};
