import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteAgreements } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { USER_AGREEMENTS_URL, analyticEvents } from '@src/constants';

import { UserAgreementProps } from './user-agreement.types';

export const UserAgreement: FC<UserAgreementProps> = ({ order }) => {
  const { t } = useTranslation();

  const handleViewAgreement = () => {
    sendAnalyticEvent(analyticEvents.userAgreement);
  };

  return (
    <SiteAgreements order={order}>
      {t('COMMON:hints.acceptTerms')}{' '}
      <a
        href={USER_AGREEMENTS_URL}
        target="_blank"
        onClick={handleViewAgreement}
        download
      >
        {t('COMMON:buttons.userAgreement')}
      </a>
    </SiteAgreements>
  );
};
