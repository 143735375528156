import { Coverage, PetRisk } from '@src/store';

export const sublimitRisksConverter = (risks: PetRisk[]) => {
  const sublimitRisks: Coverage[] = [];

  risks.map((item) => {
    if (Number(item.defaultInsuranceSum) !== 0) {
      sublimitRisks.push({
        code: item.code,
        insuranceSum: item.defaultInsuranceSum,
      });
    }
  });
  return sublimitRisks;
};
