import { getPetsSubscriptionTypeLabels } from '@src/pages/pets-form/utils';
import type { Price } from '@src/types';
import { currencyRuLocaleWithoutFraction } from '@src/utils';

import {
  BaseValue,
  Label,
  Value,
  ValueDesc,
} from './pets-radio-label-render.styles';

export const PetsRadioLabelRender = ({
  premiumAndDelta,
  premiumAndDeltaPromo,
  duration,
}: Price) => {
  const getPricePerMonth = (price: string): number => {
    return Math.ceil(Number(price) / (duration === 'P1Y' ? 12 : 3));
  };
  const petsSubscriptionTypeLabels = getPetsSubscriptionTypeLabels();

  if (!!premiumAndDeltaPromo) {
    return (
      <Label>
        <Value>{`${currencyRuLocaleWithoutFraction(
          getPricePerMonth(premiumAndDeltaPromo)
        )} ₽ `}</Value>
        <BaseValue>
          {currencyRuLocaleWithoutFraction(getPricePerMonth(premiumAndDelta))} ₽
        </BaseValue>{' '}
        <ValueDesc>{petsSubscriptionTypeLabels.get(duration)?.label}</ValueDesc>
      </Label>
    );
  }

  return (
    <Label>
      <Value>{`${currencyRuLocaleWithoutFraction(
        getPricePerMonth(premiumAndDelta)
      )} ₽ `}</Value>
      <ValueDesc>{petsSubscriptionTypeLabels.get(duration)?.label}</ValueDesc>
    </Label>
  );
};
