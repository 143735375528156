import { Spinner } from '@pulse-web-ui/spinner';

import { FRACTION_TWO, UnicodeEnum } from '@src/constants';
import { currencyRuLocaleWithoutFraction } from '@src/utils';

import {
  InsurancePriceCardContainer,
  InsurancePriceCardPremiumAndDelta,
  InsurancePriceCardTitle,
} from './insurance-price-card.styles';

interface Props {
  title: string;
  isLoading: boolean;
  premiumAndDelta?: string;
}

export const InsurancePriceCard = ({
  premiumAndDelta,
  title,
  isLoading,
}: Props) => (
  <InsurancePriceCardContainer>
    <InsurancePriceCardTitle>{title}</InsurancePriceCardTitle>
    {!!premiumAndDelta && !isLoading ? (
      <InsurancePriceCardPremiumAndDelta>
        {currencyRuLocaleWithoutFraction(
          parseFloat(premiumAndDelta),
          FRACTION_TWO
        )}{' '}
        {UnicodeEnum.RUB}
      </InsurancePriceCardPremiumAndDelta>
    ) : (
      <Spinner />
    )}
  </InsurancePriceCardContainer>
);
