import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RoutesNavigate, Skeleton } from '@src/components';
import {
  CLIENT_ORDER_ROUTE,
  PAYMENT_ERROR_ROUTE,
  PAYMENT_IN_PROGRESS_ROUTE,
  PAYMENT_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
  RoutePath,
  miteRoute,
  miteStepsRoute,
} from '@src/constants';
import {
  AuthorizationPage,
  AuthorizeFailPage,
  IdentificationPage,
  PersonalInfoPage,
} from '@src/pages/authorization';
import { AvailableSubscription } from '@src/pages/available-subscription';
import {
  BestToPayErrorPage,
  BestToPayInProgressPage,
  BestToPayStartPage,
  BestToPaySuccessPage,
} from '@src/pages/best-to-pay';
import { CheckUserData } from '@src/pages/check-user-data';
import { IFLFlatRiskDescriptions } from '@src/pages/ifl-flat-form/form-insurance-risk-descriptions';
import { IFLFlatSumDescriptions } from '@src/pages/ifl-flat-form/form-insurance-sum-descriptions';
import { IFLFlatPage } from '@src/pages/ifl-flat-form/ifl-flat-page';
import { IFLHouseMaterialsDescriptions } from '@src/pages/ifl-house-form/form-insurance-materials';
import { IFLHouseRiskDescriptions } from '@src/pages/ifl-house-form/form-insurance-risk-descriptions';
import { IFLHouseSumDescriptions } from '@src/pages/ifl-house-form/form-insurance-sum-descriptions';
import { IFLHousePage } from '@src/pages/ifl-house-form/ifl-house-page';
import { MitePage, MitePolicyChoice } from '@src/pages/mite';
import { FormNSRiskDescriptions } from '@src/pages/ns-form/form-insurance-risk-descriptions';
import { NSPage } from '@src/pages/ns-form/ns-page';
import { ClientOrderPage, OrderCheckout, OrderDetail } from '@src/pages/order';
import { PetsPage, PetsRiskDescriptions } from '@src/pages/pets-form';
import { PetsTelevetPlusPage } from '@src/pages/pets-televet-plus';
import { PresetSetting } from '@src/pages/preset-setting';
import { ScoreErrorPage } from '@src/pages/score-error';
import { SportNSPage } from '@src/pages/sport-form/sport-ns-page';
import { VipPage } from '@src/pages/vip';
import { Store } from '@src/store';

export const Router = () => {
  const {
    state: {
      stateAuthFlow: { initAuthState },
    },
  } = useContext(Store);
  return (
    <Routes>
      <Route
        path="/"
        element={initAuthState ? <Skeleton /> : <RoutesNavigate />}
      />
      <Route path="/house" element={<IFLHousePage />} />
      <Route path="/apartment" element={<IFLFlatPage />} />
      <Route path="/sport-ns" element={<SportNSPage />} />

      <Route
        path="/house-materials-descriptions"
        element={<IFLHouseMaterialsDescriptions />}
      />
      <Route
        path="/house-risk-descriptions"
        element={<IFLHouseRiskDescriptions />}
      />
      <Route
        path="/flat-risk-descriptions"
        element={<IFLFlatRiskDescriptions />}
      />
      <Route
        path="/ns-risk-descriptions"
        element={<FormNSRiskDescriptions />}
      />
      <Route path="/ns" element={<NSPage />} />
      <Route path={miteStepsRoute} element={<MitePage />} />
      <Route path={miteRoute} element={<MitePolicyChoice />} />
      <Route path="/score-error" element={<ScoreErrorPage />} />
      <Route path="/login" element={<IdentificationPage />} />
      <Route path="/authorize" element={<AuthorizationPage />} />
      <Route path="/personal-info" element={<PersonalInfoPage />} />
      <Route path="/authorize-fail" element={<AuthorizeFailPage />} />
      <Route path={PAYMENT_ROUTE} element={<BestToPayStartPage />} />
      <Route path={PAYMENT_ERROR_ROUTE} element={<BestToPayErrorPage />} />
      <Route path={PAYMENT_SUCCESS_ROUTE} element={<BestToPaySuccessPage />} />
      <Route
        path={PAYMENT_IN_PROGRESS_ROUTE}
        element={<BestToPayInProgressPage />}
      />
      <Route path="/order-detail" element={<OrderDetail />} />
      <Route path="/order-checkout" element={<OrderCheckout />} />
      <Route path="/pets" element={<PetsPage />} />
      <Route
        path={RoutePath.PETS_TELEVET_PLUS}
        element={<PetsTelevetPlusPage />}
      />
      <Route
        path="/pets-risk-descriptions"
        element={<PetsRiskDescriptions />}
      />
      <Route path="/vip" element={<VipPage />} />
      <Route path="/preset-settings" element={<PresetSetting />} />
      <Route
        path="/flat-sum-descriptions"
        element={<IFLFlatSumDescriptions />}
      />
      <Route
        path="/house-sum-descriptions"
        element={<IFLHouseSumDescriptions />}
      />
      <Route
        path="/available-subscription"
        element={<AvailableSubscription />}
      />
      <Route path="/check-user-data" element={<CheckUserData />} />
      <Route path={CLIENT_ORDER_ROUTE} element={<ClientOrderPage />} />
    </Routes>
  );
};
