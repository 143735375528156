import { styled, typography } from '@pulse-web-ui/theme';

export const Label = styled.div``;

export const BaseValue = styled.span`
  ${typography.medium14}
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: inline;
`;

export const PromoValue = styled.span`
  ${typography.bold16}
  text-decoration: initial;
  display: inline;
`;

export const SubLabel = styled.div`
  ${typography.regular14}
`;
