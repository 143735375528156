/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { CheckUserDataStoreState } from './check-user-data-store.state';
import { CheckUserDataActionTypes } from './check-user-data-store.types';

export type CheckUserDataStoreReducer = (
  state: CheckUserDataStoreState,
  action: StoreActions
) => CheckUserDataStoreState;

export const checkUserDataStoreReducer: CheckUserDataStoreReducer = (
  state,
  action
) => {
  switch (action?.type) {
    case CheckUserDataActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };

    case CheckUserDataActionTypes.SetSubobjects:
      return {
        ...state,
        subobjects: action.payload,
      };

    case CheckUserDataActionTypes.SetProductCode:
      return {
        ...state,
        productCode: action.payload,
      };

    case CheckUserDataActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };

    case CheckUserDataActionTypes.SetAddress:
      return {
        ...state,
        address: action.payload,
      };

    case CheckUserDataActionTypes.SetRegionCode:
      return {
        ...state,
        regionCode: action.payload,
      };

    case CheckUserDataActionTypes.SetAddressCode:
      return {
        ...state,
        addressCode: action.payload,
      };

    case CheckUserDataActionTypes.SetBuildingMaterial:
      return {
        ...state,
        buildingMaterial: action.payload,
      };

    case CheckUserDataActionTypes.SetPrices:
      return {
        ...state,
        prices: action.payload,
      };

    case CheckUserDataActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };

    case CheckUserDataActionTypes.ResetState:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
