import Axios from 'axios';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

const headers = {
  'X-Process-ID': uuidv4(),
  'Content-type': 'application/json',
};

interface QueryOptions {
  enabled?: boolean;
  cacheTime?: number;
  staleTime?: number;
}

export const useStorageQuery = <T = any>(
  key: string,
  url?: string,
  deps?: any[],
  options?: QueryOptions
) => {
  return useQuery(
    [key, deps],
    () =>
      Axios.get<T>(`${window.envUrls.STORAGE_URL}${url}`, {
        headers: headers,
      }),
    options
  );
};
