/* eslint-disable indent */
import { css, media, mixins, styled, typography } from '@pulse-web-ui/theme';

import type {
  FormLabelProps,
  FormSubProps,
  FormSubTitleProps,
  SiteAgreementsProps,
  TotalSumProps,
} from './form-elements.types';

export const FormLabel = styled.h2<FormLabelProps>`
  ${typography.medium24}
  color: ${(props) => props.theme.colors.text.primary};
  letter-spacing: 0;
  ${media.desktop} {
    text-align: ${(props) => (props.adaptiveCentered ? 'center' : 'initial')};
  }
  ${({ marginBottom }) =>
    marginBottom != null &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
  ${({ adaptiveMarginBottom }) =>
    adaptiveMarginBottom &&
    css`
      ${media.desktop} {
        margin-bottom: ${adaptiveMarginBottom}px;
      }
    `}
`;

export const FormSubTitle = styled.h3<FormSubTitleProps>`
  ${typography.medium20}
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 16px;
  ${media.desktop} {
    text-align: ${(props) => (props.adaptiveCentered ? 'center' : 'initial')};
  }
`;

export const SumPerMonthOuterWrapper = styled.div`
  ${typography.regular16}
`;

export const FormHead = styled.h2`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 4px;
  letter-spacing: 0;
`;

export const FormSubhead = styled.div<FormSubProps>`
  ${typography.subhead2.regular}
  color: ${({ theme, isError }) =>
    isError ? theme.colors.text.error : theme.colors.text.secondary};
  margin-bottom: 16px;
  letter-spacing: 0.02em;

  ${media.desktop} {
    letter-spacing: 0.01em;
    margin-bottom: 24px;
  }
`;

export const FormSubheadListItem = styled(FormSubhead)<FormSubProps>`
  margin-bottom: 8px;
`;

export const FormSub = styled.div<FormSubProps>`
  ${typography.pulsSubhead4.regular}
  ${(props) => (props.align ? `text-align: ${props.align};` : '')}
  color: ${({ theme, isError }) =>
    isError ? theme.colors.text.error : theme.colors.text.secondary};
  margin: ${(props) => (props.margin ? props.margin : '7px 0 32px')};
  letter-spacing: 0;
  ${media.desktop} {
    text-align: ${(props) => (props.adaptiveCentered ? 'center' : 'initial')};
  }
`;

export const FormSubLabel = styled.div<FormSubProps>`
  ${typography.subhead2.regular}

  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 7px 0 27px;
  letter-spacing: 0.02em;
  ${media.desktop} {
    text-align: ${(props) => (props.adaptiveCentered ? 'center' : 'initial')};
  }
  line-height: 24px !important;

  ${({ isVip, theme }) =>
    isVip &&
    css`
      color: ${theme.colors.text.secondary};
      ${typography.regular16}
    `}
`;

export const TabsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
`;

export const TabContent = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const SiteAgreements = styled.div<SiteAgreementsProps>`
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};

  ${media.desktop} {
    ${({ marginBottom }) =>
      marginBottom !== undefined &&
      css`
        margin-bottom: ${marginBottom}px;
      `}
    order: ${({ order }) => (order ? order : '-1')};
  }

  a {
    color: ${({ theme }) =>
      theme.common?.a?.color
        ? theme.common.a.color
        : theme.colors.text.interactive};
    text-decoration: ${({ theme }) =>
      theme.common?.a?.decoration ? theme.common.a.decoration : 'none'};

    &:hover {
      color: ${({ theme }) => theme.colors.text['interactive-clarified']};
    }
  }
`;

export const SumWrapper = styled.div<{ padding?: string }>`
  width: 100%;
  padding-top: 24px;

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

  ${media.desktop} {
    width: calc(50% - 8px);
  }
`;

export const TotalSum = styled.span<TotalSumProps>`
  color: ${({ theme, isError }) =>
    isError ? theme.colors.text.error : theme.colors.text.interactive};
  white-space: nowrap;

  ${media.desktop} {
    margin-left: 5px;
  }
`;

export const NumericInputLabel = styled.div`
  ${typography.subhead2.regular}
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const FormEditIconWrapper = styled.div`
  ${mixins.flex({ verticalAlignment: 'center' })}
  margin-left: 2px;
  cursor: pointer;
`;

export const FormFieldWrapper = styled.div`
  ${mixins.flex({ gap: 15, wrap: false })}
  flex-basis: calc(100% - 70px);

  ${media.desktop} {
    flex-basis: calc(100% - 80px);
  }

  & > [data-reach-slider-input] {
    flex-grow: 1;
  }
`;

export const FormCaption = styled.p`
  ${typography.caption1.regular}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 22px 0 32px;
  letter-spacing: 0.02em;

  ${media.desktop} {
    margin-bottom: 0;
  }
`;

export const AdaptivePerMonthHeader = styled.div`
  ${mixins.flex({
    verticalAlignment: 'flex-start',
    gap: 48,
    wrap: false,
  })}
`;

export const AdaptivePerMonthFooter = styled.div`
  ${mixins.flex({
    verticalAlignment: 'flex-start',
    gap: 8,
    wrap: false,
  })}
  flex-direction: column;

  ${media.desktop} {
    flex-direction: row;
    padding: 0;
  }
`;

export const TabsAdaptiveContainer = styled.div`
  ${mixins.flex({ horizontal: true, gap: 16, wrap: false })};
`;

export const TabsAdaptiveIcon = styled.div`
  display: none;

  ${media.desktop} {
    display: block;
  }
`;

export const MapWrapper = styled.div`
  ${mixins.flex({ gap: 16 })};

  & > div {
    width: 100%;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) =>
    theme.common?.a?.color
      ? theme.common.a.color
      : theme.colors.text.interactive};
  text-decoration: ${({ theme }) =>
    theme.common?.a?.decoration ? theme.common.a.decoration : 'none'};

  &:hover {
    color: ${({ theme }) => theme.colors.text['interactive-clarified']};
  }
`;
