import { FC, useContext } from 'react';

import { AddressSuggestions } from '@pulse-web-ui/dadata';

import { useQueryFunction } from '@src/hooks';
import { Store } from '@src/store';

import type { StyledDaDataAddressSuggestionProps } from './styled-dadata-address-suggestions.types';

export const StyledDaDataAddressSuggestion: FC<
  StyledDaDataAddressSuggestionProps
> = (props) => {
  const {
    state: {
      stateAuth: { authTokens },
    },
  } = useContext(Store);

  const queryFn = useQueryFunction(
    'post',
    '/v3/dadata/suggest/address',
    undefined,
    authTokens?.authorization?.accessToken
  );

  return (
    <AddressSuggestions
      delay={250}
      minChars={1}
      customRequest={queryFn}
      {...props}
    />
  );
};
