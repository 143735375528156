/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { MiteStoreState } from './index';
import { MiteActionTypes } from './index';

export type MiteStoreReducer = (
  state: MiteStoreState,
  action: StoreActions
) => MiteStoreState;

export const miteStoreReducer: MiteStoreReducer = (state, action) => {
  switch (action?.type) {
    case MiteActionTypes.SetChoosedPolicy:
      return {
        ...state,
        choosedPolicy: action.payload,
      };
    case MiteActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    case MiteActionTypes.SetInsuredPersons:
      return {
        ...state,
        insuredPersons: action.payload,
      };
    case MiteActionTypes.SetNumberInsuredPersons:
      return {
        ...state,
        numberInsuredPersons: action.payload,
      };
    case MiteActionTypes.SetInsuranceProductStartDate:
      return {
        ...state,
        selectedStartDate: action.payload,
      };
    case MiteActionTypes.SetInsuranceProductPolicy:
      return {
        ...state,
        insuranceProductPolicy: action.payload,
      };
    case MiteActionTypes.SetInsurancePrice:
      return {
        ...state,
        insurancePrice: action.payload,
      };
    case MiteActionTypes.UpdateState:
      return {
        ...state,
        ...action.payload,
      };
    case MiteActionTypes.SetStartDate:
      return {
        ...state,
        startDate: action.payload,
      };
    default: {
      return state;
    }
  }
};
