import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const GlobalErrorWrapper = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 24,
    alignItems: 'center',
    justifyContent: 'center',
  })}
  flex-grow: 1;
`;

export const GlobalErrorContent = styled.div`
  ${mixins.flex({
    horizontal: false,
    alignItems: 'center',
    justifyContent: 'center',
  })}
`;

export const GlobalErrorFormSub = styled.div`
  ${typography.regular18}
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;

  ${media.desktop} {
    margin-bottom: 18px;
  }
`;
