import { media, styled } from '@pulse-web-ui/theme';

import { PromoCode } from '@src/features';

export const StyledWrapper = styled.div`
  display: flex;
  gap: 32px 16px;
  flex-direction: column;

  ${media.desktop} {
    flex-direction: row;
  }
`;

export const StyledPromoCode = styled(PromoCode)`
  column-gap: 16px;
`;
