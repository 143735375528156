import { media, styled } from '@pulse-web-ui/theme';

import { HeaderProgressItemProps } from './step-wizard-form.types';

export const NavHeader = styled.div``;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 100%;
  width: 100%;
`;

export const NavFooter = styled.div`
  padding-top: 32px;
`;

export const HeaderProgressWrapper = styled.div`
  display: flex;
  height: 4px;
  margin-bottom: 24px;
  gap: 2px;
`;

export const HeaderProgressItem = styled.div<HeaderProgressItemProps>`
  background-color: ${({ theme }) => theme.colors.surface.brand};
  border-radius: 2px;
  height: 4px;
  flex-grow: 1;
`;
export const HeaderProgressEmptyItem = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.background};
  border-radius: 2px;
  height: 4px;
  flex-grow: 1;
`;
