import { createContext } from 'react';

import type { StoreDispatch, StoreState } from './index';
import { initStoreState } from './index';

export interface WizardStoreType {
  state: StoreState;
  dispatch: StoreDispatch;
}

export const Store = createContext<WizardStoreType>({
  state: initStoreState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});
