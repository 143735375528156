import { UseQueryStatus } from '@src/constants';
import { AuthFlow, QueryStatus } from '@src/types';

export interface AuthFlowStoreState {
  authStep: number;
  sepScrAuth: boolean;
  authFlowParams: AuthFlow | undefined;
  status: QueryStatus;
  initAuthState: boolean;
}

export const initAuthFlowStoreState: AuthFlowStoreState = {
  authStep: 0,
  sepScrAuth: false,
  authFlowParams: undefined,
  status: UseQueryStatus.LOADING,
  initAuthState: true,
};
