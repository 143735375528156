import * as yup from 'yup';
import { Message } from 'yup/lib/types';

import { onlyNumbers, phoneRegEx } from '@src/constants';
import i18n from '@src/i18n/config';

const MAX_CODE_LENGTH = 5;

export const phoneVerificationSchema = yup.object().shape({
  phone: yup
    .string()
    .required(i18n.t('INSURANCE_FORMS:errors.enterPhone') as Message)
    .matches(
      phoneRegEx,
      i18n.t('INSURANCE_FORMS:errors.wrongPhone') as Message
    ),
  code: yup
    .string()
    .required(i18n.t('COMMON:errors.enterCode') as Message)
    .max(MAX_CODE_LENGTH, i18n.t('COMMON:errors.maxCodeLength') as Message)
    .matches(onlyNumbers, i18n.t('COMMON:errors.onlyNumbersCode') as Message),
});
