export enum FeatureFlags {
  PetsPriceMonth = 'pets.priceMonth',
  PetsSubscriptionType = 'pets.subscriptionType',
  PetsDiscount = 'pets.discount',
  ShowCostOnRiskScreens = 'pets.showCostOnRiskScreens',
  TeleveterinaryInRisksList = 'pets.televeterinaryInRisksList',
  PDConsentAlternativeVersion = 'PDConsentAlternativeVersion',
  PDConsentSplitMainAlternativeVersion = 'PDConsentSplitMainAlternativeVersion',
  ApartmentSubscriptionType = 'apartment.subscriptionType',
  HouseSubscriptionType = 'house.subscriptionType',
  NSSubscriptionType = 'ns.subscriptionType',
  ApartmentDiscount = 'apartment.discount',
  HouseDiscount = 'house.discount',
  NSDiscount = 'ns.discount',
}
