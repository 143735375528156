import Axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { Store } from '@src/store';

export const useWebanalyticParams = () => {
  const {
    state: {
      stateUser: { regUtm },
    },
  } = useContext(Store);
  const mediaSource = regUtm?.media_source;
  const utmContent = regUtm?.utm_content;
  const campaign = regUtm?.campaign;

  if (
    mediaSource === String(window.envKeys.UNICOM24_PARTNER_CODE) &&
    campaign === String(window.envKeys.UNICOM24_CAMPAIGN) &&
    !!utmContent
  ) {
    useQuery(['unicom24', []], () =>
      Axios['get'](
        `${window.envUrls.UNICOM_24_POSTBACK_URL}/${String(
          utmContent
        )}/?status=receive`
      )
    );
  }
};
