import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const Title = styled.h3`
  ${typography.bold20}
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const MaterialsWrapper = styled.div`
  ${mixins.flex({ horizontal: false, wrap: false })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: true })}
  }
  margin-bottom: 32px;
`;

export const MatterialsGroups = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;
