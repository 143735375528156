import { ObjectType } from '@src/constants';
import { InsuranceObject, InsurePerson } from '@src/types';
import { getFormattedDate } from '@src/utils';

export const getInsuranceObjects = (
  insurePersons: InsurePerson[]
): InsuranceObject[] =>
  insurePersons.map(
    ({
      birthDate,
      firstName,
      lastName,
      middleName,
      primaryRecordId,
      isMe,
    }) => ({
      objectType: ObjectType.PERSON,
      birthDate: birthDate && getFormattedDate(new Date(birthDate)),
      firstName,
      lastName,
      middleName,
      primaryRecordId,
      isInsurer: isMe,
    })
  );
