import { Container, HeaderLarge3, TextSub3 } from '@src/components';
import { BaseLayout } from '@src/layouts/base-layout';
import type { Risk, RiskPage } from '@src/types';

import { Footer } from './footer';

type Props = {
  risks: Risk[] | undefined;
  risksPage: RiskPage;
};

export const RiskDescriptions = ({ risks, risksPage }: Props) => {
  return (
    <BaseLayout footer={<Footer />}>
      <Container>
        {risks
          ?.filter(
            (item) =>
              (risksPage === 'primary' && item.binding) ||
              (risksPage === 'secondary' && !item.binding)
          )
          .map((item) => (
            <>
              <HeaderLarge3>{item.name}</HeaderLarge3>
              <TextSub3>{item.extendedDescription}</TextSub3>
            </>
          ))}
      </Container>
    </BaseLayout>
  );
};
