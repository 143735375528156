/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { IFLFlatActionTypes, Store, initStoreState } from '@src/store';

export const useResetFlatSteps = () => {
  const {
    state: {
      stateWizard,
      stateFormIFLFlat: { isSpecialTermsInsurance },
    },
    dispatch,
  } = useContext(Store);

  useEffect(() => {
    if (stateWizard.updateFormState) {
      switch (stateWizard.currentStep) {
        case 1:
          dispatch({
            type: IFLFlatActionTypes.SetRisks,
            payload: initStoreState.stateFormIFLFlat.risks,
          });
          dispatch({
            type: IFLFlatActionTypes.SetInsuranceSubproducts,
            payload: initStoreState.stateFormIFLFlat.insuranceSubproducts,
          });
          break;
        case 2:
          if (!isSpecialTermsInsurance) {
            dispatch({
              type: IFLFlatActionTypes.SetInsuranceSubproducts,
              payload: initStoreState.stateFormIFLFlat.insuranceSubproducts,
            });
          }
          break;
        default:
          break;
      }
    }
  }, [stateWizard.currentStep, stateWizard.updateFormState]);
};
