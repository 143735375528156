import { InsuranceSubProduct } from '@src/types';

export const getPresetDefaultInsuranceSum = (
  sumFromPreset: string | undefined,
  item: InsuranceSubProduct | undefined
): string => {
  if (!sumFromPreset && !item) {
    return '';
  }

  const calculatedDefaultInsuranceSum = Number(sumFromPreset || '');

  if (item?.minLimit && calculatedDefaultInsuranceSum < item?.minLimit) {
    return String(item.minLimit);
  }

  if (item?.maxLimit && calculatedDefaultInsuranceSum > item?.maxLimit) {
    return String(item.maxLimit);
  }

  return String(calculatedDefaultInsuranceSum);
};
