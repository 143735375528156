import type { FC } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { StepWizardForm } from '@src/features';
import { useResetNsSteps } from '@src/hooks/use-reset-ns-steps';
import { FormInsuranceAdditionalRisks } from '@src/pages/ns-form/form-insurance-additional-risks';
import { FormInsuranceRisks } from '@src/pages/ns-form/form-insurance-risks';
import { FormInsuranceSum } from '@src/pages/ns-form/form-insurance-sum';
import { FormNsDetailsInsured } from '@src/pages/ns-form/form-ns-details-insured';
import { FormNsPeriod } from '@src/pages/ns-form/form-ns-period';
import { FormNsQuantity } from '@src/pages/ns-form/form-ns-quantity';
import { useAnalyticsNs } from '@src/pages/ns-form/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

export const NSForm: FC = () => {
  const {
    state: {
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();
  useAnalyticsNs();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      navigate('/login');
    } else {
      navigate(-1);
    }
  };

  return (
    <StepWizardForm maxStep={6} useResetSteps={useResetNsSteps} goBack={goBack}>
      {currentStep === 1 && <FormNsQuantity />}
      {currentStep === 2 && <FormInsuranceRisks />}
      {currentStep === 3 && <FormInsuranceAdditionalRisks />}
      {currentStep === 4 && <FormInsuranceSum />}
      {currentStep === 5 && <FormNsPeriod />}
      {currentStep === 6 && <FormNsDetailsInsured />}
    </StepWizardForm>
  );
};
