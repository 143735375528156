import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';
import { UserProfileTypes } from '@src/types';

export interface OrderAnalytivsEventsProps {
  authorizeRefRoute?: string;
  preset?: string;
  profile?: UserProfileTypes;
}

export const handleOrderAnalyticsEvents = ({
  authorizeRefRoute,
  preset,
  profile,
}: OrderAnalytivsEventsProps): void => {
  {
    const selectedProduct = authorizeRefRoute?.slice(1);
    const productsEvents: Record<string, () => void> = {
      house() {
        if (preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetStep3Confirm);
        } else {
          sendAnalyticEvent(analyticEvents.iflStep7Confirm);
          sendAnalyticEvent(analyticEvents.iflHouseStep7Confirm);
        }
      },
      apartment() {
        if (preset) {
          sendAnalyticEvent(analyticEvents.iflFlatPresetStep3Confirm);
        } else {
          sendAnalyticEvent(analyticEvents.iflStep7Confirm);
          sendAnalyticEvent(analyticEvents.iflFlatStep7Confirm);
        }
      },
      ns() {
        sendAnalyticEvent(analyticEvents.toConfirmationAccident);
      },
      pets() {
        if (preset) {
          sendAnalyticEvent(analyticEvents.petToStepConfirmPreset);
        } else {
          sendAnalyticEvent(analyticEvents.petToStepConfirm);
        }
      },
      ['sport-ns']() {
        sendAnalyticEvent(analyticEvents.sportToStepConfirm);
      },
      mite() {
        sendAnalyticEvent(analyticEvents.miteToStepConfirm);
      },
      [Product.PETS_TELEVET_PLUS]() {
        sendAnalyticEvent(analyticEvents.petTelConfirmOpen);
      },
    };

    if (authorizeRefRoute) {
      const selectedProductSlice = productsEvents[selectedProduct as string];
      if (profile?.lastName) {
        selectedProductSlice?.();
      }
    }
  }
};
