import type { FC } from 'react';
import { useContext } from 'react';

import { RiskDescriptions } from '@src/common-components/risk-descriptions';
import { Store } from '@src/store';

export const IFLFlatRiskDescriptions: FC = () => {
  const {
    state: {
      stateFormIFLFlat: { risks, risksPage },
    },
  } = useContext(Store);

  return <RiskDescriptions risks={risks} risksPage={risksPage} />;
};
