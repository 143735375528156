import { useStorageQuery } from './use-storage-query';

export const usePresetsRequest = <T>(
  key: string,
  url: string,
  deps?: any[]
) => {
  const queryResult = useStorageQuery<T>(key, `/presets${url}.json`, deps, {
    enabled: false,
  });

  const { isLoading, error, data, refetch, isFetching, isRefetching, status } =
    queryResult;

  let res;

  if (!isLoading && data?.data) {
    res = data.data;

    localStorage.setItem('preset', url);
  }

  return {
    isLoading,
    error,
    res,
    refetch,
    isFetching,
    isRefetching,
    status,
  };
};
