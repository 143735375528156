import React from 'react';

import {
  Tooltip,
  TooltipDirection,
  TriggerDirection,
} from '@pulse-web-ui/tooltip';

import { SelectedDuration } from '@src/types';
import { getPaymentPeriodCardDescription } from '@src/utils';

import { PaymentPeriodCardDescriptionWrapper } from './payment-period-card-description.styles';

interface Props {
  duration: SelectedDuration;
}

export const PaymentPeriodCardDescription = ({ duration }: Props) => {
  const { description, details } = getPaymentPeriodCardDescription(duration);
  return (
    <PaymentPeriodCardDescriptionWrapper>
      <span>{description}</span>
      <Tooltip
        trigger={TriggerDirection.HOVER}
        direction={TooltipDirection.UP}
        tooltipText={details}
      />
    </PaymentPeriodCardDescriptionWrapper>
  );
};
