import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

export interface RetryPaymentAnalytivsEventsProps {
  selectedProduct: string | null | undefined;
  preset: string | null;
}

export const handleRetryPaymentAnalyticsEvents = ({
  selectedProduct,
  preset,
}: RetryPaymentAnalytivsEventsProps): void => {
  {
    const productsEvents: Record<string, () => void> = {
      pets() {
        const event = !!preset
          ? analyticEvents.petToStepPayPreset
          : analyticEvents.petToStepPay;
        sendAnalyticEvent(event);
      },
      house() {
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetStep5Pay);
        } else {
          sendAnalyticEvent(analyticEvents.iflStep9Pay);
          sendAnalyticEvent(analyticEvents.iflHouseStep9Pay);
        }
      },
      apartment() {
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflFlatPresetStep5Pay);
        } else {
          sendAnalyticEvent(analyticEvents.iflStep9Pay);
          sendAnalyticEvent(analyticEvents.iflFlatStep9Pay);
        }
      },
      ns() {
        sendAnalyticEvent(analyticEvents.toPaymentAccident);
      },
      ['sport-ns']() {
        sendAnalyticEvent(analyticEvents.sportToStepPay);
      },
      mite() {
        sendAnalyticEvent(analyticEvents.miteToStepPay);
      },
      [Product.PETS_TELEVET_PLUS]() {
        sendAnalyticEvent(analyticEvents.petTelPayOpen);
      },
    };

    if (selectedProduct) {
      productsEvents[selectedProduct]?.();
    }
  }
};
