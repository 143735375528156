/* eslint-disable indent */
import { css, media, styled, typography } from '@pulse-web-ui/theme';

export const StyledIframe = styled.iframe<{ isPrivateOffice?: boolean }>`
  width: 100%;
  height: calc(100% - 48px);
  position: absolute;
  left: 0;
  top: 48px;

  ${media.desktop} {
    ${({ isPrivateOffice }) =>
      isPrivateOffice
        ? css`
            height: calc(100% - 78px);
            top: 78px;
          `
        : css`
            height: 100%;
            top: 0;
          `}
  }
`;

export const StyledPayCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 0px;

  ${media.desktop} {
    flex-direction: row;
    margin-top: 0px;
    gap: 16px;
  }
`;

export const StyledPayCard = styled.div`
  flex: 1;
  border-radius: ${({ theme }) => `calc(${theme.common.radius} * 6)`};
  padding: 24px 16px 24px 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  position: relative;
`;

export const StyledPayCardImageWrapper = styled.div`
  width: 64px;
  height: 64px;
`;

export const StyledPayCardImage = styled.img`
  width: 120px;
  height: 120px;
  position: absolute;
  top: -30px;
  left: 0;
`;

export const StyledPayCardText = styled.div`
  padding-top: 16px;
  ${typography.regular16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: break-spaces;
`;
