import { yupResolver } from '@hookform/resolvers/yup';
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { Accordion } from '@pulse-web-ui/accordion';
import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { DocumentTransparent } from '@pulse-web-ui/icons';

import {
  Container,
  DocumentsWrapper,
  OrderTitle,
  OrderWrapper,
  RiskList,
  Skeleton,
} from '@src/components';
import { OrderWrapperTitle } from '@src/components/container';
import { Header } from '@src/components/header';
import {
  CurrencyCode,
  FRACTION_TWO,
  INSURANCE_POLICIES,
  PAYMENT_ROUTE,
  Product,
  ProductVersion,
  SubscriptionType,
  USER_RECL_AGREEMENTS_URL,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { OrderActionTypes, Store } from '@src/store';
import { DocumentType, OrderItemType, OrderItemValueType } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  getFormPeriodLabels,
  numFormat,
  numWord,
} from '@src/utils';

import { ClientDocumentItem } from '../components/client-document-item';
import {
  OrderCheckoutAggrWrapper,
  OrderCheckoutTitleWrapper,
} from '../components/components.styles';
import { OrderCheckoutItem } from '../components/order-checkout-item';
import { OutdatedLinkError } from '../components/outdated-link-error';
import { ContractDataType } from '../order-types';
import { CURRENCY_MAP } from './client-order-page.constants';
import {
  AccordionLabel,
  AccordionSubLabel,
  ClientOrderPageLayout,
  ClientOrderPageWrapper,
  StyledOrderSubTitle,
} from './client-order-page.styles';
import type {
  DraftForPaymentResponse,
  RisksResponse,
  TerritoriesResponse,
} from './client-order-page.types';
import { prepareRisks } from './client-order-page.utils';

const PRODUCT_MAP = {
  [ProductVersion.HOUSE_VERSION]: Product.HOUSE,
  [ProductVersion.FLAT_VERSION]: Product.APARTMENT,
  [ProductVersion.NS_VERSION]: Product.NS,
  [ProductVersion.PETS_VERSION]: Product.PETS,
  [ProductVersion.SPORT_VERSION]: Product.SPORT,
  [ProductVersion.MITE_VERSION]: Product.MITE,
  [ProductVersion.TRAVEL_VERSION]: Product.TRAVEL,
};

const accordionId = uuid();

export const ClientOrderPage = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateAuth: { authTokens, isRefreshTokenLoading },
      stateOrder: { subscriptionId },
    },
    dispatch,
  } = useContext(Store);
  const [_, setSearchParams] = useSearchParams();
  const schema = yup.object().shape({
    insurancePolicies: yup
      .bool()
      .oneOf([true], t('COMMON:errors.withoutAgreement') || ''),
    userPoliciesCode002: yup.bool(),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ insurancePolicies: boolean; userPoliciesCode002: boolean }>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      insurancePolicies: false,
      userPoliciesCode002: false,
    },
  });
  const navigate = useNavigate();
  const [contractData, setContractData] = useState<ContractDataType>();
  const [orderList, setOrderList] = useState<OrderItemType[]>();
  const [documentsList, setDocumentsList] =
    useState<(DocumentType & { icon: FunctionComponent })[]>();
  const formPeriodLabels = getFormPeriodLabels();

  const {
    isLoading: isDraftForPaymentLoading,
    res: draftForPaymentRes,
    error: draftForPaymentError,
    refetch: refetchDraftForPayment,
  } = useRequest<DraftForPaymentResponse>(
    'draftForPayment',
    'get',
    `/v3/subscription/draft-for-payment/${subscriptionId}`,
    undefined,
    [subscriptionId, authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: isInitOrderLoading,
    res: initOrderRes,
    error: initOrderError,
    refetch: refetchInitOrder,
  } = useRequest(
    'initClientOrder',
    'post',
    '/v1/payment/init-order',
    contractData,
    [authTokens?.authorization?.accessToken, contractData],
    true,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: isRisksLoading,
    res: risksRes,
    error: risksError,
    refetch: refetchRisks,
  } = useRequest<RisksResponse>(
    'getRisks',
    'post',
    '/v4/references/RISK',
    {
      baseParameters: {
        productVersion: ProductVersion.TRAVEL_VERSION,
      },
      referenceProperty: {
        codeDescription: '001',
        insuranceSum: draftForPaymentRes?.insuranceSum?.toString(),
      },
    },
    [draftForPaymentRes?.insuranceSum],
    true,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: isTerritoriesLoading,
    res: territoriesRes,
    error: territoriesError,
    refetch: refetchTerritories,
  } = useRequest<TerritoriesResponse>(
    'getTerritories',
    'post',
    '/v4/references/TERRITORY',
    {
      baseParameters: {
        productVersion: ProductVersion.TRAVEL_VERSION,
      },
    },
    [],
    true,
    authTokens?.authorization.accessToken
  );

  const getPrice = ({
    premiumAndDelta,
    premiumAndDeltaPromo,
    subscriptionType,
    contractDuration,
  }: Pick<
    DraftForPaymentResponse,
    | 'premiumAndDelta'
    | 'premiumAndDeltaPromo'
    | 'subscriptionType'
    | 'contractDuration'
  >) => {
    if (subscriptionType === SubscriptionType.WITHOUT_PROLONGATION) {
      if (premiumAndDeltaPromo) {
        return t('COMMON:price.rubles', {
          price: currencyRuLocaleWithoutFraction(
            Number(premiumAndDeltaPromo),
            FRACTION_TWO
          ),
        });
      }

      return t('COMMON:price.rubles', {
        price: currencyRuLocaleWithoutFraction(
          Number(premiumAndDelta),
          FRACTION_TWO
        ),
      });
    }

    if (premiumAndDeltaPromo) {
      return `${currencyRuLocaleWithoutFraction(
        Number(premiumAndDeltaPromo),
        FRACTION_TWO
      )} ${formPeriodLabels.get(contractDuration)?.promoLabel}`;
    }

    return `${currencyRuLocaleWithoutFraction(
      Number(premiumAndDelta),
      FRACTION_TWO
    )} ${formPeriodLabels.get(contractDuration)?.label}`;
  };

  const getSubPrice = ({
    premiumAndDelta,
    premiumAndDeltaPromo,
    subscriptionType,
    contractDuration,
  }: Pick<
    DraftForPaymentResponse,
    | 'premiumAndDelta'
    | 'premiumAndDeltaPromo'
    | 'subscriptionType'
    | 'contractDuration'
  >) => {
    if (
      subscriptionType === SubscriptionType.WITHOUT_PROLONGATION ||
      !premiumAndDeltaPromo
    ) {
      return;
    }

    return `${currencyRuLocaleWithoutFraction(
      Number(premiumAndDelta),
      FRACTION_TWO
    )} ${formPeriodLabels.get(contractDuration)?.promoSubLabel}`;
  };

  useEffect(() => {
    if (subscriptionId && !isRefreshTokenLoading) {
      refetchDraftForPayment();
    }
  }, [subscriptionId, isRefreshTokenLoading]);

  useEffect(() => {
    if (subscriptionId) {
      setSearchParams({ paymentSubscriptionId: subscriptionId });
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (!isDraftForPaymentLoading && draftForPaymentRes) {
      if (
        draftForPaymentRes?.productVersion === ProductVersion.TRAVEL_VERSION
      ) {
        refetchRisks();
        refetchTerritories();
      }

      setContractData({
        amount: draftForPaymentRes.premiumAndDeltaPromo
          ? draftForPaymentRes.premiumAndDeltaPromo
          : draftForPaymentRes.premiumAndDelta,
        contractId: draftForPaymentRes.contractId,
        email: draftForPaymentRes.email,
        operationType: 'ORDER',
        orderNumber: draftForPaymentRes.orderNumber,
        paymentDocumentId: draftForPaymentRes.billId,
        subscriptionId: draftForPaymentRes.subscriptionId,
        subscriptionType: draftForPaymentRes.subscriptionType,
      });

      const price = getPrice({
        premiumAndDelta: draftForPaymentRes.premiumAndDelta,
        premiumAndDeltaPromo: draftForPaymentRes.premiumAndDeltaPromo,
        subscriptionType: draftForPaymentRes.subscriptionType,
        contractDuration: draftForPaymentRes.contractDuration,
      });

      const subPrice = getSubPrice({
        premiumAndDelta: draftForPaymentRes.premiumAndDelta,
        premiumAndDeltaPromo: draftForPaymentRes.premiumAndDeltaPromo,
        subscriptionType: draftForPaymentRes.subscriptionType,
        contractDuration: draftForPaymentRes.contractDuration,
      });

      const insuranceSumOrderItem = {
        type: OrderItemValueType.SIMPLE,
        title: t('ORDER:labels.coverageSum'),
        value: `${numFormat(draftForPaymentRes?.insuranceSum || 0)} ${
          CURRENCY_MAP[draftForPaymentRes?.currency || CurrencyCode.USD]
        }`,
      };

      setOrderList([
        {
          type: OrderItemValueType.SIMPLE,
          title: t('ORDER:labels.fullName'),
          value: draftForPaymentRes.fullName.replace(/ НЕТ$/i, ''),
        },
        {
          type: OrderItemValueType.SIMPLE,
          title:
            draftForPaymentRes.subscriptionType ===
            SubscriptionType.WITH_PROLONGATION
              ? t('ORDER:labels.priceAndPeriod')
              : t('ORDER:labels.price'),
          value: price,
          secondsValue: subPrice,
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('ORDER:labels.policyNumber'),
          value: draftForPaymentRes.contractNumber,
        },
        {
          type: OrderItemValueType.SIMPLE,
          title: t('ORDER:labels.policySentToEmail2'),
          value: draftForPaymentRes.email,
        },
        ...(draftForPaymentRes.productVersion === ProductVersion.TRAVEL_VERSION
          ? [insuranceSumOrderItem]
          : []),
      ]);

      setDocumentsList(
        draftForPaymentRes.clientDocuments.map((document) => ({
          ...document,
          icon: DocumentTransparent,
        }))
      );

      dispatch({
        type: OrderActionTypes.SetCompletePaymentData,
        payload: {
          contractId: draftForPaymentRes.contractId,
          subscriptionId: draftForPaymentRes.subscriptionId,
          billId: draftForPaymentRes.billId,
          premiumAndDelta: draftForPaymentRes.premiumAndDeltaPromo
            ? draftForPaymentRes.premiumAndDeltaPromo
            : draftForPaymentRes.premiumAndDelta,
        },
      });

      localStorage.setItem('email', draftForPaymentRes.email);
      localStorage.setItem(
        'selectedDate',
        JSON.stringify(new Date(draftForPaymentRes.effectiveSince))
      );
      localStorage.setItem(
        'selectedProduct',
        PRODUCT_MAP[draftForPaymentRes.productVersion]
      );
    }
  }, [isDraftForPaymentLoading, draftForPaymentRes]);

  useEffect(() => {
    if (!isTerritoriesLoading && !!territoriesRes) {
      const territoriesOrderItem = {
        type: OrderItemValueType.SIMPLE,
        title: t('ORDER:labels.country'),
        value: draftForPaymentRes?.insuranceObjects?.[0].territories
          ?.map(
            (code) =>
              territoriesRes.territories.find(
                (territory) => territory.code === code
              )?.name
          )
          .join(', '),
      };

      setOrderList([...(orderList || []), territoriesOrderItem]);
    }
  }, [
    isTerritoriesLoading,
    territoriesRes,
    draftForPaymentRes?.insuranceObjects?.[0].territories,
  ]);

  useEffect(() => {
    if (!isInitOrderLoading && initOrderRes) {
      dispatch({
        type: OrderActionTypes.SetOrderInitData,
        payload: initOrderRes,
      });

      if (initOrderRes?.signature) {
        navigate(PAYMENT_ROUTE);
      }
    }
  }, [isInitOrderLoading, initOrderRes]);

  const submitPage = handleSubmit(({ userPoliciesCode002 }) => {
    dispatch({
      type: OrderActionTypes.SetHasSpecialOffersConsent,
      payload: userPoliciesCode002,
    });
    refetchInitOrder();
  });

  const isLinkOutdated =
    draftForPaymentError?.response?.status === 412 &&
    draftForPaymentError?.response?.data?.code === 'ORC_0226_0005';

  const errorComponent = useMemo(() => {
    if (isLinkOutdated) {
      return <OutdatedLinkError />;
    }

    if (!!draftForPaymentError && !isLinkOutdated) {
      return <GlobalErrorInfo retryHandler={refetchDraftForPayment} />;
    }

    if (!!initOrderError && initOrderError?.response?.status !== 401) {
      return <GlobalErrorInfo retryHandler={refetchInitOrder} />;
    }

    if (!!risksError && risksError?.response?.status !== 401) {
      return <GlobalErrorInfo retryHandler={refetchRisks} />;
    }

    if (!!territoriesError && territoriesError?.response?.status !== 401) {
      return <GlobalErrorInfo retryHandler={refetchTerritories} />;
    }

    return null;
  }, [
    draftForPaymentError,
    initOrderError,
    isDraftForPaymentLoading,
    isInitOrderLoading,
    refetchDraftForPayment,
    refetchInitOrder,
    isLinkOutdated,
    risksError,
    refetchRisks,
    isRisksLoading,
    territoriesError,
    refetchTerritories,
    isTerritoriesLoading,
  ]);

  const risksAccordion = useMemo(() => {
    if (
      !!risksRes?.risks &&
      !!draftForPaymentRes?.insuranceObjects?.[0].coverages &&
      !!draftForPaymentRes?.insuranceObjects?.[0].optionCoverages &&
      !!draftForPaymentRes?.currency
    ) {
      const { risks: preparedRisks, length: optionsLength } = prepareRisks(
        risksRes.risks,
        draftForPaymentRes.insuranceObjects[0].coverages,
        draftForPaymentRes.insuranceObjects[0].optionCoverages,
        draftForPaymentRes.currency
      );

      return (
        <OrderWrapper>
          <Accordion
            value={
              <AccordionSubLabel>
                {optionsLength}{' '}
                {numWord(optionsLength, [
                  t('ORDER:declensions.option'),
                  t('ORDER:declensions.option2'),
                  t('ORDER:declensions.option3'),
                ])}
              </AccordionSubLabel>
            }
            label={
              <AccordionLabel>
                {t('ORDER:labels.includedInPolicy')}
              </AccordionLabel>
            }
            id={accordionId as any}
          >
            <RiskList risks={preparedRisks} />
          </Accordion>
        </OrderWrapper>
      );
    } else {
      return null;
    }
  }, [
    risksRes?.risks,
    draftForPaymentRes?.insuranceObjects?.[0].coverages,
    draftForPaymentRes?.insuranceObjects?.[0].optionCoverages,
    draftForPaymentRes?.currency,
  ]);

  return (
    <ClientOrderPageLayout>
      <Header />
      <ClientOrderPageWrapper error={isLinkOutdated}>
        {isDraftForPaymentLoading || isInitOrderLoading || !subscriptionId ? (
          <Skeleton />
        ) : errorComponent ? (
          errorComponent
        ) : (
          <BaseLayout
            footer={
              <Button
                label={t('ORDER:buttons.proceedToCheckout') || ''}
                variant="primary"
                onClick={submitPage}
              />
            }
          >
            <OrderCheckoutTitleWrapper>
              <Container>
                <OrderTitle>
                  {t('ORDER:headers.subscriptionPayment')}
                </OrderTitle>
                <StyledOrderSubTitle>
                  {t('ORDER:headers.paymentMustBeCompleted')}
                </StyledOrderSubTitle>
              </Container>
            </OrderCheckoutTitleWrapper>
            <OrderWrapper>
              {orderList?.map((orderItem) => (
                <OrderCheckoutItem
                  key={orderItem.title}
                  isColsIdentical={true}
                  {...orderItem}
                />
              ))}
            </OrderWrapper>
            {risksAccordion}
            <Container>
              <OrderWrapperTitle>
                {t('ORDER:headers.documentsTitle')}
              </OrderWrapperTitle>
            </Container>
            <DocumentsWrapper>
              {documentsList?.map((document) => (
                <ClientDocumentItem
                  key={document.clientDocument}
                  {...document}
                />
              ))}
            </DocumentsWrapper>
            <OrderCheckoutAggrWrapper>
              <Controller
                control={control}
                name="insurancePolicies"
                render={({ field: { onChange }, fieldState }) => (
                  <Checkbox
                    label={
                      <Trans
                        components={{
                          a: <a href={INSURANCE_POLICIES} target="_blank" />,
                        }}
                      >
                        {t(
                          'ORDER:labels.acceptTermsWithKeyInformationDocumentCondition',
                          { kid: t('ORDER:labels.kid') }
                        )}
                        {draftForPaymentRes?.subscriptionType ===
                          SubscriptionType.WITH_PROLONGATION &&
                          ' ' + t('ORDER:labels.nonAcceptanceAgreementLink')}
                      </Trans>
                    }
                    name="insurancePolicies"
                    onChange={onChange}
                    message={errors.insurancePolicies?.message}
                    status={fieldState.error && 'error'}
                  />
                )}
              />
              {draftForPaymentRes?.showConsent002 && (
                <Controller
                  control={control}
                  name="userPoliciesCode002"
                  render={({ field: { onChange }, fieldState }) => (
                    <Checkbox
                      label={
                        <Trans
                          values={{ href: USER_RECL_AGREEMENTS_URL }}
                          components={{ a: <a /> }}
                        >
                          {t('AUTH:hints.agreeReceiveSpecialOffers')}
                        </Trans>
                      }
                      name="userPoliciesCode002"
                      onChange={onChange}
                      message={errors.userPoliciesCode002?.message}
                      status={fieldState.error && 'error'}
                    />
                  )}
                />
              )}
            </OrderCheckoutAggrWrapper>
          </BaseLayout>
        )}
      </ClientOrderPageWrapper>
    </ClientOrderPageLayout>
  );
};
