/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import { PageBackButton, StyledButton } from '@src/common-components/button';
import { PageContent } from '@src/common-components/container';
import { PageSubTitle, PageTitle } from '@src/common-components/headers/header';
import { ArrowLeftIcon2 } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import {
  useClearInsuredPersons,
  useHandlePressKey,
  useRequest,
} from '@src/hooks';
import { identificationPageSchema } from '@src/schemas';
import {
  AuthActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { KeyCode } from '@src/types';
import { phoneDisplayValueCasting, phoneValueCasting } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import { IdentificationPageInput } from './authorization-pages.styles';
import type { LoginFormProps } from './authorization.types';

export const IdentificationPage: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateAuth: {
        phoneNumber,
        displayPhoneNumber,
        authenticationToken,
        confirmationId,
      },
      stateUser: {
        cachedPhoneNumber,
        userRegistrationData,
        authenticationRefetchUid,
        isPureSubscription,
        preset,
        agentLogin,
      },
      stateAuthFlow: { authFlowParams, authStep },
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);

  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [phoneValue, setPhoneValue] = useState<string>(
    cachedPhoneNumber || phoneNumber || ''
  );

  const [displayPhoneValue, setDisplayPhoneValue] = useState<string>(
    displayPhoneNumber ||
      phoneDisplayValueCasting(cachedPhoneNumber || phoneNumber || '') ||
      ''
  );
  const [shouldNavigateToSMS, setShouldNavigateToSMS] =
    useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<LoginFormProps>({
    resolver: yupResolver(identificationPageSchema),
    shouldFocusError: true,
    defaultValues: {
      phoneNumber: (cachedPhoneNumber || phoneNumber)?.replace('+7', ''),
    },
  });

  const { isLoading, error, res, refetch } = useRequest(
    'identificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber: phoneValue,
      },
      ...(agentLogin && { agentLogin }),
    },
    [
      phoneValue,
      cachedPhoneNumber,
      displayPhoneValue,
      confirmationId,
      phoneNumber,
      authenticationToken,
      authenticationRefetchUid,
      agentLogin,
    ],
    true
  );

  const goBack = () => {
    if (userRegistrationData) {
      navigate('/personal-info');
    } else if (authStep) {
      if (locationState?.isUnauthorized) {
        dispatch({
          type: WizardActionTypes.SetCurrentStep,
          payload: currentStep - 1,
        });
      }
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    // TODO: Найти решение заменить прямой поиск по дереву
    const input = document.getElementsByTagName('input')[0];
    input && !input.value && input.focus();
  }, []);

  useEffect(() => {
    dispatch({
      type: AuthActionTypes.SetConfirmationId,
      payload: undefined,
    });

    dispatch({
      type: AuthActionTypes.SetAuthenticationToken,
      payload: undefined,
    });

    dispatch({
      type: UserActionTypes.SetCachedPhoneNumber,
      payload: undefined,
    });
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: phoneValueCasting(value.phoneNumber!),
      });

      setDisplayPhoneValue(`+7 ${value.phoneNumber}`);
      setPhoneValue(phoneValueCasting(value.phoneNumber || ''));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const clearInsuredPersons = useClearInsuredPersons();

  useEffect(() => {
    if (!isLoading && res) {
      if (!!phoneNumber.length && phoneValue !== phoneNumber) {
        if (!isPureSubscription) {
          dispatch({
            type: UserActionTypes.SetUserRegistrationData,
            payload: undefined,
          });
          dispatch({
            type: UserActionTypes.SetCachedPersonalInfoData,
            payload: undefined,
          });
        }

        clearInsuredPersons();

        dispatch({
          type: UserActionTypes.SetProfile,
          payload: undefined,
        });
        dispatch({
          type: AuthActionTypes.SetAuthTokens,
          payload: undefined,
        });
        dispatch({
          type: OrderActionTypes.SetOrderRequestData,
          payload: undefined,
        });
        dispatch({
          type: OrderActionTypes.SetCachedOrderRequestData,
          payload: undefined,
        });
        dispatch({
          type: UserActionTypes.SetAuthenticationRefetchUid,
          payload: uuidv4(),
        });
      }

      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: res?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: res?.authenticationToken,
      });
      dispatch({
        type: AuthActionTypes.SetPhoneNumber,
        payload: phoneValue,
      });
      dispatch({
        type: AuthActionTypes.SetDisplayPhoneNumber,
        payload: displayPhoneValue,
      });
    }
  }, [
    res?.confirmationId,
    res?.authenticationToken,
    isLoading,
    phoneValue,
    displayPhoneValue,
    cachedPhoneNumber,
    phoneNumber,
    clearInsuredPersons,
  ]);

  useEffect(() => {
    if (error) {
      const isRateLimitExceededError =
        error?.response?.data?.code === 'RATE_LIMIT_EXCEEDED' &&
        error?.response?.data?.unlockingTime;

      const msg = !!error
        ? isRateLimitExceededError
          ? 'AUTH:errors.authorizationLimitExceeded'
          : 'COMMON:errors.sendingErrorTryAgain'
        : '';

      if (isRateLimitExceededError) {
        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });
      }

      setError('phoneNumber', { type: 'string', message: msg });
    }
  }, [error]);

  const handleKeyPressEnter = () => {
    submitPage();
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const submitPage = handleSubmit(() => {
    setShouldNavigateToSMS(true);

    if (!!agentLogin && phoneValue === agentLogin) {
      setError('phoneNumber', {
        type: 'string',
        message: 'COMMON:errors.phoneEqualsAgentPhone' || '',
      });
    } else if (
      (!phoneValue.includes('_') && displayPhoneValue !== '') ||
      phoneValue != cachedPhoneNumber
    ) {
      refetch();
    }
  });

  useEffect(() => {
    if (preset) {
      sendAnalyticEvent(analyticEvents.signupPresetPhone);
    } else {
      sendAnalyticEvent(analyticEvents.signupPhone);
    }
  }, []);

  useEffect(() => {
    if (locationState?.errorMessage) {
      setError('phoneNumber', {
        type: 'string',
        message: locationState?.errorMessage,
      });
    }
  }, [locationState?.errorMessage]);

  useEffect(() => {
    if (shouldNavigateToSMS && authenticationToken && confirmationId) {
      setShouldNavigateToSMS(false);
      navigate('/authorize', {
        state: {
          ...locationState,
        },
      });
    }
  }, [shouldNavigateToSMS, authenticationToken, confirmationId]);

  return (
    <BaseLayout>
      <PageContent>
        {!preset &&
          !locationState?.errorMessage &&
          (!authFlowParams || isPureSubscription || !!authStep) && (
            <PageBackButton
              variant="text"
              icon={<ArrowLeftIcon2 />}
              onClick={goBack}
            >
              {t('COMMON:buttons.back')}
            </PageBackButton>
          )}
        <PageTitle>{t('AUTH:headers.enterPhone')}</PageTitle>
        {!agentLogin && (
          <PageSubTitle>
            {isPureSubscription
              ? t('AUTH:hints.hereSendCode')
              : t('AUTH:hints.toFindOutIfRegistered')}
          </PageSubTitle>
        )}
        <IdentificationPageInput>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={!!fieldState.error ? 'error' : 'default'}
                message={
                  errors.phoneNumber?.message && t(errors.phoneNumber.message)
                }
              >
                <Input
                  label={t('COMMON:labels.phone') || ''}
                  id="phoneNumber"
                  name="phoneNumber"
                  value={value}
                  onChange={(val) => {
                    if (errors) {
                      clearErrors('phoneNumber');
                    }
                    onChange(val);
                  }}
                  type="tel"
                  error={!!errors.phoneNumber || !!error}
                />
              </HelperText>
            )}
          />
        </IdentificationPageInput>
        <StyledButton
          disabled={
            isLoading ||
            (!!errors.phoneNumber &&
              (errors.phoneNumber.message ===
                'AUTH:errors.authorizationLimitExceeded' ||
                errors.phoneNumber.message ===
                  'COMMON:errors.phoneEqualsAgentPhone'))
          }
          variant="primary"
          label={t('AUTH:labels.getCode') || ''}
          onClick={submitPage}
        />
      </PageContent>
    </BaseLayout>
  );
};
