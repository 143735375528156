import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Check } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import {
  AdaptivePerMonthFooter,
  Container,
  FormLabel,
  HeaderSubMedium18,
  IflSumPerMonthContainer,
} from '@src/components';
import { Product } from '@src/constants';
import { FormIFLGetPricesFull } from '@src/constants/request-data';
import { IflSumPerMonth, TotalSum } from '@src/features';
import { useLimits } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import {
  AvailableSubscriptionActionTypes,
  OrderActionTypes,
  Store,
  WizardActionTypes,
} from '@src/store';
import { numFormat, roundUp, subObjectConverter } from '@src/utils';

import {
  ButtonsWrapper,
  CheckIconWrapper,
  SubobjectName,
  SubobjectPrice,
  SubscriptionDataContent,
  SubscriptionDataContentItem,
  SubscriptionDataRisk,
  SubscriptionDataRiskText,
  SubscriptionDataWrapper,
  SumWrapper,
} from './available-subscription.styles';
import { getInsuranceSumDescription } from './utils/get-insurance-sum-description';

export const AvailableSubscription = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const theme: any = useTheme();
  const {
    state: {
      stateAvailableSubscription: {
        risks,
        subobjects,
        insuranceSum,
        insuranceSumPerMonth,
        insurancePromoSumPerMonth,
        nextRoute,
        prevRoute,
        orderData,
      },
      stateUser: { selectedProduct, preset },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();

  useEffect(
    () => () => {
      dispatch({
        type: AvailableSubscriptionActionTypes.ResetState,
        payload: {},
      });
    },
    []
  );

  const handleAccept = () => {
    if (orderData) {
      let personProperties;

      if (orderData.personProperties) {
        personProperties = {
          ...orderData.personProperties,
          subObjects: subobjects && subObjectConverter(subobjects),
        };
      }
      dispatch({
        type: OrderActionTypes.SetOrderRequestData,
        payload: {
          ...orderData,
          personProperties,
          insuranceSum: insuranceSum?.toString(),
        },
      });
    }

    nextRoute && navigate(nextRoute);
  };

  const handleEdit = () => {
    queryClient.resetQueries(FormIFLGetPricesFull);

    let step = 2;
    let navigateTo = prevRoute;

    if (preset) {
      navigateTo = '/preset-settings';
      step = 1;
    } else {
      if (selectedProduct === Product.HOUSE) {
        step = 3;
      } else if (selectedProduct === Product.APARTMENT) {
        step = 2;
      }
    }

    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: step,
    });
    navigateTo && navigate(navigateTo, { replace: true });
  };

  const limits = useLimits();

  const subtitleText = limits
    ? `${t('COMMON:labels.from')} ${numFormat(limits.min)} ₽ ` +
      `${t('COMMON:labels.to')} ${numFormat(limits.max)} ₽`
    : '';

  return (
    <BaseLayout>
      <Container>
        <FormLabel marginTop={0} marginBottom={32}>
          {t('AVAILABLE_SUBSCRIPTION:headers.availableSubscription')}
        </FormLabel>
        <SubscriptionDataWrapper>
          <HeaderSubMedium18>
            {t('AVAILABLE_SUBSCRIPTION:headers.availableSubscriptionRisks')}
          </HeaderSubMedium18>
          <SubscriptionDataContent>
            {risks
              ?.filter(({ active }) => active)
              ?.map((risk) => (
                <SubscriptionDataContentItem key={risk.name}>
                  <SubscriptionDataRisk>
                    <CheckIconWrapper>
                      <Check color={theme.colors.neutral[0]} width={14} />
                    </CheckIconWrapper>
                    <SubscriptionDataRiskText>
                      {risk.name}
                    </SubscriptionDataRiskText>
                  </SubscriptionDataRisk>
                </SubscriptionDataContentItem>
              ))}
          </SubscriptionDataContent>
        </SubscriptionDataWrapper>
        <SubscriptionDataWrapper>
          <HeaderSubMedium18>
            {t(
              'AVAILABLE_SUBSCRIPTION:headers.availableSubscriptionSubobjects'
            )}
          </HeaderSubMedium18>
          <SubscriptionDataContent>
            {subobjects?.map((subobject) => (
              <SubscriptionDataContentItem key={subobject.name}>
                <SubobjectName>{subobject.name}</SubobjectName>
                <SubobjectPrice>
                  {t('COMMON:price.rubles', {
                    price: numFormat(+subobject.defaultInsuranceSum),
                  })}
                </SubobjectPrice>
              </SubscriptionDataContentItem>
            ))}
          </SubscriptionDataContent>
        </SubscriptionDataWrapper>
        <AdaptivePerMonthFooter>
          <SumWrapper>
            {insuranceSum && (
              <TotalSum
                title={`${t('COMMON:labels.coverAmount')}:`}
                totalSum={numFormat(insuranceSum)}
                isError={false}
                subtitleText={subtitleText}
              />
            )}
          </SumWrapper>
          <SumWrapper>
            <IflSumPerMonthContainer>
              {insuranceSumPerMonth && orderData?.contractDuration && (
                <IflSumPerMonth
                  sumPerMonth={roundUp(Number(insuranceSumPerMonth))}
                  sumPromoPerMonth={roundUp(Number(insurancePromoSumPerMonth))}
                  title={getInsuranceSumDescription(orderData.contractDuration)}
                />
              )}
            </IflSumPerMonthContainer>
          </SumWrapper>
        </AdaptivePerMonthFooter>
        <ButtonsWrapper>
          <Button onClick={handleAccept}>
            {t('AVAILABLE_SUBSCRIPTION:buttons.accept')}
          </Button>
          <Button onClick={handleEdit} variant="secondary-2">
            {t('AVAILABLE_SUBSCRIPTION:buttons.edit')}
          </Button>
        </ButtonsWrapper>
      </Container>
    </BaseLayout>
  );
};
