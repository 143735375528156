/* eslint-disable indent */
import { useEffect } from 'react';

import { Product } from '@src/constants';

export const scrollToIframe = () => {
  //For IOS
  setTimeout(() => {
    window.scroll({ top: -1, left: 0, behavior: 'smooth' });
  }, 10);
  setTimeout(() => {
    window.parent.postMessage({ action: 'scrollToIframe' }, '*');
  }, 100);
};

type OptionalProduct = Product | string | null | undefined;
// Скролл только для конкретных продуктов. В будущем можно добавить и другие продукты.
export const scrollToIframeForProduct = (selectedProduct: OptionalProduct) => {
  switch (selectedProduct) {
    case Product.SPORT:
      return scrollToIframe();
  }
};

export const useScrollToIframeOnMount = (selectedProduct: OptionalProduct) => {
  useEffect(() => {
    scrollToIframeForProduct(selectedProduct);

    return () => {
      scrollToIframeForProduct(selectedProduct);
    };
  }, []);
};
