import { css, mixins, styled, typography } from '@pulse-web-ui/theme';

export const PaymentPeriodCardTitleWrapper = styled.div`
  ${mixins.flex({ gap: 8, alignItems: 'flex-end' })}
`;

export const PaymentPeriodCardActualValue = styled.div<{
  withDiscount: boolean;
}>`
  ${typography.medium32};
  ${({ withDiscount, theme }) =>
    withDiscount &&
    css`
      color: ${theme.colors.text.interactive};
    `}
`;

export const PaymentPeriodCardOldValue = styled.div`
  ${typography.regular16};
  margin-bottom: 3px;
  color: ${({ theme }) => theme.colors.text.secondary};
  text-decoration: line-through;
`;
