import i18n from '@src/i18n/config';
import type { Price } from '@src/types';

export const getPetsPaymentPeriodDescription = ({
  duration,
  discount,
}: Price) => {
  if (duration === 'P3M') {
    return i18n.t('PETS_FORM_DATA:labels.mostFlexibleOption');
  }

  if (duration === 'P1Y') {
    return i18n.t('PETS_FORM_DATA:labels.betterPayingQuarterly', {
      discount,
    });
  }

  return '';
};
