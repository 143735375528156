import { componentsConfigs } from '@src/components-configs';
import { Product } from '@src/constants';
import { ComponentsConfigType } from '@src/types';

export const useComponentsConfig = (
  product: Product,
  type: ComponentsConfigType
) => {
  if (product) {
    const currentPreset = componentsConfigs[product];

    return {
      components: currentPreset?.[type].components,
      analyticEvent: currentPreset?.analyticEvent,
      handleAnalytic: currentPreset?.handleAnalytic,
    };
  }

  return {
    components: [],
  };
};
