import { RiskChild, RiskChildType } from '@src/components/risk-list';
import { CurrencyCode } from '@src/constants';
import i18n from '@src/i18n/config';
import { numFormat } from '@src/utils';

import {
  BASE_RISK,
  CURRENCY_MAP,
  EXCLUDE_OPTIONS,
} from './client-order-page.constants';
import {
  Coverage,
  Option,
  OptionCoverage,
  Risk,
} from './client-order-page.types';

const createOption = (
  risk: Risk | Option,
  coverage: Coverage | OptionCoverage,
  currency: CurrencyCode
): RiskChild => ({
  type: RiskChildType.OPTION,
  title: risk.name,
  coverage:
    i18n.t('ORDER:labels.coverage', {
      coverage: `${numFormat(coverage.coverageSum)} ${CURRENCY_MAP[currency]}`,
    }) || '',
  description: risk.description,
});

export const prepareRisks = (
  risks: Risk[],
  coverages: Coverage[],
  optionCoverages: OptionCoverage[],
  currency: CurrencyCode
): { risks: RiskChild[]; length: number } => {
  let length = 0;
  const baseRisk = risks.find((risk) => risk.code === BASE_RISK);

  const additionalRisks: RiskChild = {
    type: RiskChildType.RISK,
    title: i18n.t('ORDER:headers.additionalProtection') || '',
    children: [
      ...coverages.reduce((acc: RiskChild[], coverage) => {
        if (coverage.risk === BASE_RISK) {
          return acc;
        } else {
          const risk = risks.find((risk) => risk.code === coverage.risk);

          if (risk) length += 1;

          return risk ? [...acc, createOption(risk, coverage, currency)] : acc;
        }
      }, []),
      ...optionCoverages.reduce((acc: RiskChild[], coverage) => {
        const option = baseRisk?.options.find(
          (option) => option.code === coverage.code
        );
        if (option?.binding) {
          return acc;
        } else {
          if (option) length += 1;

          return option
            ? [...acc, createOption(option, coverage, currency)]
            : acc;
        }
      }, []),
    ],
  };
  const baseRisks: RiskChild = {
    type: RiskChildType.RISK,
    title: i18n.t('ORDER:headers.baseProtection') || '',
    children: [
      {
        type: RiskChildType.RISK,
        title: i18n.t('ORDER:headers.manageAndPay') || '',
        children: optionCoverages.reduce((acc: RiskChild[], coverage) => {
          const option = baseRisk?.options.find(
            (option) => option.code === coverage.code
          );

          if (EXCLUDE_OPTIONS.includes(coverage.code) || !option?.binding) {
            return acc;
          } else {
            if (option) length += 1;

            return option
              ? [...acc, createOption(option, coverage, currency)]
              : acc;
          }
        }, []),
      },
      {
        type: RiskChildType.RISK,
        title: i18n.t('ORDER:headers.compensate') || '',
        children: optionCoverages.reduce((acc: RiskChild[], coverage) => {
          if (!EXCLUDE_OPTIONS.includes(coverage.code)) {
            return acc;
          } else {
            const option = baseRisk?.options.find(
              (option) => option.code === coverage.code
            );

            if (option) length += 1;

            return option
              ? [...acc, createOption(option, coverage, currency)]
              : acc;
          }
        }, []),
      },
    ],
  };

  return { risks: [additionalRisks, baseRisks], length };
};
