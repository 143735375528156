/* eslint-disable indent */
import { PetsPresetData, Sublimits } from '@src/store/pets';

export const createSublimitsFromPresetData = (
  presetData: PetsPresetData,
  sublimits: Sublimits,
  currentSublimits?: Sublimits
): Sublimits => ({
  ...sublimits,
  risks: sublimits.risks.map((risk, index) => ({
    ...risk,
    defaultInsuranceSum:
      !!currentSublimits?.risks?.[index]?.defaultInsuranceSum &&
      currentSublimits.risks[index].defaultInsuranceSum !==
        risk.defaultInsuranceSum
        ? currentSublimits.risks[index].defaultInsuranceSum
        : presetData.risks.find(({ code }) => risk.code === code)
            ?.defaultInsuranceSum || risk.defaultInsuranceSum,
  })),
});
