/* eslint-disable indent */
import { useContext, useEffect } from 'react';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { Store } from '@src/store';

const sendAnalytic = (wantNextStep: boolean, currentStep: number) => {
  if (wantNextStep) {
    switch (currentStep) {
      case 1:
        sendAnalyticEvent(analyticEvents.toStep2Realty);
        break;
      case 2:
        sendAnalyticEvent(analyticEvents.toStep3Realty);
        break;
      case 3:
        sendAnalyticEvent(analyticEvents.toStep4Realty);
        break;
      case 4:
        sendAnalyticEvent(analyticEvents.toStep5Realty);
        break;
      case 5:
        sendAnalyticEvent(analyticEvents.toStep6Realty);
        break;
      case 6:
        sendAnalyticEvent(analyticEvents.toConfirmationRealty);
        break;
      default:
        break;
    }
  }
};

export const useAnalyticsIfl = () => {
  const {
    state: {
      stateWizard: { wantNextStep, currentStep },
      stateFormIFLHouse: { presetData },
    },
  } = useContext(Store);

  useEffect(() => {
    !presetData && sendAnalytic(wantNextStep, currentStep);
  }, [wantNextStep, currentStep, presetData]);
};
