import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GlobalErrorInfo } from '@src/features';
import { AuthActionTypes, Store, WizardActionTypes } from '@src/store';

export const useHandlePricesError = (
  pricesError: any,
  refetchPrices: () => void,
  handleBusinessError?: () => void
) => {
  const { t } = useTranslation(['COMMON']);
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const [pricesGlobalError, setPricesGlobalError] = useState<
    JSX.Element | undefined
  >();

  useEffect(() => {
    setPricesGlobalError(undefined);
    if (pricesError) {
      const { status, data: { code } = { code: undefined } } =
        pricesError?.response || {};
      if (status === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: t('errors.authorizationError'),
            subtitle: t('errors.retryRegistration'),
            refRoute: '/personal-info',
          },
        });

        dispatch({
          type: WizardActionTypes.SetCurrentStep,
          payload: 1,
        });

        navigate('/authorize-fail');
      }

      if (code === 'BUSINESS_ERROR') {
        handleBusinessError && handleBusinessError();
      } else {
        setPricesGlobalError(<GlobalErrorInfo retryHandler={refetchPrices} />);
      }
    }
  }, [pricesError]);

  return pricesGlobalError;
};
