import type { FC } from 'react';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Skeleton } from '@src/components';
import { ApiEndpoints, MethodApi, insuranceProductsCode } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import {
  DraftActionTypes,
  PetsTelevetPlusActionTypes,
  Store,
} from '@src/store';
import type { PetsTelevetPlusStoreState } from '@src/store';
import { RiskPageVariant } from '@src/store/pets-televet-plus';
import type { InsuranceProductType } from '@src/types';
import { cleartDraft, getDraft } from '@src/utils';

import { QueryKeys } from './constants';
import { PetsTelevetPlusForm } from './pets-televet-plus-form';

const DEFAULT_INSURANCE_SUM = 20_000;
const DEFAULT_CURRENT_SUM = 250_000;

export const PetsTelevetPlusPage: FC = () => {
  const [isWait, setIsWait] = useState(true);
  const {
    state: {
      stateDraft: { checkDraft },
      stateUser: { selectedProduct, profile },
    },
    dispatch,
  } = useContext(Store);

  const {
    isLoading: productIsLoading,
    error: productError,
    res: resProduct,
    refetch: productRefetch,
  } = useRequest<InsuranceProductType>(
    QueryKeys.GET_INSURANCE_PRODUCT,
    MethodApi.POST,
    ApiEndpoints.GET_INSURANCE_PRODUCT,
    {
      productCode: insuranceProductsCode.petsTelevetPlus,
    }
  );

  useLayoutEffect(() => {
    if (!productIsLoading && resProduct) {
      dispatch({
        type: PetsTelevetPlusActionTypes.SET_SELECTED_PRODUCT_DATA,
        payload: resProduct,
      });
    }
  }, [productIsLoading, resProduct]);

  useEffect(() => {
    if (checkDraft && selectedProduct && !productIsLoading && resProduct) {
      dispatch({
        type: DraftActionTypes.SetCheckDraft,
        payload: false,
      });
      const { resData } = getDraft();

      if (resData) {
        if (resData.common.productType === resProduct.code) {
          const state = resData.state as PetsTelevetPlusStoreState;

          dispatch({
            type: PetsTelevetPlusActionTypes.SET_SELECTED_PRODUCT_DATA,
            payload: state?.selectedIProduct,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_RISKS,
            payload: state?.risks,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_INSURANCE_SUM,
            payload: state?.insuranceSum || DEFAULT_INSURANCE_SUM,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_GET_PRICES,
            payload: state?.getPrices,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_SELECTED_DURATION,
            payload: state?.selectedDuration,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_SELECTED_DATE,
            payload: state?.selectedDate ? new Date(state?.selectedDate) : null,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_PROMO_CODE,
            payload: state?.promoCode,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_RISKS_PAGE,
            payload: state?.risksPage || RiskPageVariant.PRIMARY,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_PETS,
            payload: state?.pets || [],
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_SELECTED_PET_DATA,
            payload: state?.selectedPetData,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_SUBLIMITS,
            payload: state?.sublimits,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_SELECTED_PET_KIND_NAME,
            payload: state?.selectedPetKindName,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_CURRENT_SUM,
            payload: state?.currentSum || DEFAULT_CURRENT_SUM,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_CURRENT_SUM_MIN_LIMIT,
            payload: state?.currentSumMinLimit || '0',
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_CURRENT_SUM_MAX_LIMIT,
            payload: state?.currentSumMaxLimit || '0',
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_CURRENT_SUM_ERR,
            payload: state?.currentSumErr || false,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_SELECTED_ID,
            payload: state?.selectedId,
          });
          dispatch({
            type: PetsTelevetPlusActionTypes.SET_INSURANCE_PROGRAMS,
            payload: state?.insurancePrograms,
          });
        } else {
          cleartDraft();
        }
      }

      setIsWait(false);
    } else if (!checkDraft) {
      setIsWait(false);
    }
  }, [checkDraft, selectedProduct, productIsLoading, resProduct, profile]);

  if (productIsLoading || isWait || checkDraft) return <Skeleton />;

  if (productError) {
    return <GlobalErrorInfo retryHandler={productRefetch} />;
  }

  return (
    <BaseLayout>
      <PetsTelevetPlusForm />
    </BaseLayout>
  );
};
