/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable quotes */

/* eslint-disable indent */
import * as yup from 'yup';
import { Message } from 'yup/lib/types';

import {
  maxLength,
  onlyCyrillic,
  onlyLetters,
  phoneRegEx,
  promoEx,
} from '@src/constants';
import i18n from '@src/i18n/config';
import { FormInsuranceContactsProps, FormPromoProps } from '@src/types';
import { calculateAge } from '@src/utils';

import { emailRegEx } from './../constants/regex';

const defaultFioSchema = yup
  .string()
  .required('INSURANCE_FORMS:errors.notFilled')
  .test('maxLength', 'INSURANCE_FORMS:errors.maxLength', (value) =>
    maxLength.test(value!)
  );

const firstNameSchema = defaultFioSchema
  .test('onlyLetters', 'INSURANCE_FORMS:errors.nameStartWithLetter', (value) =>
    onlyLetters.test(value!)
  )
  .test('onlyCyrillic', 'INSURANCE_FORMS:errors.useCyrillic', (value) =>
    onlyCyrillic.test(value!)
  );

const lastNameSchema = defaultFioSchema
  .test(
    'onlyLetters',
    'INSURANCE_FORMS:errors.lastnameStartWithLetter',
    (value) => onlyLetters.test(value!)
  )
  .test('onlyCyrillic', 'INSURANCE_FORMS:errors.useCyrillic', (value) =>
    onlyCyrillic.test(value!)
  );

const personalInfoMiddleNameSchema = yup
  .string()
  .nullable()
  .notRequired()
  .when('middleName', {
    is: (value: string | any[]) => value?.length,
    then: defaultFioSchema
      .test(
        'onlyLetters',
        'INSURANCE_FORMS:errors.middleNameStartWithLetter',
        (value) => onlyLetters.test(value!)
      )
      .test('onlyCyrillic', 'INSURANCE_FORMS:errors.useCyrillic', (value) =>
        onlyCyrillic.test(value!)
      ),
  });

export const profileFormSchema = yup.object().shape(
  {
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    middleName: personalInfoMiddleNameSchema,
  },
  [['middleName', 'middleName']]
);

export const formInsuranceContactsSchema = profileFormSchema.shape({
  email: yup
    .string()
    .nullable()
    .email('INSURANCE_FORMS:errors.incorrectEmail')
    .required('INSURANCE_FORMS:errors.enterEmail'),
});

export const formInsuranceContactsDefaultValues: FormInsuranceContactsProps = {
  lastName: '',
  firstName: '',
  middleName: '',
  useMiddleName: false,
  email: '',
  birthDate: '',
};

export const formInsurancePeriodSchema = yup.object().shape({
  promoCode: yup
    .string()
    .nullable()
    .test(
      'validPromo',
      i18n.t('INSURANCE_FORMS:errors.invalidPromoCode') as Message,
      (value) => {
        return !value?.length || promoEx.test(value);
      }
    ),
});

export const formInsurancePeriodDefaultValues: FormPromoProps = {
  promoCode: '',
};

export const personSchema = profileFormSchema.shape({
  ageMin: yup.number().required(),
  birthDate: yup
    .string()
    .required('INSURANCE_FORMS:errors.notFilled')
    .when('isMe', {
      is: true,
      then: yup
        .string()
        .test(
          'notEdult',
          'INSURANCE_FORMS:errors.insuranceFromAgeEighteen',
          (value) => calculateAge(new Date(value!)) >= 18
        ),
    }),
  isMe: yup.boolean(),
});

export const personsFormSchema = yup.object({
  persons: yup.array(personSchema).required(),
});

export const formOrderDetailSchema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .required('INSURANCE_FORMS:errors.enterEmail')
    .test(
      'validEmail',
      'INSURANCE_FORMS:errors.incorrectEmail',
      (value) => !!value && emailRegEx.test(value)
    ),
});

export const personalInfoSchema = yup.object().shape(
  {
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    middleName: personalInfoMiddleNameSchema,
    useMiddleName: yup.bool(),
    userPoliciesCode002: yup.bool(),
    userPoliciesCode003: yup
      .bool()
      .oneOf([true], 'INSURANCE_FORMS:errors.cannotWithoutConsent'),
    birthDay: yup
      .string()
      .required('INSURANCE_FORMS:errors.notFilled')
      .nullable(true)
      .test(
        'notEdult',
        'INSURANCE_FORMS:errors.insuranceFromAgeEighteen',
        (value) => calculateAge(new Date(value!)) >= 18
      ),
  },
  [['middleName', 'middleName']]
);

export const updateProfileInfoSchema = profileFormSchema.shape({
  birthDay: yup
    .string()
    .required('INSURANCE_FORMS:errors.notFilled')
    .nullable(true)
    .test(
      'notEdult',
      'INSURANCE_FORMS:errors.insuranceFromAgeEighteen',
      (value) => calculateAge(new Date(value!)) >= 18
    ),
});

export const identificationPageSchema = yup.object().shape({
  phoneNumber: yup
    .mixed()
    .required('INSURANCE_FORMS:errors.enterPhone')
    .test('validPhone', 'INSURANCE_FORMS:errors.wrongPhone', (value) =>
      phoneRegEx.test(value)
    ),
});

export const lastNameFormSchema = yup.object({
  lastName: lastNameSchema,
});
