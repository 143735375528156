import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ThemeContainerIn } from './theme-container-in';

export const ThemeContainer: FC = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [themeUrl, setThemeUrl] = useState<string | null>();

  useEffect(() => {
    const personalization = searchParams.get('personalization') || null;

    setThemeUrl(personalization);
  }, [searchParams]);

  if (themeUrl === undefined) {
    return null;
  }

  return <ThemeContainerIn themeUrl={themeUrl}>{children}</ThemeContainerIn>;
};
