import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { useQueryFunction } from '@src/hooks';
import type { Method, Url } from '@src/types';

interface ErrorResponse {
  code: string;
  unlockingTime?: string;
}

export const useRequest = <T = any, D = any>(
  key: string,
  method: Method,
  url: Url,
  request?: D,
  deps?: any[],
  fetchManual?: boolean,
  authToken?: string
) => {
  const queryFn = useQueryFunction<T, D>(method, url, request, authToken);

  const queryResult = useQuery<AxiosResponse<T>, AxiosError<ErrorResponse>>(
    [key, deps],
    queryFn,
    {
      enabled: fetchManual ? false : true,
      staleTime: 100_000_000,
    }
  );

  const {
    isLoading,
    error,
    data,
    refetch,
    isFetching,
    isRefetching,
    isFetchedAfterMount,
    status,
  } = queryResult;

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return {
    isLoading,
    error,
    res,
    refetch,
    isFetching,
    isRefetching,
    isFetchedAfterMount,
    status,
  };
};
