import { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

export const getConnectedAddress = (dadaValue: DaDataAddressSuggestion) => {
  const {
    value,
    data: { flat },
  } = dadaValue;

  const connectedHouse = value;

  return `${connectedHouse}${flat && `, кв ${flat}`}`;
};
