import { useContext, useEffect } from 'react';

import { Store, UserActionTypes, WizardActionTypes } from '@src/store';

import { useRequest } from './use-request';

export const useValidateProfileAuth = () => {
  const {
    state: {
      stateAuth: { authTokens },
      stateUser: { profile, clientCategoryFromUrl },
    },
    dispatch,
  } = useContext(Store);

  const {
    refetch: profileRefetch,
    isLoading: profileIsLoading,
    error: profileError,
    status: profileStatus,
    res: profileRes,
  } = useRequest(
    'userGetProfile',
    'post',
    '/v1/user/get-profile',
    {},
    [profile, authTokens],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (!profileIsLoading && profileRes) {
      dispatch({
        type: UserActionTypes.SetProfile,
        payload: profileRes,
      });
    }
  }, [profileIsLoading, clientCategoryFromUrl, profileRes]);

  return {
    profileRefetch,
    profileIsLoading,
    profileError,
    profileStatus,
    profileRes,
  };
};
