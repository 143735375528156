import { Option } from '@pulse-web-ui/select';

import i18n from '@src/i18n/config';
import { PetData } from '@src/store/pets';
import {
  declineWord,
  getPluralMonthDeclensions,
  getPluralYearDeclensions,
} from '@src/utils';

const MAX_MONTH_AGE = 11;

const isMonth = (age: string) => age.includes('M');

const getNumberFromString = (str: string) => Number(str.replace(/[^0-9]/g, ''));

export const getFormattedAge = (age: string) => {
  const ageNumber = getNumberFromString(age);
  const monthDeclensions = getPluralMonthDeclensions();
  const yearDeclensions = getPluralYearDeclensions();

  return isMonth(age)
    ? declineWord(ageNumber, monthDeclensions)
    : declineWord(ageNumber, yearDeclensions);
};

const createAgeOptions = (
  minAge: string,
  maxAge: string,
  options: Option[] = []
): Option[] => {
  const isSameMinAndMaxAgeMeasure =
    (isMonth(minAge) && isMonth(maxAge)) ||
    (!isMonth(minAge) && !isMonth(maxAge));
  const minAgeNumber = getNumberFromString(minAge);
  const maxAgeNumber = getNumberFromString(maxAge);
  const monthDeclensions = getPluralMonthDeclensions();
  const yearDeclensions = getPluralYearDeclensions();

  if (
    (isSameMinAndMaxAgeMeasure && minAgeNumber < maxAgeNumber) ||
    (!isSameMinAndMaxAgeMeasure &&
      isMonth(minAge) &&
      minAgeNumber < MAX_MONTH_AGE)
  ) {
    return createAgeOptions(
      `${minAgeNumber + 1}${isMonth(minAge) ? 'M' : 'Y'}`,
      maxAge,
      [
        ...options,
        {
          value: minAge,
          label: isMonth(minAge)
            ? declineWord(minAgeNumber, monthDeclensions)
            : declineWord(minAgeNumber, yearDeclensions),
        },
      ]
    );
  } else if (
    !isSameMinAndMaxAgeMeasure &&
    isMonth(minAge) &&
    minAgeNumber === MAX_MONTH_AGE
  ) {
    return createAgeOptions('1Y', maxAge, [
      ...options,
      { value: minAge, label: `11 ${i18n.t('COMMON:declensions.month-3')}` },
    ]);
  } else {
    return [
      ...options,
      {
        value: maxAge,
        label: isMonth(maxAge)
          ? declineWord(maxAgeNumber, monthDeclensions)
          : declineWord(maxAgeNumber, yearDeclensions),
      },
    ];
  }
};

export const getAgeOptionsByKind = (
  petsData: PetData[],
  kind: string
): Option[] => {
  const { minAge, maxAge } = petsData.find((pet) => pet.kind === kind) || {};

  return minAge && maxAge ? createAgeOptions(minAge, maxAge) : [];
};
