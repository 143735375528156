/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { useTheme } from '@pulse-web-ui/theme';

import {
  AdaptiveHeadersWrapper,
  Container,
  FormLabel,
  FormSubTitle,
  HeaderAdaptive5,
  InfoWrapper,
  MiddleNameWrapper,
  PromoWrapper,
  Skeleton,
  WarnigText,
  WrapperWithFlexibleGrow,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  FeatureFlags,
  analyticEvents,
  insuranceProductsCode,
} from '@src/constants';
import { FIRST_STEP_FOR_SETTINGS } from '@src/constants/preset';
import {
  GlobalErrorInfo,
  PromoCodeDiscount,
  SettingsWrapper,
} from '@src/features';
import {
  useBlockNextStep,
  useFeatureFlags,
  useHandlePressKey,
  useNextStep,
  useRequest,
} from '@src/hooks';
import { setTemporaryFranchiseHint } from '@src/pages/pets-form/utils';
import {
  AuthActionTypes,
  PetsActionTypes,
  PresetSettingActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { FormPromoProps, KeyCode, SelectedDuration } from '@src/types';
import {
  addTestAttribute,
  checkDateBefore,
  convertDateByTimeZone,
  resetPromo,
  setPaymentPageTexts,
} from '@src/utils';

import { formInsurancePeriodSchema } from '../../schemas';
import { PetsPaymentPeriodList } from './components';
import { usePetsDraft, usePetsOrderData, useRequestData } from './hooks';
import type { InsuranceLimitStartResponseData } from './pets-form.types';
import { PromoSubmitButton } from './pets-payment-period.styles';

export const PetsPaymentPeriod: FC = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const {
    state: {
      stateFormPets: {
        promoCode,
        promoCodeApplyed,
        selectedDuration,
        selectedDate,
        selectedIProduct,
        getPricesFull,
        presetData,
        risks,
      },
      stateAuth: { authTokens },
      stateUser: { promoCodeFailedMessage },
      stateWizard: { currentStep },
      stateAuthFlow: { sepScrAuth, authStep },
    },
    dispatch,
  } = useContext(Store);
  const {
    res: [isFeatureSubscriptionType],
  } = useFeatureFlags([FeatureFlags.PetsSubscriptionType]);
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [submitPromoLabel, setSubmitPromoLabel] = useState<string>(
    t('COMMON:success.apply') || ''
  );
  const [isInputActive, setIsInputActive] = useState(false);
  const [autoInitPromo, setAutoInitPromo] = useState(false);
  const [localPromo, setLocalPromo] = useState<string | undefined>();

  const navigate = useNavigate();

  const { isPricesLoading, pricesError, pricesRes } = useRequestData(
    autoInitPromo,
    localPromo
  );

  const activeRisks = useMemo(
    () =>
      risks?.filter((item) => item.active).map((item) => ({ code: item.code })),
    [risks]
  );

  const {
    isLoading: isInsuranceLimitStartLoading,
    error: insuranceLimitStartError,
    res: insuranceLimitStartRes,
    refetch: refetchInsuranceLimitStart,
  } = useRequest<InsuranceLimitStartResponseData>(
    'petsFormGetInsuranceLimitStart',
    'post',
    `/v3/references/insurance-limit-start/${insuranceProductsCode.pets}/1`,
    { risks: activeRisks || [] },
    [selectedIProduct?.code, activeRisks]
  );

  usePetsOrderData();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: isPricesLoading || isInsuranceLimitStartLoading,
    });
  }, [isPricesLoading, isInsuranceLimitStartLoading]);

  const handleKeyPressEnter = () => {
    if (sepScrAuth || currentStep !== authStep) {
      if (isInputActive) {
        submitPromoCode();
      } else {
        dispatch({
          type: WizardActionTypes.UpdateWantNextStep,
          payload: true,
        });
      }
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [isInputActive]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    getValues,
    watch,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      promoCode:
        promoCodeApplyed && getPricesFull?.isSuccessfulPromo
          ? promoCodeApplyed
          : promoCode,
    },
  });

  const { promoCode: promoCodeValue } = getValues();
  resetPromo(promoCodeValue, promoCodeApplyed, null, watch('promoCode'));

  useEffect(() => {
    if (selectedDate !== undefined) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: PetsActionTypes.SetPromoCode,
        payload: value.promoCode,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    if (promoCodeApplyed && getPricesFull?.isSuccessfulPromo) {
      setLocalPromo(promoCodeApplyed);
      setAutoInitPromo(true);
    }
  }, []);

  usePetsDraft();

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: PetsActionTypes.SetSelectedDate,
      payload: convertDateByTimeZone(newDate),
    });
  };

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: PetsActionTypes.SetSelectedDuration,
      payload: val,
    });
  };

  useNextStep(() => {
    navigate('/order-detail');
    return false;
  });

  useEffect(() => {
    setPaymentPageTexts(
      t('PETS_FORM_DATA:hints.ifSomethingHappensToYourPet'),
      selectedDate
    );
  }, [selectedDate]);

  useEffect(() => {
    if (!isPricesLoading && pricesRes) {
      dispatch({
        type: PetsActionTypes.SetGetPricesFull,
        payload: pricesRes,
      });

      if (
        selectedDuration === null ||
        pricesRes?.prices.every(({ duration }) => duration !== selectedDuration)
      ) {
        dispatch({
          type: PetsActionTypes.SetSelectedDuration,
          payload: pricesRes?.prices[0]?.duration,
        });
      }

      const isPromoCodeAppliedSuccessfully =
        pricesRes?.isSuccessfulPromo && promoCode !== '';

      if (pricesRes?.isSuccessfulPromo === false && promoCode !== '') {
        setError('promoCode', {
          type: 'string',
          message: 'COMMON:errors.promoCodeNotValid',
        });
      }

      if (
        pricesRes?.isSuccessfulPromo &&
        promoCode !== '' &&
        !promoCodeApplyed
      ) {
        dispatch({
          type: PetsActionTypes.SetPromoCodeApplyed,
          payload: promoCode,
        });
      }

      setSubmitPromoDisabled(isPromoCodeAppliedSuccessfully);
    }
  }, [isPricesLoading, pricesRes]);

  useEffect(() => {
    if (!isInsuranceLimitStartLoading && insuranceLimitStartRes) {
      const startDateLimit = new Date(insuranceLimitStartRes?.startDate);
      const defaultPolicyStartDate =
        selectedDate && selectedDate > startDateLimit
          ? selectedDate
          : startDateLimit;
      setMinDate(new Date(insuranceLimitStartRes?.startDate));
      setMaxDate(new Date(insuranceLimitStartRes?.endDate));
      if (
        JSON.stringify(insuranceLimitStartRes?.startDate) ===
        JSON.stringify(insuranceLimitStartRes?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(defaultPolicyStartDate);
    }
  }, [isInsuranceLimitStartLoading, insuranceLimitStartRes, pricesRes]);

  useEffect(() => {
    if (!isInsuranceLimitStartLoading && insuranceLimitStartRes) {
      const risksTemporaryFranchise = insuranceLimitStartRes?.risks ?? [];

      if (risksTemporaryFranchise.length && risks && selectedDate) {
        setTemporaryFranchiseHint(risksTemporaryFranchise, risks, selectedDate);
      } else {
        localStorage.removeItem('petsTemporaryFranchise');
      }
    }
  }, [
    isInsuranceLimitStartLoading,
    insuranceLimitStartRes,
    risks,
    selectedDate,
  ]);

  useEffect(() => {
    if (
      selectedDate &&
      (checkDateBefore(
        new Date(selectedDate),
        new Date(insuranceLimitStartRes?.startDate || '')
      ) ||
        checkDateBefore(
          new Date(insuranceLimitStartRes?.endDate || ''),
          new Date(selectedDate)
        ) ||
        dateEqual)
    ) {
      setSelectedDate(new Date(insuranceLimitStartRes?.startDate || ''));
    }
  }, [insuranceLimitStartRes, dateEqual]);

  useEffect(() => {
    const { promoCode } = getValues();
    const isPromoСodeAppliedSuccessfully = pricesRes?.isSuccessfulPromo;

    isPromoСodeAppliedSuccessfully && promoCode === promoCodeApplyed
      ? setSubmitPromoLabel(t('COMMON:success.applied') || '')
      : setSubmitPromoLabel(t('COMMON:success.apply') || '');
  }, [pricesRes, promoCode, promoCodeApplyed]);

  useEffect(() => {
    if (authTokens?.authorization?.accessToken) {
      reset();
    }
  }, [authTokens?.authorization?.accessToken]);

  useEffect(() => {
    if (pricesRes?.isSuccessfulPromo === false && promoCode !== '') {
      setError('promoCode', {
        type: 'string',
        message: 'COMMON:errors.promoCodeNotValid',
      });

      dispatch({
        type: UserActionTypes.SetPromoCodeFailedMessage,
        payload: t('COMMON:errors.promoCodeNotValid') || '',
      });

      dispatch({
        type: PetsActionTypes.SetPromoCodeApplyed,
        payload: undefined,
      });
    }
  }, [isPricesLoading, pricesRes, promoCode]);

  useEffect(() => {
    if (!!presetData) {
      sendAnalyticEvent(analyticEvents.petToStepPeriodPreset);
    }
  }, []);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === promoCode);
  };

  const submitPromoCode = handleSubmit((data) => {
    dispatch({
      type: PetsActionTypes.SetPromoCode,
      payload: data.promoCode,
    });

    dispatch({
      type: UserActionTypes.SetPromoCodeFailedMessage,
      payload: undefined,
    });

    dispatch({
      type: PetsActionTypes.SetPromoCodeApplyed,
      payload: undefined,
    });

    setIsInputActive(false);
    setAutoInitPromo(true);
    setLocalPromo(promoCode);
  });

  useBlockNextStep(
    isPricesLoading || isInsuranceLimitStartLoading,
    pricesError || insuranceLimitStartError
  );

  if ((isPricesLoading && pricesRes) || isInsuranceLimitStartLoading) {
    return <Skeleton />;
  }

  if (pricesError || insuranceLimitStartError) {
    const error = pricesError || insuranceLimitStartError;
    const status = error?.response?.status;
    if (status === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration'),
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }
    return <GlobalErrorInfo retryHandler={refetchInsuranceLimitStart} />;
  }

  const handleClickSettingPreset = () => {
    sendAnalyticEvent(analyticEvents.petToConfigPreset);
    navigate('/preset-settings');
    dispatch({
      type: PresetSettingActionTypes.SetIsPresetSettingDisplayed,
      payload: true,
    });
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: FIRST_STEP_FOR_SETTINGS,
    });
  };

  const subTitle = t('PETS_FORM_DATA:hints.selectPaymentFrequency', {
    defaultValue: '',
  });

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <SettingsWrapper>
          <FormLabel marginBottom={!!subTitle.length ? 8 : 0}>
            {isFeatureSubscriptionType
              ? t('PETS_FORM_DATA:headers.chooseSubscriptionType')
              : t('COMMON:headers.paymentOfPeriod')}
          </FormLabel>
          {!!presetData && (
            <Button variant="setting" onClick={handleClickSettingPreset}>
              {t('COMMON:buttons.settingSubscription')}
            </Button>
          )}
        </SettingsWrapper>
        {!!subTitle.length && <HeaderAdaptive5>{subTitle}</HeaderAdaptive5>}
      </AdaptiveHeadersWrapper>
      <PetsPaymentPeriodList
        onRadioSelector={onRadioSelector}
        selectedDuration={selectedDuration}
        getPrices={getPricesFull}
      />
      <PromoWrapper>
        <Container>
          <MiddleNameWrapper>
            <WrapperWithFlexibleGrow>
              <Controller
                control={control}
                name="promoCode"
                render={({ field: { onChange, value }, fieldState }) => (
                  <HelperText
                    status={
                      fieldState.error || promoCodeFailedMessage
                        ? 'error'
                        : 'success'
                    }
                    message={
                      (errors.promoCode?.message &&
                        t(errors.promoCode.message)) ||
                      promoCodeFailedMessage ||
                      (pricesRes?.isSuccessfulPromo &&
                      promoCodeApplyed !== '' &&
                      getPricesFull?.prices ? (
                        <PromoCodeDiscount
                          prices={getPricesFull?.prices}
                          featureFlag={FeatureFlags.PetsDiscount}
                        />
                      ) : (
                        ''
                      ))
                    }
                    testId="pet-form-promocode-message"
                  >
                    <Input
                      label={t('COMMON:labels.promoCode') || ''}
                      value={value}
                      onFocus={() => setIsInputActive(true)}
                      onBlur={() => setIsInputActive(false)}
                      onChange={(val) => {
                        checkSubmitState(val);
                        return onChange(val);
                      }}
                      error={!!errors.promoCode || !!promoCodeFailedMessage}
                      disabled={isPricesLoading || isInsuranceLimitStartLoading}
                      {...addTestAttribute('pet-form-promocode')}
                    />
                  </HelperText>
                )}
              />
            </WrapperWithFlexibleGrow>
            <PromoSubmitButton>
              <Button
                label={submitPromoLabel}
                onClick={submitPromoCode}
                disabled={submitPromoDisabled}
                variant="secondary-2"
                adaptiveWidth
                {...addTestAttribute('pet-form-promocode-apply-button')}
              />
            </PromoSubmitButton>
          </MiddleNameWrapper>
        </Container>
      </PromoWrapper>
      <FormSubTitle>
        {isFeatureSubscriptionType
          ? t('PETS_FORM_DATA:labels.subscriptionStartDate')
          : t('COMMON:labels.subscriptionActive')}
      </FormSubTitle>
      <HelperText
        message={
          dateEqual
            ? ''
            : isFeatureSubscriptionType
            ? t('PETS_FORM_DATA:hints.selectStartDateForSubscription')
            : t('COMMON:hints.selectStartDateForSubscription')
        }
        status="default"
        messagePosition="down"
        testId="pet-form-start-date"
      >
        {isPricesLoading || isInsuranceLimitStartLoading ? (
          <Skeleton />
        ) : dateEqual ? (
          <InfoWrapper>
            <Info width={24} color={theme.colors.icon.primary} />
            <WarnigText data-testid="pet-form-start-date-warning">
              {t('COMMON:labels.subscriptionWillStart')}{' '}
              {convertDateByTimeZone(minDate)?.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </WarnigText>
          </InfoWrapper>
        ) : (
          <Datepicker
            onChange={setSelectedDate}
            selected={selectedDate}
            minDate={convertDateByTimeZone(minDate)}
            maxDate={convertDateByTimeZone(maxDate)}
            {...addTestAttribute('pet-form-start-date')}
          />
        )}
      </HelperText>
    </Container>
  );
};
