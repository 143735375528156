import type { FC } from 'react';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Skeleton } from '@src/components';
import { insuranceProductsCode } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNavigateToVip, useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { DraftActionTypes, IFLFlatActionTypes, Store } from '@src/store';
import type { IFLFlatStoreState } from '@src/store';
import type { InsuranceProductType } from '@src/types';
import { cleartDraft, getDraft } from '@src/utils';

import { IFLFlatForm } from './ifl-flat-form';

export const IFLFlatPage: FC = () => {
  const {
    state: {
      stateDraft: { checkDraft },
      stateUser: { selectedProduct, profile },
      stateFormIFLFlat: { presetData },
      stateAuthFlow: { authFlowParams },
    },
    dispatch,
  } = useContext(Store);
  const [isWait, setIsWait] = useState(true);
  const { shouldNavigateToVip } = useNavigateToVip();

  const hasProfile = authFlowParams?.partnerAuthRegType === 'base' || !!profile;

  // TODO: желательно вынести запрос на версию продукта и обработку логики чтения черновика в отдельный хук,
  // но требуется ресерч и реализация на основной странице каждой формы
  // сталкивался с конфликтами вызова хуков в хуках и прочими сайд-эффектами.
  const {
    isLoading: productIsLoading,
    error: productError,
    res: resProduct,
    refetch: productRefetch,
  } = useRequest(
    'formIFLFlatGetInsuranceProduct',
    'post',
    '/v1/references/get-insurance-product',
    {
      productCode: insuranceProductsCode.flat,
    }
  );

  //TODO: временный флаг принудительной для очистки старых черновиков.
  useLayoutEffect(() => {
    if (!productIsLoading && resProduct) {
      const data: InsuranceProductType = { ...resProduct };

      dispatch({
        type: IFLFlatActionTypes.SetSelectedIProduct,
        payload: data,
      });
    }
  }, [productIsLoading, resProduct]);

  useEffect(() => {
    dispatch({
      type: DraftActionTypes.SetCheckDraft,
      payload: !presetData,
    });
  }, []);

  useEffect(() => {
    if (productIsLoading) {
      return;
    }

    if (!localStorage.getItem('selectedDraftAppartment')) {
      cleartDraft();
      localStorage.setItem('selectedDraftAppartment', 'true');
      setIsWait(false);
    } else if (
      checkDraft &&
      selectedProduct &&
      !productIsLoading &&
      resProduct &&
      hasProfile
    ) {
      dispatch({
        type: DraftActionTypes.SetCheckDraft,
        payload: false,
      });
      const { resData } = getDraft();

      if (resData) {
        if (resData.common.productType === resProduct.code) {
          const state = resData.state as unknown as IFLFlatStoreState;

          dispatch({
            type: IFLFlatActionTypes.SetSelectedIProduct,
            payload: state?.selectedIProduct,
          });

          dispatch({
            type: IFLFlatActionTypes.SetSelectedRegion,
            payload: state?.selectedRegion,
          });
          dispatch({
            type: IFLFlatActionTypes.SetRegions,
            payload: state?.regions,
          });
          dispatch({
            type: IFLFlatActionTypes.SetRisks,
            payload: state?.risks,
          });
          dispatch({
            type: IFLFlatActionTypes.SetInsuranceSubproducts,
            payload: state?.insuranceSubproducts,
          });
          dispatch({
            type: IFLFlatActionTypes.SetInsuranceSum,
            payload: state?.insuranceSum || 20000,
          });
          dispatch({
            type: IFLFlatActionTypes.SetGetPrices,
            payload: state?.getPrices,
          });
          dispatch({
            type: IFLFlatActionTypes.SetSelectedDuration,
            payload: state?.selectedDuration || null,
          });
          dispatch({
            type: IFLFlatActionTypes.SetSelectedDate,
            payload:
              state?.selectedDate && state?.selectedDate !== null
                ? new Date(state?.selectedDate as unknown as string)
                : null,
          });
          dispatch({
            type: IFLFlatActionTypes.SetDadaValue,
            payload: state?.dadaValue,
          });
          dispatch({
            type: IFLFlatActionTypes.SetPromoCode,
            payload: state?.promoCode,
          });
          dispatch({
            type: IFLFlatActionTypes.SetRisksPage,
            payload: state?.risksPage || 'primary',
          });
          dispatch({
            type: IFLFlatActionTypes.SetFieldWithEmptyDependencies,
            payload: state?.fieldWithEmptyDependencies,
          });
          dispatch({
            type: IFLFlatActionTypes.SetEmptyDependencies,
            payload: state?.emptyDependencies,
          });
          dispatch({
            type: IFLFlatActionTypes.SetIsObjectDataError,
            payload: state?.isObjectDataError,
          });
          dispatch({
            type: IFLFlatActionTypes.SetNewSubobjects,
            payload: state?.newSubobjects,
          });
          dispatch({
            type: IFLFlatActionTypes.SetCurrentSum,
            payload: state?.currentSum || 2_000_000,
          });
          dispatch({
            type: IFLFlatActionTypes.SetCurrentSumMinLimit,
            payload: state?.currentSumMinLimit || '0',
          });
          dispatch({
            type: IFLFlatActionTypes.SetCurrentSumMaxLimit,
            payload: state?.currentSumMaxLimit || '0',
          });
          dispatch({
            type: IFLFlatActionTypes.SetCurrentSumErr,
            payload: state?.currentSumErr || false,
          });
          dispatch({
            type: IFLFlatActionTypes.SetSelectedId,
            payload: state?.selectedId,
          });
          dispatch({
            type: IFLFlatActionTypes.SetIsSelectedCustomStreet,
            payload: state?.isSelectedCustomStreet,
          });
          dispatch({
            type: IFLFlatActionTypes.SetIsSelectedCustomHouse,
            payload: state?.isSelectedCustomHouse,
          });
          dispatch({
            type: IFLFlatActionTypes.SetIsEmptyStreetSuggestions,
            payload: state?.isEmptyStreetSuggestions,
          });
          dispatch({
            type: IFLFlatActionTypes.SetIsEmptyHouseSuggestions,
            payload: state?.isEmptyHouseSuggestions,
          });
          dispatch({
            type: IFLFlatActionTypes.SetIsHouseNotFound,
            payload: state?.isHouseNotFound,
          });
        } else {
          cleartDraft();
        }
      }
      setIsWait(false);
    } else if (!checkDraft) {
      setIsWait(false);
    }
  }, [checkDraft, selectedProduct, productIsLoading, resProduct, hasProfile]);

  if (productError) {
    return <GlobalErrorInfo retryHandler={productRefetch} />;
  }

  if (!hasProfile || productIsLoading || isWait || shouldNavigateToVip)
    return <Skeleton />;

  return (
    <BaseLayout>
      <IFLFlatForm />
    </BaseLayout>
  );
};
