import i18n from '@src/i18n/config';

export const getPetsSubscriptionTypeLabels = () =>
  new Map([
    [
      'P3M',
      {
        label: i18n.t('PETS_FORM_DATA:labels.perMonth'),
        subTitle: i18n.t('PETS_FORM_DATA:labels.forThreeMonths'),
        description: i18n.t('PETS_FORM_DATA:labels.behindThreeMonths'),
      },
    ],
    [
      'P1Y',
      {
        label: i18n.t('PETS_FORM_DATA:labels.perMonth'),
        subTitle: i18n.t('PETS_FORM_DATA:labels.forYear'),
        description: i18n.t('PETS_FORM_DATA:labels.behindTwelveMonths'),
      },
    ],
  ]);
