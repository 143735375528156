/* eslint-disable indent */
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

const sendEvent = (event: string, presetEvent: string, isPreset: boolean) => {
  isPreset ? sendAnalyticEvent(presetEvent) : sendAnalyticEvent(event);
};

export const handleRegistrationComplete = (
  product: Product,
  isPreset: boolean
) => {
  switch (product) {
    case Product.APARTMENT:
      sendAnalyticEvent(analyticEvents.authFlatRegistrationCompleted);
      break;
    case Product.HOUSE:
      sendEvent(
        analyticEvents.authHouseRegistrationCompleted,
        analyticEvents.authHouseRegistrationCompletedPreset,
        isPreset
      );
      break;
    case Product.NS:
      sendAnalyticEvent(analyticEvents.authNSRegistrationCompleted);
      break;
    case Product.PETS:
      sendEvent(
        analyticEvents.authPetRegistrationCompleted,
        analyticEvents.authPetRegistrationCompletedPreset,
        isPreset
      );
      break;
    case Product.SPORT:
      sendAnalyticEvent(analyticEvents.authSportRegistrationCompleted);
      break;
    case Product.MITE:
      sendAnalyticEvent(analyticEvents.authMiteRegistrationCompleted);
      break;
    case Product.PETS_TELEVET_PLUS:
      sendAnalyticEvent(analyticEvents.petTelRegCompleted);
      break;
    default:
      break;
  }
};
