export const RgsLogo = () => (
  <svg
    width="103"
    height="15"
    viewBox="0 0 103 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_427_1285)">
      <path
        d="M29.7559 14.0855H32.5345V3.13575H36.067V0.517578H29.7559V14.0855Z"
        fill="#8D1B34"
      />
      <path
        d="M63.3369 3.13575H66.1147V14.0855H68.893V3.13575H71.6703V0.517578H63.3369V3.13575Z"
        fill="#8D1B34"
      />
      <path
        d="M0.804688 0.517578V14.0854H3.52085V10.6399H7.05562L9.10267 8.6777V2.479L7.15005 0.517578H0.804688ZM6.32394 8.02142H3.52085V3.13629H6.32394V8.02142Z"
        fill="#8D1B34"
      />
      <path
        d="M12.6404 0.519531L10.6875 2.48096V12.1251L12.6416 14.0873H17.547L19.5033 12.1251V2.48096L17.547 0.519531H12.6404ZM16.7238 11.4693H13.4668V3.13757H16.7238V11.4693Z"
        fill="#8D1B34"
      />
      <path
        d="M24.04 3.13575H27.9783V0.517578H23.2149L21.2617 2.47903V12.1235L23.2149 14.0866H28.0002V11.4676H24.04V3.13575Z"
        fill="#8D1B34"
      />
      <path
        d="M38.865 0.519531L36.9121 2.48096V12.1251L38.8662 14.0873H43.7716L45.7279 12.1251V2.48096L43.7716 0.519531H38.865ZM42.9485 11.4693H39.6915V3.13757H42.9485V11.4693Z"
        fill="#8D1B34"
      />
      <path
        d="M73.1152 0.519531V14.0874H75.8313V10.6418H79.3662L81.4132 8.67965V2.48096L79.4605 0.519531H73.1152ZM78.6344 8.02337H75.8313V3.13825H78.6344V8.02337Z"
        fill="#8D1B34"
      />
      <path
        d="M50.2639 3.13575H54.2019V0.517578H49.4385L47.4854 2.47903V12.1235L49.4385 14.0866H54.2239V11.4676H50.2639V3.13575Z"
        fill="#8D1B34"
      />
      <path
        d="M58.3379 3.13575H62.2762V0.517578H57.5127L55.5596 2.47903V12.1235L57.5127 14.0866H62.2981V11.4676H58.3379V3.13575Z"
        fill="#8D1B34"
      />
      <path
        d="M100.004 7.1296L101.978 0.517578H99.1933L97.6913 5.64329H97.1903L95.6886 0.517578H92.9032L94.8837 7.15814L92.877 14.0852H95.6223L97.2163 8.7347H97.665L99.2609 14.0852H102.005L100.004 7.1296Z"
        fill="#8D1B34"
      />
      <path
        d="M89.458 14.0852H92.2536L89.4656 0.517578H84.7177L81.9297 14.0852H84.6566L85.2874 10.8512H88.8272L89.458 14.0852ZM85.7845 8.30221L86.7921 3.13561H87.3222L88.33 8.30221H85.7845Z"
        fill="#8D1B34"
      />
    </g>
    <defs>
      <clipPath id="clip0_427_1285">
        <rect
          width="101.201"
          height="13.5696"
          fill="white"
          transform="translate(0.804688 0.517578)"
        />
      </clipPath>
    </defs>
  </svg>
);
