import { Swiper } from 'swiper/react';

import { Button } from '@pulse-web-ui/button';
import { mixins, styled, typography } from '@pulse-web-ui/theme';

export const StyledCardContainer = styled.div`
  width: 100%;
  ${mixins.flex({ inline: false, wrap: false })}
  gap: 8px;
  margin-bottom: 24px;
`;

export const StyledCardBox = styled.div<{
  active?: boolean;
}>`
  width: 250px;
  position: relative;
  box-sizing: border-box;
  border: ${({ active, theme }) =>
    !active && `1px solid ${theme.colors.neutral[30]}`};
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  padding: 20px 16px;
  width: 100%;
  margin-top: 16px;
  @media screen and (min-width: 767px) {
    width: 251px;
    margin-top: 0;
  }
`;

export const StyledCardTitle = styled.div`
  ${typography.medium18}
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledCardSubTitle = styled.div`
  ${typography.regular14}
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 20px;
  margin-bottom: 9px;
`;

export const StyledTarifCardList = styled.ul<{
  isLast?: boolean;
}>`
  margin: 0;
  padding: 0;
  border-bottom: ${({ isLast, theme }) =>
    isLast ? 'none' : `1px solid ${theme.colors.neutral[30]}`};
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;

export const StyledCardItem = styled.li`
  ${mixins.flex}
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  list-style: none;
  margin-top: 13px;
  margin-bottom: 9px;
  height: 40px;
`;

export const StyledCardListKey = styled.span`
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledCardListValue = styled.span<{
  isIcon?: boolean;
}>`
  ${typography.medium16}
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0;
`;

export const StyledButtonBox = styled.div`
  margin-top: 18px;
  ${typography.medium18}
`;

export const StyledPriceBox = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledSwiperPriceValue = styled.span`
  ${typography.medium24}
  margin-top: 21px;
`;

export const StyledBorder = styled.div`
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid ${({ theme }) => theme.colors.surface.brand};
`;

export const StyledSwiperReact = styled(Swiper)`
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.neutral[30]};
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }

  .swiper-slide {
    height: 557px;
  }
`;
