import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Store } from '@src/store';

export const useSportChoosingFormSchema = () => {
  const {
    state: {
      stateFormNSSport: { sportKinds },
    },
  } = useContext(Store);
  const { t } = useTranslation();
  const maxCount = useMemo(() => sportKinds?.maxCount, [sportKinds?.maxCount]);

  return useMemo(
    () =>
      yup.object().shape({
        sportKinds: yup
          .array()
          .nullable()
          .test(
            'minCount',
            t('SPORT_FORM:errors.selectLeastOneSport') || '',
            (value) => !!value?.length
          )
          .test(
            'maxCount',
            t('SPORT_FORM:errors.maxSports', { maxCount }) || '',
            (value) =>
              !!maxCount && !!value?.length && value?.length <= maxCount
          ),
      }),
    [maxCount]
  );
};
