export enum AvailableSubscriptionActionTypes {
  SetRisks = 'AVAILABLE_SUBSCRIPTION/SET_RISKS',
  SetSubobjects = 'AVAILABLE_SUBSCRIPTION/SET_SUBOBJECTS',
  SetInsuranceSum = 'AVAILABLE_SUBSCRIPTION/SET_INSURANCE_SUM',
  SetInsuranceSumPerMonth = 'AVAILABLE_SUBSCRIPTION/SET_INSURANCE_SUM_PER_MONTH',
  SetInsurancePromoSumPerMonth = 'AVAILABLE_SUBSCRIPTION/SET_INSURANCE_PROMO_SUM_PER_MONTH',
  SetNextRoute = 'AVAILABLE_SUBSCRIPTION/SET_NEXT_ROUTE',
  SetPrevRoute = 'AVAILABLE_SUBSCRIPTION/SET_PREV_ROUTE',
  SetOrderData = 'AVAILABLE_SUBSCRIPTION/SET_ORDER_DATA',
  ResetState = 'AVAILABLE_SUBSCRIPTION/RESET_STATE',
}
