import { PulseLogo, RgsLogo } from '../icons';
import { HeaderContainer, LogoLink, Separator } from './header.styles';

export const Header = () => (
  <HeaderContainer>
    <LogoLink href="https://pulse.insure">
      <PulseLogo />
      <Separator />
      <RgsLogo />
    </LogoLink>
  </HeaderContainer>
);
