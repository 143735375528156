/* eslint-disable indent */
import type { FC } from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { SelectorCard } from '@pulse-web-ui/selector-card';

import {
  AdaptiveListWrapper,
  Container,
  FormLabel,
  FormSub,
  LinkContainer,
  RiskWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { FlatSteps, analyticEvents } from '@src/constants';
import { useHandlePressKey, useNextStep } from '@src/hooks';
import { IFLFlatActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode } from '@src/types';
import { addTestAttribute, evenRisk, sendFlatProgress } from '@src/utils';

import { useIflFlatDraft } from './hooks';

export const FormInsuranceAdditionalRisks: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLFlat,
      stateUser: { preset },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();

  useEffect(() => {
    if (!preset) {
      sendAnalyticEvent(analyticEvents.iflStep3AddRisk);
      sendAnalyticEvent(analyticEvents.iflFlatStep3Risk);
    }
  }, []);

  const handleKeyPressEnter = () => {
    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: true,
    });
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !stateFormIFLFlat.risks?.some(evenRisk) || false,
    });

    return stateFormIFLFlat.risks?.some(evenRisk) || false;
  }, [stateFormIFLFlat.risks]);

  useNextStep(validatePage);
  useIflFlatDraft();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    sendFlatProgress(FlatSteps.ADDITIONAL_RISKS);
  }, []);

  const handleRiskClick = (code: string) => {
    setStepUpdated();

    dispatch({
      type: IFLFlatActionTypes.SetInsuranceSubproducts,
      payload: undefined,
    });

    const newRisks = stateFormIFLFlat.risks?.map((item) => {
      if (item.code === code) {
        if (preset) {
          sendAnalyticEvent(analyticEvents.iflFlatPresetChangeAddRisk);
        } else {
          sendAnalyticEvent(
            item.active
              ? analyticEvents.deselectOptionRealty
              : analyticEvents.selectOptionRealty,
            {
              option: item.name,
            }
          );
        }

        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });

    dispatch({
      type: IFLFlatActionTypes.SetRisks,
      payload: newRisks,
    });

    dispatch({
      type: IFLFlatActionTypes.SetGetSubobjectsRefetchUid,
      payload: uuidv4(),
    });

    dispatch({
      type: IFLFlatActionTypes.SetEmptyDependencies,
      payload: [],
    });

    dispatch({
      type: IFLFlatActionTypes.SetFieldWithEmptyDependencies,
      payload: undefined,
    });
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: IFLFlatActionTypes.SetRisksPage,
      payload: 'secondary',
    });
    navigate('/flat-risk-descriptions');
  };

  return (
    <Container>
      <FormLabel>{t('COMMON:headers.youChooseWePayAdditional')}</FormLabel>
      <FormSub>{t('COMMON:hints.connectWish')}</FormSub>
      <AdaptiveListWrapper>
        {(!stateFormIFLFlat.risks || stateFormIFLFlat.risks?.length === 0) &&
          t('COMMON:errors.noData')}
        {stateFormIFLFlat.risks?.map((item) => {
          return item.binding === true ? (
            <></>
          ) : (
            <RiskWrapper>
              <SelectorCard
                key={`${item.code}_${item.name}`}
                id={item.code}
                mobileFullWidth
                name={item.code}
                label={item.name}
                description={item.description}
                checked={item.active}
                readOnly={!item.switchability}
                onClick={() => handleRiskClick(item.code)}
                testId="apartment-form-additional-risks"
              />
            </RiskWrapper>
          );
        })}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutAdditionalRisks') || ''}
          {...addTestAttribute('apartment-form-additional-risks-button-about')}
        />
      </LinkContainer>
    </Container>
  );
};
