/* eslint-disable indent */
import { Checkbox } from '@pulse-web-ui/checkbox';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { css, media, mixins, styled, typography } from '@pulse-web-ui/theme';

import { AdaptiveColumns } from '@src/components';

import { AuthClientRegistrationSubProps } from './authorization.types';

type AuthPageWrappereProps = {
  mobileTextAlign?: string;
};
type AuthPageTitleProps = {
  mobileTextAlign?: string;
  desktopTextAlign?: string;
  mb?: number;
  mbMobile?: number;
};

type AuthPagFullWidthWrapperProps = {
  marginBottom?: boolean;
};

export const StyledDatePicker = styled(Datepicker)`
  > input::placeholder {
    color: rgba(33, 20, 90, 0.4);
  }
`;

export const AuthPageTitle = styled.h2<AuthPageTitleProps>`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;
  padding: 0px;
  box-sizing: border-box;

  ${({ mobileTextAlign }) =>
    mobileTextAlign &&
    css`
      text-align: ${mobileTextAlign};
    `}

  ${({ mbMobile }) =>
    mbMobile &&
    css`
      margin-bottom: ${mbMobile}px;
    `}

  ${media.desktop} {
    ${({ mb }: any) => css`
      margin-bottom: ${mb >= 0 ? `${mb}px` : '24px'};
    `};
    ${({ desktopTextAlign }) =>
      desktopTextAlign &&
      css`
        text-align: ${desktopTextAlign};
      `}
  }
`;

export const AuthPageWrapper = styled.div<AuthPageWrappereProps>`
  box-sizing: border-box;
  width: 100%;
  ${mixins.flex({ horizontal: false, alignItems: 'center' })}
  text-align: ${({ mobileTextAlign }) =>
    mobileTextAlign ? mobileTextAlign : 'left'};
`;

export const StyledFooter = styled.div<{
  width?: number;
}>`
  ${mixins.flex({ horizontal: false, gap: 24 })}
  margin-top: 32px;
  box-sizing: border-box;
  width: 100%;

  ${media.desktop} {
    ${mixins.flex({ alignItems: 'center' })}
    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
      `}
  }
`;

export const StyledCheckboxContainer = styled.div<{
  gridStep?: number;
  marginBottom?: number;
}>`
  ${(props) => mixins.flex({ horizontal: false, gap: props.gridStep || 20 })}
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

export const StyledAdaptiveColumns = styled(AdaptiveColumns)`
  margin-bottom: 32px;
`;

export const StyledCheckBoxMiddleName = styled(Checkbox)``;

export const AuthPagFullWidthWrapper = styled.div<AuthPagFullWidthWrapperProps>`
  width: 100%;
  box-sizing: border-box;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 32px;
      ${media.desktop} {
        margin-bottom: 40px;
      }
    `}
`;

export const AuthSub = styled.div<AuthClientRegistrationSubProps>`
  ${typography.regular16}
  width: 100%;
  ${(props) => (props.align ? `text-align: ${props.align};` : '')}
  color: ${({ isError, theme }) =>
    isError ? theme.colors.text.error : theme.colors.text.secondary};
  margin: 8px 0 32px;
`;

export const FullWidthWrapper = styled.div<{
  marginBottom?: boolean;
}>`
  width: 100%;
  box-sizing: border-box;

  ${media.desktop} {
    display: grid;
    grid-template-columns: 2fr 2fr;
    row-gap: 10px;
    column-gap: 12px;
  }

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 32px;
      ${media.desktop} {
        margin-bottom: 40px;
      }
    `}
`;

export const IdentificationPageInput = styled.div`
  width: 100%;
  margin-bottom: 32px;

  ${media.desktop} {
    max-width: 400px;
  }
`;

export const StyledRadioCardGroupBox = styled.div`
  div {
    display: flex;
    justify-content: flex-start;
  }
`;
export const StyledMessage = styled.span`
  line-height: 20px;
`;
