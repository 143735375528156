/* eslint-disable indent */
import { StoreActions } from '@src/store';

import {
  PetsTelevetPlusActionTypes,
  initPetsTelevetPlusStoreState,
} from './index';
import type { PetsTelevetPlusStoreState } from './index';

export type PetsTelevetPlusStoreReducer = (
  state: PetsTelevetPlusStoreState,
  action: StoreActions
) => PetsTelevetPlusStoreState;

export const petsTelevetPlusStoreReducer: PetsTelevetPlusStoreReducer = (
  state,
  action
) => {
  switch (action?.type) {
    case PetsTelevetPlusActionTypes.SET_SELECTED_PRODUCT_DATA:
      return {
        ...state,
        selectedIProduct: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_RISKS:
      return {
        ...state,
        risks: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_RISKS_PAGE:
      return {
        ...state,
        risksPage: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_GET_PRICES:
      return {
        ...state,
        getPrices: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_GET_PRICES_FULL:
      return {
        ...state,
        getPricesFull: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_SELECTED_DURATION:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_INSURANCE_SUM:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_PROMO_CODE_APPLYED:
      return {
        ...state,
        promoCodeApplyed: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_PETS:
      return {
        ...state,
        pets: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_SELECTED_PET_DATA:
      return {
        ...state,
        selectedPetData: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_SUBLIMITS:
      return {
        ...state,
        sublimits: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_CURRENT_SUB_LIMITS:
      return {
        ...state,
        currentSubLimits: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_SELECTED_PET_KIND_NAME:
      return {
        ...state,
        selectedPetKindName: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_CURRENT_SUM:
      return {
        ...state,
        currentSum: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_CURRENT_SUM_MIN_LIMIT:
      return {
        ...state,
        currentSumMinLimit: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_CURRENT_SUM_MAX_LIMIT:
      return {
        ...state,
        currentSumMaxLimit: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_CURRENT_SUM_ERR:
      return {
        ...state,
        currentSumErr: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_SELECTED_ID:
      return {
        ...state,
        selectedId: action.payload,
      };
    case PetsTelevetPlusActionTypes.UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_INSURANCE_PROGRAMS:
      return {
        ...state,
        insurancePrograms: action.payload,
      };
    case PetsTelevetPlusActionTypes.SET_INSURANCE_PRICE:
      return {
        ...state,
        insurancePrice: action.payload,
      };
    case PetsTelevetPlusActionTypes.RESET_STATE:
      return {
        ...initPetsTelevetPlusStoreState,
      };
    default:
      return state;
  }
};
