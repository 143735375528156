/* eslint-disable quotes */
import { toDate, utcToZonedTime } from 'date-fns-tz';

import { getValidTimeZone } from './get-valid-timezone';

// TODO: Нужен ресерч на предмет объединения createDateWithTimezone, convertDateByTimeZone, convertDateFormatInTimeZone
export const createDateWithTimezone = (date: Date | string): Date => {
  const d = typeof date === 'string' ? toDate(date) : date;
  return utcToZonedTime(d || new Date(), getValidTimeZone(d));
};
