import * as yup from 'yup';

import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

export const getSmartAddressSchema = (dadaValue?: DaDataAddressSuggestion) =>
  yup.object().shape({
    region: yup.string().required('INSURANCE_FORMS:errors.specifyRegion'),
    city: yup
      .string()
      .required('INSURANCE_FORMS:errors.specifyLocality')
      .test(
        'isSelectedCity',
        'COMMON:hints.noSuitableAddress',
        () => !!(dadaValue?.data.city || dadaValue?.data.settlement)
      ),
    house: yup.string().required('INSURANCE_FORMS:errors.specifyHouse'),
  });
