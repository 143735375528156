import i18n from '@src/i18n/config';

export const getInsuranceSumDescription = (duration: string) => {
  if (duration === 'P3M') {
    return i18n.t('AVAILABLE_SUBSCRIPTION:labels.insuranceCostThreeMonths');
  }

  if (duration === 'P1Y') {
    return i18n.t('AVAILABLE_SUBSCRIPTION:labels.insuranceCostPerYear');
  }

  return i18n.t('AVAILABLE_SUBSCRIPTION:labels.insuranceCostPerMonth');
};
