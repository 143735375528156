import { useContext, useEffect } from 'react';

import { Product } from '@src/constants';
import {
  IFLHouseActionTypes,
  PetsActionTypes,
  SportNSActionTypes,
  Store,
} from '@src/store';
import { FlatPresetData, IFLFlatActionTypes } from '@src/store/ifl-flat';
import { HousePresetData } from '@src/store/ifl-house';
import { PetsPresetData } from '@src/store/pets';
import { SportNSPresetData } from '@src/store/sport';

import { usePresetsRequest } from './use-presets-request';

export const useSetPresetDataInStore = () => {
  const {
    state: {
      stateUser: { preset, selectedProduct: product },
    },
    dispatch,
  } = useContext(Store);

  const { isLoading, error, res, refetch, status } = usePresetsRequest<
    PetsPresetData | HousePresetData | SportNSPresetData | FlatPresetData
  >('getPreset', `/${product}/${preset}`);

  useEffect(() => {
    if (!!product && !!preset) {
      refetch();
    }
  }, [product, preset]);

  useEffect(() => {
    if (!isLoading && res) {
      if (product === Product.PETS) {
        dispatch({
          type: PetsActionTypes.SetPresetData,
          payload: res as PetsPresetData,
        });
      }

      if (product === Product.HOUSE) {
        dispatch({
          type: IFLHouseActionTypes.SetPresetData,
          payload: res as HousePresetData,
        });
      }

      if (product === Product.SPORT) {
        dispatch({
          type: SportNSActionTypes.SetPresetData,
          payload: res as SportNSPresetData,
        });
      }

      if (product === Product.APARTMENT) {
        dispatch({
          type: IFLFlatActionTypes.SetPresetData,
          payload: res as FlatPresetData,
        });
      }
    }
  }, [isLoading, res]);

  return {
    res,
    isLoading,
    isError: !!error,
    refetch,
    status,
  };
};
