/* eslint-disable indent */
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

const sendEvent = (event: string, presetEvent: string, isPreset: boolean) => {
  isPreset ? sendAnalyticEvent(presetEvent) : sendAnalyticEvent(event);
};

export const handleRegistrationStart = (
  product: Product,
  isPreset: boolean
) => {
  switch (product) {
    case Product.APARTMENT:
      sendAnalyticEvent(analyticEvents.authFlatRegistrationStart);
      break;
    case Product.HOUSE:
      sendEvent(
        analyticEvents.authHouseRegistrationStart,
        analyticEvents.authHouseRegistrationStartPreset,
        isPreset
      );
      break;
    case Product.NS:
      sendAnalyticEvent(analyticEvents.authNSRegistrationStart);
      break;
    case Product.PETS:
      sendEvent(
        analyticEvents.authPetRegistrationStart,
        analyticEvents.authPetRegistrationStartPreset,
        isPreset
      );
      break;
    case Product.SPORT:
      sendAnalyticEvent(analyticEvents.authSportRegistrationStart);
      break;
    case Product.MITE:
      sendAnalyticEvent(analyticEvents.authMiteRegistrationStart);
      break;
    case Product.PETS_TELEVET_PLUS:
      sendAnalyticEvent(analyticEvents.petTelRegStart);
      break;
    default:
      break;
  }
};
