import i18n from '@src/i18n/config';
import type { Risk } from '@src/types';
import { convertDateFormatInTimeZone } from '@src/utils';

import type { TemporaryFranchise } from '../pets-form.types';

export const setTemporaryFranchiseHint = (
  risksTemporaryFranchise: TemporaryFranchise[],
  risks: Risk[],
  policyStartDate: Date
) => {
  const temporaryFranchise = risksTemporaryFranchise
    .filter(({ minDate }) => new Date(minDate) > new Date(policyStartDate))
    .map((risk) => {
      const riskName = risks?.find(({ code }) => risk.code === code)?.name;
      const minDateFormatted = convertDateFormatInTimeZone(
        new Date(risk.minDate)
      )
        .toLocaleDateString('ru-RU', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
        .replace(' г.', '');

      return i18n.t('BEST_TO_PAY:hints.riskWillStart', {
        risk: riskName,
        date: minDateFormatted,
      });
    });

  if (temporaryFranchise.length) {
    localStorage.setItem(
      'petsTemporaryFranchise',
      temporaryFranchise.join('. ')
    );
  } else {
    localStorage.removeItem('petsTemporaryFranchise');
  }
};
