import {
  OrderRequestData,
  SubmitData,
  SubmitDataTelevetPlus,
  SumbitDataMite,
} from '@src/types';

import type {
  OrderDataTypes,
  OrderInitDataTypes,
  OrderSubmitDataTypes,
} from './order-store.types';

export interface OrderStoreState {
  order?: OrderDataTypes;
  orderSubmitData?: OrderSubmitDataTypes;
  orderInitData?: OrderInitDataTypes;
  orderPageTitle?: string;
  orderRequestData?:
    | OrderRequestData
    | SubmitData
    | SumbitDataMite
    | SubmitDataTelevetPlus;
  cachedOrderRequestData?:
    | OrderRequestData
    | SubmitData
    | SumbitDataMite
    | SubmitDataTelevetPlus;
  themeValue?: string;
  subscriptionId?: string;
  completePaymentData?: Pick<
    OrderSubmitDataTypes,
    'subscriptionId' | 'billId' | 'contractId' | 'premiumAndDelta'
  >;
  hasSpecialOffersConsent: boolean;
}

export const initOrderStoreState: OrderStoreState = {
  order: undefined,
  orderSubmitData: undefined,
  orderInitData: undefined,
  orderPageTitle: undefined,
  orderRequestData: undefined,
  cachedOrderRequestData: undefined,
  themeValue: undefined,
  hasSpecialOffersConsent: false,
};
