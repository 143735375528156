/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import { useCallback, useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { MoneyRangeInput } from '@pulse-web-ui/range-input';

import {
  AdaptiveHeadersWrapper,
  AdaptivePerMonthFooter,
  AdaptiveSliderListWrapper,
  Container,
  HeaderAdaptive1WithSubTitle,
  HeaderAdaptive5,
  IflSumPerPeriodContainer,
  LinkContainer,
  Skeleton,
  SumWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { DEFAULT_FIAS_ID, analyticEvents } from '@src/constants';
import {
  GlobalErrorInfo,
  IflSumPerPeriod,
  IflSumPerPeriodError,
  TotalSum,
} from '@src/features';
import {
  useHandlePressKey,
  useMergeSublimits,
  useNextStep,
  useRequest,
} from '@src/hooks';
import { Store } from '@src/store';
import { IFLHouseActionTypes } from '@src/store/ifl-house';
import { WizardActionTypes } from '@src/store/wizard';
import type {
  GetPrices,
  InsuranceSubProduct,
  InsuranceSubproducts,
  Price,
  SelectedDuration,
} from '@src/types';
import { KeyCode } from '@src/types';
import {
  addTestAttribute,
  currencyRuLocaleWithoutFraction,
  numBetween,
  numFormat,
  subObjectConverter,
} from '@src/utils';

import { useIflHouseDraft } from './hooks';
import { createSubProductsFromPresetData } from './utils/create-subproducts-from-preset-data';

let schemaObj: Record<string, any> = {};
let defaults: Record<string, number> = {};

export const FormInsuranceSum: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLHouse: {
        insuranceSubproducts,
        selectedIProduct,
        selectedRegion,
        risks,
        selectedBuildingMaterialCode,
        fieldWithEmptyDependencies,
        emptyDependencies,
        dadaValue,
        getSubobjectsRefetchUid,
        currentSum,
        currentSumMinLimit,
        currentSumMaxLimit,
        currentSumErr,
        presetData,
        promoCodeApplyed,
        isSpecialTermsInsurance,
      },
      stateWizard: { currentStep },
      stateAuth: { authTokens },
      stateUser: { preset },
    },
    dispatch,
  } = useContext(Store);
  const [schema, setSchema] = useState<any>();
  const navigate = useNavigate();
  const [fieldHasBeenChanged, setFieldHasBeenChanged] = useState(false);

  useEffect(() => {
    if (!preset) {
      sendAnalyticEvent(analyticEvents.iflStep4Сoverage);
      sendAnalyticEvent(analyticEvents.iflHouseStep4Сoverage);
    }

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: (errors as any)?.inner?.reduce(
              (allErrors: any, currentError: ValidationError) => ({
                ...allErrors,
                [currentError.path as string]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const getSumPerPeriod = useCallback((resSumPerPeriod: GetPrices) => {
    return resSumPerPeriod?.defaultContractDuration
      ? resSumPerPeriod.prices.find(
          (item: Price) =>
            item.duration === resSumPerPeriod?.defaultContractDuration
        )
      : resSumPerPeriod?.prices[0];
  }, []);

  const sumPerPeriodTitle = (value: SelectedDuration) => {
    switch (value) {
      case 'P1M':
        return t('COMMON:labels.insuranceCostPerMonth');
      case 'P3M':
        return t('COMMON:labels.costInQuarter');
      case 'P1Y':
        return t('COMMON:labels.costInYear');
      default:
        return t('COMMON:labels.insuranceCostPerMonth');
    }
  };

  const resolver = useYupValidationResolver(schema);

  const {
    control,
    formState: { isValid, errors },
    reset,
    trigger,
    getValues,
  } = useForm<Record<string, number>>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...defaults,
    },
  });

  const handleKeyPressEnter = () => {
    if (currentSumErr || !isValid) {
      return;
    }

    if (!!presetData) {
      const lastStepForMain = 2;

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: lastStepForMain,
      });

      sendAnalyticEvent(analyticEvents.iflHousePresetConfigExit);
      navigate(-1);
    } else {
      const isPageValidated = validatePage();
      if (isPageValidated) {
        dispatch({
          type: WizardActionTypes.UpdateWantNextStep,
          payload: true,
        });
      }
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [currentSum, isValid]);

  const updateInsuranseSubproducts = async (code: string, value: number) => {
    setStepUpdated();
    const valid = await trigger(code);

    if (!valid) {
      return;
    }

    const updatedInsuranceSubproducts: InsuranceSubproducts = {
      ...insuranceSubproducts,
      subObjects:
        insuranceSubproducts?.subObjects.map((item) => {
          if (item.code === code) {
            return {
              ...item,
              defaultInsuranceSum: String(value),
            };
          }

          return item;
        }) || [],
      subObjectsDependencyScheme:
        insuranceSubproducts?.subObjectsDependencyScheme
          ? { ...insuranceSubproducts.subObjectsDependencyScheme }
          : {},
    };

    dispatch({
      type: IFLHouseActionTypes.SetInsuranceSubproducts,
      payload: updatedInsuranceSubproducts,
    });

    let totalSum = 0;

    updatedInsuranceSubproducts.subObjects.forEach(
      (subobject: any) => (totalSum += Number(subobject.defaultInsuranceSum))
    );

    dispatch({
      type: IFLHouseActionTypes.SetCurrentSum,
      payload: totalSum,
    });

    if (
      value > 0 &&
      !!updatedInsuranceSubproducts?.subObjectsDependencyScheme?.[code]
    ) {
      const values = getValues();

      if (
        updatedInsuranceSubproducts.subObjectsDependencyScheme[code].every(
          (dependencyName) => !values[dependencyName]
        )
      ) {
        dispatch({
          type: IFLHouseActionTypes.SetEmptyDependencies,
          payload: updatedInsuranceSubproducts.subObjectsDependencyScheme[code],
        });
        dispatch({
          type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
          payload: code,
        });
      }
    }

    if (
      value === 0 &&
      !!updatedInsuranceSubproducts?.subObjectsDependencyScheme
    ) {
      const values = getValues();

      const { fieldNameWithEmptyDependencies, emptyDependencyNames } =
        Object.keys(
          updatedInsuranceSubproducts?.subObjectsDependencyScheme
        ).reduce(
          (
            acc: {
              fieldNameWithEmptyDependencies?: string;
              emptyDependencyNames: string[];
            },
            fieldName
          ) => {
            const dependencies =
              updatedInsuranceSubproducts?.subObjectsDependencyScheme?.[
                fieldName
              ];

            if (
              values[fieldName] > 0 &&
              !!dependencies?.length &&
              dependencies.some((dependencyName) => dependencyName === code) &&
              dependencies.every((dependencyName) => !values[dependencyName])
            ) {
              return {
                fieldNameWithEmptyDependencies: fieldName,
                emptyDependencyNames: dependencies,
              };
            }

            return acc;
          },
          {
            fieldNameWithEmptyDependencies: undefined,
            emptyDependencyNames: [],
          }
        );

      if (!!emptyDependencyNames && !!fieldNameWithEmptyDependencies?.length) {
        dispatch({
          type: IFLHouseActionTypes.SetEmptyDependencies,
          payload: emptyDependencyNames,
        });
        dispatch({
          type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
          payload: fieldNameWithEmptyDependencies,
        });
      }
    }

    if (
      (value === 0 && code === fieldWithEmptyDependencies) ||
      (value > 0 && emptyDependencies?.some((name) => name === code))
    ) {
      dispatch({
        type: IFLHouseActionTypes.SetEmptyDependencies,
        payload: [],
      });
      dispatch({
        type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
        payload: undefined,
      });
    }
  };

  const { isLoading, error, res, refetch } = useRequest<InsuranceSubproducts>(
    'formIFLHooksGetSubproducts',
    'post',
    '/v1/references/get-subobjects',
    {
      productCode: selectedIProduct?.code,
      region: selectedRegion?.region,
      risks: risksArray,
      buildingMaterial: selectedBuildingMaterialCode,
    },
    [
      risks,
      getSubobjectsRefetchUid,
      selectedBuildingMaterialCode,
      selectedIProduct?.code,
      selectedRegion?.region,
    ],
    false,
    authTokens?.authorization?.accessToken
  );

  const {
    isFetching: isFetchingSumPerPeriod,
    error: errorSumPerPeriod,
    res: resSumPerPeriod,
    refetch: refetchSumPerPeriod,
  } = useRequest(
    'formIFLHouseGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      risks: risksArray,
      productCode: selectedIProduct?.code,
      insuranceSum: Number(currentSum),
      personProperties: {
        subObjects: subObjectConverter(insuranceSubproducts?.subObjects || []),
        address: {
          regionCode: selectedRegion?.region,
          address: dadaValue?.value || '',
          addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
        },
      },
      buildingMaterial: selectedBuildingMaterialCode,
      returnMinDuration: false,
      promoCode: promoCodeApplyed ? promoCodeApplyed : undefined,
    },
    [
      selectedIProduct?.code,
      currentSum,
      insuranceSubproducts?.subObjects,
      selectedRegion?.region,
      selectedBuildingMaterialCode,
      dadaValue?.value,
      dadaValue?.data?.fias_id,
      promoCodeApplyed,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  const validatePage = useCallback(
    () =>
      (!!fieldWithEmptyDependencies && !!emptyDependencies?.length) ||
      !currentSumErr,
    [currentSumErr, fieldWithEmptyDependencies, emptyDependencies]
  );

  const handleRefetch = () => {
    if (error) refetch();
    if (errorSumPerPeriod) refetchSumPerPeriod();
  };

  const descriptionNavHandler = () => {
    navigate('/house-sum-descriptions');
  };

  useNextStep(validatePage);
  useIflHouseDraft();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (insuranceSubproducts?.subObjects) {
        refetchSumPerPeriod();
        if (!!presetData && fieldHasBeenChanged) {
          sendAnalyticEvent(analyticEvents.iflHousePresetChangeCoverage);
          setFieldHasBeenChanged(false);
        }
      }
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [insuranceSubproducts?.subObjects]);

  useEffect(() => {
    if (
      !insuranceSubproducts ||
      !insuranceSubproducts.totalMaxLimit ||
      !insuranceSubproducts.totalMinLimit
    ) {
      refetch();
      return;
    }

    let totalSum = 0;

    dispatch({
      type: IFLHouseActionTypes.SetCurrentSumMinLimit,
      payload:
        insuranceSubproducts.totalMinLimit ??
        selectedIProduct?.minProductLimit ??
        '0',
    });
    dispatch({
      type: IFLHouseActionTypes.SetCurrentSumMaxLimit,
      payload:
        insuranceSubproducts.totalMaxLimit ??
        selectedIProduct?.maxProductLimit ??
        '0',
    });

    defaults = {};
    schemaObj = {};

    insuranceSubproducts.subObjects.forEach((subobject) => {
      schemaObj[subobject.code] = yup
        .number()
        .typeError(t('COMMON:errors.mustEnterNumber') || '')
        .min(
          Number(subobject.minLimit),
          `${t(
            'COMMON:errors.amountNotLess'
          )} ${currencyRuLocaleWithoutFraction(Number(subobject.minLimit))} ₽`
        )
        .max(
          Number(subobject.maxLimit),
          `${t('COMMON:errors.amountNoMore')} ${currencyRuLocaleWithoutFraction(
            Number(subobject.maxLimit)
          )} ₽`
        )
        .integer(t('COMMON:errors.sumMustBeIntegerValue') || '')
        .required()
        .test(
          'In diapason?',
          t('COMMON:errors.amountCannotBeFrom') || '',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (value) => value! > 49_999 || value! < 1
        );

      totalSum += Number(subobject.defaultInsuranceSum);
      defaults[subobject.code] = Number(subobject.defaultInsuranceSum);
    });

    const newSchema = yup.object(schemaObj);
    setSchema(newSchema);

    dispatch({
      type: IFLHouseActionTypes.SetCurrentSum,
      payload: totalSum,
    });
    reset(defaults);
  }, [insuranceSubproducts]);

  const handleMergedSublimits = useCallback(
    (subObjects: InsuranceSubProduct[]) => {
      if (!isSpecialTermsInsurance) {
        dispatch({
          type: IFLHouseActionTypes.SetInsuranceSubproducts,
          payload: {
            ...insuranceSubproducts,
            subObjects,
          },
        });
      }
    },
    [isSpecialTermsInsurance]
  );

  useMergeSublimits<InsuranceSubProduct>({
    firstSublimits: res?.subObjects,
    secondSublimits: insuranceSubproducts?.subObjects,
    handleMergedSublimits,
  });

  useEffect(() => {
    if (!isLoading && res && !insuranceSubproducts) {
      const payload = !!presetData
        ? createSubProductsFromPresetData(presetData, res)
        : res;

      dispatch({
        type: IFLHouseActionTypes.SetInsuranceSubproducts,
        payload,
      });

      dispatch({
        type: IFLHouseActionTypes.SetCurrentSumMinLimit,
        payload: res.totalMinLimit ?? selectedIProduct?.minProductLimit ?? '0',
      });
      dispatch({
        type: IFLHouseActionTypes.SetCurrentSumMaxLimit,
        payload: res.totalMaxLimit ?? selectedIProduct?.maxProductLimit ?? '0',
      });

      if (isSpecialTermsInsurance) {
        dispatch({
          type: IFLHouseActionTypes.SetIsSpecialTermsInsurance,
          payload: false,
        });
      }
    }
  }, [
    isLoading,
    res,
    insuranceSubproducts,
    authTokens?.authorization?.accessToken,
  ]);

  useEffect(() => {
    if (currentSum > 0) {
      dispatch({
        type: IFLHouseActionTypes.SetInsuranceSum,
        payload: Number(currentSum),
      });
    }
  }, [currentSum]);

  useEffect(() => {
    dispatch({
      type: IFLHouseActionTypes.SetCurrentSumErr,
      payload: !numBetween(
        currentSum,
        Number(currentSumMinLimit),
        Number(currentSumMaxLimit),
        true
      ),
    });
  }, [currentSum, currentSumMinLimit, currentSumMaxLimit]);

  useEffect(() => {
    trigger();

    const disableNav =
      (!!fieldWithEmptyDependencies && !!emptyDependencies?.length) ||
      currentSumErr ||
      !isValid ||
      isFetchingSumPerPeriod;

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: disableNav,
    });
  }, [
    currentSumErr,
    currentStep,
    fieldWithEmptyDependencies,
    emptyDependencies,
    isValid,
    isFetchingSumPerPeriod,
  ]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: isLoading,
    });
  }, [isLoading]);

  if (isLoading) return <Skeleton />;

  if (error && error?.response?.data?.code === 'USER_DATA_ERROR') {
    navigate('/score-error');
  }

  if (
    errorSumPerPeriod &&
    errorSumPerPeriod?.response?.data?.code === 'OBJECT_DATA_ERROR'
  ) {
    dispatch({
      type: IFLHouseActionTypes.SetIsObjectDataError,
      payload: true,
    });

    navigate('/score-error');
  }

  if (
    (error && error?.response?.data?.code !== 'USER_DATA_ERROR') ||
    (errorSumPerPeriod &&
      errorSumPerPeriod?.response?.data?.code !== 'VALIDATION_ERROR' &&
      errorSumPerPeriod?.response?.data?.code !== 'BUSINESS_ERROR' &&
      errorSumPerPeriod?.response?.data?.code !== 'TECHNICAL_ERROR' &&
      errorSumPerPeriod?.response?.data?.code !== 'OBJECT_DATA_ERROR')
  )
    return <GlobalErrorInfo retryHandler={handleRefetch} />;

  if (!isLoading && !res)
    return (
      <div>
        <h3>{t('COMMON:labels.listEmpty')}</h3>
      </div>
    );

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <HeaderAdaptive1WithSubTitle>
          {t('COMMON:headers.selectCoverageAmounts')}
        </HeaderAdaptive1WithSubTitle>
        <HeaderAdaptive5>
          {t('COMMON:hints.determineMaximumPayout')}
        </HeaderAdaptive5>
      </AdaptiveHeadersWrapper>
      <AdaptiveSliderListWrapper>
        {insuranceSubproducts?.subObjects.map((item: InsuranceSubProduct) => (
          <Controller
            key={`${item.code}-key`}
            name={item.code}
            control={control}
            render={({ field }) => (
              <MoneyRangeInput
                hasTooltip
                isPriceFormatted
                id={item.code}
                label={item.name}
                value={Number(item.defaultInsuranceSum) || 0}
                min={Number(item.minLimit) || 0}
                max={Number(item.maxLimit) || 0}
                step={Number(item.step) || 0}
                tooltipText={item.tooltip || item.description}
                onChange={(value: number) => {
                  field.onChange(value);
                  setFieldHasBeenChanged(true);
                  updateInsuranseSubproducts(item.code, value);
                  !preset &&
                    sendAnalyticEvent(analyticEvents.coverageSumChangeRealty, {
                      coverage_type: item.name,
                    });
                }}
                error={
                  (errors && !!errors[item.code]?.message) ||
                  item.code === fieldWithEmptyDependencies
                }
                helperErrorText={
                  (item.code === fieldWithEmptyDependencies
                    ? t('COMMON:hints.civilLiabilityInsured', {
                        name: item.name,
                        canNotBe:
                          item.name === t('COMMON:hints.civilResponsibility')
                            ? t('COMMON:hints.cannotBeInsured')
                            : t('COMMON:hints.theyCannotBeInsured'),
                      }) || ''
                    : undefined) ||
                  (errors && errors[item.code]?.message)
                }
                warning={emptyDependencies?.some((name) => name === item.code)}
                testId={`house-form-${item.code}`}
              />
            )}
          />
        ))}
      </AdaptiveSliderListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutCategories') || ''}
          {...addTestAttribute('house-form-button-about')}
        />
      </LinkContainer>
      {insuranceSubproducts?.subObjects && (
        <AdaptivePerMonthFooter>
          <SumWrapper>
            <TotalSum
              title={`${t('COMMON:labels.coverAmount')}:`}
              totalSum={numFormat(currentSum)}
              isError={currentSumErr}
              subtitleText={
                `${t('COMMON:labels.from')} ${numFormat(
                  Number(currentSumMinLimit)
                )} ₽ ` +
                `${t('COMMON:labels.to')} ${numFormat(
                  Number(currentSumMaxLimit)
                )} ₽`
              }
            />
          </SumWrapper>
          <SumWrapper>
            <IflSumPerPeriodContainer>
              {(currentSum || currentSum === 0) &&
                insuranceSubproducts?.subObjects && (
                  <IflSumPerPeriod
                    title={
                      resSumPerPeriod?.defaultContractDuration
                        ? sumPerPeriodTitle(
                            resSumPerPeriod.defaultContractDuration
                          )
                        : undefined
                    }
                    isLoading={isFetchingSumPerPeriod || !!errorSumPerPeriod}
                    disabled={isLoading}
                    sumPerPeriod={Math.ceil(
                      Number(getSumPerPeriod(resSumPerPeriod)?.premiumAndDelta)
                    )}
                    sumPromoPerPeriod={
                      resSumPerPeriod?.prices[0]?.premiumAndDeltaPromo &&
                      Math.ceil(
                        Number(
                          getSumPerPeriod(resSumPerPeriod)?.premiumAndDeltaPromo
                        )
                      )
                    }
                  />
                )}
              {errorSumPerPeriod && (
                <IflSumPerPeriodError
                  errorResponse={errorSumPerPeriod}
                  currentSumErr={
                    currentSumErr &&
                    (currentSum < Number(currentSumMinLimit) ? 'less' : 'gt')
                  }
                />
              )}
            </IflSumPerPeriodContainer>
          </SumWrapper>
        </AdaptivePerMonthFooter>
      )}
    </Container>
  );
};
