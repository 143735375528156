import { AxiosError } from 'axios';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { sendAnalyticEvent } from '@src/components/web-analytic';
import { analyticEvents } from '@src/constants';
import { Store } from '@src/store';

const excludedRequests = [
  '/v3/subscription/sport/get-prices',
  '/v1/subscription/get-prices',
];

const excludedStatusCodes = [0];

export const useSendCrashAnalytics = () => {
  const { pathname } = useLocation();
  const {
    state: {
      stateUser: { selectedProduct },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  return ({
    response,
    code,
    message,
    config,
  }: AxiosError<{ code: string }>) => {
    const isExcludedRequest = excludedRequests.includes(config.url || '');
    if (isExcludedRequest) return;
    const isExcludedStatusCode = excludedStatusCodes.includes(
      Number(response?.status)
    );
    if (isExcludedStatusCode) return;

    sendAnalyticEvent(analyticEvents.globalError, {
      additionalInfo: {
        pathname,
        currentStep,
        selectedProduct,
      },
      message,
      code,
      name,
      request: {
        url: `${config.baseURL}${config.url}`,
        headers: config.headers,
        data: config.data,
        method: config.method,
      },
      response: {
        status: response?.status,
        data: response?.data,
        headers: response?.headers,
      },
    });
  };
};
