import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import { SmartAddressBounds, splitAddress } from '../index';

export const getFormDefaultValues = (
  region: string,
  dadaValue?: DaDataAddressSuggestion
) => ({
  region,
  city: splitAddress(SmartAddressBounds.CITY, dadaValue)?.value || '',
  street: splitAddress(SmartAddressBounds.STREET, dadaValue)?.value || '',
  house: splitAddress(SmartAddressBounds.HOUSE, dadaValue)?.value || '',
  flat: splitAddress(SmartAddressBounds.FLAT, dadaValue)?.value || '',
});
