import {
  FlatIcon,
  HouseIcon,
  MiteIcon,
  NsIcon,
  PetsIcon,
  SportIcon,
} from '@src/components/icons';
import { Product } from '@src/constants';
import i18n from '@src/i18n/config';

export const getProductItems = () => [
  {
    label: i18n.t('COMMON:labels.apartmentProtection'),
    id: 'id1',
    value: Product.APARTMENT,
    icon: <FlatIcon />,
  },
  {
    label: i18n.t('COMMON:labels.homeProtection'),
    id: 'id2',
    value: Product.HOUSE,
    icon: <HouseIcon />,
  },
  {
    label: i18n.t('COMMON:labels.injuryProtection'),
    id: 'id3',
    value: Product.NS,
    icon: <NsIcon />,
  },
  {
    label: i18n.t('COMMON:labels.petProtection'),
    id: 'id4',
    value: Product.PETS,
    icon: <PetsIcon />,
  },
  {
    label: i18n.t('COMMON:labels.sportsProtection'),
    id: 'id5',
    value: Product.SPORT,
    icon: <SportIcon />,
  },
  {
    label: i18n.t('COMMON:labels.miteProtection'),
    id: 'id6',
    value: Product.MITE,
    icon: <MiteIcon />,
  },
];
