import { InsuranceConvertedSubProduct, InsuranceSubProduct } from '@src/types';

export const subObjectConverter = (subobjects: InsuranceSubProduct[]) => {
  const objects: InsuranceConvertedSubProduct[] = [];

  subobjects.map((item) => {
    if (Number(item.defaultInsuranceSum) !== 0) {
      objects.push({
        code: item.code,
        insuranceSum: item.defaultInsuranceSum,
      });
    }
  });
  return objects;
};
