/* eslint-disable prettier/prettier */
import { PAYMENT_SUCCESS_ROUTE, Product } from '@src/constants';
import { PetsTelevetPlusActionTypes, Store } from '@src/store';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Skeleton } from '@src/components';
import { GlobalErrorInfo } from '@src/features';
import { useStartPath } from '@src/hooks';
import { SubscriptionChoice } from '@src/pages/authorization';

export const RoutesNavigate = () => {
  const { dispatch } = useContext(Store);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, hasError, path } = useStartPath();

  const prevPath = location.state?.prevPath;
  const selectedProduct = localStorage.getItem('selectedProduct');
  const isResetTelevetState = prevPath === PAYMENT_SUCCESS_ROUTE
    && selectedProduct === Product.PETS_TELEVET_PLUS;

  if (isLoading) {
    return <Skeleton />;
  }

  if (path) {
    if (isResetTelevetState) {
      dispatch({ type: PetsTelevetPlusActionTypes.RESET_STATE });
    }

    navigate(path);
  }

  if (hasError) {
    return <GlobalErrorInfo />;
  }

  return <SubscriptionChoice />;
};
