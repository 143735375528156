/* eslint-disable indent */
import { useContext, useEffect, useMemo } from 'react';

import {
  TELEVET_KIND,
  TELEVET_PROGRAM_KEY,
} from '@src/pages/pets-televet-plus/constants';
import { OrderActionTypes, Store } from '@src/store';
import { SubmitDataTelevetPlus } from '@src/types';
import { prepareDate } from '@src/utils';

export const useOrderData = () => {
  const {
    state: {
      statePetsTelevetPlus: {
        selectedIProduct,
        promoCode,
        insurancePrograms,
        insurancePrice,
        selectedPetData,
        selectedDate,
      },
      stateUser: { agentLogin },
    },
    dispatch,
  } = useContext(Store);

  const activeRisksCode = useMemo(
    () =>
      insurancePrograms?.antimitePrograms
        ?.find((program) => program.insuranceProgram === TELEVET_PROGRAM_KEY)
        ?.risks?.map((item) => item.code),
    [insurancePrograms?.antimitePrograms?.length]
  );

  const orderData: SubmitDataTelevetPlus = useMemo(
    () => ({
      baseParameters: {
        effectiveSince: prepareDate(selectedDate),
        productCode: selectedIProduct?.code || '',
        ...(agentLogin && { agentLogin }),
        contractDuration: 'P1Y',
        risks: activeRisksCode || [],
        ...(promoCode &&
          insurancePrice?.isSuccessfulPromo && { promoCode: promoCode }),
      },
      productProperty: {
        program: TELEVET_PROGRAM_KEY,
        kind: TELEVET_KIND,
        insuranceObjects: [
          {
            objectType: selectedPetData?.kind || '',
            age: selectedPetData?.age || '',
            breed: selectedPetData?.breed || '',
            gender: selectedPetData?.gender || '',
            name: selectedPetData?.name || '',
          },
        ],
      },
    }),
    [
      selectedIProduct,
      promoCode,
      insurancePrice?.isSuccessfulPromo,
      activeRisksCode,
      selectedPetData,
      selectedDate,
    ]
  );

  useEffect(
    () => () => {
      dispatch({
        type: OrderActionTypes.SetOrderRequestData,
        payload: orderData,
      });
    },
    [orderData]
  );
};
