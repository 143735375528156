import { TokenInputOption } from '@pulse-web-ui/token-input';

import { Category } from '@src/store';

export const getSportOptions = (categories: Category[]) =>
  categories.reduce(
    (acc: TokenInputOption[], cur) => [
      ...acc,
      ...cur.sports.map(({ code, name }) => ({ label: name, value: code })),
    ],
    []
  );
