import { FC, useContext } from 'react';

import { FioSuggestions } from '@pulse-web-ui/dadata';

import { useQueryFunction } from '@src/hooks';
import { Store } from '@src/store';

import type { StyledDadataFioSuggestionsProps } from './styled-dadata-fio-suggestions.types';

export const StyledDadataFioSuggestions: FC<StyledDadataFioSuggestionsProps> = (
  props
) => {
  const {
    state: {
      stateAuth: { authTokens },
    },
  } = useContext(Store);

  const queryFn = useQueryFunction(
    'post',
    '/v3/dadata/suggest/fio',
    undefined,
    authTokens?.authorization?.accessToken
  );

  return (
    <FioSuggestions
      isCapitalizeFirst
      delay={250}
      minChars={1}
      customRequest={queryFn}
      {...props}
    />
  );
};
