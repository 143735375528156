import type { FC } from 'react';
import { memo, useReducer } from 'react';

import { Store, initStoreState, storeReducer } from './index';

export const StoreProvider: FC = memo(({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, {
    ...initStoreState,
  });

  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
});
