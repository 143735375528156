import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UnicodeEnum } from '@src/constants';

import {
  TotalSumSubtitle,
  TotalSumTitle,
  TotalSumValue,
  TotalSumWrapper,
} from './total-sum.styles';
import { TotalSumProps } from './total-sum.types';

export const TotalSum: FC<TotalSumProps> = ({
  totalSum,
  isError,
  subtitleText,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <TotalSumWrapper isError={isError}>
      <TotalSumTitle>
        {title || `${t('COMMON:labels.coverAmount')}:`}
      </TotalSumTitle>
      <TotalSumValue>
        {totalSum} {UnicodeEnum.RUB}
      </TotalSumValue>
      <TotalSumSubtitle isError={isError}>{subtitleText}</TotalSumSubtitle>
    </TotalSumWrapper>
  );
};
