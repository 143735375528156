import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { petNameRegEx } from '@src/constants';
import { Store } from '@src/store';

export const usePetsDataSchema = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormPets: { pets },
    },
  } = useContext(Store);

  const petsWithBreeds = useMemo(
    () =>
      pets?.filter(({ breeds }) => !!breeds?.length)?.map(({ kind }) => kind),
    [pets]
  );
  const requiredField = t('COMMON:errors.requiredField') || '';
  return useMemo(
    () =>
      yup.object().shape({
        kind: yup.string().nullable().required(requiredField),
        breed: yup
          .string()
          .nullable()
          .when('kind', (kind, schema) =>
            !kind || !!petsWithBreeds.find((petKind) => petKind === kind)
              ? schema.required(requiredField)
              : schema
          ),
        gender: yup.string().nullable().required(requiredField),
        name: yup
          .string()
          .nullable()
          .required(requiredField)
          .test('validName', t('COMMON:errors.upToCharacters') || '', (value) =>
            petNameRegEx.test(value!)
          ),
        age: yup.string().nullable().required(requiredField),
      }),
    [petsWithBreeds]
  );
};
