export enum IFLFlatActionTypes {
  SetSelectedIProduct = 'IFL_FLAT/SET_SELECTED_I_PRODUCT',
  SetSelectedRegion = 'IFL_FLAT/SET_SELECTED_REGION',
  SetRegions = 'IFL_FLAT/SET_REGIONS',
  SetRisks = 'IFL_FLAT/SET_RISKS',
  SetInsuranceSubproducts = 'IFL_FLAT/SET_INSURANCE_SUBPRODUCTS',
  SetInsuranceSum = 'IFL_FLAT/SET_INSURANCE_SUM',
  SetGetPrices = 'IFL_FLAT/SET_GET_PRICES',
  SetGetPricesFull = 'IFL_FLAT/SET_GET_PRICES_FULL',
  SetSelectedDuration = 'IFL_FLAT/SET_SELECTED_DURATION',
  SetSelectedDate = 'IFL_FLAT/SET_SELECTED_DATE',
  SetDadaValue = 'IFL_FLAT/SET_DADA_VALUE',
  SetPromoCode = 'IFL_FLAT/SET_PROMO_CODE',
  SetPromoCodeApplyed = 'IFL_FLAT/SET_PROMO_CODE_APPLYED',
  SetRisksPage = 'IFL_FLAT/SET_RISKS_PAGE',
  SetFieldWithEmptyDependencies = 'IFL_FLAT/SET_FIELD_WITH_EMPTY_DEPENDENCIES',
  SetEmptyDependencies = 'IFL_FLAT/SET_EMPTY_DEPENDENCIES',
  SetIsObjectDataError = 'IFL_FLAT/SET_IS_OBJECT_DATA_ERROR',
  SetGetSubobjectsRefetchUid = 'IFL_FLAT/SET_GET_SUB_OBJECTS_REFETCH_UID',
  SetNewSubobjects = 'IFL_FLAT/SET_NEW_SUBOBJECT',
  SetCurrentSum = 'IFL_FLAT/SET_CURRENT_SUM',
  SetCurrentSumMinLimit = 'IFL_FLAT/SET_CURRENT_SUM_MIN_LIMIT',
  SetCurrentSumMaxLimit = 'IFL_FLAT/SET_CURRENT_SUM_MAX_LIMIT',
  SetCurrentSumErr = 'IFL_FLAT/SET_CURRENT_SUM_ERR',
  SetSelectedId = 'IFL_FLAT/SET_SELECTED_ID',
  SetIsSelectedCustomStreet = 'IFL_FLAT/SET_IS_SELECTED_CUSTOM_STREET',
  SetIsSelectedCustomHouse = 'IFL_FLAT/SET_IS_SELECTED_CUSTOM_HOUSE',
  SetIsEmptyStreetSuggestions = 'IFL_FLAT/SET_IS_EMPTY_STREET_SUGGESTIONS',
  SetIsEmptyHouseSuggestions = 'IFL_FLAT/SET_IS_EMPTY_HOUSE_SUGGESTIONS',
  UpdateState = 'IFL_FLAT/UPDATE_STATE',
  SetIsSpecialTermsInsurance = 'IFL_FLAT/SET_IS_SPECIAL_TERMS_INSURANCE',
  SetIsHouseNotFound = 'IFL_FLAT/SET_IS_HOUSE_NOT_FOUND',
  SetPresetData = 'IFL_FLAT/SET_PRESET_DATA',
}

export interface SubObject {
  code: string;
  defaultInsuranceSum: string;
}

export interface FlatPresetData {
  risks: string[];
  subObjects: SubObject[];
}
