import type { FC, ReactNode } from 'react';

import { Content, ContentFooter, ContentWrapper } from './base-layout.styles';

type BaseLayoutProps = {
  children: ReactNode;
  minHeight?: number;
  verticalAlignment?: boolean;
  footer?: ReactNode;
};

export const BaseLayout: FC<BaseLayoutProps> = ({
  children,
  minHeight,
  verticalAlignment,
  footer,
}) => (
  <ContentWrapper minHeight={minHeight || 748}>
    <Content verticalAlignment={verticalAlignment}>{children}</Content>
    {footer && <ContentFooter>{footer}</ContentFooter>}
  </ContentWrapper>
);
