import { UserLockedFieldsTypes, UserProfileTypes } from '@src/types';

export enum UserActionTypes {
  SetIsPhoneFromQuery = 'USER/SET_IS_PHONE_QUERY',
  SetPrivateOffice = 'USER/SET_PRIVATE_OFFICE',
  SetProfile = 'USER/SET_PROFILE',
  SetRegUtm = 'USER/SET_REG_UTM',
  SetCid = 'USER/SET_CID',
  SetUserRegistrationData = 'USER/SET_USER_REGISTRATION_DATA',
  SetCachedPersonalInfoData = 'USER/SET_CACHED_PERSONAL_INFO_DATA',
  SetCachedPhoneNumber = 'USER/SET_CACHED_PHONE_NUMBER',
  SetIsPureSubscription = 'USER/SET_IS_PURE_SUBSCRIPTION',
  SetCachedRegistrationFormData = 'USER/SET_CACHED_REGISTRATION_FORM_DATA',
  SetIsSubscriptionExists = 'USER/SET_IS_SUBSCRIPTION_EXISTS',
  SetSelectedProduct = 'USER/SET_SELECTED_PRODUCT',
  SetCachedProduct = 'USER/SET_CACHED_PRODUCT',
  SetCachedDadaValue = 'USER/SET_CACHED_DADA_VALUE',
  SetAuthenticationRefetchUid = 'USER/SET_AUTHENTICATION_REFETCH_UID',
  SetIsScrinning = 'USER/SET_IS_SCRINNING',
  SetPreset = 'USER/SET_PRESET',
  SetPromoCodeFailedMessage = 'USER/SET_PROMOCODE_FAILED_MESSAGE',
  SetClientCategoryFromUrl = 'USER/SET_CLIENT_CATEGORY_FROM_URL',
  SetRegionFiasIdFromUrl = 'USER/SET_REGION_FIAS_ID_FROM_URL',
  SetIsScoringSuccess = 'USER/SET_IS_SCORING_SUCCESS',
  SetAgentLogin = 'USER/SET_AGENT_LOGIN',
}

export type UserProfileDataTypes = {
  hasSubscriptions?: boolean;
  lockedFields?: UserLockedFieldsTypes;
  paymentInstruments?: [];
  profile: UserProfileTypes;
};

export type RegUtm = {
  utm_source?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_media_source?: string;
  campaign?: string;
  media_source?: string;
  wm_id?: string;
};

export type PersonalInfoDataProps = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  userPoliciesCode003?: boolean;
  userPoliciesCode002?: boolean;
  useMiddleName?: boolean;
  birthDay?: string;
};

export type RegistrationFormProps = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  userPoliciesCode003?: boolean;
  userPoliciesCode002?: boolean;
  useMiddleName?: boolean;
};
