import { useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from '@src/common-components/button';
import { PageContent } from '@src/common-components/container';
import { PageSubTitle, PageTitle } from '@src/common-components/headers/header';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { DONT_RECOGNIZE_YOU_TITLE, analyticEvents } from '@src/constants';
import { NewSupportBlock } from '@src/features';
import { useHandlePressKey } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { Store, WizardActionTypes } from '@src/store';
import { KeyCode } from '@src/types';

export const AuthorizeFailPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: {
      stateAuth: { authorizeFailState },
      stateUser: { preset },
    },
    dispatch,
  } = useContext(Store);
  const pageTitle =
    authorizeFailState?.title || t('COMMON:errors.authorizationError');
  const pageSubTitle =
    authorizeFailState?.subtitle || t('AUTH:hints.tryToLoginAgain');

  useEffect(() => {
    if (pageTitle === t(DONT_RECOGNIZE_YOU_TITLE)) {
      sendAnalyticEvent(
        preset
          ? analyticEvents.enterPresetSurnameFail
          : analyticEvents.enterSurnameFail
      );
    }
  }, []);

  const handleButtonClick = () => {
    const stepTo = authorizeFailState?.step;

    if (stepTo) {
      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: stepTo || 0,
      });
    }

    navigate(authorizeFailState?.refRoute || '/personal-info');
  };

  useHandlePressKey(KeyCode.ENTER, handleButtonClick);

  return (
    <BaseLayout>
      <PageContent>
        <PageTitle>{pageTitle}</PageTitle>
        <PageSubTitle marginBottom={32}>{pageSubTitle}</PageSubTitle>
        <StyledButton
          variant="primary"
          label={t('AUTH:labels.tryAgain') || ''}
          onClick={handleButtonClick}
        />
        <NewSupportBlock />
      </PageContent>
    </BaseLayout>
  );
};
