import { FRACTION_TWO } from '@src/constants';
import type { Price } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  getFormPeriodLabels,
} from '@src/utils';

import {
  BaseValue,
  Label,
  PromoValue,
  SubLabel,
} from './radio-label-render.styles';

export const RadioLabelRender = ({
  premiumAndDelta,
  premiumAndDeltaPromo,
  duration,
}: Price) => {
  const formPeriodLabels = getFormPeriodLabels();

  if (!!premiumAndDeltaPromo) {
    return (
      <Label>
        <BaseValue>
          {currencyRuLocaleWithoutFraction(
            Number(premiumAndDelta),
            FRACTION_TWO
          )}{' '}
          ₽
        </BaseValue>{' '}
        <PromoValue>{` ${currencyRuLocaleWithoutFraction(
          Number(premiumAndDeltaPromo),
          FRACTION_TWO
        )} ${formPeriodLabels.get(duration)?.label}`}</PromoValue>
        <br />
        <SubLabel>
          {`${
            formPeriodLabels.get(duration)?.promoSubLabel
          } ${currencyRuLocaleWithoutFraction(
            Number(premiumAndDelta),
            FRACTION_TWO
          )}`}
          &nbsp;₽
        </SubLabel>
      </Label>
    );
  }
  return (
    <>{`${currencyRuLocaleWithoutFraction(
      Number(premiumAndDelta),
      FRACTION_TWO
    )} ${formPeriodLabels.get(duration)?.label}`}</>
  );
};
