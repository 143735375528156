import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@pulse-web-ui/spinner';

import { UnicodeEnum } from '@src/constants';
import { currencyRuLocaleWithoutFraction } from '@src/utils';

import {
  StyledSpinnerWrapper,
  SumPerPeriodBaseValue,
  SumPerPeriodDescWrapper,
  SumPerPeriodPromoDesc,
  SumPerPeriodPromoValue,
  SumPerPeriodPromoWrapper,
  SumPerPeriodTitle,
  SumPerPeriodValue,
  SumPerPeriodWrapper,
} from './ifl-sum-per-period.styles';
import type { SumPerPeriodProps } from './ifl-sum-per-period.types';

export const SumPerPeriod: FC<SumPerPeriodProps> = ({
  isLoading,
  disabled,
  sumPerPeriod,
  sumPromoPerPeriod,
  sumPerPeriodDesc,
  title,
  description,
  isError,
}) => {
  const { t } = useTranslation();

  return (
    <SumPerPeriodWrapper disabled={disabled} isError={isError}>
      <SumPerPeriodTitle>
        {title || t('COMMON:labels.insuranceCostLabel')}
      </SumPerPeriodTitle>
      {isLoading || !sumPerPeriod ? (
        <StyledSpinnerWrapper>
          <Spinner inverse={false} />
        </StyledSpinnerWrapper>
      ) : sumPromoPerPeriod ? (
        <>
          <SumPerPeriodPromoWrapper>
            <SumPerPeriodPromoValue>
              {`${currencyRuLocaleWithoutFraction(sumPromoPerPeriod)} ${
                UnicodeEnum.RUB
              }`}
            </SumPerPeriodPromoValue>
            {sumPerPeriodDesc && (
              <SumPerPeriodPromoDesc> {sumPerPeriodDesc}</SumPerPeriodPromoDesc>
            )}
            <SumPerPeriodBaseValue>
              {currencyRuLocaleWithoutFraction(sumPerPeriod)} {UnicodeEnum.RUB}
            </SumPerPeriodBaseValue>
          </SumPerPeriodPromoWrapper>
          {description && (
            <SumPerPeriodDescWrapper>{description}</SumPerPeriodDescWrapper>
          )}
        </>
      ) : (
        <>
          <SumPerPeriodValue>
            {`${currencyRuLocaleWithoutFraction(sumPerPeriod)} ${
              UnicodeEnum.RUB
            } ${sumPerPeriodDesc ?? ''}`}
          </SumPerPeriodValue>
          {description && (
            <SumPerPeriodDescWrapper>{description}</SumPerPeriodDescWrapper>
          )}
        </>
      )}
    </SumPerPeriodWrapper>
  );
};
