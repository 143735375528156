import { media, mixins, styled } from '@pulse-web-ui/theme';

export const ContentWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 16 })}
  margin-bottom: 32px;
`;

export const PromoWrapper = styled.div`
  ${mixins.flex({ gap: 8 })};
  width: 100%;
`;

export const PromoTextInput = styled.div`
  flex: auto;
`;

export const PromoSubmitButton = styled.div`
  width: 100%;
  ${media.desktop} {
    width: auto;
  }
  div div {
    transition: 300ms ease all;
  }
`;
