import i18n from '@src/i18n/config';
import { SelectedDuration } from '@src/types';

const periodCardSubtitles = {
  P1M: i18n.t('COMMON:labels.oneMonth'),
  P3M: i18n.t('COMMON:labels.threeMonths'),
  P1Y: i18n.t('COMMON:labels.oneYear'),
};

export const getPaymentPeriodCardSubtitle = (duration: SelectedDuration) => {
  return periodCardSubtitles[duration];
};
