/* eslint-disable indent */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FRACTION_TWO } from '@src/constants';
import { Store } from '@src/store';
import { OrderItemType, OrderItemValueType } from '@src/types';
import {
  convertDateByTimeZone,
  currencyRuLocaleWithoutFraction,
  getFormPeriodLabels,
  numFormat,
} from '@src/utils';

export const useOrderDataFormatting = () => {
  const { t } = useTranslation(['COMMON']);
  const formPeriodLabels = getFormPeriodLabels();

  const {
    state: {
      stateFormNS: {
        selectedDate,
        risks,
        selectedMainRisks,
        selectedAdditionalRisks,
        insuranceSum,
        getPrices,
        selectedDuration,
        promoCode,
      },
      stateAuth: { displayPhoneNumber },
      stateUser: { promoCodeFailedMessage },
    },
  } = useContext(Store);

  const selectedPrice = getPrices?.prices.filter(
    (item) => item.duration === selectedDuration
  )[0]?.premiumAndDelta;

  const selectedPromoPrice = getPrices?.prices.filter(
    (item) => item.duration === selectedDuration
  )[0]?.premiumAndDeltaPromo;

  let label = `${
    selectedPrice
      ? currencyRuLocaleWithoutFraction(Number(selectedPrice), FRACTION_TWO)
      : ''
  } ${formPeriodLabels.get(selectedDuration!)?.label}`;

  let subLabel = '';
  const isSuccessfulPromo = getPrices?.isSuccessfulPromo;

  if (!!selectedPromoPrice) {
    label = `${currencyRuLocaleWithoutFraction(
      Number(selectedPromoPrice),
      FRACTION_TWO
    )} ${formPeriodLabels.get(selectedDuration!)?.label}`;
    subLabel =
      `${
        formPeriodLabels.get(selectedDuration!)?.promoSubLabel
      } ${currencyRuLocaleWithoutFraction(
        Number(selectedPrice),
        FRACTION_TWO
      )} ₽` || '';
  }

  const checkForFailedPromoCode = () => {
    if (promoCodeFailedMessage) {
      return t('COMMON:errors.promoCodeNotApplied');
    }

    return isSuccessfulPromo === undefined ||
      isSuccessfulPromo === null ||
      promoCode === '' ||
      (isSuccessfulPromo && !selectedPromoPrice)
      ? ''
      : t('errors.promoCodeNotApplied') || '';
  };

  const orderArray: OrderItemType[] = [
    {
      type: OrderItemValueType.SIMPLE,
      title: t('labels.periodOfPayment'),
      value: label,
      secondsValue: subLabel,
      successValue:
        isSuccessfulPromo && selectedPromoPrice
          ? t('success.promoCodeApplied') || ''
          : '',
      failedValue: checkForFailedPromoCode(),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('labels.mainRisks'),
      value: String(
        risks
          ?.filter((item) => selectedMainRisks?.includes(item.code))
          ?.map((item) => item.name)
          ?.join(', ')
      ),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('labels.additionalRisks'),
      value: selectedAdditionalRisks?.length
        ? String(
            risks
              ?.filter((item) => selectedAdditionalRisks?.includes(item.code))
              ?.map((item) => item.name)
              ?.join(', ')
          )
        : t('placeholders.notSelected'),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('labels.coverAmount'),
      value: `${numFormat(insuranceSum || 0)} ₽`,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('labels.subscriptionWillStart'),
      value: `с ${convertDateByTimeZone(
        new Date(selectedDate || new Date())
      )?.toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}`,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('labels.phoneOfNumber'),
      value: displayPhoneNumber,
    },
  ];

  return {
    orderArray,
  };
};
