import { memo, useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OrderActionTypes, Store } from '@src/store';

export const ThemeInterceptor: FC = memo(() => {
  const { dispatch } = useContext(Store);
  const [searchParams] = useSearchParams();
  const themeUrl = searchParams.get('personalization') || undefined;

  useEffect(() => {
    if (themeUrl) {
      dispatch({
        type: OrderActionTypes.SetThemeValue,
        payload: themeUrl,
      });
    }
  }, []);

  return null;
});
