import React from 'react';

import { numFormat } from '@src/utils';

import {
  PaymentPeriodCardActualValue,
  PaymentPeriodCardOldValue,
  PaymentPeriodCardTitleWrapper,
} from './payment-period-card-title.styles';

interface Props {
  premiumAndDelta: string;
  premiumAndDeltaPromo: string | null;
}

export const PaymentPeriodCardTitle = ({
  premiumAndDelta,
  premiumAndDeltaPromo,
}: Props) => {
  return (
    <PaymentPeriodCardTitleWrapper>
      <PaymentPeriodCardActualValue withDiscount={!!premiumAndDeltaPromo}>
        {!!premiumAndDeltaPromo
          ? numFormat(Math.ceil(Number(premiumAndDeltaPromo)))
          : numFormat(Math.ceil(Number(premiumAndDelta)))}{' '}
        ₽
      </PaymentPeriodCardActualValue>
      {!!premiumAndDeltaPromo && (
        <PaymentPeriodCardOldValue>
          {numFormat(Math.ceil(Number(premiumAndDelta)))} ₽
        </PaymentPeriodCardOldValue>
      )}
    </PaymentPeriodCardTitleWrapper>
  );
};
