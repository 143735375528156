import { css, mixins, styled, typography } from '@pulse-web-ui/theme';

export const StyledBox = styled.div`
  text-align: center;
  margin-top: 122px;
  max-width: 680px;
  ${mixins.flex({
    verticalAlignment: 'center',
  })};
  flex-direction: column;
`;

export const StyledText = styled.p<{
  isObjectDataError?: boolean;
}>`
  ${typography.medium24}
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 26px 0 32px 0;
  max-width: 440px;

  ${({ isObjectDataError }) =>
    isObjectDataError &&
    css`
      margin: 26px 0 16px 0;
    `}
`;

export const StyledErrorSubText = styled.p`
  ${typography.regular18}
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledButtonBox = styled.div<{
  isObjectDataError?: boolean;
}>`
  ${({ isObjectDataError }) =>
    isObjectDataError &&
    css`
      margin-top: 32px;
    `}
`;
