import React from 'react';
import { useTranslation } from 'react-i18next';

import outdatedLinkIcon from '../icons/outdated-link-icon.svg';
import { StyledStateBlock } from './outdated-link-error.styles';

export const OutdatedLinkError = () => {
  const { t } = useTranslation();

  return (
    <StyledStateBlock
      icon={outdatedLinkIcon}
      title={t('COMMON:errors.linkIsOutdated')}
    />
  );
};
