/* eslint-disable indent */
import { Datepicker } from '@pulse-web-ui/datepicker';
import { NumericInput } from '@pulse-web-ui/numeric-input';
import { media, styled, typography } from '@pulse-web-ui/theme';

export const StyledText = styled.p`
  ${typography.regular16};
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledDateTextMobile = styled.div<{ isAgent?: boolean }>`
  ${media.desktop} {
    display: none;
  }

  ${typography.regular14}
  display: block;
  line-height: 20px;
  color: ${({ theme, isAgent }) =>
    isAgent ? theme.colors.text.warning : theme.colors.text.secondary};
  margin-top: 8px;
  margin-right: 16px;
  margin-bottom: 24px;
  max-width: 348px;
  height: 40px;
`;

export const StyledDatepickerBox = styled.div`
  ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledDatepicker = styled(Datepicker)`
  width: 100%;

  ${media.desktop} {
    width: 350px;
  }
`;

export const StyledDateText = styled.div<{ isAgent?: boolean }>`
  ${media.desktop} {
    ${typography.regular14}
    line-height: 20px !important;
    color: ${({ theme, isAgent }) =>
      isAgent ? theme.colors.text.warning : theme.colors.text.secondary};
    margin-top: 8px;
    margin-right: 16px;
    margin-bottom: 32px;
    max-width: 348px;
    height: 40px;
    display: block;
  }

  display: none;
`;

export const ContinueBtnInfoLink = styled.div`
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 32px;

  a {
    color: ${({ theme }) =>
      theme.common?.a?.color
        ? theme.common.a.color
        : theme.colors.text.secondary};
    text-decoration: ${({ theme }) =>
      theme.common?.a?.decoration ? theme.common.a.decoration : 'underline'};
  }
`;

export const StyledNumericInput = styled(NumericInput)`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const RiskWrapper = styled.div`
  width: 100%;

  ${media.desktop} {
    width: calc(50% - 4px);
  }
`;

export const StyledCheckboxBox = styled.div`
  margin-bottom: 24px;
`;
