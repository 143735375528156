import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const TotalSumWrapper = styled.div<{ isError: boolean }>`
  border: ${({ isError, theme }) =>
    isError
      ? `2px solid ${theme.colors.accent.error.normal}`
      : `2px solid ${theme.colors.surface.background}`};
  border-radius: ${({ theme }) => theme.common.radius12};
  padding: 16px 24px 12px;
  box-sizing: border-box;
  width: 100%;
  background: ${({ isError, theme }) =>
    isError
      ? theme.colors.accent.error.faded
      : theme.colors.surface.background};

  ${mixins.flex({ horizontal: false })}
  ${media.desktop} {
    min-height: 114px;
    padding: 16px 24px;
    min-height: 114px;
  }
`;

export const TotalSumValue = styled.div`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
`;

export const TotalSumTitle = styled.div`
  ${typography.regular16}
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
`;

export const TotalSumSubtitle = styled.div<{ isError: boolean }>`
  ${typography.regular16}
  line-height: 24px;
  color: ${({ isError, theme }) =>
    isError ? theme.colors.text.error : theme.colors.text.secondary};
  letter-spacing: 0.02em;
`;
