import type { FC } from 'react';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Skeleton } from '@src/components';
import { insuranceProductsCode } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNavigateToVip, useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { DraftActionTypes, IFLHouseActionTypes, Store } from '@src/store';
import type { IFLHouseStoreState } from '@src/store';
import type { InsuranceProductType } from '@src/types';
import { cleartDraft, getDraft } from '@src/utils';

import { IFLHouseForm } from './ifl-house-form';

export const IFLHousePage: FC = () => {
  const {
    state: {
      stateDraft: { checkDraft },
      stateUser: { selectedProduct, profile },
      stateFormIFLHouse: { presetData },
      stateAuthFlow: { authFlowParams },
    },
    dispatch,
  } = useContext(Store);
  const [isWait, setIsWait] = useState(true);
  const { shouldNavigateToVip } = useNavigateToVip();

  const hasProfile = authFlowParams?.partnerAuthRegType === 'base' || !!profile;

  const {
    isLoading: productIsLoading,
    error: productError,
    res: resProduct,
    refetch: productRefetch,
  } = useRequest(
    'formIFLHouseGetInsuranceProduct',
    'post',
    '/v1/references/get-insurance-product',
    {
      productCode: insuranceProductsCode.house,
    }
  );

  //TODO: временный флаг принудительной для очистки старых черновиков.
  useLayoutEffect(() => {
    if (!productIsLoading && resProduct) {
      const data: InsuranceProductType = { ...resProduct };

      dispatch({
        type: IFLHouseActionTypes.SetSelectedIProduct,
        payload: data,
      });
    }
  }, [productIsLoading, resProduct]);

  useEffect(() => {
    dispatch({
      type: DraftActionTypes.SetCheckDraft,
      payload: !presetData,
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('selectedDraftHouse')) {
      cleartDraft();
      localStorage.setItem('selectedDraftHouse', 'true');
      setIsWait(false);
    } else if (
      checkDraft &&
      selectedProduct &&
      !productIsLoading &&
      resProduct &&
      hasProfile
    ) {
      dispatch({
        type: DraftActionTypes.SetCheckDraft,
        payload: false,
      });
      const { resData } = getDraft();

      if (resData) {
        if (resData.common.productType === resProduct.code) {
          const state = resData.state as unknown as IFLHouseStoreState;

          dispatch({
            type: IFLHouseActionTypes.SetSelectedIProduct,
            payload: state?.selectedIProduct,
          });
          dispatch({
            type: IFLHouseActionTypes.SetSelectedRegion,
            payload: state?.selectedRegion,
          });
          dispatch({
            type: IFLHouseActionTypes.SetRegions,
            payload: state?.regions,
          });
          dispatch({
            type: IFLHouseActionTypes.SetRisks,
            payload: state?.risks,
          });
          dispatch({
            type: IFLHouseActionTypes.SetInsuranceSubproducts,
            payload: state?.insuranceSubproducts,
          });
          dispatch({
            type: IFLHouseActionTypes.SetInsuranceSum,
            payload: state?.insuranceSum || 20000,
          });
          dispatch({
            type: IFLHouseActionTypes.SetGetPrices,
            payload: state?.getPrices,
          });
          dispatch({
            type: IFLHouseActionTypes.SetSelectedDuration,
            payload: state?.selectedDuration || null,
          });
          dispatch({
            type: IFLHouseActionTypes.SetSelectedDate,
            payload:
              state?.selectedDate && state?.selectedDate !== null
                ? new Date(state?.selectedDate as unknown as string)
                : null,
          });
          dispatch({
            type: IFLHouseActionTypes.SetDadaValue,
            payload: state?.dadaValue,
          });
          dispatch({
            type: IFLHouseActionTypes.SetPromoCode,
            payload: state?.promoCode,
          });
          dispatch({
            type: IFLHouseActionTypes.SetRisksPage,
            payload: state?.risksPage || 'primary',
          });
          dispatch({
            type: IFLHouseActionTypes.SetMaterials,
            payload: state?.materials,
          });
          dispatch({
            type: IFLHouseActionTypes.SetSelectedBuildingMaterialCode,
            payload: state?.selectedBuildingMaterialCode,
          });
          dispatch({
            type: IFLHouseActionTypes.SetFieldWithEmptyDependencies,
            payload: state?.fieldWithEmptyDependencies,
          });
          dispatch({
            type: IFLHouseActionTypes.SetEmptyDependencies,
            payload: state?.emptyDependencies,
          });
          dispatch({
            type: IFLHouseActionTypes.SetIsObjectDataError,
            payload: state?.isObjectDataError,
          });
          dispatch({
            type: IFLHouseActionTypes.SetGetSubobjectsRefetchUid,
            payload: state?.getSubobjectsRefetchUid,
          });
          dispatch({
            type: IFLHouseActionTypes.SetCurrentSum,
            payload: state?.currentSum || 2_000_000,
          });
          dispatch({
            type: IFLHouseActionTypes.SetCurrentSumMinLimit,
            payload: state?.currentSumMinLimit || '0',
          });
          dispatch({
            type: IFLHouseActionTypes.SetCurrentSumMaxLimit,
            payload: state?.currentSumMaxLimit || '0',
          });
          dispatch({
            type: IFLHouseActionTypes.SetCurrentSumErr,
            payload: state?.currentSumErr || false,
          });
          dispatch({
            type: IFLHouseActionTypes.SetSelectedId,
            payload: state?.selectedId,
          });
          dispatch({
            type: IFLHouseActionTypes.SetIsSelectedCustomStreet,
            payload: state?.isSelectedCustomStreet,
          });
          dispatch({
            type: IFLHouseActionTypes.SetIsSelectedCustomHouse,
            payload: state?.isSelectedCustomHouse,
          });
          dispatch({
            type: IFLHouseActionTypes.SetIsEmptyStreetSuggestions,
            payload: state?.isEmptyStreetSuggestions,
          });
          dispatch({
            type: IFLHouseActionTypes.SetIsEmptyHouseSuggestions,
            payload: state?.isEmptyHouseSuggestions,
          });
          dispatch({
            type: IFLHouseActionTypes.SetIsHouseNotFound,
            payload: state?.isHouseNotFound,
          });
        } else {
          cleartDraft();
        }
      }
      setIsWait(false);
    } else if (!checkDraft) {
      setIsWait(false);
    }
  }, [checkDraft, selectedProduct, productIsLoading, resProduct, hasProfile]);

  if (!hasProfile || productIsLoading || isWait || shouldNavigateToVip)
    return <Skeleton />;

  if (productError) {
    return <GlobalErrorInfo retryHandler={productRefetch} />;
  }

  return (
    <BaseLayout>
      <IFLHouseForm />
    </BaseLayout>
  );
};
