export const useScrollToErrorElement = (errors: any) => {
  let index = null;
  const SCROLL_ADJUST = 50;
  const keys = Object.keys(errors);
  for (let i = 0; i <= keys.length; i++) {
    const key = keys[i];
    const fields = errors[key];

    if (Array.isArray(fields)) {
      index = fields.findIndex((item) => item);
      break;
    }
  }
  const element: HTMLElement | null = document.querySelector(
    `[data-fieldindex="${index}"]`
  );

  if (!element) return;

  if (window !== window.parent) {
    window.parent.postMessage(
      {
        action: 'scrollToElement',
        elementOffsetTop: element.offsetTop - SCROLL_ADJUST,
      },
      '*'
    );
  } else {
    element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
};
