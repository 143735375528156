import { useContext } from 'react';

import { Store, WizardActionTypes } from '@src/store';
import { initWizardStoreState } from '@src/store/wizard';

export const useClearWizardStore = () => {
  const { dispatch } = useContext(Store);

  return () => {
    dispatch({
      type: WizardActionTypes.UpdateState,
      payload: initWizardStoreState,
    });
  };
};
