/* eslint-disable indent */
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

const sendEvent = (event: string, presetEvent: string, isPreset: boolean) => {
  isPreset ? sendAnalyticEvent(presetEvent) : sendAnalyticEvent(event);
};

export const handleSignupComplete = (product: Product, isPreset: boolean) => {
  switch (product) {
    case Product.APARTMENT:
      sendAnalyticEvent(analyticEvents.authFlatSignupCompleted);
      break;
    case Product.HOUSE:
      sendEvent(
        analyticEvents.authHouseSignupCompleted,
        analyticEvents.authHouseSignupCompletedPreset,
        isPreset
      );
      break;
    case Product.NS:
      sendAnalyticEvent(analyticEvents.authNSSignupCompleted);
      break;
    case Product.PETS:
      sendEvent(
        analyticEvents.authPetSignupCompleted,
        analyticEvents.authPetSignupCompletedPreset,
        isPreset
      );
      break;
    case Product.SPORT:
      sendAnalyticEvent(analyticEvents.authSportSignupCompleted);
      break;
    case Product.MITE:
      sendAnalyticEvent(analyticEvents.authMiteSignupCompleted);
      break;
    case Product.PETS_TELEVET_PLUS:
      sendAnalyticEvent(analyticEvents.petTelSignupCompleted);
      break;
    default:
      break;
  }
};
