import { FC } from 'react';

import type { OrderItemType } from '@src/types';
import { addTestAttribute } from '@src/utils';

import {
  OrderItemStatusValue,
  OrderItemSubValue,
  OrderItemTitle,
  OrderItemValue,
  OrderItemValueWrapper,
  OrderItemWrapper,
} from './components.styles';

export const OrderItem: FC<OrderItemType> = ({
  isColsIdentical,
  title,
  value,
  secondsValue,
  successValue,
  failedValue,
  testId = 'orderItem',
  showSeparator,
}) => (
  <OrderItemWrapper showSeparator={showSeparator}>
    <OrderItemTitle
      isColsIdentical={isColsIdentical}
      {...addTestAttribute(`${testId}.title`)}
    >
      {title}
    </OrderItemTitle>
    <OrderItemValueWrapper>
      <OrderItemValue {...addTestAttribute(`${testId}.value`)}>
        {value}
      </OrderItemValue>
      {secondsValue ? (
        <OrderItemSubValue {...addTestAttribute(`${testId}.secondValue`)}>
          {secondsValue}
        </OrderItemSubValue>
      ) : null}
      {successValue ? (
        <OrderItemStatusValue
          error={false}
          {...addTestAttribute(`${testId}.successValue`)}
        >
          {successValue}
        </OrderItemStatusValue>
      ) : failedValue ? (
        <OrderItemStatusValue
          error
          {...addTestAttribute(`${testId}.failedValue`)}
        >
          {failedValue}
        </OrderItemStatusValue>
      ) : null}
    </OrderItemValueWrapper>
  </OrderItemWrapper>
);
