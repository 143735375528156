import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AppContent } from '@src/common-components/app-content';
import {
  RefreshBox,
  ScoringInterceptor,
  ScrollToTop,
  ThemeContainer,
  ThemeInterceptor,
} from '@src/components';
import { AgentController } from '@src/components/agent';
import { WebAnalytic } from '@src/components/web-analytic';
import { Router } from '@src/routes';
import { StoreProvider } from '@src/store';

import './i18n/config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => (
  <StoreProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AgentController />
        <WebAnalytic />
        <ScrollToTop />
        <RefreshBox />
        <ThemeContainer>
          <AppContent>
            <ThemeInterceptor />
            <ScoringInterceptor />
            <Router />
          </AppContent>
        </ThemeContainer>
      </BrowserRouter>
    </QueryClientProvider>
  </StoreProvider>
);

export default App;
