import { Option } from '@pulse-web-ui/select';

import { PetData } from '@src/store/pets';

export const getBreedOptionsByKind = (petsData: PetData[], kind: string) =>
  petsData
    .find((pet) => pet.kind === kind)
    ?.breeds?.reduce(
      (acc: Option[], { breedNames }) => [
        ...acc,
        ...breedNames.map((name) => ({ label: name, value: name })),
      ],
      []
    );
