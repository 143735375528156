/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { DraftStoreState } from './index';
import { DraftActionTypes } from './index';

export type DraftStoreReducer = (
  state: DraftStoreState,
  action: StoreActions
) => DraftStoreState;

export const draftStoreReducer: DraftStoreReducer = (state, action) => {
  switch (action?.type) {
    case DraftActionTypes.SetDraft:
      return {
        ...state,
        draft: action.payload,
      };
    case DraftActionTypes.SetCheckDraft:
      return {
        ...state,
        checkDraft: action.payload,
      };
    case DraftActionTypes.SetFormDraft:
      return {
        ...state,
        formDraft: action.payload,
      };
    default:
      return state;
  }
};
