/* eslint-disable indent */
import type { FC } from 'react';
import { memo, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { useTimer } from '@src/hooks';
import { Store } from '@src/store';
import { addTestAttribute } from '@src/utils';

import {
  Resend as StyledResend,
  ResentText as StyledResendText,
} from './resend.style';
import { ResendProps } from './resend.types';

const Resend: FC<ResendProps> = ({
  expiryTimestamp,
  onResend,
  onAbleResendOtp,
  isExpiryTimestampText,
}) => {
  const {
    state: {
      stateUser: { preset },
    },
  } = useContext(Store);
  const { t } = useTranslation();
  const { seconds, restart } = useTimer({
    expiryTimestamp,
  });

  const handleResend = useCallback(() => {
    onResend(restart);
    if (preset) {
      sendAnalyticEvent(analyticEvents.requestPresetNewOtp);
    } else {
      sendAnalyticEvent(analyticEvents.requestNewOtp);
    }
  }, [onResend, restart]);

  useEffect(() => {
    if (seconds <= 0 && onAbleResendOtp) {
      onAbleResendOtp();
    }
  }, [seconds, onAbleResendOtp]);

  useEffect(() => restart(expiryTimestamp), [expiryTimestamp]);

  const secondsRemaining = (
    <span>{`${t('AUTH:hints.getNewCode')}${
      seconds > 0
        ? ` ${t('AUTH:hints.through')} 0:${
            seconds < 10 ? '0' + seconds : seconds
          }`
        : ''
    }`}</span>
  );

  const handleResetClick = () => {
    if (seconds > 0) {
      return null;
    } else {
      return handleResend();
    }
  };

  return (
    <>
      {isExpiryTimestampText ? (
        <StyledResendText
          seconds={seconds}
          onClick={handleResetClick}
          {...addTestAttribute('otp.button.resendCode')}
        >
          {secondsRemaining}
        </StyledResendText>
      ) : (
        <StyledResend>
          <Button
            variant="secondary"
            onClick={handleResend}
            disabled={seconds > 0}
            {...addTestAttribute('otp.button.resendCode')}
          >
            {secondsRemaining}
          </Button>
        </StyledResend>
      )}
    </>
  );
};

export default memo(Resend);
