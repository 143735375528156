import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Store, UserActionTypes } from '@src/store';

//TODO: Проверка на нахождения анкет в iframe в проекте sd-private-office
export const useIsPrivateOffice = () => {
  const [searchParams] = useSearchParams();

  const privateOffice = searchParams.get('private_office');

  const { dispatch } = useContext(Store);

  useEffect(() => {
    if (privateOffice) {
      dispatch({ type: UserActionTypes.SetPrivateOffice, payload: true });
    }
  }, [privateOffice]);
};
