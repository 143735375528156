import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRequest } from '@src/hooks';
import { AuthActionTypes, Store } from '@src/store';
import { phoneDisplayValueCasting } from '@src/utils';

interface Params {
  phone?: string;
  authenticationHandler?: () => void;
  authenticationErrorHandler?: (message: string) => void;
}

export const useAuthentication = ({
  phone,
  authenticationHandler,
  authenticationErrorHandler,
}: Params) => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { agentLogin },
    },
    dispatch,
  } = useContext(Store);

  const { isLoading, error, res, refetch } = useRequest(
    'identificationRequest',
    'post',
    '/v1/user/authentication',
    {
      authenticationUser: {
        phoneNumber: phone,
      },
      ...(agentLogin && { agentLogin }),
    },
    [phone, agentLogin],
    true
  );

  useEffect(() => {
    if (!isLoading && res) {
      authenticationHandler && authenticationHandler();

      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: res?.confirmationId,
      });
      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: res?.authenticationToken,
      });

      if (phone) {
        dispatch({
          type: AuthActionTypes.SetPhoneNumber,
          payload: phone,
        });
        dispatch({
          type: AuthActionTypes.SetDisplayPhoneNumber,
          payload: phoneDisplayValueCasting(phone!),
        });
      }
    }
  }, [
    res?.confirmationId,
    res?.authenticationToken,
    isLoading,
    phone,
    authenticationHandler,
  ]);

  useEffect(() => {
    if (error) {
      const isRateLimitExceededError =
        error?.response?.data?.code === 'RATE_LIMIT_EXCEEDED' &&
        error?.response?.data?.unlockingTime;

      const msg = !!error
        ? isRateLimitExceededError
          ? t('AUTH:errors.authorizationLimitExceeded')
          : t('COMMON:errors.sendingErrorTryAgain')
        : '';

      authenticationErrorHandler && authenticationErrorHandler(msg);

      if (isRateLimitExceededError) {
        dispatch({
          type: AuthActionTypes.SetConfirmationId,
          payload: undefined,
        });

        dispatch({
          type: AuthActionTypes.SetAuthenticationToken,
          payload: undefined,
        });
      }
    }
  }, [error, authenticationErrorHandler]);

  return { refetch, isLoading };
};
